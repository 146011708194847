import React from 'react';
import styled from 'styled-components';

const ContainerBox = styled.div`
  display: flex;
`;

const MediaLeft = styled.div`
  padding: 1rem;
`;

const MediaContent = styled.div`
  padding: 1rem 1rem 1rem 0;
  width: 100%;
  text-align: left;
`;

const MediaActions = styled.div``;

type MediaProps = {
  image?: React.ReactNode;
  actions?: React.ReactNode;
  style?: React.CSSProperties;
};

export const Media: React.FC<MediaProps> = ({
  children,
  image,
  actions,
  style,
}) => {
  return (
    <ContainerBox style={style}>
      {image && <MediaLeft>{image}</MediaLeft>}
      <MediaContent>{children}</MediaContent>
      {actions && <MediaActions>{actions}</MediaActions>}
    </ContainerBox>
  );
};
