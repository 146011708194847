import styled from 'styled-components';
import { Meta } from './Meta';
import { ShadowBox } from './ui/Box';

export const GreenShell = ({ heading, children }) => (
  <PageContainer>
    <Meta>
      <title>{heading} | MakeSoil</title>
    </Meta>
    <Container>
      <ShadowBox style={{ display: 'flex', flexDirection: 'column' }}>
        <Header>
          <h3>{heading}</h3>
        </Header>
        {children}
      </ShadowBox>
    </Container>
  </PageContainer>
);

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;

  background-color: ${({ theme }) => theme.colors.primary};
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #45b139 230px
    ),
    repeating-linear-gradient(${({ theme }) => theme.colors.primary}, #328929);

  a {
    font-weight: 500;
    text-decoration: underline;
  }
`;

const Container = styled.div`
  width: 90vw;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: clamp(25rem, 30vw, 550px);
  }

  margin: 0 auto;

  line-height: 1.25;

  h3 {
    margin: 0;
  }
`;

const Header = styled.div`
  padding: 1rem;

  background-color: #f7f7f7;
  border-bottom: 1px solid #eaeaea;
`;

export const Content = styled.div`
  flex: 1;
  padding: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  padding: 1rem;

  background-color: #f7f7f7;
  border-top: 1px solid #eaeaea;
`;
