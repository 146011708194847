import { useEffect, useState } from 'react';
import { QR, QROptions } from '../utils/qr';

type QrCodeProps = {
  text: string;
  options?: QROptions;
};

export const QrCode = ({
  text,
  options = {
    width: 250,
    margin: 2,
  },
}: QrCodeProps) => {
  const imageUrl = useQrCode(text, options);

  return (
    <img
      src={imageUrl}
      alt={text}
      style={{ border: '1px solid #ebebeb', height: `${options.width}px` }}
    />
  );
};

function useQrCode(text: string, options: QROptions) {
  const [dataUrl, setDataUrl] = useState(text);

  useEffect(() => {
    (async function generateQrCode() {
      const qrImageUrl = await QR.generate(text, options);
      setDataUrl(qrImageUrl);
    })();
  }, [options, text]);

  return dataUrl;
}
