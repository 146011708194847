import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Collapsible,
  ErrorComponent,
  Loader,
  SmartImage,
} from '../../components/ui';
import { Card } from './Card';
import { generateSiteIdentifier } from '../../utils/url';
import accessrequestService from '../site/accessrequestService';
import { TimeAgo } from '../../components/ui/TimeAgo';
import { Placeholder } from './Placeholder';
import { pluralize } from '../../utils/helpers';
import { DropOffScore } from './SiteCard';

const GET_MY_ACCESS_REQUESTS = gql`
  query usersRequests {
    usersRequests {
      id
      siteId
      createdAt
      siteName
      picture
      active
      dropOffs
    }
  }
`;

export const MyAccessRequests = () => {
  const { loading, error, data } = useQuery(GET_MY_ACCESS_REQUESTS, {
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;
  if (error) return <ErrorComponent error={error} />;

  const accessRequestCount = data.usersRequests.length;

  if (!accessRequestCount) return null;

  const header = `You are waiting for approval to join ${accessRequestCount} soil ${pluralize(
    accessRequestCount,
    'site'
  )}`;

  const handleCancelRequest = async (requestId: number) => {
    const { data } = await accessrequestService.cancelAccessRequest(
      requestId,
      (cache) => {
        const { usersRequests: cachedRequests } = cache.readQuery({
          query: GET_MY_ACCESS_REQUESTS,
        }) as any;

        // remove the access request from the cache
        cache.writeQuery({
          query: GET_MY_ACCESS_REQUESTS,
          data: {
            usersRequests: cachedRequests.filter((ar) => ar.id !== requestId),
          },
        });
      }
    );
    toast.success(data.cancelRequest.message);
  };

  return (
    <Collapsible label={header}>
      <CardListContainer>
        {data.usersRequests.map(
          ({
            id,
            createdAt,
            siteId,
            siteName,
            picture,
            active,
            dropOffs,
          }: any) => {
            const mapSiteUrl = `/map/site/${generateSiteIdentifier(
              siteId,
              siteName
            )}`;
            return (
              <Card
                key={id}
                image={
                  <Link
                    to={mapSiteUrl}
                    style={!active ? { pointerEvents: 'none' } : {}}
                  >
                    <SiteImage
                      src={picture}
                      width={400}
                      type="site"
                      asBackground
                      deactivated={!active}
                    >
                      {!active && (
                        <div>
                          <span>DEACTIVATED</span>
                        </div>
                      )}
                    </SiteImage>
                  </Link>
                }
                content={
                  <>
                    <h4>{siteName}</h4>
                    <p>
                      You requested access <TimeAgo date={createdAt} />
                    </p>
                  </>
                }
                actions={
                  <>
                    <Link
                      to="#"
                      className="outlined"
                      onClick={() => handleCancelRequest(id)}
                    >
                      Cancel Request
                    </Link>
                    <Link to={mapSiteUrl}>View Site</Link>
                  </>
                }
                footer={
                  <>
                    <DropOffScore dropOffs={dropOffs} />
                  </>
                }
              />
            );
          }
        )}
        <Placeholder type="Supporter" first={false} />
      </CardListContainer>
    </Collapsible>
  );
};

const CardListContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    > div {
      width: 100%;
    }
  }
`;

const SiteImage = styled(SmartImage)<{ deactivated?: boolean }>`
  border-radius: 4px 4px 0 0;
  position: relative;

  ${({ deactivated }) =>
    deactivated &&
    `
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);

			font-size: 1.75rem;
			font-style: italic;
			color: #fff;
			text-shadow: 0 0 5px #000;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba(0, 0, 0, 0.5);
				height: 100%;
				width: 100%;

				position: absolute;
        bottom: 0;
			}
		`}
`;
