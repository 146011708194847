import React from 'react';
import { StaticPage } from '../../components/StaticPage';

const TERMS_URL =
  'https://raw.githubusercontent.com/MakeSoil/public-pages/master/terms.md';

const Terms = () => (
  <StaticPage markdownURL={TERMS_URL} pageTitle="Terms of Use" />
);

export default Terms;
