import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IDrawerSiteItem } from '../model';
import {
  getSoilMakers,
  getSupporters,
  isMember,
  isSoilMaker,
  isSoilSupporter,
} from '../../../utils/site';
import { Flex, Button, SmartImage } from '../../../components/ui';
import { Badge } from '../../../components/ui/Badge';
import { generateSiteIdentifier } from '../../../utils/url';
import { useAppDispatch } from '../../../app/hooks';
import {
  additionalContactInfoRequired,
  firstNameRequired,
} from '../../auth/utils';
import { cancelAccessRequest } from '../mapSlice';
import { ShareButton } from '../../../components/ui/ShareButton';
import { SiteOnboardAction } from '../../onboarding/model';
import { TimeAgo } from '../../../components/ui/TimeAgo';
import { useAuthUser } from '../../auth/useAuthUser';
import { useThemeContext } from '../../../app/useThemeContext';

type SiteDetailsProps = {
  site: IDrawerSiteItem;
};

export const SiteDetails: React.FC<SiteDetailsProps> = ({ site }) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const authUser = useAuthUser();
  const theme = useThemeContext();

  const {
    id: siteId,
    name: siteName,
    picture,
    description,
    requiresApproval,
    accessRequests,
    access,
    dropOff,
    hours,
    accepted,
    prohibited,
    members,
    isUnlisted,
    createdAt,
  } = site;
  const { id: userId, isLoggedIn, verified } = authUser;

  const isMaker = isSoilMaker(members, userId);
  const isSupporter = isSoilSupporter(members, userId);
  const isParticipant = isMember(members, userId);

  // priviledged info only for (authenticated) members
  const showPriviledgedInfo = isParticipant;

  const soilMakers = getSoilMakers(members).length;
  const soilSupporters = getSupporters(members).length;

  const pendingAccessRequest = accessRequests.find(
    (ar) => ar.userId === userId
  );

  const cancelRequest = async (requestId: number) => {
    const result = await dispatch(cancelAccessRequest(requestId)).unwrap();
    toast.success(result.message);
  };

  const renderActionButton = () => {
    if ((isMaker || isSupporter) && isLoggedIn) {
      const text = isMaker ? 'MANAGE SOIL SITE' : 'ENTER SOIL SITE';
      return (
        <Link to={`/my-soil-sites/${generateSiteIdentifier(siteId, siteName)}`}>
          <ActionButton round={false}>{text}</ActionButton>
        </Link>
      );
    }

    if (pendingAccessRequest) {
      return (
        <ActionButton
          round={false}
          onClick={() => cancelRequest(pendingAccessRequest.id)}
        >
          CANCEL REQUEST
        </ActionButton>
      );
    }

    const handleSiteOnboardAction = (action: SiteOnboardAction) => {
      const state = {
        action,
        site: { siteId, siteName },
      };

      if (!isLoggedIn) {
        return history.push(`/signup`, state);
      }
      if (!verified) {
        return history.push(`/verify`, state);
      }

      if (firstNameRequired(authUser, action)) {
        return history.push(`/onboarding/your-name`, state);
      } else if (additionalContactInfoRequired(authUser, action)) {
        return history.push(`/onboarding/verify-contact-info`, state);
      } else if (!authUser.hasAddress) {
        return history.push(`/onboarding/your-location`, state);
      } else {
        return history.push(`/onboarding/stay-safe`, state);
      }
    };

    const canJoin = !requiresApproval && !isUnlisted;
    const action = canJoin ? 'join' : 'request';

    return (
      <ActionButton
        round={false}
        onClick={() => handleSiteOnboardAction(action)}
      >
        JOIN SOIL SITE
      </ActionButton>
    );
  };

  return (
    <Fragment>
      <SiteDetailsContainer>
        <SiteInfoHeader>
          <SiteImageContainer>
            <SiteImage src={picture} type="site" width={400} />
          </SiteImageContainer>

          <StyledShareButton url={window.location.href} />

          <SiteImageOverlay>
            <SiteName>
              <h3>{siteName}</h3>
            </SiteName>
            <SitePrivacy>
              <FontAwesomeIcon icon={'seedling'} />
              <h5>
                Registered <TimeAgo date={createdAt} />
              </h5>
            </SitePrivacy>
          </SiteImageOverlay>
        </SiteInfoHeader>

        {renderActionButton()}

        <SiteInfoContent>
          <Details>
            <Info>
              <FontAwesomeIcon
                color={theme.colors.primary}
                icon="info-circle"
              />
              <Flex direction="column">
                <h5>About Soil Site:</h5>
                <p>{description.trim()}</p>
              </Flex>
            </Info>

            <Info>
              <FontAwesomeIcon color={theme.colors.primary} icon="users" />
              <Flex direction="column">
                <h5>Participants:</h5>
                <small style={{ marginTop: '0.5rem' }}>
                  Soil Makers: <strong>{soilMakers}</strong>
                </small>
                <small style={{ marginTop: '0.25rem' }}>
                  Soil Supporters: <strong>{soilSupporters}</strong>
                </small>
              </Flex>
            </Info>

            {showPriviledgedInfo && (
              <Fragment>
                <Info>
                  <FontAwesomeIcon color={theme.colors.primary} icon="clock" />
                  <Flex direction="column">
                    <h5>Opening Times:</h5>
                    <p>{hours}</p>
                  </Flex>
                </Info>

                <Info>
                  <FontAwesomeIcon color={theme.colors.primary} icon="key" />
                  <Flex direction="column">
                    <h5>
                      {dropOff
                        ? `Access Information`
                        : `Access & Drop-off Instructions`}
                      :
                    </h5>
                    <p>{access}</p>
                  </Flex>
                </Info>

                {dropOff && (
                  <Info>
                    <FontAwesomeIcon
                      color={theme.colors.primary}
                      icon="trash-alt"
                    />
                    <Flex direction="column">
                      <h5>Drop-off Instructions:</h5>
                      <p>{dropOff}</p>
                    </Flex>
                  </Info>
                )}
              </Fragment>
            )}
          </Details>
          <SiteMaterials>
            <Info>
              <FontAwesomeIcon color={theme.colors.primary} icon="check" />
              <Flex direction="column">
                <h5>Accepts:</h5>
                <div>
                  {accepted.map((a: any) => (
                    <Tag className="accepted" key={a}>
                      {a}
                    </Tag>
                  ))}
                </div>
              </Flex>
            </Info>

            <Info>
              <FontAwesomeIcon color={theme.colors.error} icon="ban" />
              <Flex direction="column">
                <h5>Does NOT Accept:</h5>
                <div>
                  {prohibited.map((p: any) => (
                    <Tag className="prohibited" key={p}>
                      {p}
                    </Tag>
                  ))}
                </div>
              </Flex>
            </Info>
          </SiteMaterials>
        </SiteInfoContent>
        {renderActionButton()}
      </SiteDetailsContainer>
    </Fragment>
  );
};

const StyledShareButton = styled(ShareButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 8px;

  background-color: rgba(0, 0, 0, 0.5);
  color: #e8e8e8;

  :hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
  }

  transition: all 0.25s ease;
`;

const ActionButton = styled(Button)`
  width: 100%;
  padding: 0.25rem 1rem;
`;

const SiteDetailsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  overflow-y: auto;
  border-radius: 0 0 4px 4px;
`;

const SiteImageContainer = styled.div`
  position: relative;
  height: 300px;
`;

const SiteImage = styled(SmartImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SiteImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.75rem 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #e8e8e8;
  width: 100%;

  z-index: 5;
`;

const SiteInfoHeader = styled.div`
  position: relative;

  h3,
  h4,
  h5 {
    margin: 0;
    font-weight: 400;
  }
`;

const SiteName = styled.div`
  svg {
    margin-right: 0.25rem;
  }
`;

const SitePrivacy = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;

  svg {
    margin-right: 0.25rem;
  }
`;

// const ContactLink = styled.span`
//   margin-left: 0.5rem;
//   cursor: pointer;

//   :hover {
//     text-decoration: underline;
//   }
// `;

const SiteInfoContent = styled.div`
  // background: #f7f7f7;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-wrap: wrap;

  > div {
    min-width: 33%;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: calc(400px * 999 - 100% * 999);
  }
`;

const Details = styled.div`
  padding: 0 1rem;

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 1rem;
    flex: 1;
  }
`;

const SiteMaterials = styled.div`
  padding: 0 1rem 1rem;
`;

const Info = styled.div`
  display: flex;
  margin-top: 1rem;

  h5 {
    margin: 0;
  }

  i,
  svg {
    // color: ${(props) => props.theme.colors.primary};
    min-width: 1.25rem;
    margin-right: 0.35rem;
  }

  p {
    margin: 0.5rem 0;
    color: #7b7b7b;
    font-size: 0.875rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  small {
    color: #7b7b7b;
  }
`;

const Tag = styled(Badge)`
  margin: 0.5rem 0.5rem 0 0;

  &.accepted {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
  &.prohibited {
    border: 1px solid ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.error};
  }
`;
