import { apolloClient } from '../../app/apolloClient';
import { convertThreadId } from '../../utils/url';
import {
  GET_THREADS,
  GET_THREAD_MESSAGES,
  GET_UNREAD_MESSAGE_COUNT,
  MARK_READ,
  MESSENGER_SUBSCRIPTION,
  SEND_MESSAGE,
  START_NEW_THREAD,
} from './graphql';
import { IMessageInput } from './model';

class NotificationCenterService {
  subscribeToMessages = (userId) => {
    return apolloClient.subscribe({
      query: MESSENGER_SUBSCRIPTION,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };

  getUnreadMessageCount = () => {
    return apolloClient.query({
      query: GET_UNREAD_MESSAGE_COUNT,
      fetchPolicy: 'no-cache',
    });
  };

  getThreads = () => {
    return apolloClient.query({
      query: GET_THREADS,
      fetchPolicy: 'no-cache',
    });
  };

  getThreadMessages = (
    threadId: string | number,
    cursor: Date | null = null
  ) => {
    let directMessageThreadId = convertThreadId(threadId);

    return apolloClient.query({
      query: GET_THREAD_MESSAGES,
      variables: {
        directMessageThreadId,
        cursor,
      },
      fetchPolicy: 'no-cache',
    });
  };

  sendMessage = (message: IMessageInput, threadId: number) => {
    return apolloClient.mutate({
      mutation: SEND_MESSAGE,
      variables: {
        message,
        directMessageThreadId: threadId,
      },
      fetchPolicy: 'no-cache',
    });
  };

  startThread = (
    message: IMessageInput,
    participants: { id: number; isAnonymous: boolean }[]
  ) => {
    return apolloClient.mutate({
      mutation: START_NEW_THREAD,
      variables: {
        message,
        participants,
      },
      fetchPolicy: 'no-cache',
    });
  };

  markRead = (directMessageThreadId: number) => {
    return apolloClient.mutate({
      mutation: MARK_READ,
      variables: {
        directMessageThreadId,
      },
      fetchPolicy: 'no-cache',
    });
  };
}

export default new NotificationCenterService();
