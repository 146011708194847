import styled from 'styled-components';

const HIDE_SCROLLBAR = `
  ::-webkit-scrollbar { 
    display: none; 
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
`;

export const ScrollContainer = styled.div<{ hideScrollbar?: boolean }>`
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${(props) => props.hideScrollbar && HIDE_SCROLLBAR}
`;
