import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import { Meta } from '../components/Meta';
import { SiteList } from '../features/mySoilSites/SiteList';

const MySoilSites = ({ history }) => {
  const handlers = useSwipeable({
    delta: 30,
    onSwiped: (e) => {
      if (e.dir === 'Right') history.goBack();
    },
  });

  return (
    <PageContainer {...handlers}>
      <Meta>
        <title>My Soil Sites | MakeSoil</title>
      </Meta>
      <SiteList />
    </PageContainer>
  );
};

export default MySoilSites;

const PageContainer = styled.div`
  height: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: calc(100% - 64px);
  }
`;
