import React from 'react';
import styled from 'styled-components';

import { Participants } from './Participants';
import { Flex } from '../../../components/ui';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { queries } from '../../../app/theme';
import { SiteMember, IAccessRequest } from '../model';
import { ParticipationMap } from './ParticipationMap';

export type ParticipationProps = {
  onGetMoreSupporters: () => void;
  onPromoteToSoilMaker: (member: SiteMember) => void;
  onDemoteSoilMaker: (member: SiteMember) => void;
  onTransferOwnership: (member: SiteMember) => void;
  onRemoveMember: (member: SiteMember, reason?: string) => void;
  onReviewAccessRequest: (accessRequest: IAccessRequest) => void;
};

export const Participation: React.FC<ParticipationProps> = ({
  onGetMoreSupporters,
  onPromoteToSoilMaker,
  onDemoteSoilMaker,
  onTransferOwnership,
  onRemoveMember,
  onReviewAccessRequest,
}) => {
  const isMobile = useMediaQuery(queries.sm);

  return (
    <Wrapper direction="column">
      {!isMobile && <ParticipationMap />}

      <Participants
        onGetMoreSupporters={onGetMoreSupporters}
        onPromoteToSoilMaker={onPromoteToSoilMaker}
        onDemoteSoilMaker={onDemoteSoilMaker}
        onTransferOwnership={onTransferOwnership}
        onRemoveMember={onRemoveMember}
        onReviewAccessRequest={onReviewAccessRequest}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  height: 100%;
  overflow: auto;
`;
