import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import { Meta } from '../../components/Meta';

import {
  Button,
  Flex,
  FormInputError,
  Input,
  Modal,
  TextArea,
} from '../../components/ui';
import { ShadowBox } from '../../components/ui/Box';
import { Container } from '../../components/ui/Container';
import { FormContainer, FormControl } from '../../components/ui/forms/Forms';
import { LightBox } from '../../components/ui/LightBox';
import { MediaModal } from '../../components/ui/MediaModal';
import { YTPlayer } from '../../components/ui/YTPlayer';
import { isEmail, validationMessages } from '../../utils/formValidation';
import { trimFormValues } from '../../utils/helpers';
import { BouncingArrow } from '../../components/ui/BouncingArrow';
import { theme } from '../../app/theme';

const Organics = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  return (
    <PageContainer>
      <Meta>
        <title>Organics | MakeSoil</title>
      </Meta>
      <MediaModal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        ytPlayer={<YTPlayer videoId="1JJnRGuUxXM" autoplay={true} />}
      />
      <ContactModal
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
      />
      <Hero backgroundColor="#5AB474">
        <Flex direction="column">
          {/* <Logo size="l" inverted /> */}
          <h1 style={{ textAlign: 'center', lineHeight: '1.5' }}>
            The world's leading platform for <br />
            <strong>DISTRIBUTED ORGANICS RECYCLING</strong>
          </h1>
          <Flex
            justify="space-between"
            gap="3rem"
            collapse="md"
            style={{ marginTop: '1rem' }}
          >
            <VideoWrapper align="center" justify="center">
              <div>
                <WatchTheVideoTitle>
                  Watch this video to see how it works
                </WatchTheVideoTitle>
                <VideoEmbed onClick={() => setVideoModalOpen(true)} />
              </div>
            </VideoWrapper>

            <Flex
              direction="column"
              justify="center"
              gap="1.5rem"
              style={{ fontSize: '1.25rem' }}
            >
              <Flex gap="1.5rem" align="center">
                <FontAwesomeIcon icon="globe-americas" size="2x" fixedWidth />
                <div>
                  The most{' '}
                  <strong>
                    cost-effective,
                    <br /> planet-friendly
                  </strong>{' '}
                  solution.
                </div>
              </Flex>
              <Flex gap="1.5rem" align="center">
                <FontAwesomeIcon icon="mobile-alt" size="2x" fixedWidth />
                <div>
                  Available to <strong>every citizen</strong>
                  <br /> on their computer or phone.
                </div>
              </Flex>
            </Flex>
          </Flex>

          <BouncingArrow icon="arrow-alt-circle-down" size="3x" />
        </Flex>
      </Hero>

      <Section backgroundColor="#fbfffb">
        <h1 style={{ textAlign: 'center', marginTop: 0, fontSize: '2rem' }}>
          WHY USE MAKESOIL?
        </h1>

        <Centered style={{ marginTop: 0 }}>
          <Flex direction="column" gap="3rem">
            <Flex align="center" gap="3rem" collapse="sm">
              <FontAwesomeIcon
                icon="exclamation-triangle"
                size="5x"
                color={theme.colors.error}
              />

              <ul>
                <li>
                  <strong>Curbside composting programs</strong> are expensive
                  and complicated to implement.
                </li>
                <li>
                  Industrial composting facilities are increasingly opposed by
                  local residents.
                </li>
                <li>
                  And emissions from all the trucks and machinery, cancel out
                  much of the environmental benefit.
                </li>
              </ul>
            </Flex>

            <Flex align="center" gap="3rem" collapse="sm">
              <FontAwesomeIcon
                icon="heart"
                size="5x"
                color={theme.colors.primary}
              />

              <ul>
                <li>
                  <strong>MakeSoil</strong> is a globally-popular,
                  community-based solution.
                </li>
                <li>
                  Enabling municipalities to make quick, visible progress on
                  organics recycling now.
                </li>
                <li>
                  With little effort, and for a fraction of the cost of a
                  curbside composting program.
                </li>
                <li>
                  Plus, when organic waste is recycled via community composting,
                  residents also get educated.
                </li>
                <li>
                  This reduces contamination and thus produces higher-quality
                  compost that can be used locally to grow food.
                </li>
              </ul>
            </Flex>
          </Flex>
        </Centered>
      </Section>

      <Section>
        <Container px="2rem">
          <h1 style={{ textAlign: 'center', marginTop: 0, fontSize: '2rem' }}>
            HOW CITIES & COUNTIES CAN USE MAKESOIL
          </h1>

          <Option>
            <img src="/images/organics/link.png" alt="link icon" />

            <div>
              <h1>Link your residents to the MakeSoil app.</h1>
              <h3>Via your website, email newsletter, social media etc</h3>

              <div>
                <p>
                  We can provide you with a customized link and QR code, as well
                  as some suggested copy and images to share MakeSoil with your
                  community.
                </p>
                <p>
                  We also have a range of{' '}
                  <a
                    href="https://www.makesoil.org/posters-and-flyers"
                    target="_blank"
                    rel="noreferrer"
                  >
                    posters and flyers
                  </a>{' '}
                  you can print to use in your offline outreach.
                </p>
              </div>
            </div>
          </Option>

          <Option>
            <img src="/images/organics/bin.png" alt="bin icon" />

            <div>
              <h1>Include MakeSoil in your compost bin program.</h1>

              <div>
                <p>
                  Whether you're distributing compost bins or kitchen countertop
                  containers, getting more of these residents to join MakeSoil
                  delivers you a higher return-on-investment. Because each
                  container becomes more likely to be used, and to be used more
                  effectively. And each compost bin registered on MakeSoil, can
                  serve not just one, but <i>multiple</i> households.
                </p>
                <p>
                  Simply require residents who want a container, to either join
                  or create a “soil site” on MakeSoil, and show you the proof.
                  Then we'll take care of the rest.
                </p>
              </div>
            </div>
          </Option>

          <Option>
            <img src="/images/organics/soilsite.png" alt="soil site icon" />

            <div>
              <h1>Register municipal drop-off sites on MakeSoil.</h1>

              <div>
                <p>
                  If you run your own organic waste drop-off sites, listing and
                  running them via MakeSoil gives you better visibility and
                  control over how residents participate.
                </p>
                <p>
                  This reduces contamination, and improves communication and
                  education. It also inspires more residents to start their own
                  soil sites.
                </p>
              </div>
            </div>
          </Option>

          <Option>
            <img
              src="/images/organics/notification.png"
              alt="notification bell icon"
            />

            <div>
              <h1>Communicate with your residents.</h1>
              <h3>Via in-app notifications and email.</h3>

              <div>
                <p>
                  Want to share news and opportunities with your residents
                  participating with MakeSoil? You can send alerts and messages
                  via MakeSoil, informing them about your programs and
                  activities.
                </p>
                <p>
                  And these communications can be segmented for particular
                  subsets of MakeSoil users. For example, for Soil Makers (who
                  host soil sites), or for Soil Supporters (who contribute
                  scraps).
                </p>
              </div>
            </div>
          </Option>

          <Option>
            <img src="/images/organics/trophy.png" alt="trophy icon" />

            <div>
              <h1>Track your success with impact data.</h1>

              <div>
                <p>
                  We can provide you with impact data (see sample report below)
                  that shows the level of participation and impact in your
                  community, in terms of organic waste diverted, GHG emissions
                  prevented, money saved etc.
                </p>
                <p>
                  This can be used internally to track your progress, or also
                  shared with other stakeholders to encourage and inspire even
                  greater levels of participation and impact.
                </p>
              </div>
            </div>
          </Option>

          <div
            style={{
              marginTop: '3rem',
            }}
          >
            <LightBox src="/images/organics/sample_impact_report.jpg">
              <ShadowBox>
                <img
                  src="/images/organics/sample_impact_report.jpg"
                  alt="sample impact report"
                  width="100%"
                />
              </ShadowBox>
            </LightBox>
          </div>
        </Container>
      </Section>

      <Section backgroundColor="#56A668" color="#fff">
        <Flex justify="center" align="center">
          <ContactButton onClick={() => setContactModalOpen(true)}>
            TALK WITH US
          </ContactButton>
        </Flex>
      </Section>
    </PageContainer>
  );
};

const VideoEmbed = ({ onClick }) => {
  return (
    <WatchTheVideoWrapper
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <ImageContainer>
        <WatchTheVideoImage
          src="/images/welcomevideothumbnail.jpg"
          srcSet="/images/welcomevideothumbnail.jpg"
        />
        <HoverContainer>
          <VideoHover>
            <PlayButton />
          </VideoHover>
        </HoverContainer>
      </ImageContainer>
    </WatchTheVideoWrapper>
  );
};

const SEND_GOV_MESSAGE = gql`
  mutation SendGovMessage($input: GoveMessageInput!) {
    sendGovMessage(input: $input) {
      errors
      message
    }
  }
`;

type ContactFormData = {
  name: string;
  email: string;
  message: string;
  phoneNum: string;
};

const ContactModal = ({ isOpen, onClose }) => {
  const [sendGovMessage] = useMutation(SEND_GOV_MESSAGE);

  const initialValues = {
    name: '',
    email: '',
    phoneNum: '',
    message: '',
  };

  const validateForm = (values: ContactFormData) => {
    let errors: FormikErrors<ContactFormData> = {};

    const { name, message, email, phoneNum } = values;

    if (!name) {
      errors.name = 'Please provide a name.';
    }
    if (!isEmail(email)) {
      errors.email = validationMessages.email;
    }
    if (!phoneNum) {
      errors.phoneNum = validationMessages.phoneNum;
    }
    if (!message) {
      errors.message = 'Please provide a message.';
    }
    return errors;
  };

  const handleSubmit = async (values: ContactFormData, { setSubmitting }) => {
    values = trimFormValues(values);
    const { data } = await sendGovMessage({ variables: { input: values } });
    setSubmitting(false);
    if (data.sendGovMessage) {
      toast.success(data.sendGovMessage.message);
      onClose();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} header="Talk With Us" hideFooter>
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <FormContainer>
              <Form>
                <FormControl>
                  <Field name="name" as={Input} placeholder="Name" />
                  <ErrorMessage name="name" component={FormInputError} />
                </FormControl>
                <FormControl>
                  <Field name="email" as={Input} placeholder="Email address" />
                  <ErrorMessage name="email" component={FormInputError} />
                </FormControl>
                <FormControl>
                  <Field
                    type="tel"
                    name="phoneNum"
                    as={Input}
                    placeholder="Phone number"
                  />
                  <ErrorMessage name="phoneNum" component={FormInputError} />
                </FormControl>
                <FormControl>
                  <Field as={TextArea} name="message" placeholder="Message" />
                  <ErrorMessage name="message" component={FormInputError} />
                </FormControl>

                <Flex
                  justify="flex-end"
                  gap="1rem"
                  style={{
                    marginTop: '2rem',
                  }}
                >
                  <Button variant="link" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    SEND MESSAGE
                  </Button>
                </Flex>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Organics;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 5rem;
  }

  svg {
    min-width: 90px;
  }

  ul {
    list-style: revert;
  }

  ul > li {
    line-height: 1.5;
    margin-top: 0.5rem;
  }

  p {
    margin-top: 0;
    font-size: 1.175rem;
  }
`;

const Option = styled.div`
  margin-top: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    text-align: left;
    align-items: flex-start;
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 5rem;
  }

  img {
    width: 280px;
    height: 276px;
  }

  // alternating the option content
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    :nth-child(odd) {
      flex-direction: row-reverse;
    }
  }

  h1 {
    margin: 0;
    padding: 0;
  }

  h3 {
    margin-top: 0.5rem;
    font-weight: 400;
    font-style: italic;
  }

  p {
    font-size: 1.175rem;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;

const WatchTheVideoWrapper = styled.div`
  text-align: center;
  margin: auto;
  max-width: 35rem;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const WatchTheVideoTitle = styled.span`
  color: #fff;
  font-style: italic;
  font-size: 1rem;
  text-align: center;
`;

const VideoHover = styled.div`
  background-color: rgb(226, 35, 26);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
`;
const PlayButton = styled.div`
  width: 0;
  height: 0;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;

  border-left: 1.75rem solid white;
`;
const HoverContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover ${VideoHover} {
    background-color: rgb(180, 28, 20);
  }
`;
const ImageContainer = styled.div`
  position: relative;
`;
const WatchTheVideoImage = styled.img`
  display: block;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;
const VideoWrapper = styled(Flex)`
  text-align: center;
`;

const PageContainer = styled.div`
  height: 100%;
  overflow: auto;

  header {
    display: flex;
    justify-content: space-between;
    padding: 2rem;

    svg {
      g {
        fill: #fff;
      }
    }
  }
`;

const Hero = styled.section<{ backgroundColor: string }>`
  padding: 2rem 0 2rem;
  background: url('/images/map.png') center no-repeat;
  background-size: cover;
  background-color: ${(props) => props.backgroundColor};

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 2rem 2rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0 5rem 3rem;
    }

    > div {
      flex: 1;
    }
  }

  color: #fff;
`;

const Section = styled.section<{ backgroundColor?: string; color?: string }>`
  padding: 3rem 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 5rem 3rem;
  }

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  p {
    font-weight: lighter;
  }
`;

const ContactButton = styled.button<{ green?: boolean }>`
  padding: 0.75rem 1.5rem;
  outline: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  :hover {
    color: #56a668;
    background: #fff;
    cursor: pointer;
  }
  ${({ green }) =>
    green &&
    css`
      background-color: #56a568;
      border: none;
      color: #fff;
      :hover {
        background: #498c58;
        color: #fff;
      }
    `}
  transition: all 0.35s ease;
`;
