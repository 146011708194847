import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from './Flex';

interface TagInputProps {
  name: string;
  tabIndex?: number;
  forwardedRef?: RefObject<HTMLInputElement>;
  defaultTags?: string[];
  delimiters?: string[];
  className?: string;
  style?: any;
  placeholder?: string;
  onAdd?: (tag: string) => void;
  onAddMultiple?: (tags: string[]) => void;
  onDelete?: (tag: string) => void;
  validate?: (tag: string) => boolean;
}

export const TagInput: React.FC<TagInputProps> = ({
  name,
  tabIndex,
  forwardedRef,
  placeholder,
  className,
  style,
  defaultTags = [],
  delimiters = ['Enter', 'Comma', 'Space'],
  onAdd,
  onAddMultiple,
  onDelete,
  validate,
}) => {
  const inputRef = forwardedRef || createRef<HTMLInputElement>();

  const [tags, setTags] = useState<string[]>(() => defaultTags);

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  const handleAddTag = (tag: string) => {
    const trimmed = tag.trim();
    if (trimmed) {
      if (validate) {
        if (validate(trimmed)) {
          addTag(trimmed);
        }
      } else {
        addTag(trimmed);
      }
    }
  };

  const handleBlur = (e) => {
    const { value }: { value: string } = e.target;
    if (value !== '') {
      handleAddTag(value);
      e.target.value = '';
    }
  };

  const handlekeyUp = (e) => {
    // prevents the submit action on android
    e.preventDefault();

    const { value }: { value: string } = e.target;

    // on android mobile the "e.code" might sometimes be empty
    // so use e.key if needed
    const key = e.code || e.key;
    if (delimiters.includes(key) && value !== '') {
      let tag = value.trim();
      if (tag && !tags.includes(tag)) {
        if (key === 'Comma') {
          tag = tag.replace(',', '');
        }
        handleAddTag(tag);
        e.target.value = '';
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    e.target.value = '';

    const clipboardData = e.clipboardData;
    const pasted: string = clipboardData.getData('text');

    // we're using Set because Sets enforce unique values
    const newTags: string[] = pasted.split(',').map((e) => e.trim());
    const allTags = [...tags, ...newTags];
    const uniqueTags = new Set(allTags);

    const tagsToAdd = [];
    uniqueTags.forEach((tag: string) => {
      if (!tags.includes(tag)) {
        tagsToAdd.push(tag);
      }
    });

    addTags(Array.from(tagsToAdd));
    return;
  };

  const addTag = (tag: string) => {
    // make sure the list is unique
    if (!tags.includes(tag)) {
      setTags((prev) => [...prev, tag]);
      onAdd && onAdd(tag);
    }
  };

  const addTags = (tags: string[]) => {
    setTags((prev) => [...prev, ...tags]);
    onAddMultiple && onAddMultiple(tags);
  };

  const removeTag = (tag: string) => {
    setTags((prev) => [...prev.filter((t) => t !== tag)]);
    onDelete && onDelete(tag);
  };

  return (
    <TagInputContainer className={className} style={style}>
      <Tags tags={tags} onDelete={removeTag} />
      <Flex>
        <input
          tabIndex={tabIndex}
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          aria-label={placeholder}
          onKeyUp={handlekeyUp}
          onPaste={handlePaste}
          onBlur={handleBlur}
          name={name}
          autoComplete="off"
          // maxLength={maxLength}
          // value={inputValue}
        />
      </Flex>
    </TagInputContainer>
  );
};

interface TagsProps {
  tags: string[];
  className?: string;
  onDelete: (tag: string) => void;
}

const Tags: React.FC<TagsProps> = ({ tags, className, onDelete }) => {
  return (
    <TagsContainer className={className}>
      {tags.map((tag) => (
        <Tag key={tag}>
          <span>{tag}</span>
          <FontAwesomeIcon icon="times" onClick={() => onDelete(tag)} />
        </Tag>
      ))}
    </TagsContainer>
  );
};

const TagInputContainer = styled.div`
  padding: 0.5rem;
  background: #fdfdfd;
  border: 1px solid #cecece;
  border-radius: 4px;

  input {
    width: 100%;
    border: none;
    background: none;

    ::placeholder {
      font-style: normal;
    }

    :focus {
      outline: none;
    }
  }
`;

const TagsContainer = styled.div`
  position: relative;
`;

const Tag = styled.span`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0.5rem 0.5rem 0;
  padding: 0.25rem 0.5rem;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background: #ebebeb;

  font-size: 0.875rem;

  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;
