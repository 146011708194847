import { PaymentIntent } from '@stripe/stripe-js';
import { useEffect, useRef } from 'react';

type PaymentIntentOptions = {
  label?: string;
  currency?: string;
  amount?: number;
  intent?: string;
};

type UpdatePaymentIntentOptions = {
  intentId: string;
  amount: number;
  currency?: string;
};

export function usePaymentIntent() {
  const paymentIntentRef = useRef<PaymentIntent>();

  async function createPaymentIntent(options: PaymentIntentOptions) {
    const res = await fetch('/api/stripe/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });

    const intent = await res.json();

    paymentIntentRef.current = intent;

    return intent as PaymentIntent;
  }

  async function updatePaymentIntent({
    intentId,
    amount,
    currency = 'usd',
  }: UpdatePaymentIntentOptions) {
    const res = await fetch('/api/stripe/update-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ intent: intentId, amount, currency }),
    });

    const intent = await res.json();

    paymentIntentRef.current = intent;

    return intent as PaymentIntent;
  }

  return {
    clientSecret: paymentIntentRef.current?.client_secret,
    createPaymentIntent,
    updatePaymentIntent,
  };
}
