import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from '@react-google-maps/api';

import { mapSettings } from '../map/utils';
import {
  DirectionsContextProvider,
  useDirectionsContext,
} from './DirectionsContext';
import { DirectionsPanel } from './DirectionsPanel';
import { ILocation } from '../../app/model/ILocation';
import { Flex } from '../../components/ui';
import { isMobile } from '../../utils/helpers';

type DirectionsProps = {
  destination: ILocation;
};

const DirectionsInner = ({ destination }: DirectionsProps) => {
  const {
    origin,
    travelMode,
    directions,
    setDirections,
    selectedRouteIndex,
  } = useDirectionsContext();

  const [directionsError, setDirectionsError] = useState(null);

  const mapRef = useRef<google.maps.Map>(null);

  const directionsServiceOptions: google.maps.DirectionsRequest = useMemo(() => {
    return {
      destination: new google.maps.LatLng(destination.lat, destination.lng),
      origin,
      travelMode,
      provideRouteAlternatives: true,
    };
  }, [destination, origin, travelMode]);

  const directionsCallback = useCallback(
    (
      result: google.maps.DirectionsResult,
      status: google.maps.DirectionsStatus
    ) => {
      if (status === 'ZERO_RESULTS') {
        setDirectionsError('Could not provide directions!');
      }

      if (result && status === 'OK') {
        setDirections(result);
        setDirectionsError(null);
      }
    },
    [setDirections]
  );

  const fitBounds = useCallback(() => {
    const selectedRouteBounds = directions.routes[selectedRouteIndex].bounds;
    const padding = !isMobile() ? { left: 325 } : 0;

    mapRef.current?.fitBounds(selectedRouteBounds, padding);
  }, [directions?.routes, selectedRouteIndex]);

  if (directionsError) {
    return (
      <Flex justify="center" align="center" style={{ height: '100%' }}>
        {directionsError}
      </Flex>
    );
  }

  return (
    <MapContainer>
      <DirectionsPanel />

      {origin && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={origin}
          zoom={18}
          options={{
            styles: [
              {
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            restriction: {
              latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              strictBounds: true,
            },

            minZoom: mapSettings.minZoom,
            maxZoom: mapSettings.maxZoom,
          }}
        >
          {destination && (
            <DirectionsService
              options={directionsServiceOptions}
              callback={directionsCallback}
            />
          )}

          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                directions,
                routeIndex: selectedRouteIndex,
                preserveViewport: true,
              }}
              onLoad={(directionsRenderer: google.maps.DirectionsRenderer) => {
                mapRef.current = directionsRenderer.getMap();
                fitBounds();
              }}
              onDirectionsChanged={fitBounds}
              panel={document.getElementById('panel')}
            />
          )}
        </GoogleMap>
      )}
    </MapContainer>
  );
};

export const Directions = ({ destination }: DirectionsProps) => {
  return (
    <DirectionsContextProvider>
      <DirectionsInner destination={destination} />
    </DirectionsContextProvider>
  );
};

const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
