import { FetchResult } from '@apollo/client';
import { apolloClient } from '../../app/apolloClient';
import {
  CHANGE_VERIFICATION_EMAIL_ADDRESS,
  GET_ME,
  LOGIN,
  LOGOUT,
  MAGIC_LOGIN,
  SEND_ACCOUNT_VERIFICATION_CODE,
  SIGNUP,
  UPDATE_CONTACT_INFO,
  VERIFY_ACCOUNT,
} from './graphql';
import { ContactInfo } from './model';

type AuthResponse = {
  accessToken: string;
  errors: any;
};

type SignupResponse = { createUserAccount: AuthResponse };
type LoginResponse = { login: AuthResponse };
type MagicLoginResponse = { loginWithMagicId: AuthResponse };
type VerifyResponse = { verifyAccount: AuthResponse };
type VerificationActionResponse = { errors: string[]; message: string };

type SendAccountVerificationCodeResponse = {
  sendAccountVerificationCode: VerificationActionResponse;
};

type ChangeVerificationEmailAddressResponse = {
  changeVerificationEmailAddress: VerificationActionResponse;
};

export type LoginCredentials = { email: string; password: string };
export type LoginFormData = LoginCredentials;

export type SignupFormData = {
  email: string;
  recaptcha: string;
};

class AuthService {
  getMe = (userId: number) => {
    return apolloClient.query({
      query: GET_ME,
      variables: { userId },
      fetchPolicy: 'network-only',
    });
  };

  signup = (formData: SignupFormData): Promise<FetchResult<SignupResponse>> => {
    return apolloClient.mutate({
      mutation: SIGNUP,
      variables: {
        input: { ...formData },
      },
    });
  };

  login = ({
    email,
    password,
  }: LoginCredentials): Promise<FetchResult<LoginResponse>> => {
    return apolloClient.mutate({
      mutation: LOGIN,
      variables: { email, password },
    });
  };

  loginWithMagicId = (
    magicId: string
  ): Promise<FetchResult<MagicLoginResponse>> => {
    return apolloClient.mutate({
      mutation: MAGIC_LOGIN,
      variables: { magicId },
    });
  };

  logout = () => {
    return apolloClient.mutate({
      mutation: LOGOUT,
    });
  };

  verifyAccount = (code: string): Promise<FetchResult<VerifyResponse>> => {
    return apolloClient.mutate({
      mutation: VERIFY_ACCOUNT,
      variables: { code },
    });
  };

  sendAccountVerificationCode = (): Promise<
    FetchResult<SendAccountVerificationCodeResponse>
  > => {
    return apolloClient.mutate({
      mutation: SEND_ACCOUNT_VERIFICATION_CODE,
    });
  };

  changeVerificationEmailAddress = (
    email: string
  ): Promise<FetchResult<ChangeVerificationEmailAddressResponse>> => {
    return apolloClient.mutate({
      mutation: CHANGE_VERIFICATION_EMAIL_ADDRESS,
      variables: { email },
    });
  };

  updateContactInfo = (info: ContactInfo) => {
    return apolloClient.mutate({
      mutation: UPDATE_CONTACT_INFO,
      variables: { info },
    });
  };
}

/**
 * Talks to the GraphQL Server.
 * Currently using Apollo Client
 */
export default new AuthService();
