import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMessenger } from '../useMessenger';

/**
 * select first thread when user lands on `/messages`
 */
export function useSelectFirstThreadOnInit() {
  const match = useRouteMatch();
  const { threads, selectThread } = useMessenger();

  useEffect(() => {
    const canSelectThread = match.isExact && threads.length && threads[0].id;
    if (canSelectThread) selectThread(threads[0].id);
  }, [match, selectThread, threads]);
}
