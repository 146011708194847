import React from 'react';
import styled from 'styled-components';
import { theme } from '../../app/theme';

export type ColorVariant =
  | 'default'
  | 'primary'
  | 'warning'
  | 'error'
  | 'info'
  | 'brown';

export const BadgeContainer = styled.span<{
  variant: ColorVariant;
}>`
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;

  ${({ variant }) =>
    variant !== 'default' &&
    `
      border: none;
      background-color: ${theme.colors[variant]};
      color: #fff;
    `}
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  variant?: ColorVariant;
  title?: string;
  style?: React.CSSProperties;
};

export const Badge: React.FC<Props> = ({
  children,
  className,
  variant = 'default',
  title,
  style,
}) => {
  return (
    <BadgeContainer
      className={className}
      variant={variant}
      title={title}
      style={style}
    >
      {children}
    </BadgeContainer>
  );
};
