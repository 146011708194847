import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Flex, SmartImage } from '../../../components/ui';
import { timeAgo } from '../../../utils/date';
import { INotification } from '../model/INotification';
import { NotificationCategory } from '../model/NotificationCategory';
import {
  shouldMarkAssociated,
  toggleReadState,
} from '../notificationCentreSlice';

type NotificationToastProps = {
  notification: INotification;
};

export const NotificationToast = ({ notification }: NotificationToastProps) => {
  const {
    id: notificationId,
    text,
    linkUrl,
    imageUrl,
    category,
    createdAt,
  } = notification;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const imageType =
    category === NotificationCategory[NotificationCategory[category]]
      ? 'profile'
      : 'site';

  const handleClick = () => {
    /**
     * For now we have direct message notification with id of 0
     * as we do not create and store those in DB.
     * We just notify online users.
     * This is why we do not toggle read state because there is no notification to 'read'
     */
    const isDirectMessageNotification = notificationId === 0;
    if (!isDirectMessageNotification) {
      /**
       * We want to mark read all soil site message board notifications
       * when just one of them is clicked
       */
      const markReadAssociated = shouldMarkAssociated(notification);
      dispatch(
        toggleReadState({ notificationId, read: true, markReadAssociated })
      );
    }
    if (linkUrl) history.push(linkUrl);
  };

  return (
    <div onClick={handleClick}>
      <Flex gap="0.5rem">
        <SmartImage
          src={imageUrl}
          type={imageType}
          width={40}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
          }}
        />

        <Flex direction="column" justify="space-between">
          <div className="text">{text}</div>
          <div className="timeAgo">
            <small>{timeAgo(createdAt)}</small>
          </div>
        </Flex>
      </Flex>
    </div>
  );
};
