import React from 'react';
import styled from 'styled-components';

const TheContainer = styled.div<{ px?: string }>`
  padding: 0 ${({ px }) => px};
`;

interface Containerprops {
  children: React.ReactNode;
  /**
   * Padding on the x axis
   * example: 1rem
   */
  px?: string;
}

export const Container: React.FC<Containerprops> = ({ children, px }) => {
  return <TheContainer px={px}>{children}</TheContainer>;
};
