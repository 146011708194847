import { useEffect } from 'react';
import { fetchUnreadMessageCount } from '../features/messenger/messengerSlice';
import { isMobileApp, mobilePushStart } from '../mobile/mobile';
import { useAuthUser } from '../features/auth/useAuthUser';
import { useAppDispatch } from '../app/hooks';

/**
 * Fetches the unread direct messages count.
 * On mobile app also triggers "push-start" event (app is ready to register for push notifications)
 */
export function useFetchUnreadMessageCount() {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  useEffect(() => {
    if (authUser.isLoggedIn) {
      dispatch(fetchUnreadMessageCount());
      if (isMobileApp()) {
        void mobilePushStart();
      }
    }
  }, [dispatch, authUser]);
}
