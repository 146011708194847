import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { TagInput, TextArea, Button } from '../../../components/ui';
import { isEmail } from '../../../utils/formValidation';
import { useAuthUser } from '../../auth/useAuthUser';
import { isAdmin } from '../../auth/utils';
import { SiteRoleSelector } from './SiteRoleSelector';
import { SEND_INVITATION } from '../graphql';
import { SITE_ROLE } from '../model';

export const InviteByEmail = (props) => {
  const { generalInvite, site } = props;

  const authUser = useAuthUser();
  const showUserTypeSelector = isAdmin(authUser);

  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState('');
  const [siteRole, setSiteRole] = useState(SITE_ROLE.SUPPORTER);

  const invitingTo = !generalInvite ? 'this soil site' : 'MakeSoil';

  const [sendEmailInvitation] = useMutation(SEND_INVITATION);

  const validateEmail = (email: string) => {
    try {
      if (email.trim() === authUser.email) {
        throw new Error("Can't invite yourself.");
      }
      if (isEmail(email)) return true;
    } catch (err) {
      toast.error(err.message);
      return false;
    }
  };

  const addEmail = (email: string) => {
    if (!email || !email.length) return;
    let trimmedEmail = email;
    const foundEmails = trimmedEmail.match(
      /((([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})))/g
    );
    trimmedEmail = foundEmails ? foundEmails[0] : '';
    if (validateEmail(trimmedEmail)) {
      setEmails([...emails, trimmedEmail]);
    }
  };

  const addEmails = (emails: string[]) => {
    if (!emails || !emails.length) return;
    let trimmedEmails = emails;

    const foundEmails = [];
    trimmedEmails.forEach((email, _index) => {
      const found = email.match(
        /((([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})))/g
      );
      if (found && validateEmail(found[0])) {
        foundEmails.push(found[0]);
      }
    });

    setEmails((prev) => [...prev, ...foundEmails]);
  };

  const deleteEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const sendInvitation = async () => {
    if (emails.length < 1) return toast.error('Please enter an e-mail.');
    setEmails([]);
    setMessage('');

    try {
      if (emails && emails.length > 0) {
        const { data } = await sendEmailInvitation({
          variables: {
            emails,
            message,
            siteRole,
            siteId: site.id,
          },
        });

        const { error } = data.sendEmailInvitation;
        if (error) return toast.error(error);
        return toast.success(`Invitation${emails.length > 1 ? 's' : ''} sent!`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormWrapper>
      <TagInput
        name="emails"
        className="selector"
        defaultTags={emails}
        onAdd={(email) => addEmail(email)}
        onAddMultiple={(emails) => addEmails(emails)}
        onDelete={(email) => deleteEmail(email)}
        placeholder="Type or paste one or more email addresses"
        validate={(email) => validateEmail(email)}
      />
      <InviteTextArea
        placeholder={`Write a brief, personal note here to explain why you are inviting them to ${invitingTo} (OPTIONAL)`}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <div>
        {showUserTypeSelector && (
          <SiteRoleSelector
            siteRole={siteRole}
            onSiteRoleChange={(r) => setSiteRole(r)}
          />
        )}
      </div>

      <SendButton
        disabled={emails.length === 0}
        onClick={() => sendInvitation()}
      >
        Send Invitation
      </SendButton>
    </FormWrapper>
  );
};

const FormWrapper = styled.div``;

const InviteTextArea = styled(TextArea)`
  border-color: #cecece;
  color: black;
  margin-top: 1rem;
  padding: 1rem;
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
  :focus {
    color: black;
  }
  resize: vertical;
`;

const SendButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;
