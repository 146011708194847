import React from 'react';
import styled from 'styled-components';

const SelectControl = styled.select`
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: ${({ theme }) => theme.borderRadius}px;

  font-size: 1rem;

  color: #495057;
  outline: none;

  :focus {
    border: 1px solid #a3abb3;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
`;

type SelectProps = {
  style?: React.CSSProperties;
  className?: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const Select: React.FC<SelectProps> = ({ children, ...props }) => {
  return <SelectControl {...props}>{children}</SelectControl>;
};
