import styled from 'styled-components';
import { mobileAppStoreUrls } from '../utils/config';
import { MobileAppBadge } from './MobileBadge';

type Props = {
  badgeStyle?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  className?: string;
};

export const MobileAppBadges = ({
  badgeStyle,
  popupStyle,
  style,
  className,
}: Props) => {
  return (
    <Wrapper style={style} className={className}>
      <MobileAppBadge
        badgeSrc="/images/mobile/appstore-badge.webp"
        device="iPhone"
        qrSrc="/images/mobile/qr-code-ios.svg"
        storeUrl={mobileAppStoreUrls.appleStore}
        badgeStyle={badgeStyle}
        popupStyle={popupStyle}
      />
      <MobileAppBadge
        badgeSrc="/images/mobile/playstore-badge.webp"
        device="Android"
        qrSrc="/images/mobile/qr-code-android.svg"
        storeUrl={mobileAppStoreUrls.googlePlayStore}
        badgeStyle={badgeStyle}
        popupStyle={popupStyle}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 1rem;
`;
