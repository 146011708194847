import styled from 'styled-components';

export const FormError = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.error};
  border-radius: 4px;

  color: ${({ theme }) => theme.colors.error};
  font-size: 0.875rem;
`;

export const FormInputError = styled.span`
  display: block;
  margin-top: 0.5rem;
  text-align: left;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.error};
  white-space: pre-line;
`;
