import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { TextArea, Flex, Input, Avatar, Button } from '../../../components/ui';
import { UserDisplayName } from '../../../components/ui/UserDisplayName';
import { HelpText } from '../../../components/ui/forms/Forms';
import { useSoilSiteContext } from '../SoilSiteContext';
import { getWeightUnit, WeightUnit } from '../../../utils/formatting';
import { theme } from '../../../app/theme';
import { MessageAttachmentPicker } from '../messageboard/MessageAttachmentPicker';
import { FilestackUploadResponse } from '../../../components/ui/FilePicker';
import { MessageAttachmentPreview } from '../messageboard/MessageAttachmentPreview';
import { Attachment } from '../messageboard/model';
import { useAuthUser } from '../../auth/useAuthUser';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useDropOff } from './useDropOff';
import { DropOff, DropOffPayload, EditDropOffPayload } from './model';
import { useMessageBoard } from '../messageboard/useMessageBoard';

type Props = {
  editMode?: boolean;
  useDropOffAs: boolean;
  useWeight: boolean;
  dropOff?: EditDropOffPayload;
  onRegisterDropOff: (payload: DropOffPayload) => void;
  onCancel?: () => void;
};

export function DropOffForm({
  editMode = false,
  useDropOffAs,
  useWeight,
  dropOff,

  onRegisterDropOff,
  onCancel,
}: Props) {
  const authUser = useAuthUser();
  const { soilSite } = useSoilSiteContext();

  const { convertWeightToDisplay, convertWeightBeforeSaving } = useDropOff();

  const [dropOffAuthorId, setDropOffAuthorId] = useState<number>(authUser.id);
  const [comment, setComment] = useState(dropOff?.comment || '');
  const [weight, setWeight] = useState(
    dropOff?.weight
      ? convertWeightToDisplay(dropOff.weight, getWeightUnit().unit).toString()
      : ''
  );
  const [attachments, setAttachments] = useState<Attachment[]>(
    dropOff?.attachments
  );
  const [weightUnit, setWeightUnit] = useLocalStorage<{ unit: WeightUnit }>(
    'weight-unit',
    getWeightUnit()
  );

  const handleSubmit = async () => {
    onRegisterDropOff({
      comment,
      weight: convertWeightBeforeSaving(weight, weightUnit.unit),
      siteId: soilSite.id,
      dropAsUserId: dropOffAuthorId !== authUser.id ? dropOffAuthorId : null,
      attachments,
    });
  };

  const handleParticipantSelected = (userId: number) => {
    setDropOffAuthorId(userId);
  };

  const handleAttachmentsUploaded = (attachments: Attachment[]) => {
    setAttachments(attachments);
  };

  const handleRemoveAttachment = (attachment: Attachment) => {
    setAttachments((curr) =>
      curr.filter((a) => a.handle !== attachment.handle)
    );
  };

  const handleWeightInputChange = (e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    if (parseFloat(value) < 0) return;
    setWeight(value);
  };

  const showWeightInput = useWeight && weightUnit;

  return (
    <>
      {useDropOffAs && (
        <>
          <Flex
            align="center"
            gap="0.5rem"
            justify="space-between"
            style={{ flexWrap: 'wrap' }}
          >
            <p style={{ margin: 0 }}>Who is making this drop-off?</p>
            <ParticipantSelector
              selectedUserId={dropOffAuthorId}
              onParticipantSelected={handleParticipantSelected}
            />
          </Flex>
          <hr />
        </>
      )}

      <p style={{ margin: '0.25rem 0' }}>
        What is being dropped off? (optional)
      </p>
      <HelpText
        style={{
          color: '#7f7f7f',
          margin: 0,
          marginBottom: '0.5rem',
          fontSize: '0.875rem',
        }}
      >
        E.g. Vegetable peelings and coffee grounds.
      </HelpText>
      <TextArea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        rows={3}
      />

      {showWeightInput && (
        <div>
          <p
            style={{
              marginBottom: '0.5rem',
            }}
          >
            How much do the scraps weigh? (optional)
          </p>
          <Flex gap="1rem" align="center">
            <Flex gap="0.25rem" align="center" style={{ flex: 1 }}>
              <Input
                type="number"
                name="weight"
                value={weight}
                pattern="^\d*(\.\d{0,2})?$"
                inputMode="numeric"
                onChange={handleWeightInputChange}
              />
              {weightUnit.unit === 'kilogram' ? 'kg' : 'lb'}
            </Flex>
            <div style={{ flex: 3 }}>
              <WeightUnitSwitch
                weightUnit={weightUnit}
                setWeightUnit={setWeightUnit}
              />
            </div>
          </Flex>
        </div>
      )}

      <PhotoSection
        editMode={editMode}
        attachments={attachments}
        sitePostId={dropOff?.sitePostId}
        onAttachmentsUploaded={handleAttachmentsUploaded}
        onRemoveAttachment={handleRemoveAttachment}
      />

      {/* <DistanceIndicator
            address={soilSite.address}
            siteLocation={soilSite.location}
           /> 
      */}

      <FormFooter>
        {editMode ? (
          <>
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>

            <Button onClick={handleSubmit}>UPDATE DROP-OFF</Button>
          </>
        ) : (
          <>
            <div />
            <Button onClick={handleSubmit}>RECORD DROP-OFF</Button>
          </>
        )}
      </FormFooter>
    </>
  );
}

const FormFooter = styled.div`
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid #eaeaea;

  display: flex;
  justify-content: space-between;
`;

function WeightUnitSwitch({ weightUnit, setWeightUnit }) {
  const switchText =
    weightUnit.unit === 'kilogram' ? 'Switch to lb' : 'Switch to kg';

  const handleSwitchWeightUnit = () => {
    setWeightUnit((prev) => {
      return {
        unit: prev.unit === 'kilogram' ? 'pound' : 'kilogram',
      };
    });
  };

  return (
    <span
      style={{
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.colors.primary,
      }}
      onClick={handleSwitchWeightUnit}
    >
      {switchText}
    </span>
  );
}

function PhotoSection({
  editMode,
  attachments,
  sitePostId,
  onAttachmentsUploaded,
  onRemoveAttachment,
}) {
  const { soilSite } = useSoilSiteContext();
  const { deleteAttachment, storeUploadedAttachments } = useMessageBoard();

  const [filePickerOpen, setFilePickerOpen] = useState(false);

  const handleFileUploadSuccess = (response: FilestackUploadResponse) => {
    const attachments = response.filesUploaded.map((file) => ({
      fileType: file.mimetype,
      fileName: file.filename,
      url: file.url,
      handle: file.handle,
      postId: sitePostId,
    }));

    onAttachmentsUploaded(attachments);

    // store the uploaded attachments so we
    // can track those that need to be removed if user
    // does not post a message
    storeUploadedAttachments([...attachments]);
  };

  const handleRemoveAttachment = async (attachment: Attachment) => {
    await deleteAttachment({
      handle: attachment.handle,
      postId: sitePostId,
    });

    // update the UI
    onRemoveAttachment(attachment);
  };

  return (
    <PhotoSectionContainer>
      <p>Add photos of the drop-off. (optional)</p>
      <Button onClick={() => setFilePickerOpen(true)} padding="0.5rem 0.75rem">
        <FontAwesomeIcon icon="images" style={{ marginRight: '0.175rem' }} />{' '}
        Attach Photo(s)
      </Button>

      <MessageAttachmentPreview
        attachments={attachments}
        onRemoveAttachment={handleRemoveAttachment}
        style={{ marginTop: '1rem' }}
      />

      <MessageAttachmentPicker
        open={filePickerOpen}
        options={{
          storeTo: {
            path: `/site/${soilSite.id}/messageboard/`,
          },
        }}
        onSuccess={handleFileUploadSuccess}
        onClose={() => setFilePickerOpen(false)}
      />
    </PhotoSectionContainer>
  );
}

const PhotoSectionContainer = styled.div`
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #e3e3e3;

  > p {
    margin-top: 0;
  }
`;

function ParticipantSelector({ selectedUserId, onParticipantSelected }) {
  const {
    soilSite: { members },
  } = useSoilSiteContext();

  const [open, setOpen] = useState(false);

  const handleParticipantSelected = (userId: number) => {
    setOpen(false);
    onParticipantSelected(userId);
  };

  const selectedParticipant = members.find((p) => p.userId === selectedUserId);

  return (
    <ParticipantSelectorContainer>
      <Selector onClick={() => setOpen((o) => !o)} role="button">
        {selectedParticipant ? (
          <Flex align="center" gap="0.5rem">
            <Avatar
              user={{
                firstName: selectedParticipant.firstName,
                lastName: selectedParticipant.lastName,
                picture: selectedParticipant.picture,
                id: selectedParticipant.userId,
              }}
              size="sm"
            />

            <Truncate>
              <UserDisplayName
                firstName={selectedParticipant.firstName}
                lastName={selectedParticipant.lastName}
              />
            </Truncate>

            <CancelButton>
              <FontAwesomeIcon icon="caret-down" />
            </CancelButton>
          </Flex>
        ) : (
          <span>Select Participant</span>
        )}
      </Selector>
      <AnimatePresence>
        {open && (
          <DropDown
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ul
              style={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {members.map(({ firstName, lastName, userId, picture }, i) => (
                <Item
                  key={userId}
                  onClick={() => handleParticipantSelected(userId)}
                  active={selectedUserId === userId}
                >
                  <Avatar
                    user={{ firstName, lastName, picture, id: userId }}
                    size="sm"
                  />
                  <Truncate>
                    <UserDisplayName
                      firstName={firstName}
                      lastName={lastName}
                    />
                  </Truncate>
                </Item>
              ))}
            </ul>
          </DropDown>
        )}
      </AnimatePresence>
    </ParticipantSelectorContainer>
  );
}

const ParticipantSelectorContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const Truncate = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Selector = styled.div`
  width: 100%;

  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;

  background-color: #fff;

  &:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  }
`;

const DropDown = styled(motion.div)`
  position: fixed;

  margin-top: 0.5rem;
  width: min-content;
  max-width: 80vw;
  max-height: 70vh;
  overflow-y: hidden;
  z-index: 9999;

  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);

  font-size: 0.875rem;
  h5 {
    margin: 0;
    margin-top: 1rem;
    padding: 0 1rem;
    font-size: 0.7rem;
    color: #888;
  }

  hr {
    border: none;
    border-bottom: 1px solid #e2e2e2;
  }
`;

const CancelButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
`;

export const Item = styled(motion.li)<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem;

  :hover {
    background-color: rgb(242, 242, 242) !important;
    cursor: pointer;
  }

  ${({ active }) =>
    active && `background-color: rgb(242, 242, 242) !important;`}
`;
