import { DonateForm } from './DonateForm';
import { Stripe } from './Stripe';

export function StripeDonation() {
  const amounts = [5, 10, 25, 50, 100, 250, 500, 1000, 2500];
  const defaultAmount = 100;

  return (
    <Stripe
      options={{
        mode: 'payment',
        currency: 'usd',
        amount: defaultAmount * 100,
      }}
    >
      <DonateForm amounts={amounts} defaultAmount={defaultAmount} />
    </Stripe>
  );
}
