import { User } from '../../../app/model/User';

type PostAuthor = Pick<User, 'id' | 'firstName' | 'lastName' | 'picture'> & {
  role: string;
};

export enum PostType {
  MESSAGE = 'message',
  DROPOFF = 'dropoff',
  USER_JOINED = 'user.joined',
  USER_LEFT = 'user.left',
}

export type Attachment = {
  url: string;
  fileType: string;
  fileName: string;
  handle: string;
};

export type SitePost = {
  id: number;
  siteId: number;
  pinned: boolean;
  text?: string;
  author?: PostAuthor;
  attachments: Attachment[];

  type: PostType;

  createdAt: Date;
  updatedAt: Date;

  comments?: SitePostComment[];
  commentsCount: number;

  dropOff?: { weight?: number };
};

export type PostInputData = {
  id?: number;
  postId?: number;
  text?: string;
  pinned: boolean;
  attachments: Attachment[];
  type: PostType;
};

export type SitePostComment = {
  id: number;
  text: string;
  postId: number;
  author?: PostAuthor;
};

export type NewPostPayload = Pick<
  SitePost,
  'text' | 'pinned' | 'attachments' | 'type'
>;
export type UpdatePostPayload = Pick<
  SitePost,
  'id' | 'text' | 'pinned' | 'attachments' | 'type'
>;
