import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

import { Box } from '../../components/ui';

interface ParticipationProps {
  participation: any;
}

export const ParticipationBreakdown: React.FC<ParticipationProps> = ({
  participation,
}) => {
  const { users, makers, supporters, unmatched } = participation;

  return (
    <Fragment>
      <Box padding="1rem">
        <div style={{ height: '400px' }}>
          <Chart
            options={{
              chart: {
                id: 'participation-donut',
                type: 'donut',
              },
              colors: ['#5AB474', '#008FFB', '#FEB019'],
              title: {
                text: 'Participation Details',
                style: {
                  fontSize: '18px',
                },
              },
              subtitle: {
                text: `Total Users: ${users}`,
                style: {
                  fontSize: '14px',
                },
              },
              labels: ['Soil Makers', 'Soil Supporters', 'Unmatched Users'],
              legend: {
                position: 'left',
                offsetY: 60,
              },
              responsive: [
                {
                  breakpoint: 576,
                  options: {
                    chart: {
                      height: '85%',
                    },
                    legend: { show: false },
                  },
                },
              ],
            }}
            series={[makers, supporters, unmatched]}
            type="donut"
            height="100%"
          />
        </div>
      </Box>
    </Fragment>
  );
};
