import { useEffect } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { generateThreadIdentifier } from '../../../utils/url';
import { threadCreated } from '../messengerSlice';
import { useMessenger } from '../useMessenger';
import { useAppDispatch } from '../../../app/hooks';

/**
 * Handle starting a new thread
 */
export function useStartNewThread() {
  const match = useRouteMatch<{ threadIdentifier }>();
  const location = useLocation<{ recipients }>();
  const history = useHistory();

  const threadIdentifier = match.params.threadIdentifier;

  const dispatch = useAppDispatch();

  const { currentThread, threads } = useMessenger();

  useEffect(() => {
    const canStartNewThread =
      threadIdentifier === 'new' && location.state?.recipients;
    if (!canStartNewThread) return;

    if (threads.length > 0) {
      switchToThreadIfExistsOrCreateNew(
        threads,
        location.state.recipients,
        dispatch,
        history
      );
    } else {
      // this is a first thread ever
      // dispatch only when there's no new thread so we don't go into infinite loop
      if (!currentThread) {
        dispatch(threadCreated(location.state.recipients));
      }
    }
  }, [
    dispatch,
    history,
    location.state?.recipients,
    currentThread,
    threadIdentifier,
    threads,
  ]);
}

/**
 * we might already have a thread with this participant
 * if we do, redirect to it
 * otherwise create new one
 * @param threads
 * @param recipients
 * @param dispatch
 * @param history
 */
function switchToThreadIfExistsOrCreateNew(
  threads,
  recipients,
  dispatch,
  history
) {
  const existingThread = threads.find((t) =>
    t.participants.find((p) =>
      [...recipients.map((tp) => tp.id)].includes(p.id)
    )
  );

  const canSwitchToThread = existingThread && !existingThread.isNew;
  if (canSwitchToThread) {
    history.push(`/messages/t/${generateThreadIdentifier(existingThread.id)}`);
  } else if (!existingThread) {
    dispatch(threadCreated(recipients));
  }
}
