import { useCallback } from 'react';
import {
  navigationToggled,
  selectNavigationOpen,
  selectScreenSize,
} from '../features/layout/layoutSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';

export function useSidenavToggle() {
  const dispatch = useAppDispatch();
  const sideNavigationOpen = useAppSelector(selectNavigationOpen);
  const screenSize = useAppSelector(selectScreenSize);

  const toggleSideNav = useCallback(
    (userTriggered: boolean = false) => {
      dispatch(navigationToggled({ userTriggered }));
    },
    [dispatch]
  );

  const hideNavigationAfterClikedInMobile = useCallback(() => {
    if (sideNavigationOpen && screenSize === 'sm') {
      toggleSideNav(true);
    }
  }, [screenSize, sideNavigationOpen, toggleSideNav]);

  return {
    toggleSideNav,
    hideNavigationAfterClikedInMobile,
  };
}
