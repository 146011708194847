import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '.';

type ModalPlacement =
  | 'center'
  | 'top-right'
  | 'bottom-right'
  | 'bottom-left'
  | 'top-left';

type ModalProps = {
  isOpen: boolean;
  header?: string;
  hideFooter?: boolean;
  onClose: () => void;
  action?: () => void;
  actionButton?: React.ReactNode;
  actionButtonText?: string;
  cancelButtonText?: string;
  noPadding?: boolean;
  fitToSize?: boolean;
  full?: boolean;
};

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    children,
    header,
    isOpen,
    onClose,
    action,
    actionButton,
    actionButtonText,
    cancelButtonText = 'Cancel',
    hideFooter,
    noPadding,
    fitToSize,
    full,
  } = props;

  const modalRef = useRef(null);

  const modal = (
    <AnimatePresence>
      {isOpen && (
        <ModalBackdrop
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={(e) => {
            // close when clicked outside but not within modal
            // the click on the backdrop bubbles up
            if (modalRef.current && !modalRef.current.contains(e.target)) {
              // close only if clicked on the backdrop of THIS modal
              // it closes the modal of the backdrop being clicked
              // (if two modals are open it will close the right one)
              if (modalRef.current.parentNode.contains(e.target)) {
                onClose();
              }
            }
          }}
          // placement
        >
          <ModalContainer
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            ref={modalRef}
            layout
            fitToSize={fitToSize}
            full={full}
          >
            {header && (
              <ModalHeader>
                <h4>{header}</h4>
                <div onClick={onClose}>
                  <FontAwesomeIcon icon="times" />
                </div>
              </ModalHeader>
            )}

            <ModalBody noPadding={noPadding}>{children}</ModalBody>

            {!hideFooter && (
              <ModalFooter>
                {!action && !actionButton && (
                  <Button
                    style={{ marginLeft: 'auto' }}
                    variant="link"
                    onClick={onClose}
                  >
                    {actionButtonText ? actionButtonText : 'Close'}
                  </Button>
                )}
                {action && actionButtonText && (
                  <Fragment>
                    <Button variant="link" onClick={onClose}>
                      {cancelButtonText}
                    </Button>
                    <Button
                      variant="primary"
                      className="ModalButton"
                      onClick={action}
                    >
                      {actionButtonText}
                    </Button>
                  </Fragment>
                )}
                {actionButton && (
                  <Fragment>
                    <Button variant="link" onClick={onClose}>
                      {cancelButtonText}
                    </Button>
                    {actionButton}
                  </Fragment>
                )}
              </ModalFooter>
            )}
          </ModalContainer>
        </ModalBackdrop>
      )}
    </AnimatePresence>
  );

  return createPortal(modal, document.getElementById('modal-root'));
};

//#region styled components

const ModalBackdrop = styled(motion.div)<{
  placement?: ModalPlacement;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled(motion.div)<{
  fitToSize?: boolean;
  full?: boolean;
}>`
  display: flex;
  flex-direction: column;

  width: 90vw;
  max-width: 550px;
  top: env(safe-area-inset-top);
  max-height: calc(95% - env(safe-area-inset-top));
  // overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 70vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 60vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 45vw;
  }

  ${({ fitToSize }) =>
    fitToSize &&
    `
    width: min-content !important;
    max-width: revert;
    `}

  ${({ full }) =>
    full &&
    `
    height: 95vh;
    width: 95vw !important;
    max-width: revert;
  `}

  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-top: 5px solid ${({ theme }) => theme.colors.primary};

  border-radius: 4px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  background-color: #f7f7f7;
  border-bottom: 1px solid #eaeaea;

  h4 {
    margin: 0;
  }

  > div,
  h4 {
    padding: 0.75rem 1rem;
  }

  svg {
    cursor: pointer;
  }
`;

const ModalBody = styled.div<{ noPadding: boolean }>`
  padding: ${({ noPadding }) => (noPadding ? '0' : '1rem 0.5rem')};
  height: 100%;

  /*
		Fix the flicker when scrollbar appears
	*/
  overflow-y: scroll;
  // account for scrollbar width
  margin-left: 10px;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  // Firefox
  scrollbar-width: 10px;
  scrollbar-color: #888 transparent;

  input,
  textarea {
    width: 100%;
    border-radius: 4px;
  }
`;

const ModalFooter = styled.div`
  padding: 0.75rem 1rem;
  background-color: #f7f7f7;
  border-top: 1px solid #eaeaea;

  display: flex;
  justify-content: space-between;

  button:last-child {
    margin-left: 1rem;
  }
`;

//#endregion
