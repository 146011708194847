import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import styled from 'styled-components';
import { isMobile } from '../../../utils/helpers';

export const ShareOn = (props) => {
  const { siteName, inviteUrl, shareText } = props;

  const preloadedText =
    shareText ||
    encodeURIComponent(
      `${siteName} is a soil site on MakeSoil. Click this link to join this soil site and start contributing your scraps.`
    );

  // we'll use them only on mobile to open apps
  const instagramUrl = `instagram://`;
  const whatsAppUrl = `https://wa.me/?text=${preloadedText} ${inviteUrl}`;

  const nextdoorUrl = `https://nextdoor.com`;
  const meetupUrl = `https://meetup.com`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${preloadedText} ${inviteUrl}`;

  const facebookUrl = !isMobile()
    ? `https://www.facebook.com/sharer/sharer.php?u=${inviteUrl}&quote=${preloadedText}`
    : `https://m.facebook.com/sharer/sharer.php?u=${inviteUrl}&quote=${preloadedText}`;

  return (
    <ShareLinks>
      <div>Share via:</div>
      <div className="icons">
        <a
          href={facebookUrl}
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
        >
          <FontAwesomeIcon icon={['fab', 'facebook']} />
        </a>
        <a
          href={nextdoorUrl}
          target="_blank"
          rel="noopener noreferrer"
          title="Nexdoor"
        >
          <FontAwesomeIcon icon="home" flip="horizontal" />
        </a>
        <a
          href={meetupUrl}
          target="_blank"
          rel="noopener noreferrer"
          title="Meetup"
        >
          <FontAwesomeIcon icon={['fab', 'meetup']} />
        </a>
        {isMobile && (
          <Fragment>
            <a href={instagramUrl} title="Instagram">
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </a>
            <a href={whatsAppUrl} title="Whatsapp">
              <FontAwesomeIcon icon={['fab', 'whatsapp']} />
            </a>
          </Fragment>
        )}
        <a
          href={twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a>
      </div>
    </ShareLinks>
  );
};

const ShareLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  .icons {
    display: flex;
    flex-direction: row;

    a {
      margin-left: 1rem;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    margin-top: 1.5rem;

    .icons {
      justify-content: space-between;
      margin-top: 0.5rem;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #efefef;
        border-radius: 50%;
        height: 2.25rem;
        width: 2.25rem;
        font-size: 1.25rem;
        margin-left: 0;
      }
    }
  }
`;
