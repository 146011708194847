import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { Site, SiteMember } from '../model';

import {
  TRANSFER_OWNERSHIP,
  UPDATE_SITE,
  LEAVE_SITE,
  DEACTIVATE_SITE,
  REACTIVATE_SITE,
  PROMOTE_SOIL_MAKER,
  DEMOTE_MEMBER,
  REMOVE_MEMBER,
  REPORT_USER,
  GET_SOIL_SITE,
  UPDATE_SITE_IMAGE,
} from '../graphql';
import accessrequestService from '../accessrequestService';

export function useSiteManager(siteId: number) {
  const [transferOwnership] = useMutation(TRANSFER_OWNERSHIP);
  const [updateSite] = useMutation(UPDATE_SITE);
  const [updateSiteImage] = useMutation(UPDATE_SITE_IMAGE);
  const [leaveSite] = useMutation(LEAVE_SITE);
  const [deactivateSite] = useMutation(DEACTIVATE_SITE);
  const [reactivateSite] = useMutation(REACTIVATE_SITE);

  const [promoteToSoilMaker] = useMutation(PROMOTE_SOIL_MAKER);
  const [demoteSoilMaker] = useMutation(DEMOTE_MEMBER);
  const [removeMember] = useMutation(REMOVE_MEMBER);
  const [reportUser] = useMutation(REPORT_USER);

  const updateSoilSite = useCallback(
    async (siteData: Partial<Site>) => {
      return await updateSite({
        variables: siteData,
      });
    },
    [updateSite]
  );

  const updateSoilSiteImage = useCallback(
    async (picture: string) => {
      return await updateSiteImage({
        variables: { siteId, picture },
        update: (cache) => {
          const { soilSite: cachedSoilSite } = cache.readQuery<any>({
            query: GET_SOIL_SITE,
            variables: { id: siteId },
          });

          cache.writeQuery({
            query: GET_SOIL_SITE,
            variables: { siteId },
            data: {
              soilSite: {
                ...cachedSoilSite,
                picture,
              },
            },
          });
        },
      });
    },
    [updateSiteImage, siteId]
  );

  const transfer = useCallback(
    async (member: SiteMember) => {
      return await transferOwnership({
        variables: { siteId, newOwnerId: member.userId },
      });
    },
    [transferOwnership, siteId]
  );

  const promote = useCallback(
    async ({ userId }: SiteMember) => {
      return await promoteToSoilMaker({
        variables: { siteId, userId },
      });
    },
    [promoteToSoilMaker, siteId]
  );

  const demote = useCallback(
    async ({ userId }: SiteMember) => {
      return await demoteSoilMaker({
        variables: { siteId, userId },
      });
    },
    [demoteSoilMaker, siteId]
  );

  const leave = useCallback(
    async (reason?: string) => {
      return await leaveSite({
        variables: { id: siteId, reason },
      });
    },
    [leaveSite, siteId]
  );

  const removeParticipant = useCallback(
    async ({ userId }: SiteMember, reason?: string) => {
      return await removeMember({
        variables: { siteId, userId, reason },
        update: (cache, { data: { removeMember } }) => {
          const { soilSite: cachedSoilSite } = cache.readQuery<any>({
            query: GET_SOIL_SITE,
            variables: { id: siteId },
          });

          cache.writeQuery({
            query: GET_SOIL_SITE,
            variables: { siteId },
            data: {
              soilSite: {
                ...cachedSoilSite,
                // remove the member
                members: cachedSoilSite.members.filter(
                  (m) => m.userId !== userId
                ),
              },
            },
          });
        },
      });
    },
    [removeMember, siteId]
  );

  const deactivate = useCallback(
    async (reason: string) => {
      return await deactivateSite({
        variables: { id: siteId, reason },
      });
    },
    [deactivateSite, siteId]
  );

  const reactivate = useCallback(async () => {
    return await reactivateSite({
      variables: { id: siteId },
    });
  }, [reactivateSite, siteId]);

  const approveAccessRequest = useCallback(
    async (requestId: number) => {
      return accessrequestService.approveAccessRequest(
        requestId,
        (cache, { data: { approveRequest } }) => {
          const { soilSite: cachedSoilSite } = cache.readQuery<any>({
            query: GET_SOIL_SITE,
            variables: { id: siteId },
          });

          // updating the soil site
          // we have a new member and one less access request
          if (approveRequest.soilSite) {
            cache.writeQuery({
              query: GET_SOIL_SITE,
              variables: { siteId },
              data: {
                soilSite: {
                  ...cachedSoilSite,
                  // update the members
                  members: approveRequest.soilSite.members,
                  // remove the access request
                  accessRequests: cachedSoilSite.accessRequests.filter(
                    (ar) => ar.id !== requestId
                  ),
                },
              },
            });
          }
        }
      );
    },
    [siteId]
  );

  const declineAccessRequest = useCallback(
    async (requestId: number, reason?: string) => {
      return accessrequestService.declineAccessRequest(
        requestId,
        reason,
        (cache) => {
          const { soilSite: cachedSoilSite } = cache.readQuery<any>({
            query: GET_SOIL_SITE,
            variables: { id: siteId },
          });
          // remove the access request from the cache
          cache.writeQuery({
            query: GET_SOIL_SITE,
            variables: { id: siteId },
            data: {
              soilSite: {
                ...cachedSoilSite,
                accessRequests: cachedSoilSite.accessRequests.filter(
                  (ar) => ar.id !== requestId
                ),
              },
            },
          });
        }
      );
    },
    [siteId]
  );

  const report = useCallback(
    async (userId: number, flaggedContent: string, reason: string) => {
      return reportUser({ variables: { userId, flaggedContent, reason } });
    },
    [reportUser]
  );

  return {
    transfer,
    promote,
    demote,
    removeParticipant,
    updateSoilSite,
    updateSoilSiteImage,
    leave,
    deactivate,
    reactivate,
    approveAccessRequest,
    declineAccessRequest,
    report,
  };
}
