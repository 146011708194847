import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { generateUserIdentifier } from '../../utils/url';
import { SmartImage } from './SmartImage';
import { getUserDisplayName } from '../../utils/helpers';
import { theme } from '../../app/theme';

type AvatarSize = 'sm' | 'lg';

type AvatarProps = {
  user: {
    id: number;
    firstName: string;
    lastName?: string;
    picture?: string;
  };
  size?: AvatarSize;
  profileLink?: boolean;
  round?: boolean;
  badge?: React.ReactNode;
  triggerAction?: () => void;
};

export const Avatar = (props: AvatarProps) => {
  const {
    size = 'sm',
    user,
    triggerAction,
    profileLink = false,
    round = false,
    badge,
  } = props;
  const { id, firstName, lastName, picture } = user;

  function renderAvatar() {
    let avatar = <SmartImage src={picture} width={32} alt="User Profile" />;
    if (!picture)
      avatar = (
        <DefaultAvatar
          firstName={firstName}
          lastName={lastName}
          round={round}
        />
      );

    if (!profileLink) return avatar;
    return <Link to={`/user/${generateUserIdentifier(id)}`}>{avatar}</Link>;
  }

  return (
    <AvatarContainer size={size} round={round} onClick={triggerAction}>
      {renderAvatar()}
      <BadgeContainer>{badge}</BadgeContainer>
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div<{ size: AvatarSize; round?: boolean }>`
  position: relative;
  min-width: 32px;
  width: 32px;
  height: 32px;

  width: ${({ size }) => (size === 'lg' ? '38px' : '32px')};
  height: ${({ size }) => (size === 'lg' ? '38px' : '32px')};

  border-radius: 4px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    ${({ round }) => round && `border-radius: 50%;`}

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      width: ${({ size }) => (size === 'lg' ? '38px' : '32px')};
      height: ${({ size }) => (size === 'lg' ? '38px' : '32px')};
    }
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
`;

function assignColor(initials: string) {
  const colors = [
    '#45B139',
    '#009FD4',
    '#B381B3',
    '#6939b1',
    '#E3BC00',
    '#DC2A2A',
    '#A23FBB',
    '#D47500',
    '#3981B1',
  ];
  return colors[numberFromText(initials) % colors.length];
}

function numberFromText(text: string) {
  const charCodes = text
    .split('') // => ["A", "A"]
    .map((char) => char.charCodeAt(0)) // => [65, 65]
    .join(''); // => "6565"
  return parseInt(charCodes, 10);
}

function extractInitialsFromDisplayName(displayName: string) {
  const [first, last] = displayName.split(' ');
  return `${first.substr(0, 1).toUpperCase()}${
    last?.substr(0, 1).toUpperCase() || ''
  }`;
}

type DefaultAvatarProps = {
  firstName: string;
  lastName?: string;
  round?: boolean;
};

const DefaultAvatar = ({
  round,
  firstName = '',
  lastName = '',
}: DefaultAvatarProps) => {
  let avatar: React.ReactNode = <FontAwesomeIcon icon="user-alt-slash" />;
  let avatarStyle = { backgroundColor: '#939393' };
  if (firstName) {
    const displayName = getUserDisplayName(firstName, lastName) || '';
    const initials = extractInitialsFromDisplayName(displayName);

    if (displayName !== 'Account deleted') {
      avatar = initials;
      avatarStyle = { backgroundColor: assignColor(initials) };
    }
  } else {
    avatar = <FontAwesomeIcon icon="user" />;
    avatarStyle = { backgroundColor: theme.colors.primary };
  }

  return (
    <DefaultAvatarContainer round={round} style={avatarStyle}>
      {avatar}
    </DefaultAvatarContainer>
  );
};

const DefaultAvatarContainer = styled.div<{ round?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  border-radius: 4px;
  ${({ round }) => round && `border-radius: 50%;`}
  background-color: #ccc;
  color: #fff;
`;
