import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';

type LightBoxProps = {
  src: string;
  alt?: string;
};

export const LightBox: React.FC<LightBoxProps> = (props) => {
  const { children, src, alt = 'full size image' } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((open) => !open);
  };

  return (
    <Wrapper onClick={toggleIsOpen}>
      <Overlay id="overlay">
        <FontAwesomeIcon icon="search" size="4x" />
      </Overlay>
      {children}
      {isOpen ? (
        <Container>
          <img src={src} alt={alt} />
        </Container>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    #overlay {
      opacity: 1;
    }
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2rem;
  }

  img {
    width: auto;
    max-width: 90vw;
    max-height: 90vh;
  }
`;

const Overlay = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  background-color: rgba(244, 244, 244, 0.5);
  color: #898989;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    opacity: 0;
  }

  transition: opacity 0.25s ease;
`;
