import { gql } from '@apollo/client';

//#region fragments

export const MEMBER_FRAGMENT = `
	fragment Member on ParticipantOT {
		userId
    firstName
    lastName
		picture
		role {
			id
			name
		}
		createdAt

	}
`;

export const SITE_FRAGMENT = `
	fragment SiteData on SiteOT {
		id
		name
		description
		createdAt
		isUnlisted
		address
		location {
			lat
			lng
		}
		accepted
		prohibited
		picture
		active
		access
    dropOff
		hours
		requiresApproval
    obfuscate
    dropOffs {
      id
      siteId
      userId
      weight
    }
	}
`;

export const ACCESSREQUEST_FRAGMENT = `
	fragment AccessRequest on AccessRequestOT {
		id
		siteId
		userId
		approved
		message
		createdAt
		user {
			id
      firstName
      lastName
			picture
		}
	}
`;

//#endregion

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($siteId: Int!, $invitationCode: String!) {
    acceptInvitation(siteId: $siteId, invitationCode: $invitationCode) {
      message
      errors
    }
  }
`;

export const JOIN_SITE = gql`
  mutation($siteId: Int!) {
    joinSoilSite(siteId: $siteId) {
      id
      members {
        userId
        firstName
        lastName
        role {
          id
          name
        }
        createdAt
      }
    }
  }
`;

export const REQUEST_ACCESS = gql`
  mutation($siteId: Int!, $message: String!) {
    requestSiteAccess(input: { siteId: $siteId, message: $message }) {
      message
      errors
      accessRequest {
        ...AccessRequest
      }
    }
  }
  ${ACCESSREQUEST_FRAGMENT}
`;

export const CANCEL_ACCESS_REQUEST = gql`
  mutation($requestId: Int!) {
    cancelRequest(requestId: $requestId) {
      message
      errors
    }
  }
`;

export const APPROVE_ACCESS_REQUEST = gql`
  mutation($requestId: Int!) {
    approveRequest(requestId: $requestId) {
      message
      errors
      soilSite {
        accessRequests {
          ...AccessRequest
        }
        members {
          ...Member
        }
      }
    }
  }
  ${ACCESSREQUEST_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const DECLINE_ACCESS_REQUEST = gql`
  mutation($requestId: Int!, $reason: String) {
    declineRequest(requestId: $requestId, reason: $reason) {
      message
      errors
    }
  }
`;

export const REPORT_USER = gql`
  mutation($userId: Int!, $flaggedContent: String!, $reason: String!) {
    reportUser(
      userId: $userId
      flaggedContent: $flaggedContent
      reason: $reason
    ) {
      message
      errors
    }
  }
`;

export const GET_SOIL_SITE = gql`
  query getSoilSite($id: Int!) {
    soilSite(id: $id) {
      ...SiteData
      accessRequests {
        ...AccessRequest
      }
      members {
        ...Member
        dropOffs {
          id
          siteId
          userId
          weight
          createdAt
        }
      }
    }
  }

  ${SITE_FRAGMENT}
  ${ACCESSREQUEST_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const GET_SITE_MINIMAL_DETAILS = gql`
  query GetSoilSitMinimalDetails($id: Int!) {
    soilSiteMinimalDetails(id: $id) {
      id
      name
      picture
      createdAt
      membersCount
    }
  }
`;

export const UPDATE_SITE = gql`
  mutation(
    $id: Int!
    $name: String!
    $address: String!
    $addressComponents: [AddressComponent]
    $placeId: String
    $location: LocationInput!
    $picture: String
    $description: String
    $access: String
    $dropOff: String
    $hours: String
    $accepted: [String!]!
    $prohibited: [String!]!
    $requiresApproval: Boolean!
    $obfuscate: Boolean!
    $isUnlisted: Boolean!
  ) {
    updateSite(
      site: {
        id: $id
        name: $name
        address: $address
        addressComponents: $addressComponents
        placeId: $placeId
        location: $location
        picture: $picture
        description: $description
        access: $access
        dropOff: $dropOff
        hours: $hours
        accepted: $accepted
        prohibited: $prohibited
        requiresApproval: $requiresApproval
        obfuscate: $obfuscate
        isUnlisted: $isUnlisted
      }
    ) {
      id
      name
      description
      createdAt
      isUnlisted
      address
      accepted
      prohibited
      picture
      active
      access
      dropOff
      hours
      location {
        lat
        lng
      }
      requiresApproval
      obfuscate
    }
  }
`;

export const UPDATE_SITE_IMAGE = gql`
  mutation($siteId: Int!, $picture: String!) {
    updateSiteImage(siteId: $siteId, picture: $picture)
  }
`;

export const DEACTIVATE_SITE = gql`
  mutation DeactivateSite($id: Int!, $reason: String!) {
    deactivateSite(id: $id, reason: $reason) {
      soilSite {
        id
        active
      }
      message
      errors
    }
  }
`;

export const REACTIVATE_SITE = gql`
  mutation ReactivateSite($id: Int!) {
    reactivateSite(id: $id) {
      soilSite {
        id
        active
      }
      message
      errors
    }
  }
`;

export const LEAVE_SITE = gql`
  mutation LeaveSite($id: Int!, $reason: String) {
    leaveSite(id: $id, reason: $reason) {
      soilSite {
        id
        members {
          ...Member
        }
      }
      message
      errors
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const PROMOTE_SOIL_MAKER = gql`
  mutation PromoteToSoilMaker($userId: Int!, $siteId: Int!) {
    promoteToSoilMaker(userId: $userId, siteId: $siteId) {
      soilSite {
        id
        members {
          ...Member
        }
      }
      message
      errors
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const DEMOTE_MEMBER = gql`
  mutation DemoteSoilMaker($userId: Int!, $siteId: Int!) {
    demoteSoilMaker(userId: $userId, siteId: $siteId) {
      soilSite {
        id
        members {
          ...Member
        }
      }
      message
      errors
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const REMOVE_MEMBER = gql`
  mutation($siteId: Int!, $userId: Int!, $reason: String) {
    removeMember(siteId: $siteId, userId: $userId, reason: $reason) {
      message
      errors
    }
  }
`;

export const TRANSFER_OWNERSHIP = gql`
  mutation($siteId: Int!, $newOwnerId: Int!) {
    transferOwnership(siteId: $siteId, newOwnerId: $newOwnerId) {
      soilSite {
        id
        members {
          ...Member
        }
      }
      message
      errors
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const SEND_INVITATION = gql`
  mutation SendEmailInvitation(
    $emails: [String!]!
    $message: String
    # We're sending an enum value but have to use it as String
    # because of the GraphQL enum mapping on the server (https://typegraphql.com/docs/enums.html)
    $siteRole: String!
    $siteId: Int!
  ) {
    sendEmailInvitation(
      input: {
        emails: $emails
        message: $message
        siteRole: $siteRole
        siteId: $siteId
      }
    )
  }
`;

export const SOIL_SITES_NEARBY = gql`
  query SoilSitesNearby($location: LocationInput!) {
    soilSitesNearby(location: $location) {
      sites {
        id
        name
        location {
          lat
          lng
        }
        picture
        createdAt
        participants
        distance
      }
      address
      maxDistance
    }
  }
`;
