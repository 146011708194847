import { useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectThreads,
  selectSelectedThread,
  fetchThreads,
  unmounted,
  sendMessageInNewThread,
  sendMessage,
  threadSelected,
  selectSidebarOpen,
  sidebarToggled,
  fetchMessages,
  markRead,
} from './messengerSlice';
import { IMessageInput } from './model';
import { generateThreadIdentifier } from '../../utils/url';
import { useHistory } from 'react-router-dom';

export function useMessenger() {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const threads = useAppSelector(selectThreads);
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const currentThread = useAppSelector(selectSelectedThread);

  const fetchAllThreads = useCallback(() => dispatch(fetchThreads()), [
    dispatch,
  ]);

  const fetchThreadMessages = useCallback(
    (threadId: number) => dispatch(fetchMessages(threadId)),
    [dispatch]
  );

  const send = useCallback(
    async (message: IMessageInput) => {
      const isNewThread = !currentThread.id && currentThread.isNew;
      if (isNewThread) {
        return dispatch(
          sendMessageInNewThread({
            message,
            participants: currentThread.participants.map((p) => ({
              id: p.id,
              isAnonymous: p.isAnonymous,
            })),
          })
        );
      }

      return dispatch(sendMessage({ message, threadId: currentThread.id }));
    },
    [
      currentThread?.id,
      currentThread?.isNew,
      currentThread?.participants,
      dispatch,
    ]
  );

  const selectThread = useCallback(
    (threadId: number) => {
      dispatch(threadSelected(threadId));
      history.push(`/messages/t/${generateThreadIdentifier(threadId)}`);
    },
    [dispatch, history]
  );

  const doMarkRead = useCallback(
    (threadId: number) => dispatch(markRead(threadId)),
    [dispatch]
  );

  const toggleSidebar = useCallback(
    (open: boolean) => dispatch(sidebarToggled(open)),
    [dispatch]
  );

  const cleanup = useCallback(() => dispatch(unmounted()), [dispatch]);

  return {
    threads,
    currentThread,

    sidebarOpen,
    toggleSidebar,

    fetchThreads: fetchAllThreads,
    fetchMessages: fetchThreadMessages,
    sendMessage: send,
    selectThread,
    markRead: doMarkRead,

    cleanup,
  };
}
