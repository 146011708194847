import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { Meta } from './Meta';
import { SmartLink } from './ui/SmartLink';

const stripTitle = ([...text]) => {
  // console.log("stripTitle. text", text);
  const basicText = text.reduce((acc, cur) => {
    if (typeof cur === 'string') {
      return (acc += `${cur} `);
    }
    return acc;
  }, '');
  return basicText
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^a-z0-9-]/g, '');
};

type StaticPageProps = {
  markdownURL: string;
  pageTitle: string;
  className?: string;
  hideNavigation?: boolean;
};

interface Nav {
  text: string;
  level: number;
  id: string;
  offsetTop: number;
}

let time_out;

export const StaticPage: React.FC<StaticPageProps> = ({
  markdownURL,
  pageTitle,
  className,
  hideNavigation,
}) => {
  const [markdown, setMarkdown] = useState('');

  const location = useLocation();

  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  const [navigation, setNavigation] = useState<Nav[]>([]);
  const [selectedNavId, setSelectedNavId] = useState<string>(null);

  useEffect(() => {
    fetch(markdownURL)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
      .then(() => {
        buildNavigation();
        // we need to rebuild the navigation on resize as the headings
        // offset values would change
        window.addEventListener('resize', handleResize);
      })
      .catch(console.error);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const buildNavigation = () => {
    const documentHeadings = Array.from(document.querySelectorAll('.heading'));

    const navigation = documentHeadings.map((h) => ({
      text: h.innerHTML,
      level: parseInt(h.tagName[1]),
      id: h.id,
      offsetTop: document.getElementById(h.id)?.offsetTop,
    }));

    setNavigation(navigation);

    // if the user came to the page with the specific id in the url
    // select it and scroll to it
    const headingIdFromHash = location.hash.replace('#', '');
    if (
      headingIdFromHash &&
      navigation.some((n) => n.id === headingIdFromHash)
    ) {
      setSelectedNavId(headingIdFromHash);
    } else {
      setSelectedNavId(navigation[0].id);
    }
    document.getElementById(headingIdFromHash)?.scrollIntoView(true);
  };

  const handleContentScroll = (e: any) => {
    const currentScrollPosition = e.target.scrollTop;
    const nav = navigation.find((n) => n.offsetTop >= currentScrollPosition);

    // select the navigation node if the user scrolls within 100px of the heading
    if (nav && nav.offsetTop - currentScrollPosition < 100) {
      clearTimeout(time_out);
      time_out = setTimeout(() => {
        setSelectedNavId(nav.id);
        // update the url
        window.history.pushState({}, '', `#${nav.id}`);

        // if the navigation node is not visible, scroll to it
        // const navNode = document.querySelector(`[data-nav='${nav.id}']`);
        // if (isElementOffScreen(navNode)) {
        //   navNode.scrollIntoView({ behavior: 'smooth' });
        // }
      }, 200);
    }
  };

  const handleNavClick = (id: string) => {
    setSelectedNavId(id);
  };

  let resizeTimer = null;
  const handleResize = (event) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      buildNavigation();
    }, 500);
  };

  return (
    <Wrapper className={className}>
      <Meta>
        <title>{pageTitle} | MakeSoil</title>
      </Meta>
      {!hideNavigation && (
        <Sidebar ref={sidebarRef}>
          <Navigation
            navigation={navigation}
            selectedNavId={selectedNavId}
            onNavClick={handleNavClick}
          />
        </Sidebar>
      )}
      <Content ref={contentRef} onScroll={handleContentScroll}>
        <Markdown
          children={markdown}
          className="markdown"
          rehypePlugins={[rehypeRaw]}
          components={{
            a: (props) => {
              // render <a> as SmartLink
              return <SmartLink href={props.href}>{props.children}</SmartLink>;
            },
            h1: ({ children }) => {
              return (
                <h1 className="heading" id={stripTitle(children)}>
                  {children}
                </h1>
              );
            },
            h2: ({ children }) => {
              return (
                <h2 className="heading" id={stripTitle(children)}>
                  {children}
                </h2>
              );
            },
            h3: ({ children }) => {
              return (
                <h3 className="heading" id={stripTitle(children)}>
                  {children}
                </h3>
              );
            },
            iframe: (props) => {
              return (
                <div style={{ textAlign: props.selfalign || 'center' }}>
                  {
                    <iframe
                      {...props}
                      title={props.src}
                      scrolling="no"
                      frameBorder="0"
                    />
                  }
                </div>
              );
            },
          }}
        />
      </Content>
    </Wrapper>
  );
};

type NavProps = {
  navigation: Nav[];
  selectedNavId: string;
  onNavClick: (navId: string) => void;
};

const Navigation: React.FC<NavProps> = React.memo(
  ({ navigation, selectedNavId, onNavClick }) => {
    return (
      <ul>
        {navigation.length > 0 &&
          navigation.map((nav) => (
            <NavItem
              key={nav.id}
              selected={nav.id === selectedNavId}
              indent={nav.level > 1}
              onClick={() => onNavClick(nav.id)}
              data-nav={nav.id}
            >
              <a href={`#${nav.id}`}>{nav.text}</a>
            </NavItem>
          ))}
      </ul>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.selectedNavId === nextProps.selectedNavId;
  }
);

const Markdown: React.FC<any> = React.memo(
  (props) => <ReactMarkdown {...props} />,
  (prevProps, nextProps) => {
    return prevProps.children === nextProps.children;
  }
);

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;

  display: flex;
`;

const Sidebar = styled.aside`
  height: 100%;
  min-width: 250px;
  max-width: 300px;
  padding: 1rem;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }

  flex: 2;

  color: rgb(86, 86, 86);
  box-shadow: rgb(0 0 0 / 18%) -14px 0px 14px -14px inset;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const NavItem = styled.li<{ selected: boolean; indent?: boolean }>`
  :not(:first-child) {
    border-top: 1px solid rgb(235, 235, 235);
  }

  a {
    display: block;
    padding: 8px 0;
  }

  ${({ selected }) => selected && `font-weight: bold;`}
  ${({ indent }) => indent && `margin-left: 0.5rem;`}
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;

  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem 3rem;
  }

  padding-bottom: 60vh;

  flex: 5;

  //scroll-behavior: smooth;

  h1,
  h2,
  h3 {
    font-weight: bold;
    margin: 4rem 0px 2rem;
  }

  a {
    text-decoration: underline;
  }

  ul {
    list-style: revert;
    margin: revert;
    padding: revert;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto 1rem auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.12);
  }

  line-height: 1.5;
`;
