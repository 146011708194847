import { StaticPage } from '../../components/StaticPage';

const RESOURCES_URL =
  'https://raw.githubusercontent.com/MakeSoil/public-pages/master/resources.md';

const Resources = () => (
  <StaticPage markdownURL={RESOURCES_URL} pageTitle="Posters & Flyers" />
);

export default Resources;
