import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  Observable,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';

import { getUrlByConnectionType } from '../utils/url';
import cache from '../components/cache';
import {
  getJwtToken,
  requestAccessToken,
  storeJwtToken,
} from '../features/auth/utils';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const URL = getUrlByConnectionType('http');

/**
 * Add the user's JWT authentication token to the operation prior to execution.
 */
const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `bearer ${getJwtToken()}`,
    },
  }));

  return forward(operation);
});

/**
 * Set the HTTP URL for Apollo to use.
 */
const httpLink = new HttpLink({
  uri: `${URL}/graphql`,
  credentials: 'include',
});

const getAuthTokenForWebsocketLink = async () => {
  let token = getJwtToken();
  if (!token) {
    const authResult = await requestAccessToken();
    token = authResult.accessToken;
  }
  return token;
};

export const subscriptionClient = new SubscriptionClient(
  `${getUrlByConnectionType('socket')}/graphql`,
  {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: 5,
    connectionParams: async () => {
      const authToken = await getAuthTokenForWebsocketLink();
      return { authToken };
    },
  }
);

/**
 * Split the links so the Provider knows how to handle subscriptions and queries
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  new WebSocketLink(subscriptionClient),
  httpLink
);

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  // handling the case when a graphQL request is unathorized (token expired)
  // we try to grefresh the access token and repeat the last operation
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
    return new Observable((observer) => {
      requestAccessToken()
        .then((response) => {
          const { ok, accessToken } = response;
          if (!ok) {
            return window.location.replace('/login');
          }
          storeJwtToken(accessToken);
          operation.setContext(({ headers = {} }: any) => ({
            headers: {
              ...headers,
              authorization: `bearer ${accessToken || undefined}`,
            },
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          // retry the last operation
          return forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, splitLink]),
  cache,
});
