import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, FormInputError, Input } from '../../components/ui';
import { UserDisplayName } from '../../components/ui/UserDisplayName';
import { HelpText } from '../../components/ui/forms/Forms';
import { useHistory } from 'react-router-dom';
import { useAuthUser } from '../auth/useAuthUser';
import { SiteOnboardLocationState } from './model';
import { additionalContactInfoRequired } from '../auth/utils';
import { useAppDispatch } from '../../app/hooks';
import { fetchMe } from '../auth/authSlice';
import { validationMessages } from '../../utils/formValidation';
import { AuthPageLayout } from '../../pages';

const SET_USER_NAME = gql`
  mutation SetUserName($firstName: String!, $lastName: String) {
    setUserName(firstName: $firstName, lastName: $lastName) {
      message
      errors
    }
  }
`;

type FormData = {
  firstName: string;
  lastName?: string;
};

export default function YourName() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [setUserName] = useMutation(SET_USER_NAME);

  const validateForm = ({ firstName }: FormData) => {
    let errors: FormikErrors<FormData> = {};

    if (!firstName) {
      errors.firstName = validationMessages.firstName;
    }

    return errors;
  };

  const handleSubmit = async (
    { firstName, lastName }: FormData,
    { setSubmitting }: any
  ) => {
    await setUserName({ variables: { firstName, lastName } });

    await dispatch(fetchMe(authUser.id));

    setSubmitting(false);

    if (history.location.state) {
      const state = history.location.state as SiteOnboardLocationState;
      if (additionalContactInfoRequired(authUser, state.action)) {
        return history.push(`/onboarding/verify-contact-info`, state);
      } else if (!authUser.hasAddress) {
        return history.push(`/onboarding/your-location`, state);
      } else {
        return history.push(`/onboarding/stay-safe`, state);
      }
    }
  };

  return (
    <AuthPageLayout onClose={() => history.push('/map')}>
      <h2>What is your name?</h2>
      <p>So other people on MakeSoil know whom they&apos;re connecting with.</p>

      <Formik
        initialValues={{ firstName: '', lastName: '' }}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <Field
              as={Input}
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={({ target: { value } }) => {
                setFieldValue('firstName', value);
                setFirstName(value);
              }}
              autoComplete="off"
            />
            <ErrorMessage name="firstName" component={FormInputError} />

            <Field
              as={Input}
              type="text"
              name="lastName"
              placeholder="Last Name (optional)"
              onChange={({ target: { value } }) => {
                setFieldValue('lastName', value);
                setLastName(value);
              }}
              autoComplete="off"
              style={{ marginTop: '1rem' }}
            />

            <div style={{ textAlign: 'left' }}>
              {values.firstName && (
                <span>
                  <HelpText style={{ color: '#ebebeb', marginBottom: 0 }}>
                    People will see you as:{' '}
                    <strong>
                      <UserDisplayName
                        firstName={firstName}
                        lastName={lastName}
                      />
                    </strong>
                  </HelpText>
                </span>
              )}
            </div>

            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              style={{ marginTop: '1.5rem', width: '100%' }}
            >
              CONTINUE
            </Button>
          </Form>
        )}
      </Formik>
    </AuthPageLayout>
  );
}
