import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikErrors, Formik, Form, Field, ErrorMessage } from 'formik';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  Loader,
  ErrorComponent,
  SmartImage,
  Button,
  FormError,
  Input,
  FormInputError,
  Flex,
} from '../../components/ui';
import {
  FilestackUploadResponse,
  FilePicker,
} from '../../components/ui/FilePicker';
import { AddressField } from '../../components/ui/forms/AddressField';
import {
  validationMessages,
  isEmail,
  isPhoneNumber,
} from '../../utils/formValidation';
import { getIdFromIdentifier } from '../../utils/url';
import { FormControl } from '../admin/components/EmailManagement';
import {
  selectAuthUser,
  contactInfoUpdated,
  avatarUpdated,
} from '../auth/authSlice';
import { removeOldFilestackImage } from '../filestack/utils';
import { IUserProfile } from './model';
import {
  selectUserProfile,
  selectProfileLoading,
  selectProfileError,
  fetchUserProfile,
  updateUserProfile,
  updateUserProfilePhoto,
} from './userProfileSlice';

export const Profile = ({ userHashId }) => {
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(selectAuthUser);
  const profile = useAppSelector(selectUserProfile);
  const loading = useAppSelector(selectProfileLoading);
  const error = useAppSelector(selectProfileError);

  const [editMode, setEditMode] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const containerRef = useRef(null);

  const userId = getIdFromIdentifier(userHashId);

  useEffect(() => {
    dispatch(fetchUserProfile(userId));
  }, [dispatch, userId]);

  if (loading) return <Loader />;
  if (error) return <ErrorComponent error={error} />;
  if (!profile) return null;

  const updateProfile = async (profile: IUserProfile) => {
    await dispatch(
      updateUserProfile({
        ...profile,
        picture: profilePic || profile.picture,
      })
    );

    // need to also update the auth user
    dispatch(
      contactInfoUpdated({
        ...profile,
        picture: profilePic || profile.picture,
      })
    );

    toast.success('Your info has been updated.');
    setEditMode(false);
  };

  const handleFileUpload = async (response: FilestackUploadResponse) => {
    const picture = response.filesUploaded[0].url;
    await dispatch(updateUserProfilePhoto(picture));
    // also update the auth user
    await dispatch(avatarUpdated(picture));

    setProfilePic((currentPicture) => {
      // we need to remove the current/old image from filestack
      removeOldFilestackImage(currentPicture);
      return picture;
    });
  };

  const handleEditInfo = () => {
    setEditMode(true);
    containerRef.current?.scrollTo(0, 0);
  };

  const handleCancelEditMode = () => {
    setEditMode(false);
    containerRef.current?.scrollTo(0, 0);
  };

  const isCurrentUser = userId === authUser.id;
  const canEdit = isCurrentUser;

  return (
    <UserProfileContainer>
      <ProfileImageContainer>
        <SmartImage
          src={profilePic || profile.picture}
          width={300}
          type="profile"
        />
        <FilePickerTrigger onClick={() => setIsPickerOpen(true)}>
          <FontAwesomeIcon icon="camera" size="2x" />
          Update Image
        </FilePickerTrigger>
      </ProfileImageContainer>

      <FilePicker
        type="profileImage"
        open={isPickerOpen}
        onSuccess={handleFileUpload}
        onClose={() => setIsPickerOpen(false)}
        options={{
          storeTo: {
            region: 'us-east-1',
            container: `ms-userfiles`,
            path: `/user/${userId}/`,
          },
        }}
      />

      <Content>
        <h2>Personal Info</h2>

        {isCurrentUser && (
          <Info>Only you can see this profile information.</Info>
        )}

        {editMode ? (
          <ProfileForm
            profile={profile}
            onSaveChanges={updateProfile}
            onCancel={() => handleCancelEditMode()}
          />
        ) : (
          <ProfileInformation
            profile={profile}
            showPriviledgedInfo={isCurrentUser}
          />
        )}

        {canEdit && !editMode && (
          <Button
            variant="outlined"
            onClick={handleEditInfo}
            style={{ width: '100%', marginTop: '1.5rem' }}
          >
            Edit Info
          </Button>
        )}
      </Content>
    </UserProfileContainer>
  );
};

type ProfileProps = {
  profile: IUserProfile;
  showPriviledgedInfo: boolean;
};

const ProfileInformation: React.FC<ProfileProps> = ({
  profile,
  showPriviledgedInfo,
}) => {
  const {
    firstName,
    email,
    lastName,
    phoneNum,
    address,
    twitter,
    instagram,
    facebook,
    linkedin,
  } = profile;

  return (
    <ProfileInformationContainer>
      <div>
        <div>
          <strong>First Name:</strong>
          <span>{firstName || 'not provided'}</span>
        </div>
        {showPriviledgedInfo && (
          <>
            <div>
              <strong>Last Name:</strong>{' '}
              <span>{lastName || 'not provided'}</span>
            </div>

            <div>
              <strong>Email:</strong> <span>{email}</span>
            </div>
            <div>
              <strong>Primary location/address:</strong>{' '}
              <span>{address || 'not provided'}</span>
            </div>
            <div>
              <strong>Phone Number:</strong>{' '}
              <span>{phoneNum || 'not provided'}</span>
            </div>
            <div>
              <strong>X (Twitter) Handle:</strong>{' '}
              <span>{twitter || 'not provided'}</span>
            </div>
            <div>
              <strong>Instagram Handle:</strong>{' '}
              <span>{instagram || 'not provided'}</span>
            </div>
            <div>
              <strong>Facebook URL:</strong>{' '}
              <span>{facebook || 'not provided'}</span>
            </div>
            <div>
              <strong>LinkedIn URL:</strong>{' '}
              <span>{linkedin || 'not provided'}</span>
            </div>
          </>
        )}
      </div>
    </ProfileInformationContainer>
  );
};

type ProfileFormProps = {
  profile: IUserProfile;
  onSaveChanges: (profile: IUserProfile) => void;
  onCancel: () => void;
};

const ProfileForm: React.FC<ProfileFormProps> = ({
  profile,
  onSaveChanges,
  onCancel,
}) => {
  const [phoneNumGivenInitially] = useState(!!profile.phoneNum);
  const [addressGivenInitially] = useState(!!profile.address);

  const [formError, setFormError] = useState('');

  const validateForm = (values: IUserProfile) => {
    const { firstName, email, phoneNum, address } = values;

    let errors: FormikErrors<IUserProfile> = {};
    if (!firstName) {
      errors.firstName = validationMessages.firstName;
    }
    if (!email || !isEmail(email)) {
      errors.email = validationMessages.email;
    }

    if (phoneNum && !isPhoneNumber(phoneNum)) {
      errors.phoneNum = validationMessages.phoneNum;
    }

    if (!phoneNum && phoneNumGivenInitially) {
      errors.phoneNum = validationMessages.phoneNum;
    }

    if (!address && addressGivenInitially) {
      errors.address = validationMessages.address;
    }

    return errors;
  };

  const handleSubmit = async (values: IUserProfile, { setSubmitting }: any) => {
    try {
      setFormError('');
      await onSaveChanges(values);
    } catch (error) {
      setSubmitting(false);
      return setFormError(error.message);
    }
  };

  // make sure that when focused the cursor is at the end of the text
  const handleFocus = (e) => {
    const { value } = e.target;
    if (value.length) {
      e.target.setSelectionRange(value.length, value.length);
    }
  };

  return (
    <ProfileFormContainer>
      {formError && <FormError>{formError}</FormError>}
      <Formik<IUserProfile>
        initialValues={profile}
        validate={validateForm}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldTouched }) => {
          return (
            <Form autoComplete="off">
              <FormControl>
                <label>First Name:</label>
                <Field
                  type="text"
                  as={Input}
                  name="firstName"
                  placeholder="First Name"
                  autoComplete="off"
                  onFocus={handleFocus}
                />
                <ErrorMessage name="firstName" component={FormInputError} />
              </FormControl>

              <FormControl>
                <label>Last Name:</label>
                <Field
                  type="text"
                  as={Input}
                  name="lastName"
                  value={values.lastName || ''}
                  placeholder="Last Name (optional)"
                  autoComplete="off"
                  onFocus={handleFocus}
                />
              </FormControl>

              <FormControl>
                <label>Email:</label>
                <Field
                  type="email"
                  name="email"
                  as={Input}
                  placeholder="Email"
                  onBlur={() => setFieldTouched('email', true, true)}
                />
                <ErrorMessage name="email" component={FormInputError} />
              </FormControl>

              <FormControl>
                <label style={{ margin: 0 }}>Primary location/address:</label>
                <AddressField
                  name="address"
                  addressType="home"
                  placeholder="Enter address or drop a pin"
                />
              </FormControl>

              <FormControl>
                <label style={{ marginTop: '0.5rem' }}>Phone number:</label>
                <Field
                  type="text"
                  as={Input}
                  name="phoneNum"
                  value={values.phoneNum || ''}
                  placeholder="Phone Number"
                  onFocus={handleFocus}
                  onBlur={() => setFieldTouched('phoneNum', true, true)}
                />
                <ErrorMessage name="phoneNum" component={FormInputError} />
              </FormControl>

              <FormControl>
                <label>X (Twitter) Handle:</label>
                <Field
                  type="text"
                  as={Input}
                  name="twitter"
                  value={values.twitter || ''}
                  onFocus={handleFocus}
                  placeholder="X (Twitter) handle (optional)"
                />
              </FormControl>

              <FormControl>
                <label>Instagram Handle</label>

                <Field
                  type="text"
                  as={Input}
                  name="instagram"
                  value={values.instagram || ''}
                  placeholder="Instagram handle (optional)"
                  onFocus={handleFocus}
                />
              </FormControl>

              <FormControl>
                <label>Facebook URL</label>

                <Field
                  type="text"
                  as={Input}
                  name="facebook"
                  value={values.facebook || ''}
                  placeholder="Facebook URL (optional)"
                  onFocus={handleFocus}
                />
              </FormControl>

              <FormControl>
                <label>LinkedIn URL</label>

                <Field
                  type="text"
                  as={Input}
                  name="linkedin"
                  value={values.linkedin || ''}
                  placeholder="LinkedIn URL (optional)"
                  onFocus={handleFocus}
                />

                <ErrorMessage name="linkedin" component={FormInputError} />
              </FormControl>

              <Flex justify="space-between" style={{ marginTop: '2rem' }}>
                <Button variant="secondary" onClick={onCancel}>
                  Cancel
                </Button>

                <Button type="submit" disabled={isSubmitting}>
                  Save Changes
                </Button>
              </Flex>
              {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            </Form>
          );
        }}
      </Formik>
    </ProfileFormContainer>
  );
};

const UserProfileContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Info = styled.div`
  font-size: 14px;
  color: 606060;
  box-sizing: border-box;
  margin-top: 2rem;
  border: 1px solid rgba(104, 183, 78, 0.53);
  border-radius: 4px;
  background: rgba(104, 183, 78, 0.13);
  padding: 1rem;
  text-align: center;
`;

const Content = styled.div`
  margin-top: 2rem;
  padding-left: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0;
    padding-left: 2rem;
    border-left: 1px solid #ddd;
  }
`;

const ProfileImageContainer = styled.div`
  position: relative;
  height: 12rem;
  width: 12rem;
  min-width: 12rem;
  margin: 0 2rem;
  border-radius: 50%;
  background-color: #333;
  overflow: hidden;

  border: 5px solid ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 1.5rem;
  }
`;

const FilePickerTrigger = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.25);
    color: #fff;
  }

  transition: 0.25s ease-in;
`;

const ProfileInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-top: 1rem;
  }

  span {
    display: block;
    margin-top: 0.5rem;
    margin-left: 1rem;
    color: #a2a2a2;
  }
`;

const ProfileFormContainer = styled.div`
  form {
    label {
      display: inline-block;
      margin-bottom: 0.25rem;
    }
  }
`;
