import styled from 'styled-components';

import { Meta } from '../components/Meta';
import { Button, Flex } from '../components/ui';
import { Donorbox } from '../features/donate/Donorbox';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthUser } from '../features/auth/useAuthUser';

const redirectPath = '/choose-your-role';

const Donation = ({ location }) => {
  let DonationComponent = DonationContent;
  if (location.pathname === '/thank-you-for-your-donation') {
    DonationComponent = DonationThankYou;
  }

  return (
    <Container>
      <Meta>
        <title>Donate | MakeSoil</title>
      </Meta>
      <DonationComponent />
    </Container>
  );
};

const DonationContent = () => {
  const history = useHistory();
  const authUser = useAuthUser();

  const [showDonationScreen, setShowDonationScreen] = useState(false);

  let donorboxUrl = `https://donorbox.org/embed/makesoil-donate-to-repair-the-earth`;

  // options
  donorboxUrl += `?first_name=${authUser.firstName}`;
  if (authUser.lastName) {
    donorboxUrl += `&last_name=${authUser.lastName}`;
  }
  if (authUser.email) {
    donorboxUrl += `&email=${encodeURIComponent(authUser.email)}`;
  }
  // donorboxUrl += '&default_interval=m';
  // donorboxUrl += '&amount=10';
  donorboxUrl += '&hide_donation_meter=true';

  const handleClose = () => {
    history.push(redirectPath);
  };

  return (
    <ContentContainer justify="center" align="center">
      <CloseButton onClick={handleClose}>
        <FontAwesomeIcon icon="times" className="close" />
      </CloseButton>
      <div
        className={showDonationScreen ? '' : 'hidden'}
        style={{ height: '100%' }}
      >
        <DonationScreen donorboxUrl={donorboxUrl} />
      </div>

      <div className={showDonationScreen ? 'hidden' : ''}>
        <DonationText
          onShowDonationScreen={() => setShowDonationScreen(true)}
        />
      </div>
    </ContentContainer>
  );
};

const DonationText = ({ onShowDonationScreen }) => {
  return (
    <div>
      <h1>Donate to repair the Earth</h1>
      <h2>MakeSoil is free to use.</h2>

      <h2>But as a non-profit, we only continue thanks to your donations.</h2>

      <div style={{ marginTop: '2.5rem' }}>
        <Button onClick={onShowDonationScreen} padding="1rem 3rem">
          DONATE NOW
        </Button>

        <Link
          to={redirectPath}
          style={{ marginTop: '2rem', display: 'block', fontSize: '0.95rem' }}
        >
          Give later
        </Link>
      </div>
    </div>
  );
};

const DonationScreen = ({ donorboxUrl }) => {
  return (
    <div style={{ height: '100%', paddingTop: '2rem' }}>
      <h1>Make your donation</h1>
      <h3 style={{ marginTop: '1.5rem' }}>With your bank card, below.</h3>
      <p style={{ marginBottom: '2rem', fontSize: '0.875rem' }}>
        Or{' '}
        <Link to="/donate" target="_blank">
          give via bank transfer, crypto or check
        </Link>
        .
      </p>

      <Donorbox donorboxUrl={donorboxUrl} />
    </div>
  );
};

const DonationThankYou = () => {
  const history = useHistory();

  const handleContinue = () => {
    history.push(redirectPath);
  };

  return (
    <ContentContainer justify="center" align="center">
      <CloseButton onClick={handleContinue}>
        <FontAwesomeIcon icon="times" className="close" />
      </CloseButton>
      <div>
        <h1>Thank you for donating!</h1>
        <h2>And helping us to grow the MakeSoil movement.</h2>

        <Button
          onClick={handleContinue}
          padding="1rem 3rem"
          style={{ marginTop: '2rem' }}
        >
          CONTINUE
        </Button>
      </div>
    </ContentContainer>
  );
};

export default Donation;

const ContentContainer = styled(Flex)`
  height: 100%;
  margin: 0 auto;
  max-width: 960px;
  position: relative;

  text-align: center;
  color: #fff;

  div.hidden {
    display: none;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 400;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.35rem;
      font-weight: 400;
    }
  }
`;

const Container = styled.div`
  height: 100%;
  padding: 0 1rem;
  overflow-y: auto;

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('/images/soil.webp') center no-repeat;
  background-size: cover;

  a {
    text-decoration: underline;
  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1rem;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 2rem;
    right: 3rem;
  }

  width: 2.5rem;
  height: 2.5rem;

  cursor: pointer;
  border-radius: 50%;

  :hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
