import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { Flex } from '../../../components/ui';
import { IconButtonWithTooltip } from '../../../components/ui/IconButtonWithTooltip';
import { useDebounce } from '../../../hooks/useDebounce';
import { isMobileApp } from '../../../mobile/mobile';
import { theme } from '../../../app/theme';
import { isMobile } from '../../../utils/helpers';
import { googleService } from '../../map/googleService';

const minSearchLength = 3;

export const SearchBox = (props) => {
  const { onShareLocation, onPlaceSelected } = props;

  const searchInputRef = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [places, setPlaces] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const debouncedSearchText = useDebounce(searchText, 500);

  const selectFirstPlace = () => {
    if (places?.length > 0) {
      getPlaceDetailsAndSelect(places[0]);
    }
  };

  const getPlaceDetailsAndSelect = useCallback(
    async (place: google.maps.places.AutocompletePrediction) => {
      const [placeDetails] = await googleService().geocoder.getPlaceLocation(
        place.place_id
      );
      if (placeDetails) {
        setPlaces(null);
        setSearchText('');
        onPlaceSelected(placeDetails);
      }
    },
    [onPlaceSelected]
  );

  const searchPlaces = useCallback(async () => {
    if (!debouncedSearchText) {
      setPlaces(null);
    }
    if (debouncedSearchText.length > 3) {
      const foundPlaces = await googleService().places.searchPlaces(
        debouncedSearchText
      );
      setPlaces(foundPlaces);
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    searchPlaces();
  }, [searchPlaces]);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      selectFirstPlace();
    }
  };

  const handleGooglePlaceSelected = async (place) => {
    getPlaceDetailsAndSelect(place);
  };

  const clearSearchText = () => {
    setSearchText('');
    searchInputRef.current?.focus();
  };

  const showResultBox = places?.length > 0;
  const showClearSearchText =
    searchText.length > 0 && (isMobile() || isMobileApp());
  const searchDisabled = searchText.length < minSearchLength;

  return (
    <SearchBoxWrapper>
      <SearchBoxContainer>
        <SearchInputContainer align="center">
          <SearchInput
            ref={searchInputRef}
            type="text"
            value={searchText}
            placeholder="Find your location"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {showClearSearchText ? (
            <SearchBoxButton
              onClick={() => clearSearchText()}
              title="Clear SearchText"
            >
              <FontAwesomeIcon
                icon="times-circle"
                color={theme.colors.primary}
              />
            </SearchBoxButton>
          ) : (
            <SearchBoxButton
              onClick={() => selectFirstPlace()}
              disabled={searchDisabled}
              title="Search"
            >
              <FontAwesomeIcon icon="search" color={theme.colors.primary} />
            </SearchBoxButton>
          )}
          <Divider />

          <div style={{ padding: '0 0.25rem' }}>
            <IconButtonWithTooltip
              tooltipText="Use Current Location"
              icon="crosshairs"
              onClick={onShareLocation}
            />
          </div>
        </SearchInputContainer>
      </SearchBoxContainer>

      {showResultBox && (
        <ResultsContainer>
          <ul>
            {places.map((place) => (
              <li
                key={place.place_id}
                onClick={() => handleGooglePlaceSelected(place)}
                onKeyDown={() => handleGooglePlaceSelected(place)}
              >
                <FontAwesomeIcon icon="map-marker-alt" />
                <span>{place.description}</span>
              </li>
            ))}
          </ul>
        </ResultsContainer>
      )}
    </SearchBoxWrapper>
  );
};

//#region styles

const SearchBoxWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 90%;

  z-index: 15;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 315px;
  }

  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);
`;

const SearchBoxContainer = styled.div`
  border-radius: 8px;
  background-color: #fff;
`;

const SearchInputContainer = styled(Flex)`
  height: 48px;
  padding: 0 0.5rem;
  border-radius: 8px 8px 0 0;
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;

  padding: 0.5rem;
  font-size: 15px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const SearchBoxButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;

  font-size: 1rem;

  cursor: pointer;
`;

const Divider = styled.div`
  height: 60%;
  width: 1px;
  margin: 0 0.25rem;
  border-left: 1px solid #d6d6d6;
`;

const ResultsContainer = styled.div`
  position: absolute;
  margin-top: 1rem;

  width: 100%;
  max-height: 250px;
  overflow-y: hidden;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  font-size: 0.875rem;

  li {
    padding: 0.5rem 1rem;
    line-height: 30px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      margin-left: 0.5rem;
    }

    :hover {
      background-color: rgb(242, 242, 242) !important;
      cursor: pointer;
    }
  }
`;

//#endregion
