import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import { selectMapItems } from '../mapSlice';
import { IDrawerListItem, MapItemType } from '../model';
import { DrawerListItem } from './DrawerListItem';
import { ShareButton } from '../../../components/ui/ShareButton';

export const DrawerList = React.memo(() => {
  const items = useAppSelector(selectMapItems) as IDrawerListItem[];

  if (!items.length) {
    return (
      <NoItemsContainer>
        <h3>Can't find a soil site near you?</h3>

        <p style={{ marginBottom: '2rem' }}>
          Share MakeSoil to help grow the network.
        </p>

        <ShareButton url={window.location.host} />

        <p style={{ marginTop: '2rem' }}>
          You can also{' '}
          <Link to="/create-soil-site">start your own soil site</Link>.
        </p>
      </NoItemsContainer>
    );
  }

  const sites = items.filter((i) => i.type === MapItemType.SoilSite);

  return (
    <DrawerListContainer>
      {sites.length > 0 && (
        <div>
          <h5>Soil Sites</h5>
          {sites.map((item) => (
            <DrawerListItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </DrawerListContainer>
  );
});

const DrawerListContainer = styled.div`
  padding: 0 1rem;

  > div {
    margin-bottom: 1rem;
  }

  h5 {
    margin: 0 0 0.5rem;
  }
`;

const NoItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 1rem;
  line-height: 1.375;

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
