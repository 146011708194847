import React from 'react';
import { StaticPage } from '../../components/StaticPage';

const PRIVACY_URL =
  'https://raw.githubusercontent.com/MakeSoil/public-pages/master/privacy.md';
const Privacy = () => (
  <StaticPage markdownURL={PRIVACY_URL} pageTitle="Privacy Policy" />
);

export default Privacy;
