import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Flex } from '../components/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MobileAppBadges } from '../components/MobileAppBadges';
import { isMobileApp } from '../mobile/mobile';

const MobileAppLanding = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Mobile App | MakeSoil</title>
      </Helmet>

      <MobileAppLandingContainer>
        <Hero>
          <Container>
            <Flex justify="space-around" align="center" gap="2rem">
              <div>
                <h1>Get the MakeSoil app</h1>
                <p style={{ marginBottom: 0 }}>
                  Give your food scraps to a Soil Maker near you.
                </p>
                <p style={{ marginTop: '1rem' }}>
                  Or become a Soil Maker and compost your neighbors' food
                  scraps.
                </p>
                {!isMobileApp() && (
                  <StyledAppBadges
                    popupStyle={{
                      width: '149px',
                      height: '149px',
                      top: '2.5rem',
                      right: '0',
                    }}
                    badgeStyle={{ width: '150px' }}
                  />
                )}
              </div>

              <img
                src="/images/applandingpage/iphone-map.png"
                alt="iPhone and Android with MakeSoil App"
                id="iphone-map"
              />
            </Flex>
          </Container>
        </Hero>

        <Section2>
          <Container>
            <h2 style={{ textAlign: 'center' }}>
              Together, we can create healthy soil, abundant food, and repair
              the Earth.
            </h2>
            <Flex
              justify="space-around"
              align="center"
              style={{ marginTop: '3rem' }}
            >
              <img
                src="/images/applandingpage/iphone-drawer.png"
                alt="iPhone MakeSoil App"
                style={{ width: '325px', margin: '0 1rem' }}
                id="iphone-drawer"
              />

              <Flex direction="column" gap="1rem">
                <div>
                  <Flex align="center" gap="1rem">
                    <span className="icon-circle">
                      <FontAwesomeIcon icon="search" />
                    </span>
                    <h4>Find a soil site near you</h4>
                  </Flex>
                  <p>Connect with a Soil Maker in your neighborhood.</p>
                </div>

                <div>
                  <Flex align="center" gap="1rem">
                    <span className="icon-circle">
                      <FontAwesomeIcon icon="apple-alt" />
                    </span>
                    <h4>Drop off your food scraps</h4>
                  </Flex>
                  <p>Go to the soil site and contribute your scraps.</p>
                </div>

                <div>
                  <Flex align="center" gap="1rem">
                    <span className="icon-circle">
                      <FontAwesomeIcon icon="users" />
                    </span>
                    <h4>Or start your own soil site</h4>
                  </Flex>
                  <p>Become a Soil Maker who turns scraps into soil.</p>
                </div>

                {!isMobileApp() && (
                  <StyledAppBadges
                    popupStyle={{
                      width: '149px',
                      height: '149px',
                      top: '2.5rem',
                      right: '0',
                    }}
                    badgeStyle={{ width: '150px' }}
                    style={{ marginTop: '1rem' }}
                  />
                )}
              </Flex>
            </Flex>
          </Container>
        </Section2>

        <Section3>
          <div className="blockquote2-wrapper">
            <div className="blockquote2">
              <blockquote>
                <h4>
                  MakeSoil has made it possible for me to find local people who
                  bring me their food scraps, and to communicate effectively
                  with them. Having a dedicated soil site page on MakeSoil helps
                  our participants understand how our local project is part of a
                  global vision.
                </h4>
              </blockquote>

              <Flex
                align="center"
                gap="0.75rem"
                style={{ marginLeft: '175px' }}
              >
                <img
                  src="/images/applandingpage/elizabeth.png"
                  alt="quote author"
                  className="rounded-circle"
                  style={{ width: '3.25rem', height: '3.25rem' }}
                />
                <div>
                  <p style={{ fontSize: '1.25rem', margin: '0 0 0.25rem' }}>
                    Elizabeth
                  </p>
                  <p style={{ fontSize: '0.875rem', margin: 0 }}>
                    Soil Maker in Florida
                  </p>
                </div>
              </Flex>
            </div>
          </div>
        </Section3>
      </MobileAppLandingContainer>
    </PageContainer>
  );
};

export default MobileAppLanding;

const StyledAppBadges = styled(MobileAppBadges)`
  padding-left: 0.5rem;
  justify-content: center;

  @media (min-width: 900px) {
    padding-left: 0;
    justify-content: unset;
  }
`;

const PageContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  background-color: #fff;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.breakpoints.lg};
`;

const Hero = styled.div`
  padding: 5rem 1rem;
  background: linear-gradient(
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.5)
    ),
    url('/images/felixx.jpg') center no-repeat;
  background-size: cover;

  color: #212529;
  text-align: center;

  h1 {
    font-size: 3.25rem;
    font-weight: normal;
    margin: 0;
  }

  p {
    font-size: 1.75rem;
  }

  img#iphone-map {
    display: none;
    max-width: 300px;
  }

  @media (min-width: 900px) {
    img#iphone-map {
      display: block;
    }
    text-align: unset;
  }
`;

const Section2 = styled.div`
  padding: 3rem 1rem;

  h4 {
    font-size: 0.925rem;
    text-transform: uppercase;
  }

  img#iphone-drawer {
    display: none;
  }

  p {
    margin-top: 0.75rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    img#iphone-drawer {
      display: block;
    }
    text-align: unset;
  }

  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;

    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    color: #fff;

    font-size: 1.25rem;
  }
`;

const Section3 = styled.div`
  background-color: rgb(69, 177, 57);

  color: #fff;

  .blockquote2-wrapper {
    display: flex;
  }

  .blockquote2 {
    position: relative;
    font-family: inherit;
    max-width: 820px;
    margin: 80px auto;
    padding: 10px;
    align-self: center;
    background-color: rgb(69, 177, 57);
  }

  .blockquote2 h4 {
    font-family: inherit;
    position: relative; /* for pseudos */
    color: #fff;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1;
    margin: 0;
    border: 4px solid #fff;
    border: solid 4px;
    border-radius: 20px;
    padding: 25px;
    text-transform: none;
  }

  /* Blockquote right double quotes */
  .blockquote2 h4:after {
    content: '';
    position: absolute;
    border: 4px solid #fff;
    border-radius: 0 50px 0 0;
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: 48px;
    border-bottom: none;
    border-left: none;
    z-index: 3;
  }

  .blockquote2 h4:before {
    content: '';
    position: absolute;
    width: 80px;
    border: 10px solid rgb(69, 177, 57);
    bottom: -8px;
    left: 50px;
    z-index: 2;
  }

  /* increase header size after 600px */
  @media all and (min-width: 600px) {
    .blockquote2 h4 {
      font-size: 1.125rem;
      line-height: 1.2;
      text-transform: none;
    }
  }

  /* Blockquote subheader */
  .blockquote2 h5 {
    position: relative;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 0.8;
    margin: 0;
    padding-top: 0px;
    z-index: 1;
    margin-left: 160px;
    padding-left: 12px;
  }

  .blockquote2 h5:first-letter {
    margin-left: -12px;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }
`;

const MobileAppLandingContainer = styled.div`
  position: relative;
  overflow: auto;
`;
