import styled from 'styled-components';
import { Meta } from '../components/Meta';
import { BouncingArrow } from '../components/ui/BouncingArrow';
import { Flex } from '../components/ui';
import { ImageSlider } from '../components/ImageSlider';

const Garden = () => {
  return (
    <PageContainer>
      <Meta>
        <title>The Garden | MakeSoil</title>
      </Meta>
      <Hero>
        <Flex gap="1rem" align="center">
          <img src="/images/logo-white.svg" alt="white logo" />
          <h1>
            The <strong>Garden</strong>
          </h1>
        </Flex>

        <h2>
          A select group of visionary leaders <br /> dedicated to repairing the
          Earth.
        </h2>
        <p style={{ lineHeight: 1.5 }}>
          Members of The Garden support MakeSoil's mission financially: <br />
          <strong>
            activating citizens everywhere to make soil and grow food together
            on a planetary scale
          </strong>
          .
        </p>

        <p>Scroll down to see some of the current members.</p>

        <BouncingArrow
          icon="arrow-alt-circle-down"
          size="3x"
          style={{ marginTop: '2rem' }}
        />
      </Hero>

      <Donors>
        <h2>Members of The Garden</h2>

        <DonorList>
          <CardContainer>
            <CardImageContainer>
              <img src={'/images/josh-garden.webp'} alt="Josh Whiton" />
            </CardImageContainer>

            <CardContent>
              <h1>Josh Whiton</h1>
              <p className="bio">
                Regenerative technologist{' '}
                <a
                  href="https://www.joshwhiton.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Josh Whiton
                </a>{' '}
                set up MakeSoil in 2018 as a nonprofit that exists 100% for the
                public, planetary good.
              </p>
              <p className="bio">
                Prior to founding MakeSoil, Josh was named Champion of Change by
                the White House for his positive impact from innovating public
                transit. He was also awarded a Global Impact Visa by the New
                Zealand government.
              </p>
            </CardContent>
          </CardContainer>

          <CardContainer>
            <CardImageContainer>
              <ImageSlider
                images={[
                  '/images/garden/ft_home.png',
                  '/images/garden/ft_team.png',
                ]}
                interval={7000}
              />
            </CardImageContainer>

            <CardContent>
              <h1>Flourish Trust</h1>
              <p className="bio">
                <a
                  href="https://www.flourishtrust.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Flourish Trust
                </a>{' '}
                - co-founded by Kimbal and Christiana Musk - is dedicated to
                catalyzing the healing and regeneration of humanity and our
                planet.
              </p>

              <p className="bio">
                Their efforts include supporting sustainable, equitable food
                systems, and catalyzing conscious capital to create an inclusive
                and regenerative economy.
              </p>
            </CardContent>
          </CardContainer>
        </DonorList>
      </Donors>
      <JoinTheGarden>
        <h2>Join The Garden</h2>

        <p style={{ lineHeight: 1.5 }}>
          Levels of support range from $10K per year, to $500K (the largest
          single donation so far).
        </p>

        <p style={{ lineHeight: 1.5 }}>
          Members receive personal updates on progress, and join a core group
          <br />
          whom we consult to advance the MakeSoil mission.
        </p>

        <p style={{ lineHeight: 1.5 }}>
          To become a member of The Garden, please email{' '}
          <strong>thegarden@makesoil.org</strong>
        </p>
      </JoinTheGarden>
    </PageContainer>
  );
};

export default Garden;

const Donors = styled.section`
  padding: 2rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 3rem 2rem;
  }

  text-align: center;
`;

const DonorList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;

  margin-top: 2.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 900px;
    margin: 2.5rem auto;
  }
`;

const CardContainer = styled.div`
  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 325px;
    max-width: 400px;
  }

  background-color: #fff;
  border: 1px solid #dfdfdf;

  border-top: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;

  box-shadow: 0 40px 81px -16px rgba(50, 50, 93, 0.25),
    0 24px 48px -24px rgba(0, 0, 0, 0.3);

  :hover {
    transform: scale(1.05);

    box-shadow: 0 40px 81px -16px rgba(50, 50, 93, 0.35),
      0 24px 48px -24px rgba(0, 0, 0, 0.45);
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1 {
    font-size: 1.725rem;
    font-weight: 400;
  }

  p {
    margin: 0;
    margin-top: 0.75rem;
    line-height: 1.25;
  }

  transition: 0.25s ease;
`;

const CardImageContainer = styled.div`
  height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardContent = styled.div`
  padding: 2rem;

  .bio {
    line-height: 1.5;

    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const PageContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Hero = styled.section`
  position: relative;
  min-height: calc(60vh - 61px);
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 3rem 2rem;
  }

  img {
    height: 64px;
  }

  background: url('/images/map.png') center no-repeat;
  background-size: cover;
  background-color: #5ab474;

  text-align: center;
  color: #fff;

  h1 {
    margin: 0;
    margin-top: 1rem;
    font-size: 2.75rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
  }

  p {
    font-size: 1.25rem;
    font-weight: 300;
  }
`;

const JoinTheGarden = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2rem 5rem;
  }

  background-color: #5ab474;
  color: #fff;
  text-align: center;

  p {
    font-size: 1.25rem;
    font-weight: 300;
    margin: 0.75rem;
  }
`;
