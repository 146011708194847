import React from "react";
import {Modal} from "../../components/ui";
import {StaticPage} from "../../components/StaticPage";

const TERMS_URL = 'https://raw.githubusercontent.com/MakeSoil/public-pages/master/terms.md';

export const TermsOfUseModal = ({
  isOpen,
  onClose
}) => {

  console.log("Render ToU");

  return (
    <Modal
      header="Terms of Use"
      isOpen={isOpen}
      onClose={onClose}
      actionButtonText={"Close"}
    >
      <StaticPage hideNavigation markdownURL={TERMS_URL} pageTitle="Terms of Use" />
    </Modal>
  );
};
