import styled from 'styled-components';
import { Box } from '../../components/ui/Box';
import { ComponentProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type InsightBoxProps = ComponentProps<'div'> & {
  title: string;
  value: string | number;
  color: string;
  icon?: IconProp;
};

export const InsightBox = ({
  title,
  value,
  color,
  icon,
  style,
}: InsightBoxProps) => {
  return (
    <InsightBoxContainer color={color} style={style}>
      <div>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>
        <h4>{title}</h4>
        <span>{value}</span>
      </div>
    </InsightBoxContainer>
  );
};

const InsightBoxContainer = styled(Box)<{ color: string }>`
  display: flex;
  flex: 1;

  padding: 0;

  div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 2;

    border-radius: 4px 0 0 4px;
    background-color: ${(props) => props.color};
    font-size: 2rem;
    color: #fff;
  }

  div:nth-child(2) {
    flex: 5;

    padding: 1rem;

    h4 {
      font-size: 0.875rem;
      font-weight: 300;
      text-transform: uppercase;
      margin: 0 0 0.5rem;
    }
    span {
      font-weight: 600;
      font-size: 1.5rem;
    }
  }
`;
