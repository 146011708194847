import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { CountIndicator } from '../../../components/ui/CountIndicator';
import { useNotifications } from '../useNotifications';
import { Flex } from '../../../components/ui';
import { NotificationListItem } from './NotificationListItem';

export const NotificationCentre = () => {
  const {
    notifications,
    unreadCount,
    isDropDownOpen,
    toggleDropDownVisibility,
    fetchMoreNotifications,
  } = useNotifications();

  // hide dropdown when clicked outside of it
  const outsideRef = useClickOutside(
    () => isDropDownOpen && toggleDropDownVisibility()
  );

  // for now, disabled web push notifications https://trello.com/c/sEKE6vm3
  // useWebPush();

  const handleScroll = ({ target }) => {
    // when scrolled to bottom fetch more notifications
    const isBottom =
      target.scrollTop + target.clientHeight === target.scrollHeight;
    if (!isBottom || notifications.length < 20) {
      return;
    }
    fetchMoreNotifications(notifications[notifications.length - 1].createdAt);
  };

  return (
    <NotificationCentreContainer>
      <div ref={outsideRef}>
        <DropdownToggle onClick={() => toggleDropDownVisibility()}>
          <FontAwesomeIcon icon="bell" />
          <CountIndicator count={unreadCount} />
        </DropdownToggle>

        <NotificationsDropdownContainer visible={isDropDownOpen}>
          <NotificationsHeader justify="space-between" align="center">
            <div></div>
            <div style={{ color: '#616161' }}>Notifications</div>
            <div
              onClick={() => toggleDropDownVisibility()}
              style={{ cursor: 'pointer' }}
            >
              <FontAwesomeIcon icon="times" />
            </div>
          </NotificationsHeader>

          {notifications.length === 0 ? (
            <Empty />
          ) : (
            <NotificationsList onScroll={handleScroll}>
              {notifications.map((notification) => (
                <NotificationListItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </NotificationsList>
          )}
        </NotificationsDropdownContainer>
      </div>
    </NotificationCentreContainer>
  );
};

const Empty = () => {
  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      style={{ padding: '4rem 2rem' }}
    >
      <img width="20%" src="/images/logo.svg" alt="" />
      <h3>No Notifications</h3>
    </Flex>
  );
};

const NotificationCentreContainer = styled.div``;

const DropdownToggle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 38px;
  width: 38px;
  background-color: #333;
  border-radius: 50%;

  cursor: pointer;
  transition 0.35s ease;

  :hover {
    background-color: #3c3c3c;
  }

  svg {
    width: 1rem;
  }
`;

const NotificationsDropdownContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  top: calc(3.875rem + env(safe-area-inset-top));
  right: 0.875rem;

  z-index: 999;
  width: 92vw;
  max-width: 92vw;
  max-height: calc(
    90vh - env(safe-area-inset-top) - ${({ theme }) => theme.headerHeight}
  );

  background-color: #fff;

  display: none;
  ${({ visible }) => visible && 'display: flex; flex-direction: column;'}

  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 300px;
    width: 350px;
  }
`;

const NotificationsHeader = styled(Flex)`
  background-color: #f7f7f7;
  color: #a5a5a5;
  border-bottom: 1px solid #ebebeb;
  border-radius: 4px 4px 0 0;

  > div {
    padding: 0.75rem 1rem;
  }
`;

const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;

  overflow-y: auto;

  > :not(:first-child) {
    border-top: 1px solid rgb(235, 235, 235);
  }

  :last-child {
    border-radius: 0 0 4px 4px;
  }
`;
