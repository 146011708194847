import React from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea<{
  resize?: 'vertical' | 'horizontal' | 'none';
}>`
  padding: 0.5rem;
  width: 100%;
  min-height: 5rem;
  border: 1px solid #ced4da;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  color: #495057;
  outline: none;

  ${({ resize }) =>
    resize &&
    `
			resize: ${resize};
		`}

  ::placeholder {
    font-style: normal;
    font-size: 1rem;
  }

  font-family: inherit;
  font-size: inherit;
  font-style: inherit;

  :focus {
    border: 1px solid #a3abb3;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }

  transition: border-color 0.25s ease-in-out;
`;

type Props = {
  className?: string;
  placeholder?: string;
  rows?: number;
  value?: any;
  maxLength?: number;
  resize?: 'vertical' | 'horizontal' | 'none';
  onChange?: (e: any) => void;
};

export const TextArea: React.FC<Props> = (props) => {
  return <StyledTextArea {...props} />;
};
