import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { Loader } from '../../../components/ui';
import { ThreadMessage } from '../components/ThreadMessage';
import { useMessenger } from '../useMessenger';
import { useStartNewThread } from './useStartNewThread';
import { useScrollHandler } from './useScrollHandler';
import { ThreadHeader } from './ThreadHeader';
import { useSelectThreadFromUrl } from './useSelectThreadFromUrl';

const messageLoadLimit = 20;

export const Thread: React.FC = () => {
  const { currentThread } = useMessenger();
  useSelectThreadFromUrl();
  useStartNewThread();

  const {
    threadRef,
    bottomRef,
    fetchMoreWhenScrolledToTop,
  } = useScrollHandler({ messageLoadLimit });

  const animationVariants = {
    enter: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  // if this is not a new thread display loader until the messages are loaded
  if (
    !currentThread ||
    (!currentThread.messages.length && currentThread.lastMessage)
  ) {
    return <Loader />;
  }

  return (
    <ThreadContainer
      key={currentThread.id}
      variants={animationVariants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <ThreadHeader />

      <ThreadMessages onScroll={fetchMoreWhenScrolledToTop} ref={threadRef}>
        {[...currentThread.messages].reverse().map((m) => (
          <motion.div
            key={m.id}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={animationVariants}
          >
            <ThreadMessage key={m.id} message={m} />
          </motion.div>
        ))}
        <div ref={bottomRef} />
      </ThreadMessages>
    </ThreadContainer>
  );
};

const ThreadContainer = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const ThreadMessages = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 1rem;
  padding-bottom: 1rem;

  overflow-y: auto;
`;
