import { useEffect, useState } from 'react';

/**
 * Returns boolean when screen size matches the specified media query
 * @param query media query
 */
export const useMediaQuery = (query: string) => {
  const mql: MediaQueryList = window.matchMedia(query);
  const [match, setMatch] = useState<boolean>(mql.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => {
      setMatch(e.matches);
    };

    // older Safari does not support addEventListener on MediaQueryList
    if (mql.addEventListener) {
      // listen to the screen change
      mql.addEventListener('change', handler);
      // cleanup
      return () => mql.removeEventListener('change', handler);
    } else if (mql.addListener) {
      // listen to the screen change
      mql.addListener(handler);
      // cleanup
      return () => mql.removeListener(handler);
    }
  }, [mql]);

  return match;
};
