import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { ILocation } from '../../../app/model/ILocation';
import {
  mapCenterUpdated,
  mapZoomUpdated,
  selectAddress,
  selectGeocoderResult,
  selectMapCenter,
  selectMapZoom,
  selectPinnedLocation,
  setGeocoderResult,
  setPinLocation,
} from '../locationPickerSlice';

import { validationMessages } from '../../../utils/formValidation';
import { getCurrentLocation } from '../../../utils/geo';
import { Button, Modal } from '../../../components/ui';
import { GeocoderResult } from '../../../components/ui/forms/GeoSuggestInput';
import { SearchBox } from './SearchBox';
import { googleService } from '../../map/googleService';
import GoogleMapNoLinks from '../../../components/GoogleMapNoLinks';

const geocodeLocation = async (location: ILocation) => {
  return googleService().geocoder.getLocationDetails(location);
};

type ExistingAddressLocation = {
  location?: ILocation;
  address?: string;
};

type LocationModalProps = {
  open: boolean;
  onClose: () => void;
  onLocationConfirmed: (result: GeocoderResult) => void;

  existingAddressLocation?: ExistingAddressLocation;
  header?: string;
  markerIcon?: 'site' | 'home';
};

export const LocationModal: React.FC<LocationModalProps> = (props) => {
  const {
    open,
    onClose,
    onLocationConfirmed,
    existingAddressLocation,
    header = 'Set location on map',
    markerIcon = 'site',
  } = props;

  const dispatch = useAppDispatch();

  const mapCenter = useSelector(selectMapCenter);
  const mapZoom = useSelector(selectMapZoom);
  const address = useAppSelector(selectAddress);
  const pinLocation = useAppSelector(selectPinnedLocation);
  const result = useAppSelector(selectGeocoderResult);

  const [map, setMap] = useState<google.maps.Map>(null);

  useEffect(() => {
    if (open && !existingAddressLocation?.location) {
      (async function getLocation() {
        try {
          const {
            coords: { latitude: lat, longitude: lng },
          } = await getCurrentLocation();
          dispatch(mapCenterUpdated({ lat, lng }));
          dispatch(mapZoomUpdated(12));
        } catch (error) {
        } finally {
          return;
        }
      })();
    }
  }, [dispatch, existingAddressLocation?.location, open]);

  const handleConfirmLocation = () => {
    if (!result && pinLocation) return onClose();

    if (result && pinLocation) {
      // we're setting the map center as well as saving the coordinates of the location
      // to where user dropped a pin rather than the geometry from geocoder result
      // because we want the pin to be at the exact location rather than geocoded/address location
      dispatch(mapCenterUpdated(pinLocation));
      onLocationConfirmed({
        ...result,
        lat: pinLocation.lat,
        lng: pinLocation.lng,
      });
    }
  };

  const getAndSetGeocodeResult = async (location: ILocation) => {
    const results = await geocodeLocation(location);
    const result = results[0];
    dispatch(
      setGeocoderResult({
        address: result.formatted_address,
        addressComponents: result.address_components.map((comp) => ({
          longName: comp.long_name,
          shortName: comp.short_name,
          componentType: comp.types,
        })),
        placeId: result.place_id,
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      })
    );
    // // we're setting the pin exactly where the user droppped it
    // // and disregard the coordinates that come from geocoder
    // // Sunny's decision
    dispatch(setPinLocation(location));
  };

  const handleMapClick = async (e: google.maps.MapMouseEvent) => {
    getAndSetGeocodeResult(e.latLng.toJSON());
  };

  const handleDragEnd = async (e: google.maps.MapMouseEvent) => {
    getAndSetGeocodeResult(e.latLng.toJSON());
  };

  const handleShareLocation = async () => {
    try {
      const {
        coords: { latitude: lat, longitude: lng },
      } = await getCurrentLocation();
      dispatch(mapCenterUpdated({ lat, lng }));
      dispatch(mapZoomUpdated(12));
      dispatch(setPinLocation({ lat, lng }));
    } catch (error) {
      toast.error(validationMessages.location);
    }
  };

  const handlePlaceSelected = (place: google.maps.GeocoderResult) => {
    map.fitBounds(place.geometry.viewport);
  };

  return (
    <Modal
      header={header}
      isOpen={open}
      onClose={onClose}
      actionButton={
        <Button
          disabled={!pinLocation && !result}
          onClick={handleConfirmLocation}
        >
          CONFIRM LOCATION
        </Button>
      }
      noPadding
    >
      <div>
        {!result && !pinLocation && (
          <HelpBox>
            <FontAwesomeIcon icon="info-circle" size="2x" /> Click on the map to
            drop a pin. You can also drag the pin.
          </HelpBox>
        )}

        {address && pinLocation && (
          <ResultContainer>
            <p>
              <strong>Address:</strong> {address}
            </p>
            <p>
              <strong>Coordinates:</strong>{' '}
              {`${pinLocation.lat}, ${pinLocation.lng}`}
            </p>
          </ResultContainer>
        )}
      </div>

      <MapContainer>
        <GoogleMapNoLinks
          mapContainerStyle={{
            height: '100%',
          }}
          clickableIcons={false}
          center={mapCenter}
          zoom={mapZoom}
          options={{
            styles: [
              {
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            restriction: {
              latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              strictBounds: true,
            },
            gestureHandling: 'greedy',
          }}
          onClick={handleMapClick}
          onLoad={(m) => {
            setMap(m);
          }}
          onZoomChanged={() => {
            if (map) dispatch(mapZoomUpdated(map.getZoom()));
          }}
        >
          {pinLocation && (
            <Marker
              position={{ lat: pinLocation.lat, lng: pinLocation.lng }}
              icon={
                markerIcon === 'site' ? '/icons/soil-site-icon-exact.svg' : null
              }
              draggable
              onDragEnd={handleDragEnd}
            />
          )}

          <SearchBox
            onShareLocation={handleShareLocation}
            onPlaceSelected={handlePlaceSelected}
          />
        </GoogleMapNoLinks>
      </MapContainer>
    </Modal>
  );
};

const MapContainer = styled.div`
  position: relative;
  height: 350px;
  width: 100%;

  contain: layout paint;

  margin-top: 0.75rem;

  img,
  img:focus {
    outline: none;
  }
  div[role='button'] {
    outline: none;
  }
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;

  gap: 0.5rem;

  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 0.75rem;
  font-size: 0.875rem;
`;

const HelpBox = styled(InfoBox)`
  border: 1px solid #74b95e;
  color: #74b95e;
`;

const ResultContainer = styled.div`
  margin-top: 0.75rem;

  p {
    margin: 0;
    line-height: 1.5;
    font-size: 0.875rem;
  }
`;
