import styled from 'styled-components';

import { Avatar } from '../../../components/ui';
import { formatMessageDate } from '../../../utils/date';
import { IDirectMessage } from '../model';
import { LinkParser } from '../../../components/ui/LinkParser';
import { useAuthUser } from '../../auth/useAuthUser';

type ThreadMessageProps = {
  message: IDirectMessage;
};

export const ThreadMessage = (props: ThreadMessageProps) => {
  const { text, createdAt, author, authorId } = props.message;

  const authUser = useAuthUser();
  const align = authUser.id === authorId ? 'right' : 'left';

  return (
    <MessageContainer align={align}>
      <Avatar user={author} />
      <Message align={align}>
        <MessageDate align={align}>{formatMessageDate(createdAt)}</MessageDate>
        <MessageContent align={align}>
          <LinkParser blank>{text}</LinkParser>
        </MessageContent>
      </Message>
    </MessageContainer>
  );
};

//#region styled components

const MessageContainer = styled.div<{ align: 'left' | 'right' }>`
  display: flex;
  flex: 1;

  // avatar
  > :first-child {
    margin-top: 0.25rem;
  }

  ${({ align }) =>
    align === 'left'
      ? `
      align-self: flex-start;
      `
      : `
      align-self: flex-end;
      flex-direction: row-reverse;
    `};

  margin-top: 1rem;
`;

const Message = styled.div<{ align: 'left' | 'right' }>`
  ${({ align }) =>
    align === 'left'
      ? `
margin-left: 1rem;
`
      : `
margin-right: 1rem;
`};
`;

const MessageContent = styled.div<{ align: 'left' | 'right' }>`
  border-radius: 8px;
  padding: 0.75rem 1rem;
  position: relative;
  font-size: 0.925rem;
  word-break: break-word;
  white-space: pre-wrap;

  ${({ align }) =>
    align === 'left'
      ? `
    background-color: #ddd;
    border: 1px solid #ccc;
    border-top-left-radius: 0;
    `
      : `
    background-color: #d0ecc6;
    border: 1px solid #aeda9e;
    border-top-right-radius: 0;
  `};

  &::after {
    content: '';
    border-radius: inherit;
    background-color: inherit;
    border: inherit;
    border-bottom: none;
    height: 10px;
    width: 17px;

    ${({ align }) =>
      align === 'left'
        ? `
    border-right: none;
    `
        : `
    border-left: none;
  `};

    top: -1px;
    position: absolute;
    transform: ${({ align }) =>
      align === 'right' ? 'skewX(-35deg)' : 'skewX(35deg)'};
    left: ${({ align }) => align !== 'right' && '-5px'};
    right: ${({ align }) => align === 'right' && '-5px'};
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const MessageDate = styled.small<{ align: 'left' | 'right' }>`
  width: 100%;
  display: inline-block;
  text-align: ${({ align }) => align};
  color: #969696;
`;

//#endregion
