import React from 'react';
import { isMobileApp, mobileOpenUrl } from '../../mobile/mobile';
import { Link } from 'react-router-dom';

// A SmartLink can be used instead of <Link> or <a> to standardize handling of in-app links:
// - relative links are routed inside the app as <Link>
// - absolute links with internal origins (see list) are handled as relative links
// - all document links (links with file extension) are opened in a new browser window

// known origins handled as internal links
const origins = [
  'https://makesoil.org',
  'https://www.makesoil.org',
  'https://makesoil2-staging.herokuapp.com',
  'https://proxy.blink.at',
  'http://localhost:3000',
];

type DocLinkProps = {
  href: string;
  children?: React.ReactNode;
};

export const SmartLink: React.FC<DocLinkProps> = (props) => {
  // normalize the href to include the origin
  const href =
    !props.href.startsWith('http') && isMobileApp()
      ? window.location.origin + props.href
      : props.href;

  // check if the url is internal (from a known origin)
  const url = new URL(href, window.location.origin);
  const isInternal = origins.some((o) => url.origin === o);
  // and points to a document (contains a file extension)
  const isDoc = url.pathname.split('/').pop().includes('.');

  //  console.log({
  //    hrefIn: props.href,
  //    hrefOut:href,
  //    isInternal: isInternal,
  //    isDoc: isDoc
  //  });

  // link to relative href if not a document and internal origin
  // clicking/tapping causes in-app routing
  if (!isDoc && isInternal) {
    const dest = url.href.replace(url.origin, '');
    //    console.log(props.href + " => LINK: " + dest);
    return <Link to={dest}>{props.children}</Link>;
  }

  //  console.log(props.href + " => A: " + href);

  // open all other links (external origin or internal/external document link in a new browser)
  const linkProps = isMobileApp()
    ? {
        // on mobileapp: pass to cordova on click
        onClick: (e) => {
          mobileOpenUrl(href);
          e.preventDefault();
          return false;
        },
      }
    : {
        // in browser: generate normal link
        target: '_blank',
        rel: 'noopener noreferrer',
      };

  return (
    <a href={href} {...linkProps}>
      {props.children}
    </a>
  );
};
