import { useEffect } from 'react';
import {
  getMessengerObservable,
  messageReceived,
  markRead,
  threadReceived,
  selectSelectedThread,
} from './messengerSlice';
import { useAuthUser } from '../auth/useAuthUser';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

/**
 * handle messenger subscription and cleanup
 */
export function useMessengerSubscription() {
  const dispatch = useAppDispatch();
  const currentThread = useAppSelector(selectSelectedThread);
  const authUser = useAuthUser();

  useEffect(() => {
    const subscription = getMessengerObservable(authUser.id).subscribe(
      ({ data }) => {
        if (data.messengerSubscription) {
          const { messengerSubscription } = data;
          const { thread, event } = messengerSubscription;

          if (event === 'newMessage') {
            const message = thread.messages[0];
            dispatch(messageReceived(message));
            // if message came to currently selected thread, then mark as read
            const shouldMarkRead =
              message.directMessageThreadId === currentThread.id &&
              currentThread.unreadMessageCount > 0;
            if (shouldMarkRead) {
              dispatch(markRead(message.directMessageThreadId));
            }
          } else if (event === 'newThread') {
            if (thread.lastMessage.authorId !== authUser.id) {
              dispatch(threadReceived(thread));
            }
          }
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [
    authUser.id,
    currentThread?.id,
    currentThread?.unreadMessageCount,
    dispatch,
  ]);
}
