import { useState } from 'react';
import styled from 'styled-components';

import { isMobileApp } from '../mobile/mobile';
import { getMobileDevice } from '../utils/helpers';
import { Button, Flex } from './ui';
import { mobileAppStoreUrls } from '../utils/config';
import { useLocation } from 'react-router-dom';

const appBannerStorageKey = 'app-banner';

type Props = {
  excludePaths?: string[];
};

export const MobileBanner = ({ excludePaths }: Props) => {
  const shownThisSession = sessionStorage.getItem(appBannerStorageKey);
  const [visible, setVisible] = useState(!shownThisSession);

  const location = useLocation();

  if (excludePaths.includes(location.pathname)) return null;
  if (isMobileApp()) return null;

  const device = getMobileDevice();
  const isMobileDevice = device === 'Android' || device === 'iOS';
  if (!isMobileDevice) return null;

  if (!visible) return null;

  const handleConfirm = () => {
    sessionStorage.setItem(appBannerStorageKey, 'true');
    setVisible(false);

    if (device === 'iOS') {
      // return window.location.replace(`makesoil://${window.location.pathname}`);
      return window.location.replace(mobileAppStoreUrls.appleStore);
    }
    if (device === 'Android') {
      // const fallbackUrl = `S.browser_fallback_url=${mobileAppStoreUrls.googlePlayStore}`;
      // const url = `intent://view/#Intent;scheme=makesoil;package=app.makesoil.org;${fallbackUrl};end`;
      // return window.location.replace(url);
      return window.location.replace(mobileAppStoreUrls.googlePlayStore);
    }
  };

  const handleCancel = () => {
    sessionStorage.setItem(appBannerStorageKey, 'true');
    setVisible(false);
  };

  return (
    <Container>
      <TextContainer>
        <h3>Switch to the MakeSoil app</h3>
        <p>
          Never miss new activity. Switch to the MakeSoil app to get the full
          experience.
        </p>
      </TextContainer>
      <ButtonsContainer justify="space-between">
        <CancelButton variant="outlined" onClick={handleCancel}>
          Not now
        </CancelButton>
        <ConfirmButton variant="secondary" onClick={handleConfirm}>
          Switch to the app
        </ConfirmButton>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9999;

  padding: 0.5rem 1.5rem 1rem;

  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;

const TextContainer = styled.div`
  h3 {
    margin-bottom: 1rem;
  }
`;

const ButtonsContainer = styled(Flex)`
  margin-top: 1.5rem;

  button {
    border-radius: 8px !important;
  }
`;

const CancelButton = styled(Button)`
  border: 1px solid #fff !important;
  color: #fff !important;
`;

const ConfirmButton = styled(Button)`
  font-weight: bold;
  color: #333;
`;
