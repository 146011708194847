import { useState } from 'react';

export const useSubmitting = (cb: (args?: any) => Promise<any>) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!isSubmitting) {
      setSubmitting(true);
      await cb();
      setSubmitting(false);
    }
  };

  return { handleSubmit, isSubmitting };
};
