import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SearchResults, ResultListSelectionState } from './SearchResults';
import { Flex } from '../../../components/ui';
import {
  centerChanged,
  fetchSearchResults,
  hideSearchResults,
  mapReset,
  placeSelected,
  searchTermChanged,
  selectSearchBoxFocused,
  selectSearchBoxVisible,
  selectSearchResults,
  selectSearchTerm,
  selectSelectedMapItem,
  selectSelectedPlace,
  zoomChanged,
} from '../mapSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getCurrentLocation, mapSettings } from '../utils';
import { isMobile, isTextLongEnough } from '../../../utils/helpers';
import { useDebounce } from '../../../hooks/useDebounce';
import { FilterPanel } from './FilterPanel';
import { IconButtonWithTooltip } from '../../../components/ui/IconButtonWithTooltip';
import { validationMessages } from '../../../utils/formValidation';
import MakeSoilMapContext from '../MapContext';
import { isMobileApp } from '../../../mobile/mobile';
import { theme } from '../../../app/theme';
import { IMapItem } from '../model';
import { generateMapItemIdentifier } from '../../../utils/url';

export type SearchboxProps = {
  minSearchLength?: number;
  placeholder?: string;
  showFilter?: boolean;
};

const initialResultSelection: ResultListSelectionState = {
  resultType: 'places',
  selectedIndex: -1,
};

export const SearchBox: React.FC<SearchboxProps> = (props) => {
  const {
    minSearchLength = 3,
    placeholder = 'Find a soil site near you',
    showFilter = false,
  } = props;
  const history = useHistory();

  const { map } = useContext(MakeSoilMapContext);

  const dispatch = useAppDispatch();
  const searchBoxVisible = useAppSelector(selectSearchBoxVisible);
  const searchBoxFocused = useAppSelector(selectSearchBoxFocused);
  const searchTerm = useAppSelector(selectSearchTerm);
  const selectedPlace = useAppSelector(selectSelectedPlace);
  const selectedMapItem = useAppSelector(selectSelectedMapItem);
  const results = useAppSelector(selectSearchResults);

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 250);

  // state of result navigation with keyboard
  const [
    resultSelection,
    setResultSelection,
  ] = useState<ResultListSelectionState>(initialResultSelection);

  /**
   * When user selects the searched result we set the search input to the description of the selected place
   * it triggers autocomplete to search again so we need to block that
   */
  const [autocompleteBlocked, setAutocompleteBlocked] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (searchBoxFocused) {
      inputRef.current?.focus();
    }
  }, [searchBoxFocused]);

  const handleGooglePlaceSelected = useCallback(
    async (place: google.maps.places.AutocompletePrediction) => {
      setAutocompleteBlocked(true);
      setSearchInput(place.description);
      dispatch(searchTermChanged(place.description));
      dispatch(placeSelected(place));
      dispatch(mapReset());
      setResultSelection(initialResultSelection);

      // if we are already on that place in search (url) then reposition
      const searchPath = `/map?search=${place.description}`;
      const currentPath = `${history.location.pathname}${history.location.search}`;
      if (currentPath === searchPath) {
        if (map && selectedPlace.details) {
          map.fitBounds(selectedPlace.details.geometry.viewport);
        }
      }

      history.push(searchPath);
    },
    [dispatch, history, map, selectedPlace.details]
  );

  const handleMakeSoilSearchItemSelected = async ({
    id,
    name,
    type,
  }: IMapItem) => {
    dispatch(hideSearchResults());

    // based on the type (Soil Site, Soil Supporter, Garden etc.)
    const identifier = generateMapItemIdentifier(id, name);
    const mapItemUrl = `/map/${type}/${identifier}`;

    const itemAlreadySelected =
      mapItemUrl === history.location.pathname && selectedMapItem?.id === id;
    if (itemAlreadySelected) {
      dispatch(centerChanged(selectedMapItem.location));
      dispatch(zoomChanged(mapSettings.maxZoom));
      return;
    }

    history.push(mapItemUrl);
  };

  const search = useCallback(
    async (searchTerm: string, autoSelect = false) => {
      const { predictions: places } = await dispatch(
        fetchSearchResults(searchTerm)
      ).unwrap();

      if (places.length && autoSelect) {
        handleGooglePlaceSelected(places[0]);
      }
    },
    [dispatch, handleGooglePlaceSelected]
  );

  useEffect(() => {
    (function performSearchWhenSearchTermChanges() {
      if (!debouncedSearchInput) {
        dispatch(hideSearchResults());
        return;
      }
      if (!isTextLongEnough(debouncedSearchInput, minSearchLength)) return;
      if (!autocompleteBlocked) {
        dispatch(searchTermChanged(debouncedSearchInput));
        search(debouncedSearchInput);
      }
    })();
  }, [
    dispatch,
    debouncedSearchInput,
    minSearchLength,
    search,
    searchTerm,
    autocompleteBlocked,
  ]);

  const handleSearchInputChange = (searchTerm: string) => {
    setSearchInput(searchTerm);
    // user changed the input so unblock the autocomplete
    if (autocompleteBlocked) {
      setAutocompleteBlocked(false);
    }

    setResultSelection(initialResultSelection);
  };

  const handleKeyboardNavigation = (key: 'ArrowDown' | 'ArrowUp') => {
    if (!results.makesoil.length && !results.predictions.length) return;

    const { resultType, selectedIndex } = resultSelection;

    const lastPlaceIndex = results.predictions.length - 1;
    const lastMakesoilIndex = results.makesoil.length - 1;
    const hasPlaces = !!results.predictions.length;
    const hasMakesoil = !!results.makesoil.length;

    if (key === 'ArrowDown') {
      const isLastPlace =
        resultType === 'places' && selectedIndex === lastPlaceIndex;
      const isLastMakesoil =
        resultType === 'makesoil' && selectedIndex === lastMakesoilIndex;

      setResultSelection((prev) => {
        if (isLastPlace && hasMakesoil) {
          return { selectedIndex: 0, resultType: 'makesoil' };
        }
        if (isLastMakesoil) {
          return prev;
        }
        return { ...prev, selectedIndex: prev.selectedIndex + 1 };
      });
    }

    if (key === 'ArrowUp') {
      const isFirstPlace = resultType === 'places' && selectedIndex === 0;
      const isFirstMakesoil = resultType === 'makesoil' && selectedIndex === 0;

      setResultSelection((prev) => {
        if (isFirstMakesoil && hasPlaces) {
          return { selectedIndex: lastPlaceIndex, resultType: 'places' };
        }
        if (isFirstPlace) {
          return prev;
        }
        return { ...prev, selectedIndex: prev.selectedIndex - 1 };
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      handleKeyboardNavigation(e.key);
    }

    if (e.key === 'Enter') {
      const hasSearchResults =
        results.makesoil.length || results.predictions.length;
      if (hasSearchResults && resultSelection.selectedIndex !== -1) {
        if (resultSelection.resultType === 'places') {
          handleGooglePlaceSelected(
            results.predictions[resultSelection.selectedIndex]
          );
        }

        if (resultSelection.resultType === 'makesoil') {
          handleMakeSoilSearchItemSelected(
            results.makesoil[resultSelection.selectedIndex]
          );
        }
        return;
      }

      if (isTextLongEnough(e.currentTarget.value, minSearchLength)) {
        search(e.currentTarget.value, true);
      }
    }
  };

  const handleShareUserLocation = async () => {
    try {
      const {
        coords: { latitude: lat, longitude: lng },
      } = await getCurrentLocation();
      dispatch(zoomChanged(12));
      dispatch(centerChanged({ lat, lng }));
    } catch (error) {
      toast.error(validationMessages.location);
    }
  };

  const clearSearchText = () => {
    setSearchInput('');
    inputRef.current?.focus();
  };

  if (!searchBoxVisible) return null;

  const searchDisabled = searchInput.length < minSearchLength;
  const showClearSearchTextButton =
    searchInput.length > 0 && (isMobile() || isMobileApp());

  return (
    <SearchBoxWrapper>
      <SearchBoxContainer>
        <SearchInputContainer align="center" showBorderBottom={showFilter}>
          <SearchInput
            ref={inputRef}
            type="text"
            value={searchInput}
            placeholder={placeholder}
            onChange={(e) => handleSearchInputChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {showClearSearchTextButton ? (
            <SearchBoxButton
              onClick={() => clearSearchText()}
              title="Clear SearchText"
            >
              <FontAwesomeIcon
                icon="times-circle"
                color={theme.colors.primary}
              />
            </SearchBoxButton>
          ) : (
            <SearchBoxButton
              onClick={() => search(searchTerm, true)}
              disabled={searchDisabled}
              title="Search"
            >
              <FontAwesomeIcon icon="search" color={theme.colors.primary} />
            </SearchBoxButton>
          )}

          <Divider />
          <div style={{ padding: '0 0.5rem' }}>
            <IconButtonWithTooltip
              tooltipText="Use Current Location"
              icon="crosshairs"
              onClick={handleShareUserLocation}
            />
          </div>
        </SearchInputContainer>
      </SearchBoxContainer>

      <FilterPanel filterVisible={showFilter} />

      <SearchResults
        resultSelection={resultSelection}
        handleGooglePlaceSelected={handleGooglePlaceSelected}
        handleMakeSoilSearchItemSelected={handleMakeSoilSearchItemSelected}
      />
    </SearchBoxWrapper>
  );
};

//#region styles

const SearchBoxWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 90%;

  z-index: 12;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 315px;
  }

  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);
`;

const SearchBoxContainer = styled.div`
  border-radius: 8px;
  background-color: #fff;
`;

const SearchInputContainer = styled(Flex)<{ showBorderBottom: boolean }>`
  height: 48px;
  padding: 0 0.5rem;
  border-radius: 8px 8px 0 0;
  ${({ showBorderBottom }) =>
    showBorderBottom &&
    css`
      border-bottom: 1px solid #e2e2e2;
    `}
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;

  padding: 0.5rem;
  font-size: 15px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const SearchBoxButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  align-items: center;

  font-size: 1rem;

  cursor: pointer;
`;

const Divider = styled.div`
  height: 60%;
  width: 1px;
  margin: 0 0.25rem;
  border-left: 1px solid #d6d6d6;
`;

//#endregion
