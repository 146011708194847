import { gql } from '@apollo/client';

export const GET_UNREAD_MESSAGE_COUNT = gql`
  query GetUnreadMessageCount {
    unreadMessageCount
  }
`;

export const GET_THREADS = gql`
  query GetThreadsForUser {
    threads {
      id
      lastMessage {
        id
        text
        authorId
      }
      participants {
        id
        firstName
        lastName
        picture
        isAnonymous
      }
      messages {
        id
        text
        authorId
        createdAt
        author {
          id
          firstName
          lastName
          picture
        }
      }
      unreadMessageCount
    }
  }
`;

export const START_NEW_THREAD = gql`
  mutation StartThread(
    $participants: [ThreadParticipant!]!
    $message: DirectMessageInput!
  ) {
    startThread(participants: $participants, message: $message) {
      id
      lastMessage {
        id
        text
        authorId
      }
      messages {
        id
        text
        createdAt
        directMessageThreadId
        authorId
        author {
          id
          firstName
          lastName
          picture
        }
      }
      participants {
        id
        firstName
        lastName
        picture
        isAnonymous
      }
      unreadMessageCount
    }
  }
`;

export const MESSENGER_SUBSCRIPTION = gql`
  subscription MessengerSUbscription($userId: Int!) {
    messengerSubscription(userId: $userId) {
      thread {
        id
        lastMessage {
          id
          text
          authorId
          directMessageThreadId
        }
        messages {
          id
          text
          authorId
          createdAt
          directMessageThreadId
          author {
            id
            firstName
            lastName
            picture
          }
        }
        participants {
          id
          firstName
          lastName
          picture
          isAnonymous
        }
      }
      event
    }
  }
`;

export const GET_THREAD_MESSAGES = gql`
  query GetThreadMessages($directMessageThreadId: Int!, $cursor: String) {
    threadMessages(
      directMessageThreadId: $directMessageThreadId
      cursor: $cursor
    ) {
      id
      text
      createdAt
      directMessageThreadId
      authorId
      author {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $directMessageThreadId: Int!
    $message: DirectMessageInput!
  ) {
    sendMessage(
      directMessageThreadId: $directMessageThreadId
      message: $message
    ) {
      id
      text
      directMessageThreadId
      createdAt
      authorId
      author {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;

export const MARK_READ = gql`
  mutation MarkRead($directMessageThreadId: Int!) {
    markRead(directMessageThreadId: $directMessageThreadId)
  }
`;
