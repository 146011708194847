import { apolloClient } from '../../../app/apolloClient';
import { Site } from '../../site/model';

import {
  FETCH_PARTICIPATION_INFO,
  FIND_SOIL_SITES,
  UPDATE_ACTIVE_STATUS,
  UPDATE_SITE_VISIBILITY,
} from './graphql';

type SiteSearchResult = {
  findSites: Site[];
};

type SiteStatusResult = {
  updateSiteActiveStatus: {
    soilSite: { id: number; active: boolean };
    message: string;
    errors: string[];
  };
};

type SiteVisibilityResult = {
  updateSiteVisbility: {
    soilSite: { id: number; isUnlisted: boolean };
    message: string;
    errors: string[];
  };
};

type ParticipationInfoResult = {
  siteParticipationInfo: {
    siteId: number;
    usersRequestingAccess: any[];
    participants: any[];
  };
};

export type ParticipationInfo = ParticipationInfoResult['siteParticipationInfo'];

class SiteManagementService {
  findSites(keywords: string) {
    return apolloClient.query<SiteSearchResult>({
      query: FIND_SOIL_SITES,
      variables: { keywords },
      fetchPolicy: 'no-cache',
    });
  }

  fetchParticipationInfo(id: number) {
    return apolloClient.query<ParticipationInfoResult>({
      query: FETCH_PARTICIPATION_INFO,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  }

  updateActiveStatus(id: number, active: boolean) {
    return apolloClient.mutate<SiteStatusResult>({
      mutation: UPDATE_ACTIVE_STATUS,
      variables: { id, active },
      fetchPolicy: 'no-cache',
    });
  }

  updateVisibility(id: number, isUnlisted: boolean) {
    return apolloClient.mutate<SiteVisibilityResult>({
      mutation: UPDATE_SITE_VISIBILITY,
      variables: { id, isUnlisted },
      fetchPolicy: 'no-cache',
    });
  }
}

export default new SiteManagementService();
