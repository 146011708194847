import { isMobileApp, mobileGetCurrentGeoPosition } from '../mobile/mobile';
import { ILocation } from '../app/model/ILocation';

export interface GeolocationPosition {
  coords: GeolocationCoordinates;
  timestamp: number;
}

export interface GeolocationCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export const getCurrentLocation = () => {
  if (isMobileApp()) {
    return mobileGetCurrentGeoPosition();
  }

  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (!navigator.geolocation)
      reject('Geolocation is not supported by your browser');

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      },
      { enableHighAccuracy: true }
    );
  });
};

export const getDirectionsOnGoogleMaps = async (destination: ILocation) => {
  const location = await getCurrentLocation();
  const { latitude, longitude } = location.coords;

  window.open(`https://www.google.com/maps/dir/?api=1
    	&travelmode=walking
    	&origin=${latitude},${longitude}
    	&destination=${destination.lat},${destination.lng}
    `);
};

//This function takes in latitude and longitude of two locations
// and returns the distance between them as the crow flies (in meters)
export function calculateDistance(location1: ILocation, location2: ILocation) {
  // var R = 6.371; // km
  var R = 6371000;
  var dLat = toRad(location2.lat - location1.lat);
  var dLon = toRad(location2.lng - location1.lng);
  var lat1 = toRad(location1.lat);
  var lat2 = toRad(location2.lat);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(value: number) {
  return (value * Math.PI) / 180;
}
