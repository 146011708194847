import React from 'react';
import styled, { css } from 'styled-components';

type LogoProps = {
  hideLogoType?: boolean;
  color?: string;
  size?: 'l' | 'xl';
  inverted?: boolean;
};

export const Logo = ({
  hideLogoType,
  color,
  size,
  inverted = false,
}: LogoProps) => (
  <LogoContainer hideLogoType={hideLogoType}>
    <LogoImage
      xmlns="http://www.w3.org/2000/svg"
      size={size}
      // width="41"
      // height="54"
      viewBox="0 0 41 54"
    >
      <g fill={inverted ? '#fff' : '#49BB3B'}>
        <path d="M31.494 5.835c-6.23 11.779-8.134 22.978-5.504 32.514 1.497 5.43 4.106 9.01 5.504 10.64 1.398-1.63 4.006-5.21 5.504-10.64 2.63-9.536.726-20.735-5.504-32.514zm0 47.165l-.946-.851c-.2-.178-4.888-4.466-7.25-12.843-2.151-7.631-2.69-20.27 6.963-37.15L31.494 0l1.232 2.156c5.329 9.316 10.861 23.324 6.963 37.15-2.361 8.377-7.05 12.665-7.249 12.843l-.946.851z" />
        <path d="M10.964 19.722c.488 11.16 3.95 19.84 10.076 25.201 3.447 3.016 6.928 4.272 8.734 4.758.338-1.853.703-5.56-.525-9.989-2.182-7.874-8.484-14.744-18.285-19.97zM31.858 53l-1.182-.15c-.234-.029-5.8-.78-11.391-5.604-9.22-7.954-11.219-20.657-11.272-29.913L8 15l2.086 1.012c8.28 4.014 18.815 11.276 21.999 23.077 1.93 7.158.219 12.545.145 12.771L31.858 53z" />
        <path d="M4.806 36.92c4.45 7.502 10.011 12.21 16.185 13.67 3.328.787 6.1.457 7.623.134-.5-1.496-1.63-4.09-3.99-6.6-4.376-4.656-11.195-7.126-19.818-7.203M25.162 54c-1.375 0-2.943-.138-4.64-.523-5.326-1.21-13.02-5.19-19.431-17.285L0 34.134l2.3-.092c7.456-.3 17.768.88 24.533 8.206 4.116 4.456 4.934 9.06 4.967 9.254l.2 1.185-1.106.427c-.129.05-2.35.886-5.732.886" />
      </g>
    </LogoImage>
    <LogoType hideLogoType={hideLogoType} color={color} size={size}>
      <span className="make">Make</span>
      <span>Soil</span>
    </LogoType>
  </LogoContainer>
);

export const BrandContainer = styled.div<LogoProps>`
  display: flex;
  justify-content: center;
  width: 250px;
  ${({ hideLogoType }) => hideLogoType && 'width: 60px;'}

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    justify-content: center;

    order: 2;
    border: none;
    width: unset;
    ${({ hideLogoType }) => hideLogoType && 'width: unset;'}
  }

  border-right: 1px solid #333;
  transition: all 0.35s ease;
`;

const LogoContainer = styled.div<LogoProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  position: relative;
  width: min-content;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    ${({ hideLogoType }) => hideLogoType && `margin-left: 2px;`}
  }

  transition: all 0.35s ease;
`;

const LogoImage = styled.svg<LogoProps>`
  height: 2.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    ${({ size }) =>
      size === 'l' &&
      css`
        height: 4rem;
      `};

    ${({ size }) =>
      size === 'xl' &&
      css`
        height: 6rem;
      `};
  }
`;

const LogoType = styled.div<LogoProps>`
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 2rem;

    ${({ size }) =>
      size === 'l' &&
      css`
        font-size: 2.25rem;
      `};

    ${({ size }) =>
      size === 'xl' &&
      css`
        font-size: 3rem;
      `};
  }

  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  width: 100%;
  overflow: hidden;

  color: ${({ color }) => color || '#fff'};

  // we are making it look as if the text slides/hides with the nav
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ hideLogoType }) => hideLogoType && 'width: 0;'}
  }

  transition: width 0.35s ease-in;

  .make {
    font-weight: 900;
    letter-spacing: -0.8px;
  }
`;
