import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MaterialsPicker } from '../site/components/MaterialsPicker';
import { WizardForm } from './model';

export const Step2 = ({ stepCount }) => {
  const { values, setFieldValue } = useFormikContext<WizardForm>();

  return (
    <div>
      <h1 className="step-title">
        Create a soil site: Step 2/
        {stepCount}
      </h1>
      <hr />
      <FormSection>
        <h3>What materials do you accept?*</h3>
        <HelpText>
          These will be shown to the public so they can see if your soil site is
          right for them. We've started you off with some common options. <br />
          <br />
          You can learn why some Soil Makers don't accept certain items{' '}
          <Link
            to="/learn#what-materials-can-i-make-soil-out-of-"
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            here
          </Link>
          .
        </HelpText>

        <MaterialsPicker
          accepted={values.accepted || []}
          prohibited={values.prohibited || []}
          acceptedHeader="You accept"
          prohibitedHeader="You DO NOT accept"
          onMaterialsChange={(materials) => {
            setFieldValue('accepted', materials.accepted);
            setFieldValue('prohibited', materials.prohibited);
          }}
        />
      </FormSection>
    </div>
  );
};

const HelpText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.4;
  color: #838383;
`;

const FormSection = styled.section`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;
