import { gql, useMutation } from '@apollo/client';
import {
  FormikErrors,
  FormikHelpers,
  Formik,
  Form,
  ErrorMessage,
} from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Flex, FormError, FormInputError } from '../../components/ui';
import { FormControl } from '../../components/ui/forms/Forms';
import { PasswordInput } from '../../components/ui/forms/PasswordInput';

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      message
      errors
    }
  }
`;

export interface IPasswordChange {
  currentPassword: string;
  newPassword: string;
}

type PasswordFormProps = {};

export const ChangePassword: React.FC<PasswordFormProps> = () => {
  const [formError, setFormError] = useState('');

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const handleChangePassword = async (passwordData: IPasswordChange) => {
    return changePassword({
      variables: {
        ...passwordData,
      },
    });
  };

  const validateForm = (values: IPasswordChange) => {
    const { currentPassword, newPassword } = values;

    let errors: FormikErrors<IPasswordChange> = {};
    if (!currentPassword) {
      errors.currentPassword = 'Please provide your current password.';
    }
    if (currentPassword.trim() === newPassword.trim()) {
      errors.newPassword =
        "New password can't be the same as the current password.";
    }
    return errors;
  };

  const handleSubmit = async (
    values: IPasswordChange,
    { setSubmitting, resetForm }: FormikHelpers<IPasswordChange>
  ) => {
    try {
      setFormError('');
      const { data } = await handleChangePassword(values);
      setSubmitting(false);

      // just adding a comment for so this file is being pushed to staging

      if (data.changePassword?.errors.length > 0) {
        return toast.error(data.changePassword.errors[0]);
      }

      resetForm();
      toast.success(data.changePassword.message);
    } catch (error) {
      return setFormError(error.message);
    }
  };

  return (
    <PasswordFormContainer>
      {formError && <FormError>{formError}</FormError>}
      <Formik<IPasswordChange>
        initialValues={{ currentPassword: '', newPassword: '' }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, setFieldTouched, isValid, isSubmitting }) => {
          return (
            <Form autoComplete="off">
              <FormControl>
                {' '}
                <PasswordInput
                  name="currentPassword"
                  placeholder="Current password"
                  noValidate
                  onChange={(e) => {
                    setFieldValue('currentPassword', e.target.value, true);
                  }}
                />
                <ErrorMessage
                  name="currentPassword"
                  component={FormInputError}
                />
              </FormControl>

              <FormControl>
                <PasswordInput
                  name="newPassword"
                  placeholder="New password"
                  onChange={(e) => {
                    setFieldValue('newPassword', e.target.value, true);
                    setFieldTouched('newPassword');
                  }}
                />
                <ErrorMessage name="newPassword" component={FormInputError} />
              </FormControl>

              <Flex style={{ marginTop: '2rem' }}>
                <Button
                  style={{ flex: 'auto' }}
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Change Password
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </PasswordFormContainer>
  );
};

const PasswordFormContainer = styled.div`
  padding 1rem;

  form {
    label {
      display: inline-block;
      margin-bottom: 0.25rem;
    }
  }
`;
