import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AccessRequests } from './AccessRequests';
import { NoParticipants } from './NoParticipants';
import { DropMenu, Flex, Collapsible } from '../../../components/ui';

import { getSoilMakers, getSupporters } from '../../../utils/site';
import { UserBadge } from './UserBadge';
import { UserBox } from './UserBox';
import { SiteMember, IAccessRequest, SITE_ROLE } from '../model';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useAuthUser } from '../../auth/useAuthUser';
import { useThemeContext } from '../../../app/useThemeContext';
import { useCurrentSiteUser } from '../hooks';

export type ParticipantsProps = {
  onGetMoreSupporters: () => void;
  onPromoteToSoilMaker: (member: SiteMember) => void;
  onDemoteSoilMaker: (member: SiteMember) => void;
  onTransferOwnership: (member: SiteMember) => void;
  onRemoveMember: (member: SiteMember, reason?: string) => void;
  onReviewAccessRequest: (accessRequest: IAccessRequest) => void;
};

export const Participants: React.FC<ParticipantsProps> = ({
  onGetMoreSupporters,
  onPromoteToSoilMaker,
  onDemoteSoilMaker,
  onTransferOwnership,
  onRemoveMember,
  onReviewAccessRequest,
}) => {
  const history = useHistory();
  const authUser = useAuthUser();

  const theme = useThemeContext();
  const { soilSite } = useSoilSiteContext();
  const { members, accessRequests } = soilSite;

  const {
    iAmAdmin,
    iAmPrimarySoilMaker,
    iAmSecondarySoilMaker,
    iAmSupporter,
  } = useCurrentSiteUser(soilSite);

  const soilMakers = getSoilMakers(members);
  const supporters = getSupporters(members);

  const hasParticipants = members.length > 1;
  const hasAccessRequests = accessRequests.length > 0;
  const showAccessRequests = !iAmSupporter;

  const sendDirectMessage = async (recipient: SiteMember) => {
    const participants = [
      { ...recipient, id: recipient.userId, isAnonymous: false },
    ];

    history.push(`/messages/t/new`, { recipients: [...participants] });
  };

  const renderUserBoxActions = (user: SiteMember) => {
    const isPrimarySoilMaker = SITE_ROLE.OWNER.toString() === user.role.name;
    const isMaker = SITE_ROLE.MAKER.toString() === user.role.name;
    const isSoilSupporter = SITE_ROLE.SUPPORTER.toString() === user.role.name;
    const isMe = user.userId === authUser.id;

    const canBePromoted = isSoilSupporter;
    const canBeTransferredTo = !isPrimarySoilMaker && !isSoilSupporter;
    const canBeDemoted = isMaker;
    const canBeRemoved = !isPrimarySoilMaker;

    // no options if list item is current user
    if (isMe) return null;

    // 1. Primary Soil Maker can do everything
    // 2. Admin can do everything
    if (iAmPrimarySoilMaker || iAmAdmin) {
      return (
        <DropMenu
          triggerElement={
            <Toggle>
              <FontAwesomeIcon icon="ellipsis-h" />
            </Toggle>
          }
        >
          <span onClick={() => sendDirectMessage(user)}>
            <FontAwesomeIcon icon="envelope" /> Direct Message
          </span>
          {canBeTransferredTo && (
            <span onClick={() => onTransferOwnership(user)}>
              <FontAwesomeIcon icon="user-shield" /> Transfer Ownership
            </span>
          )}
          {canBePromoted && (
            <span onClick={() => onPromoteToSoilMaker(user)}>
              <FontAwesomeIcon icon="user-plus" /> Promote to Soil Maker
            </span>
          )}
          {canBeDemoted && (
            <span onClick={() => onDemoteSoilMaker(user)}>
              <FontAwesomeIcon icon="user-minus" /> Demote Soil Maker
            </span>
          )}
          {canBeRemoved && (
            <span onClick={() => onRemoveMember(user)}>
              <FontAwesomeIcon icon="ban" /> Remove from Soil Site
            </span>
          )}
        </DropMenu>
      );
    }

    // 1. Supporters can only message other participants
    // 2. Primary Soil Maker Can only be messaged by other participants
    // 3. Soil Makers can only message other Soil Makers
    if (
      iAmSupporter ||
      isPrimarySoilMaker ||
      (iAmSecondarySoilMaker && isMaker)
    ) {
      return (
        <span
          onClick={() => sendDirectMessage(user)}
          title="Direct Message"
          style={{
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon icon="envelope" color={theme.colors.primary} />
        </span>
      );
    }

    // 1. Soil Maker can promote and remove SOil Supporters
    if (iAmSecondarySoilMaker) {
      return (
        <DropMenu
          triggerElement={
            <Toggle>
              <FontAwesomeIcon icon="ellipsis-h" />
            </Toggle>
          }
        >
          <span onClick={() => sendDirectMessage(user)}>
            <FontAwesomeIcon icon="envelope" /> Direct Message
          </span>
          {canBePromoted && (
            <span onClick={() => onPromoteToSoilMaker(user)}>
              <FontAwesomeIcon icon="user-plus" /> Promote to Soil Maker
            </span>
          )}
          {canBeRemoved && (
            <span onClick={() => onRemoveMember(user)}>
              <FontAwesomeIcon icon="ban" /> Remove from Soil Site
            </span>
          )}
        </DropMenu>
      );
    }
  };

  return (
    <>
      {hasParticipants || hasAccessRequests ? (
        <ParticipantsContainer style={{ flex: '2' }}>
          <h3 style={{ marginBottom: 0 }}>Participants</h3>
          {showAccessRequests && (
            <AccessRequests
              accessRequests={accessRequests}
              onReview={onReviewAccessRequest}
            />
          )}

          {soilMakers.length > 0 && (
            <Collapsible label={`Soil Makers (${soilMakers.length})`}>
              <Flex direction="column" style={{ gap: '0.25rem' }}>
                {soilMakers.map((user: SiteMember) => {
                  // show Primary Soil Maker Badge if there's more than one Soil Maker
                  const isPrimarySoilMaker =
                    user.role.name === SITE_ROLE.OWNER.toString();

                  const badge = isPrimarySoilMaker && soilMakers.length > 1 && (
                    <UserBadge
                      text={user.role.name}
                      color={theme.colors.primary}
                    />
                  );

                  return (
                    <UserBox
                      key={user.userId}
                      {...user}
                      dropOffs={user.dropOffs.length}
                      id={user.userId}
                      badge={badge}
                    >
                      {renderUserBoxActions(user)}
                    </UserBox>
                  );
                })}
              </Flex>
            </Collapsible>
          )}

          {supporters.length > 0 && (
            <Collapsible label={`Soil Supporters (${supporters.length})`}>
              <Flex direction="column" style={{ gap: '0.25rem' }}>
                {supporters
                  .sort((a, b) => (a.dropOffs < b.dropOffs ? 1 : -1))
                  .map((supporter: any) => (
                    <UserBox
                      key={supporter.userId}
                      {...supporter}
                      dropOffs={supporter.dropOffs.length}
                    >
                      {renderUserBoxActions(supporter)}
                    </UserBox>
                  ))}
              </Flex>
            </Collapsible>
          )}
        </ParticipantsContainer>
      ) : (
        <NoParticipants onGetMoreSupporters={onGetMoreSupporters} />
      )}
    </>
  );
};

const ParticipantsContainer = styled.div`
  padding: 0.5rem 1rem 1rem;

  > section > div:first-child {
    margin: 1rem 0 0.5rem 0;
  }
`;

const Toggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 1.5rem;
  width: 1.5rem;

  border-radius: 50%;
  border: 1px solid transparent;
  color: #616161;

  :hover {
    background-color: #ebebeb;
    border: 1px solid #cfcfcf;
  }

  transition 0.35s ease;
`;
