import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';

const RadioButtonContainer = styled.div<{ checked?: boolean }>`
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${({ checked }) => !checked && '#a2a2a2'};

  label {
    cursor: pointer;
  }

  // hide the native radio button
  input {
    position: absolute;
    opacity: 0;
  }
`;

const Checkbox = styled.span<{ checked?: boolean }>`
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f4f4f4;
  border-radius: 50%;
  border: 2px solid #bcbcbc;

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.primary};

    ${({ checked }) =>
      checked &&
      `
      display: block;
    `}
  }
`;

interface RadioButtonProps {
  value: any;
  checked: boolean;
  name?: string;
  label?: string;
  onChange: (checked: boolean) => void;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  name,
  checked,
  label,
  onChange,
}) => {
  return (
    <RadioButtonContainer checked={checked}>
      <label>
        <Checkbox checked={checked} />
        <Field
          name={name}
          type="radio"
          checked={checked}
          value={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        {label}
      </label>
    </RadioButtonContainer>
  );
};
