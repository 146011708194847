import { getUserDisplayName } from '../../utils/helpers';

type Props = {
  firstName: string;
  lastName?: string;
};

// function shortenText(text: string, count = 12, insertDots = false) {
//   return (
//     text.slice(0, count) + (text.length > count && insertDots ? '...' : '')
//   );
// }

export const UserDisplayName = ({ firstName, lastName = '' }: Props) => {
  return <>{getUserDisplayName(firstName, lastName)}</>;
};
