import React, { useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { cdn, defaultSiteImages } from '../../utils/config';
import { _isProd, injectFilestackOptions } from '../../utils/url';

// For now we have two types of images that require fallback photo if null
// as we add more types we might want to refactor the code
const selectFallbackPhoto = (type) => {
  if (!type) return '';

  return type === 'profile'
    ? cdn.profileImage.FALLBACK
    : getRandomSoilSiteImage();
};

function getRandomSoilSiteImage() {
  return defaultSiteImages[
    Math.floor(Math.random() * defaultSiteImages.length)
  ];
}

const isFilestackUrl = (url) => url.includes('filestackcontent');

const getResizeQueryString = (width, height) => {
  let resize;
  if (!width && !height) {
    // default width if not set
    resize = `resize=width:600`;
  } else {
    resize = width ? `resize=width:${width}` : `resize=height:${height}`;
  }
  return resize;
};

const getOutputFormatQueryString = (format: 'webp' | 'jpg') =>
  `output=format:${format}`;

const getAspectRatio = (type: 'site' | 'profile' | 'any') => {
  let ratio;
  switch (type) {
    case 'site':
      ratio = cdn.siteImage.RATIO;
      break;
    case 'profile':
      ratio = cdn.profileImage.RATIO;
      break;
    default:
      ratio = '16/9';
      break;
  }
  return ratio;
};

function getUrl(src: string, type: ImagerType, width: number, height: number) {
  // if the src is null, then we need to load the fallback photo
  const imgUrl = src || selectFallbackPhoto(type);

  if (!isFilestackUrl(imgUrl)) return imgUrl;

  // if the url is pointing to filestack then inject the filestack options
  const options = ['rotate=deg:exif', getResizeQueryString(width, height)];
  return injectFilestackOptions(imgUrl, options);
}

type ImagerType = 'site' | 'profile' | 'any';

type SmartImageProps = {
  className?: string;
  style?: CSSProperties;
  src: string;
  width: number;
  height?: number;
  ratio?: '4/3' | '16/9' | '1/1';
  type?: ImagerType;
  alt?: string;
  asBackground?: boolean;
};

/**
 * Displays provided image as a background image if asBackground = true otherwise displays a regular image component.
 * @param props
 * @returns React.ReactNode
 */
export const SmartImage: React.FC<SmartImageProps> = (props) => {
  const {
    className,
    src,
    type = 'any',
    width,
    height,
    alt,
    asBackground,
    ratio,
    style,
    children,
  } = props;

  const [imgUrl, setImgUrl] = useState();

  useEffect(() => {
    setImgUrl(getUrl(src, type, width, height));
  }, [src, type, width, height, setImgUrl]);

  if (!imgUrl) return null;

  return asBackground ? (
    <BackgroundImage
      className={className}
      url={imgUrl}
      title={alt}
      ratio={ratio || getAspectRatio(type)}
      style={style}
    >
      {children}
    </BackgroundImage>
  ) : (
    <picture>
      {isFilestackUrl(imgUrl) && <AdditionalImageFormats imgUrl={imgUrl} />}
      <Image className={className} src={imgUrl} alt={alt} style={style} />
    </picture>
  );
};

const AdditionalImageFormats = ({ imgUrl }) => {
  return (
    <>
      <source
        srcSet={injectFilestackOptions(imgUrl, [
          getOutputFormatQueryString('webp'),
        ])}
        type="image/webp"
      />
      <source
        srcSet={injectFilestackOptions(imgUrl, [
          getOutputFormatQueryString('jpg'),
        ])}
        type="image/jpg"
      />
    </>
  );
};

const BackgroundImage = styled.div<{
  url: string;
  ratio: '4/3' | '16/9' | '1/1';
  height?: string;
}>`
  background: url(${({ url }) => url}) no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;

  ${({ ratio }) =>
    ratio === '4/3' &&
    `
		padding-top: 75%;
	`}

  ${({ ratio }) =>
    ratio === '16/9' &&
    `
		padding-top: 56.25%;
	`}

	${({ ratio }) =>
    ratio === '1/1' &&
    `
		padding-top: 100%;
	`}
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
`;
