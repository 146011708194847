import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query SearchUsers($keywords: String!) {
    usersByKeywords(keywords: $keywords) {
      id
      firstName
      lastName
      email
      firstName
      lastName
      picture
      address
      country
      phoneNum
      twitter
      instagram
      location {
        lat
        lng
      }
      verified
      createdAt
      roles {
        id
        name
      }
      howDidYouLearn
    }
  }
`;

export const GET_USER_ROLES = gql`
  query {
    userRoles {
      id
      name
    }
  }
`;

export const ASSIGN_ROLE = gql`
  mutation assignRole($userId: Int!, $roleId: Int!) {
    assignRole(input: { userId: $userId, roleId: $roleId }) {
      id
      roles {
        id
        name
      }
    }
  }
`;

export const GET_PREMIUM_FEATURES = gql`
  query PremiumFeatures($userId: Int!) {
    premiumFeatures(userId: $userId) {
      id
      displayName
      enabled
      enabledAt
    }
  }
`;

export const GET_ENABLED_PREMIUM_FEATURES = gql`
  query {
    premiumUserFeatures {
      id
      displayName
    }
  }
`;

export const ENABLE_FEATURE = gql`
  mutation EnableFeature($input: PremiumFeatureInput!) {
    enableFeature(input: $input)
  }
`;

export const DISABLE_FEATURE = gql`
  mutation DisableFeature($input: PremiumFeatureInput!) {
    disableFeature(input: $input)
  }
`;

export const PARTICIPATION_DETAILS = gql`
  query GetParticipationDetails($userId: Int!) {
    participationDetails(userId: $userId) {
      sites {
        role
        siteName
        siteUrl
      }
      accessRequests {
        siteName
        siteUrl
        createdAt
      }
    }
  }
`;

export const ACCOUNT_ACTIVITY = gql`
  query GetAccountActivity($userId: Int!) {
    accountActivity(userId: $userId) {
      lastLogin {
        web {
          createdAt
          loginType
          useragent
        }
        app {
          createdAt
          loginType
          useragent
        }
      }
    }
  }
`;

export const GET_VERIFICATION_CODE = gql`
  query GetVerificationCode($userId: Int!) {
    verificationCode(userId: $userId) {
      code
      updatedAt
    }
  }
`;

export const REGENERATE_VERIFICATION_CODE = gql`
  query RegenerateVerificationCode($userId: Int!) {
    regenerateVerificationCode(userId: $userId) {
      code
      updatedAt
    }
  }
`;

export const VERIFY_USER_ACCOUNT = gql`
  mutation VerifyUserAccount($userId: Int!, $code: String!) {
    verifyUserAccount(input: { userId: $userId, code: $code }) {
      status
    }
  }
`;

export const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount($userId: Int!) {
    deleteUserAccount(userId: $userId) {
      errors
      message
    }
  }
`;
