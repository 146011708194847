import { Counter } from '../../components/Counter';
import { formatWeight } from '../../utils/formatting';
import { impactCalculator } from './impactCalculator';

export function ImpactCounters({
  dropOffs,
}: {
  dropOffs: { weight?: number }[];
}) {
  const { foodWasteSaved, ghg, soilCreated } = impactCalculator.impact(
    dropOffs
  );

  return (
    <>
      <Counter
        count={formatWeight(foodWasteSaved)}
        text="Nutrient-rich scraps saved from landfill"
        backgroundColor="rgba(69, 177, 57, 0.09)"
      />
      <Counter
        count={formatWeight(ghg)}
        text="GHG emissions prevented"
        variant="info"
        backgroundColor="rgba(69, 177, 57, 0.09)"
      />
      <Counter
        count={formatWeight(soilCreated)}
        text="New soil created (or that will be)"
        variant="brown"
        backgroundColor="rgba(69, 177, 57, 0.09)"
      />
    </>
  );
}
