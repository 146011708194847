import jwtDecode from 'jwt-decode';
import { IAuthUser } from './model';
import { getUrlByConnectionType } from '../../utils/url';
import { storage } from '../../utils/storage';
import { SiteOnboardAction } from '../onboarding/model';

type AccessTokenRequestResult = {
  ok: boolean;
  accessToken: string;
};

export interface PasswordStrengthRules {
  minLength: number;
  minLowercase: number;
  minUppercase: number;
  minNumbers: number;
  minSymbols: number;
}

export interface PasswordStrengthValidationResult {
  ok: boolean;
  errors: string[];
}

type SiteAction = SiteOnboardAction | 'create';

export const firstNameRequired = (authUser: IAuthUser, action: SiteAction) => {
  if (['request', 'join', 'create'].includes(action)) {
    return !authUser.firstName;
  }
};

export const additionalContactInfoRequired = (
  authUser: IAuthUser,
  action: SiteAction
) => {
  if (action === 'create') {
    return (
      !authUser.phoneNum ||
      !authUser.twitter ||
      !authUser.instagram ||
      !authUser.facebook ||
      !authUser.linkedin
    );
  }

  if (['request', 'join', 'invitation'].includes(action)) {
    const minimumSocialAccountsRequired = 2;
    return (
      !authUser.phoneNum &&
      [
        authUser.twitter,
        authUser.instagram,
        authUser.facebook,
        authUser.linkedin,
      ].filter((v) => !!v).length < minimumSocialAccountsRequired
    );
  }
};

export const storeJwtToken = (token: string): void => {
  if (typeof window !== 'undefined') {
    storage.session.set('jwt', token);
  }
};

export const removeJwtToken = (): void => {
  if (typeof window !== 'undefined') {
    storage.session.remove('jwt');
  }
};

/**
 * Retrieves stored JWT, decodes it
 * and validates the expiry date
 */
export const getJwtToken = (): string => {
  if (typeof window !== 'undefined') {
    const token = storage.session.get('jwt');
    if (!token) return null;
    const { exp } = jwtDecode<IAuthUser>(token);
    // multiply expiry by 1000 to get ms
    if (new Date(exp * 1000) < new Date()) return null;
    return token;
  }
};

/**
 * Checks if there's a token present
 * @returns boolean
 */
export const hasJwtToken = () => {
  return !!storage.session.get('jwt');
};

export const isTokenExpired = (token: string) => {
  const { exp } = jwtDecode<IAuthUser>(token);
  return new Date(exp * 1000) < new Date();
};

export const decodeToken = (token: string): IAuthUser => {
  return jwtDecode(token);
};

/**
 * Retrieves stored JWT, decodes it
 * and validates the expiry date
 */
export const getAuthUser = (): IAuthUser => {
  let token = null;
  if (typeof window !== 'undefined') {
    token = storage.session.get('jwt');
  }
  if (!token) return null;
  let authUser = jwtDecode<IAuthUser>(token);
  // validate the token expiry date
  if (new Date(authUser.exp * 1000) < new Date()) {
    return null;
  }
  return authUser;
};

/**
 * Requests the JWT using the Refresh Token
 * @returns Promise<IAuthUser>
 */
export const loginWithRefreshToken = async (): Promise<IAuthUser> => {
  const { ok, accessToken } = await requestAccessToken();
  if (!ok) return null;

  storeJwtToken(accessToken);
  return jwtDecode<IAuthUser>(accessToken);
};

export const requestAccessToken = async (): Promise<AccessTokenRequestResult> => {
  return fetch(`${getUrlByConnectionType('http')}/refresh_token`, {
    credentials: 'include',
  }).then((res) => res.json());
};

export const isAdmin = (user: IAuthUser) => {
  if (!user.isLoggedIn) return false;
  return !!user.roles?.find((r) => r.name === 'Admin');
};

/**
 * Validate the password's strength against the given rules/policy
 * @param password user's password
 * @param options rules to validate the password strength against
 * @returns
 */
export const validatePasswordStrength = (
  password: string,
  options?: PasswordStrengthRules
) => {
  const validationResult: PasswordStrengthValidationResult = {
    ok: true,
    errors: [],
  };

  const rules = Object.assign(
    {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
    },
    options
  );

  const analysis = analyzePassword(password);

  const characters = (num: number) => (num > 1 ? `characters` : 'character');

  if (analysis.length < rules.minLength) {
    validationResult.ok = false;
    validationResult.errors.push(
      `- ${rules.minLength} ${characters(rules.minLength)}`
    );
  }

  if (analysis.lowercaseCount < rules.minLowercase) {
    validationResult.ok = false;
    validationResult.errors.push(
      `- ${rules.minLowercase} lower case ${characters(rules.minLowercase)}`
    );
  }

  if (analysis.uppercaseCount < rules.minUppercase) {
    validationResult.ok = false;
    validationResult.errors.push(
      `- ${rules.minUppercase} upper case ${characters(rules.minUppercase)}`
    );
  }

  if (analysis.numberCount < rules.minNumbers) {
    validationResult.ok = false;
    validationResult.errors.push(
      `- ${rules.minNumbers} numeric ${characters(rules.minNumbers)}`
    );
  }

  if (analysis.symbolCount < rules.minSymbols) {
    validationResult.ok = false;
    validationResult.errors.push(
      `- ${rules.minSymbols} symbol ${characters(rules.minSymbols)}`
    );
  }

  return validationResult;
};

const analyzePassword = (password: string) => {
  const upperCaseRegex = /^[A-Z]$/;
  const lowerCaseRegex = /^[a-z]$/;
  const numberRegex = /^\d$/;
  const symbolRegex = /^[-#!$%^&*()_+|~=`{}[\]:";'<>?,./ ]$/;

  let charMap: any = {};
  Array.from(password).forEach((char) => {
    let curVal = charMap[char];
    if (curVal) {
      charMap[char] += 1;
    } else {
      charMap[char] = 1;
    }
  });

  let analysis = {
    length: password.length,
    uppercaseCount: 0,
    lowercaseCount: 0,
    numberCount: 0,
    symbolCount: 0,
  };

  Object.keys(charMap).forEach((char) => {
    if (upperCaseRegex.test(char)) {
      analysis.uppercaseCount += charMap[char];
    } else if (lowerCaseRegex.test(char)) {
      analysis.lowercaseCount += charMap[char];
    } else if (numberRegex.test(char)) {
      analysis.numberCount += charMap[char];
    } else if (symbolRegex.test(char)) {
      analysis.symbolCount += charMap[char];
    }
  });

  return analysis;
};
