import { useState } from 'react';
import styled from 'styled-components';

import { Flex } from './ui';
import { isMobile } from '../utils/helpers';

export type Device = 'Android' | 'iPhone';

type Props = {
  device: Device;
  badgeSrc: string;
  qrSrc: string;
  storeUrl: string;
  badgeStyle?: React.CSSProperties;
  popupStyle?: React.CSSProperties;
};

export const MobileAppBadge: React.FC<Props> = ({
  device,
  badgeSrc,
  qrSrc,
  storeUrl,
  badgeStyle,
  popupStyle,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    if (isMobile()) {
      window.location.replace(storeUrl);
      return null;
    }
    setVisible((prev) => !prev);
  };

  return (
    <Wrapper>
      <Badge
        src={badgeSrc}
        alt="badge"
        style={badgeStyle}
        onClick={handleClick}
      />

      {visible && (
        <Container style={popupStyle}>
          <Flex direction="column" justify="center" align="center">
            <img src={qrSrc} alt="QR Code" />
          </Flex>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
`;

const Badge = styled.img`
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  height: 128px;
  width: 128px;
  padding: 0.5rem;

  display: flex;
  flex-direction: column;

  background-color: #242424f2;
  border-radius: ${({ theme }) => theme.borderRadius}px;

  z-index: 900;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: ${({ theme }) => theme.borderRadius}px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
`;
