import React from 'react';
import styled from 'styled-components';
import {
  Link,
  withRouter,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage, FormikErrors } from 'formik';

import { isEmail, validationMessages } from '../../utils/formValidation';
import { useReturnUrl } from '../../hooks/useReturnUrl';
import { Button, FormError, FormInputError } from '../../components/ui';
import { Meta } from '../../components/Meta';
import { AuthPageLayout } from '..';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { login, selectAuthError } from '../../features/auth/authSlice';
import { LoginFormData } from '../../features/auth/authService';
import { Divider } from '../../components/ui/Divider';

const Login: React.FC<RouteComponentProps> = () => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector(selectAuthError);

  const history = useHistory();

  const initialValues: LoginFormData = { email: '', password: '' };

  const validateForm = (values: LoginFormData) => {
    let errors: FormikErrors<LoginFormData> = {};
    const { email, password } = values;
    if (!email || !isEmail(email)) {
      errors.email = validationMessages.email;
    }
    if (!password) {
      errors.password = validationMessages.password;
    }
    return errors;
  };

  const redirectPath = useReturnUrl();

  const handleSubmit = async (
    values: LoginFormData,
    { setSubmitting }: any
  ) => {
    const result = await dispatch(login(values)).unwrap();
    setSubmitting(false);
    if (result.ok) {
      history.replace(redirectPath);
    }
  };

  const signupPath = () => {
    let returnUrl = '';
    if (redirectPath) {
      returnUrl = `?returnUrl=${redirectPath}`;
    }
    return `/signup${returnUrl}`;
  };

  return (
    <AuthPageLayout contentContainerStyle={{ justifyContent: 'center' }}>
      <Container>
        <Meta>
          <title>Log In | MakeSoil</title>
        </Meta>
        <div style={{ marginBottom: '1rem' }}>
          <h2>Log in to your account</h2>
          <small>
            Don't have an account? <Link to={signupPath()}>Create one</Link>
          </small>
        </div>
        {loginError && (
          <FormError style={{ marginTop: '1rem' }}>{loginError}</FormError>
        )}
        <Formik<LoginFormData>
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                />
                <ErrorMessage name="email" component={FormInputError} />
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                />
                <ErrorMessage name="password" component={FormInputError} />
                <Button type="submit" disabled={isSubmitting}>
                  Log In With Password
                </Button>
              </Form>
            );
          }}
        </Formik>

        <Divider style={{ marginTop: '1.5rem' }}>OR</Divider>

        <LoginWithoutPasswordButton
          type="button"
          onClick={() => history.push('/log-in-without-password')}
        >
          Log in without Password
        </LoginWithoutPasswordButton>

        <small style={{ marginTop: '2rem' }}>
          <Link to="/forgot-password">Forgot Password?</Link>
        </small>
        <small style={{ marginTop: '1.5rem' }}>
          Can't log in? <Link to="/contact-us">Contact us</Link>
        </small>
      </Container>
    </AuthPageLayout>
  );
};

export default withRouter(Login);

const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    padding: 0 !important;
    margin-top: 1.5rem;
    text-transform: uppercase;
  }
`;

const LoginWithoutPasswordButton = styled(Button)`
  background-color: transparent !important;
  border: 1px solid #d0d0d0 !important;
  color: #d0d0d0 !important;

  text-transform: uppercase;

  @media (hover: hover) {
    :hover {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      border-color: ${({ theme }) => theme.colors.primary} !important;
      color: #fff !important;
    }
  }
`;
