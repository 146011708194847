import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 60px;
  height: 60px;
  font-size: 1.25rem;
  text-align: center;
  line-height: 60px;
  color: #d9d9d9;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  svg {
    cursor: pointer;
  }

  // change the order of the hamburger to move it to the left
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    order: 1;
  }
`;

type HamburgerProps = {
  color?: string;
  toggle: (e: any) => void;
};

export const Hamburger = ({ toggle, color }: HamburgerProps) => {
  return (
    <Container color={color} onClick={toggle}>
      <FontAwesomeIcon icon="bars" />
    </Container>
  );
};
