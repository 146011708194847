import Hashids from 'hashids';

export class Hasher {
  private hashid;

  constructor(private salt?: string, private minLength?: number) {
    // REMEMBER
    // Hasher for Soil SIte ids can't have a salt because the legacy system wasn't using one by mistake (bug)
    // If we use salt the the siteIdentifier will be different hash and
    // OQ codes on Soil Site posters that were printed on the legacy system won't work.
    const options = {
      salt: salt || '',
      minLength: minLength || 7,
    };

    this.hashid = new Hashids(options.salt, options.minLength);
  }

  encode(id: number) {
    return this.hashid.encode(id);
  }

  decode(uuid: string) {
    return this.hashid.decode(uuid).join('');
  }
}
