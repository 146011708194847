import styled, { css } from 'styled-components';

import { Meta } from '../components/Meta';
import { Credits } from '../components/ui/Credits';
import { isMobileApp } from '../mobile/mobile';
import { ContactForm } from '../features/contact/ContactForm';
import { useSwipeable } from 'react-swipeable';

export default function ContactUs({ history }) {
  const handlers = useSwipeable({
    delta: 30,
    onSwiped: (e) => {
      if (e.dir === 'Right') history.goBack();
    },
  });

  return (
    <PageContainer isMobileApp={isMobileApp()} {...handlers}>
      <Meta>
        <title>Contact Us | MakeSoil</title>
      </Meta>
      <FormContainer>
        <div style={{ textAlign: 'center' }}>
          <h3>Contact Us</h3>
          <p>We would love to hear from you</p>
        </div>
        <ContactForm />
      </FormContainer>
      <Credits align="left" hide="md">
        Image from{' '}
        <a
          href="https://www.felixx.nl/projects/isle-of-dikes.html"
          target="_blank"
          rel="noreferrer"
        >
          Felixx
        </a>
      </Credits>
    </PageContainer>
  );
}

type PageContainerProps = {
  isMobileApp: boolean;
};

const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center safe;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: center;
  }

  height: 100%;
  overflow: auto;

  ${(props) => {
    if (props.isMobileApp) {
      return css`
        background-color: rgba(36, 36, 36);
      `;
    } else {
      return css`
        background-color: #e2e2e2;

        background: url('/images/felixx.jpg') center;
        background-size: cover;
      `;
    }
  }}

  color: #d0d0d0;
`;

const FormContainer = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 66%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 70%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 50%;
  }

  padding: 2rem;
  background-color: rgba(36, 36, 36, 0.9);
  box-shadow: 0 0 18px 4px rgb(0 0 0 / 7%);

  border-radius: 4px;

  h3 {
    margin-top: 0;
  }

  form {
    display: flex;
    flex-direction: column;

		input {
      outline: none;
    }

    input:not([type="checkbox"]), textarea {
      margin-top: 1rem;
      font-size: 1rem;
      background: rgba(255, 255, 255, 0.1);
      color: rgb(255, 255, 255);
      border-top: none;
      border-right: none;
      border-left: none;
      border-image: initial;
      border-bottom: thin solid rgb(138 138 138);
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      padding-left: 8px;
      height: 2.25rem;
      width: 100%;
    }

    button {
      background-color: ${(props) => props.theme.colors.primary};

      cursor: pointer;

      &[disabled] {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }
  }
`;
