import { Marker, MarkerClusterer } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { debounce } from '../../../utils/debounce';
import { isMobile } from '../../../utils/helpers';
import {
  generateMapItemIdentifier,
  getIdFromIdentifier,
  getQueryParameter,
} from '../../../utils/url';
import { mapStyles } from '../map.styles';
import MakeSoilMapContext from '../MapContext';
import {
  boundsChanged,
  centerChanged,
  didSetCenter,
  drawerToggled,
  fetchMapItemsWithinBounds,
  fetchPlaceDetails,
  fetchSiteById,
  isDefaultCenter,
  mapReset,
  mapItemSelected,
  mapLoaded,
  selectHighlightedDrawerItemId,
  selectMapCenter,
  selectMapItems,
  selectMapZoom,
  zoomChanged,
} from '../mapSlice';
import { IMapItem, MapItemType, MapMarker } from '../model';
import {
  getBoundsFromMap,
  getCurrentLocation,
  isEmptyBounds,
  mapMarkers,
  mapSettings,
} from '../utils';
import { MapDrawer } from './MapDrawer';
import { SearchBox } from './SearchBox';
import GoogleMapNoLinks from '../../../components/GoogleMapNoLinks';
import { isAdmin } from '../../auth/utils';
import { useAuthUser } from '../../auth/useAuthUser';

export const MakeSoilMap = () => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();
  const mapCenter = useAppSelector(selectMapCenter);
  const mapZoom = useAppSelector(selectMapZoom);
  const history = useHistory();

  useEffect(() => {
    // set the current location as default map location if not yet set
    (async function setCurrentLocationAsDefault() {
      if (map && !didSetCenter()) {
        try {
          const {
            coords: { latitude: lat, longitude: lng },
          } = await getCurrentLocation();
          // the user might meanwhile have changed the map so ignore the current location
          if (isDefaultCenter()) {
            map.setZoom(10);
            map.setCenter({ lat, lng });
          } else {
            // Ignoring position as map meanwhile changed;
          }
        } catch (error) {
          console.log("Couldn't set current map location", error);
        }
      }
    })();
  }, [dispatch, map]);

  /**
   * If the page is linked to with address in search parameter,
   * find a place and center the map on it */
  useEffect(() => {
    (async function findPlaceFromUrlSearchParamAndCenterPage() {
      const searchParam = getQueryParameter(history.location.search, 'search');
      if (!searchParam) return;

      if (map) {
        const placeDetails = await dispatch(
          fetchPlaceDetails(searchParam)
        ).unwrap();
        if (!placeDetails) return;

        // hide or prevent opening of drawer in mobile view
        // keep drawer open or open it on bigger screens
        dispatch(drawerToggled(!isMobile()));

        map.fitBounds(placeDetails.details.geometry.viewport);
      }
    })();
  }, [dispatch, history.location.search, map]);

  /**
   * Handle automatically loading the Soil Site data
   * if the Map opens with a Map Item (Site, Garden etc) Url
   *
   * Also triggered when the map marker or drawer list item is selected
   */
  useEffect(() => {
    (async function openMapItemFromUrl() {
      const [, , itemType, itemIdentifier] = history.location.pathname.split(
        '/'
      );
      if (!itemType) return;

      await dispatch(fetchSiteById(getIdFromIdentifier(itemIdentifier)));

      const zoomParam = getQueryParameter(history.location.search, 'z');
      if (zoomParam) {
        dispatch(zoomChanged(parseInt(zoomParam)));
      }

      // on mobile open the drawer when user lands on the soil site url
      // only from outside
      const drawerOpenParam = getQueryParameter(
        history.location.search,
        'drawerOpen'
      );
      const drawerOpen =
        drawerOpenParam !== null ? JSON.parse(drawerOpenParam) : true;
      dispatch(drawerToggled(!isMobile() || drawerOpen));
    })();
  }, [dispatch, history.location.pathname, history.location.search]);

  // reset when leaving the map
  useEffect(() => {
    return () => {
      dispatch(mapReset());
    };
  }, [dispatch]);

  const handleMapLoaded = useCallback(
    (map: google.maps.Map) => {
      const bounds = getBoundsFromMap(map);
      if (!isEmptyBounds(bounds)) {
        dispatch(mapLoaded(bounds));
      }
      setMap(map);
    },
    [dispatch]
  );

  const handleMapUnmounted = useCallback(
    (map: google.maps.Map) => {
      // save the map center for when user comes back in this session
      if (map) {
        dispatch(centerChanged(map.getCenter().toJSON()));
      }
      setMap(null);
    },
    [dispatch]
  );

  const handleZoomChanged = useCallback(() => {
    if (map) {
      dispatch(zoomChanged(map.getZoom()));
    }
  }, [dispatch, map]);

  const handleBoundsChanged = debounce(() => {
    if (map) {
      const bounds = getBoundsFromMap(map);
      if (!isEmptyBounds(bounds)) {
        dispatch(fetchMapItemsWithinBounds(bounds));
        if (map.getZoom() !== mapZoom) {
          dispatch(zoomChanged(map.getZoom()));
        }
        dispatch(
          boundsChanged({
            bounds,
            center: map.getCenter().toJSON(),
          })
        );
      }
    }
  }, 250);

  return (
    <MakeSoilMapContext.Provider value={{ map }}>
      <MapContainer>
        <SearchBox showFilter={isAdmin(authUser)} />
        <MapDrawer />

        <GoogleMapNoLinks
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            styles: mapStyles,
            rotateControl: true,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            restriction: {
              latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
              strictBounds: true,
            },
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER,
            },
            minZoom: mapSettings.minZoom,
            maxZoom: mapSettings.maxZoom,
            gestureHandling: 'greedy',
          }}
          clickableIcons={false}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={handleMapLoaded}
          onUnmount={handleMapUnmounted}
          onIdle={handleBoundsChanged}
          onZoomChanged={handleZoomChanged}
        >
          <MarkersRenderer />
        </GoogleMapNoLinks>
      </MapContainer>
    </MakeSoilMapContext.Provider>
  );
};

const MapContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;

  img,
  img:focus {
    outline: none;
  }
  div[role='button'] {
    outline: none;
  }
`;

type MarkersRendererProps = {
  clustered?: boolean;
};

const MarkersRenderer: React.FC<MarkersRendererProps> = (props) => {
  const { clustered = false } = props;

  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  };

  if (clustered) {
    return (
      <MarkerClusterer options={options} minimumClusterSize={3}>
        {(clusterer) => <MarkerList clusterer={clusterer} />}
      </MarkerClusterer>
    );
  } else {
    return <MarkerList />;
  }
};

type MarkerListProps = {
  clusterer?: Clusterer;
};

const MarkerList: React.FC<MarkerListProps> = (props) => {
  const { clusterer } = props;

  const history = useHistory();

  const dispatch = useAppDispatch();
  const markers = useAppSelector(selectMapItems);
  const highlightedMarkerId = useAppSelector(selectHighlightedDrawerItemId);

  /**
   * Map MakeSoil Items (Soil Site, Soil Supporter etc) to Map Markers
   */
  const generateMarkers = useCallback((): MapMarker[] => {
    return mapMarkers(markers, highlightedMarkerId);
  }, [markers, highlightedMarkerId]);

  const handleMarkerSelected = useCallback(
    (marker: IMapItem) => {
      if (marker.type === MapItemType.SoilSite) {
        const { id, name, type, location } = marker;
        dispatch(mapItemSelected({ id, name, type, location }));

        const identifier = generateMapItemIdentifier(id, name);
        history.push(`/map/${type}/${identifier}?drawerOpen=true`);
      }
    },
    [dispatch, history]
  );

  return (
    <>
      {generateMarkers().map((marker) => (
        <Marker
          key={`${marker.id}-${marker.name}`}
          position={{
            lat: marker.location.lat,
            lng: marker.location.lng,
          }}
          onClick={() => handleMarkerSelected(marker)}
          icon={marker.icon}
          clusterer={clusterer}
        />
      ))}
    </>
  );
};
