import { Hasher } from './hasher';

const siteHasher = new Hasher();

const envDomains = {
  development: 'localhost:4000',
  staging: 'makesoil2-staging.herokuapp.com',
  production: 'www.makesoil.org',
};

type Environments = keyof typeof envDomains;
export const environment = process.env.REACT_APP_BRANCH as Environments;
export const _isProd = environment === 'production';

const getScheme = (
  environment: Environments,
  connectionType: 'http' | 'socket'
) => {
  let scheme;
  if (connectionType === 'http')
    scheme = environment === 'development' ? 'http://' : 'https://';
  else if (connectionType === 'socket')
    scheme = environment === 'development' ? 'ws://' : 'wss://';
  return scheme;
};

export const getUrlByConnectionType = (connectionType: 'http' | 'socket') => {
  if (!envDomains[environment]) {
    throw new Error('No REACT_APP_BRANCH specified');
  }
  const scheme = getScheme(environment, connectionType);

  let url = `${scheme}${envDomains[environment]}`;

  // on development, use the current protocol and host instead of http://localhost
  // this allows testing via an ip-address or public domain routed to the local dev server
  if (environment === 'development') {
    url = url
      .replace(envDomains.development, window.location.host)
      .replace(':3000', ':4000')
      .replace('http:', window.location.protocol)
      .replace('ws:', window.location.protocol === 'https:' ? 'wss:' : 'ws:');
  }

  //  console.log(`Environment=${environment} scheme=${scheme} url=${url}`);

  return url;
};

export const removeNonAlphanumeric = (str: string) =>
  str.replace(/[\W_]+/g, '');

/**
 * Gets the Query Parameter from a QueryString
 * @param search query string (ex. location.search)
 * @param key the param to get
 */
export const getQueryParameter = (search: string, key: string) => {
  const urlParams = new URLSearchParams(search);
  if (!urlParams.has(key)) return null;
  return urlParams.get(key);
};

/*
  Site urls are by default in format www.makesoil.org/site/{UUID}--{site-name-here}
  ex: https//www.makesoil.org/site/YQa0RxZ--zachs-soil-site

	To get at the UUID we parse the url with this function.
	We then decode it back to the Id we can use on the server.
  If parsing fails, fall back to returning the entire URL.
 */
/**
 * Get Soil Site ID from the Url
 * @param url site url
 */
export const parseSiteUrl = (url: string): number | string => {
  try {
    const siteIdentifier = url.substr(url.lastIndexOf('/') + 1);
    const [uuid] = siteIdentifier.split('-');
    return parseInt(siteHasher.decode(uuid));
  } catch (e) {
    console.error(e);
    return url;
  }
};

export const convertThreadId = (id: string | number) => {
  return typeof id === 'string' ? getIdFromIdentifier(id) : id;
};

export const getHashFromId = (id: number) => {
  return siteHasher.encode(id);
};

export const getHashFromIdentifier = (identifier: string) => {
  try {
    const [uuid] = identifier.split('-');
    return uuid;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Get Entiry ID from it's identifier
 * @param identifier Map Item Identifier
 */
export const getIdFromIdentifier = (identifier: string): number => {
  try {
    const [uuid] = identifier.split('-');
    return parseInt(siteHasher.decode(uuid));
  } catch (error) {
    console.error(error);
    return null;
  }
};

const generateIdentifier = (id: number, name: string) => {
  return `${siteHasher.encode(id)}-${name
    .split(' ')
    .map(removeNonAlphanumeric)
    .map((substr: string) => substr.toLowerCase())
    .join('-')}`;
};

export const generateUserIdentifier = (userId: number) => {
  return generateIdentifier(userId, 'u');
};

export const generateThreadIdentifier = (threadId: number) => {
  return generateIdentifier(threadId, 't');
};

export const generateMapItemIdentifier = (
  itemId: number,
  itemName?: string
) => {
  return generateIdentifier(itemId, itemName);
};

export const generateSiteIdentifier = (siteId: number, siteName: string) => {
  return generateIdentifier(siteId, siteName);
};

export const generateSiteUrl = (siteId: number, siteName: string) => {
  return `${getUrlByConnectionType('http')}/map/site/${generateSiteIdentifier(
    siteId,
    siteName
  )}`;
};

export const generateInviteUrl = (siteId: number, siteName: string) => {
  return siteId
    ? `${generateSiteUrl(siteId, siteName)}`
    : window.location.origin;
};

export const parseUrlsAsLinks = (text: string) => {
  const unacceptedChars = [',', '?', '!'];
  // find the url
  //const oldRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  const newRegex = /(http(s)?:\/\/.)?(www\.)?([\w_-]+(?:\.[\w_-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g;

  const matches = text.match(newRegex) || [];

  // replace the url text with a link
  matches.forEach((url: string) => {
    // remove unaccepted chars
    unacceptedChars.forEach((char) => (url = url.replace(char, '')));

    // add the protocol if needed
    const link = !/^(f|ht)tps?:\/\//i.test(url) ? `http://${url}` : url;
    text = text.replace(url, `<a href="${link}" target="_blank">${url}</a>`);
  });
  return text;
};

/**
 * Secure way to open new windows (https://mathiasbynens.github.io/rel-noopener/)
 */
// NO longer use this, instead use <SmartLink>
// export const safelyOpen = (url: string) => {
//  window.open(url, 'target=blank,rel=noopener,rel=noreferrer');
// };

/**
 * Injects the Filestack options for a given url
 * @example https://cdn.filestackcontent.com/rotate=deg:exif/844f2xv22c1x97b2
 * @param {String}  url url that the options need to be injected into
 * @param {String | Array<String>} options Filestack options
 * @return {String} modified url
 */
export const injectFilestackOptions = (url, options) => {
  if (!Array.isArray(options)) options = [options];
  return (
    url.slice(0, url.lastIndexOf('/') + 1) +
    options.join('/') +
    url.slice(url.lastIndexOf('/'))
  );
};
