import { useState, useEffect } from 'react';
import { queries } from '../../../app/theme';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

type SoilSiteViewType = 'grid' | 'tab';

/**
 * React to screen size change and switch between grid and tab view
 * @returns
 */
export function useAdaptiveLayout() {
  const isTablet = useMediaQuery(queries.lg);

  const [tabView, toggleTabView] = useState<SoilSiteViewType>(() =>
    isTablet ? 'tab' : 'grid'
  );

  useEffect(() => {
    toggleTabView(() => (isTablet ? 'tab' : 'grid'));
  }, [isTablet]);

  return tabView;
}
