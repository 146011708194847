import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { theme } from '../../../app/theme';
import { DropMenu, Flex, SmartImage } from '../../../components/ui';
import { INotification } from '../model/INotification';
import { NotificationCategory } from '../model/NotificationCategory';
import { useAppDispatch } from '../../../app/hooks';
import {
  deleteNotification,
  shouldMarkAssociated,
  toggleReadState,
  toggleVisible,
} from '../notificationCentreSlice';
import { DropMenuItem } from '../../../components/ui/DropMenu';
import { TimeAgo } from '../../../components/ui/TimeAgo';

type NotificationProps = {
  notification: INotification;
};

export const NotificationListItem = ({ notification }: NotificationProps) => {
  const {
    id,
    text,
    read,
    imageUrl,
    linkUrl,
    category,
    createdAt,
  } = notification;

  const {
    location: { pathname, search },
    push,
  } = useHistory();
  const dispatch = useAppDispatch();

  const handleToggleRead = async (notificationId: number, read: boolean) => {
    try {
      // We want to mark read all soil site message board notifications
      // when just one of them is clicked
      const markReadAssociated = shouldMarkAssociated(notification);
      dispatch(toggleReadState({ notificationId, read, markReadAssociated }));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDelete = async (notificationId: number) => {
    try {
      await dispatch(deleteNotification(notificationId));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleNotificationClick = (e) => {
    // Don't toggle dropdown when clicked on notification menu
    const notificationMenuClicked = e.target.className.includes(
      'notification-menu'
    );
    if (notificationMenuClicked) return;

    const alreadyAtNotificationTargetUrl =
      linkUrl && linkUrl === `${pathname}${search}`;
    if (!alreadyAtNotificationTargetUrl) push(linkUrl);

    if (!read) handleToggleRead(id, true);
    // hide the Notification Centre
    dispatch(toggleVisible(false));
  };

  const imageType =
    category === NotificationCategory[NotificationCategory[category]]
      ? 'profile'
      : 'site';

  return (
    <div onClick={handleNotificationClick}>
      <NotificationContainer
        read={read}
        justify="space-between"
        style={{
          padding: '0.5rem 1rem',
          backgroundColor: read ? '' : '#e7f3e3',
        }}
      >
        <Flex gap="0.5rem">
          <SmartImage
            src={imageUrl}
            type={imageType}
            width={40}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '4px',
            }}
          />

          <Flex direction="column">
            <div className="text">{text}</div>
            <div className="timeAgo" style={{ color: theme.colors.primary }}>
              <TimeAgo date={createdAt} />
            </div>
          </Flex>
        </Flex>
        <Flex align="center" gap="0.5rem">
          <Menu
            notificationId={id}
            read={read}
            onToggleRead={handleToggleRead}
            onDelete={handleDelete}
          />
        </Flex>
      </NotificationContainer>
    </div>
  );
};

const NotificationContainer = styled(Flex)<{ read: boolean }>`
  padding: 0.5rem;

  cursor: pointer;
  background-color: #fff;

  .text {
    color: ${({ read }) => (read ? '#a5a5a5' : '#616161')};
    font-size: 0.875rem;
  }

  .timeAgo {
    margin-top: 0.25rem;
    font-size: 0.825rem;
  }

  &:hover {
    background-color: #f7f7f7;
    color: #616161;

    .notification-menu {
      opacity: 1;

      :hover {
        background-color: #d9d9d9;
      }
    }
  }
`;

const Menu = ({ notificationId, read, onToggleRead, onDelete }) => {
  const readText = read ? 'Mark as Unread' : 'Mark as Read';

  return (
    <DropMenu
      triggerElement={
        <Toggle align="center" justify="center" className="notification-menu">
          <FontAwesomeIcon
            icon="ellipsis-h"
            style={{ pointerEvents: 'none' }}
          />
        </Toggle>
      }
    >
      <DropMenuItem
        onClick={() => onToggleRead(notificationId, !read)}
        className="notification-menu-item"
      >
        <FontAwesomeIcon icon="envelope" /> {readText}
      </DropMenuItem>

      <DropMenuItem
        onClick={() => onDelete(notificationId)}
        className="notification-menu-item"
      >
        <FontAwesomeIcon icon="trash" /> Delete Notification
      </DropMenuItem>
    </DropMenu>
  );
};

const Toggle = styled(Flex)`
  height: 2rem;
  width: 2rem;

  opacity: 1;

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}) {
    opacity: 0;
  }

  background-color: #ebebeb;
  border: 1px solid #cfcfcf;
  border-radius: 50%;
  color: #616161;

  transition 0.35s ease;
`;
