import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Modal,
  Button,
  TextArea,
  FormInputError,
  Collapsible,
} from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';

import { useSoilSiteContext } from '../SoilSiteContext';
import { useSiteManager } from '../hooks';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const MIN_REASON_LENGTH = 10;

export const DeactivateSiteModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { soilSite } = useSoilSiteContext();
  const history = useHistory();

  const [reason, setReason] = useState('');

  const { deactivate: deactivateSite } = useSiteManager(soilSite.id);

  const handleDeactivate = async (reason: string) => {
    try {
      const { data } = await deactivateSite(reason);

      if (data && data.deactivateSite.errors.length > 0) {
        toast.error(`${data.deactivateSite.errors[0]}`);
      }

      if (data && data.deactivateSite.soilSite) {
        toast.success('Your soil site has been deactivated.');
        onClose();
        setTimeout(() => history.push('/my-soil-sites'), 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(() =>
    handleDeactivate(reason)
  );

  return (
    <Modal
      header="Deactivate soil site"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={
        <Button
          disabled={reason.length < MIN_REASON_LENGTH || isSubmitting}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          DEACTIVATE
        </Button>
      }
    >
      <h3>Are you sure you want to deactivate this soil site?</h3>
      <p>
        Once your soil site is deactivated, it will no longer show up on the map
        or in search results, people won't be able to join it, and current Soil
        Supporters will not be able to interact with it. You can reactivate it
        in the future.
      </p>

      <h4>Why are you deactivating this soil site?</h4>
      <p>
        This helps us to improve the platform for all our current and future
        users.
      </p>

      <TextArea
        onChange={(e) => setReason(e.target.value)}
        rows={3}
        placeholder="Please tell us why you're deactivating this soil site"
      />
      {reason.length > 0 && reason.length < MIN_REASON_LENGTH && (
        <FormInputError>Please tell us more.</FormInputError>
      )}

      <h4>Other Options</h4>
      <Collapsible label="Make Your soil site Private or Unlisted" open={false}>
        <div>
          <p>
            You can make your soil site 'Private', so that people can only join
            if they are approved by you.
          </p>
          <p>
            You can also make it 'Unlisted', so it won't appear on the map or
            search results. Unlisted sites are Private by default.
          </p>
          <p>
            Just go back to your soil site page and click Edit soil site on the
            left.
          </p>
        </div>
      </Collapsible>
      <Collapsible label="Hand Over To Another Soil Maker" open={false}>
        <div>
          <p>
            You can hand over the management responsibility to another Soil
            Maker (after they join your soil site), or even to one of your
            existing Soil Supporters.
          </p>
          <p>
            Just go back to your soil site page and on the right you can either
            invite a new participant as a Soil Maker, or adjust the roles of
            existing participants.
          </p>
        </div>
      </Collapsible>
    </Modal>
  );
};
