import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/themes/light.css';
import { isMobile } from '../../utils/helpers';

const TipWrapper = styled.div`
  width: 100%;
  font-weight: 100;
  font-size: 0.875rem;
`;

type ToolTipProps = {
  trigger?: 'mouseenter focus' | 'click' | 'mouseenter click' | 'focusin';
  triggerElement?: React.ReactElement;
  className?: string;
  color?: string;
  hideArrow?: boolean;
  hideOnClickInside?: boolean;
  hideOnClickOutside?: boolean;
  style?: CSSProperties;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  theme?: 'dark' | 'light';
};

export const Tooltip: React.FC<ToolTipProps> = (props) => {
  const {
    children,
    triggerElement,
    trigger = 'mouseenter',
    color = '#68b74e',
    placement = 'bottom',
    theme = 'dark',
    style,
  } = props;

  return (
    <Tippy
      trigger={trigger}
      animation="shift-away"
      content={<TipWrapper>{children}</TipWrapper>}
      placement={placement}
      theme={theme}
    >
      {triggerElement || (
        <span style={style}>
          <FontAwesomeIcon
            icon="question-circle"
            color={color}
            size={isMobile() ? 'lg' : '1x'}
          />
        </span>
      )}
    </Tippy>
  );
};
