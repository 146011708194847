import { ILocation } from '../../app/model/ILocation';
import { DropOff } from './dropoff/model';

export interface ISiteRole {
  id: number;
  name: string;
}

export enum SITE_ROLE {
  OWNER = 'Primary Soil Maker',
  MAKER = 'Soil Maker',
  SUPPORTER = 'Soil Supporter',
}
export interface SiteMember {
  userId: number;
  firstName: string;
  lastName?: string;
  picture: string;
  location: ILocation;
  role: ISiteRole;
  createdAt: Date;
  dropOffs: DropOff[];
}

export interface Site {
  id?: number;
  name: string;
  picture?: string;
  description: string;

  // Google Maps formatted_address field
  address?: string;
  // JSON response of Gmaps parsed address
  addressComponents?: object;
  // PlaceId assigned by Google
  googlePlaceId?: string;
  location: ILocation;
  obfuscatedLng?: number;
  obfuscatedLat?: number;

  access: string;
  dropOff: string;
  hours: string;
  accepted: string[];
  prohibited: string[];

  active: boolean;
  requiresApproval: boolean;
  obfuscate: boolean;
  isUnlisted: boolean;
  dropOffs: DropOff[];

  createdAt?: Date;
  updatedAt?: Date;

  // added for participants
  members?: SiteMember[];
  accessRequests?: IAccessRequest[];
}

export interface SiteListItem {
  id: number;
  name: string;
  picture?: string;
  active: boolean;
  makers: SiteMember[];
  pendingAccessRequests: number;
  dropOffs: number;
}

export interface IAccessRequest {
  id: number;
  siteId: number;
  userId: number;
  user: SiteMember;
  approved: number;
  message: string;
  createdAt: Date;
}

export interface SitePermissions {
  canEdit: boolean;
  canPromote: boolean;
  canDemote: boolean;
  canRemove: boolean;
  canTransferOwnership: boolean;
  canLeaveSite: boolean;
  canDeactivate: boolean;
}
