import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Field, useFormikContext } from 'formik';
import { validatePasswordStrength } from '../../../features/auth/utils';

type InputProps = {
  name?: string;
  placeholder?: string;
  value?: any;
  noValidate?: boolean;
  className?: string;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  onChange?: (e: any) => void;
};

export const PasswordInput: React.FC<InputProps> = (props) => {
  const { style, className, name, noValidate = false } = props;
  const [inputType, setInputType] = useState('password');
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = ({ target: { value } }) => {
    setFieldValue(name, value, true);
    setFieldTouched(name, true);
  };

  const validate = (password: string) => {
    if (!noValidate) {
      const validatePassword = validatePasswordStrength(password);
      const reducer = (prev, cur) => prev + '\n' + cur;
      if (!validatePassword.ok) {
        return validatePassword.errors.reduce(reducer);
      }
    }
  };

  return (
    <TextInputWrapper style={style} className={className}>
      <StyledInput
        {...props}
        validate={validate}
        type={inputType}
        autoComplete="no-auto-complete"
        onChange={handleChange}
      />
      <Cloaker className="password-cloaker">
        <FontAwesomeIcon
          icon={inputType === 'password' ? 'eye' : 'eye-slash'}
          onClick={() => {
            setInputType((prev) => (prev === 'password' ? 'text' : 'password'));
          }}
        />
      </Cloaker>
    </TextInputWrapper>
  );
};

const StyledInput = styled(Field)<{ type?: string }>`
  padding: 0.5rem;
  height: 2.25rem;
  width: 100%;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: ${({ theme }) => theme.borderRadius}px;

  ${({ prefix }) =>
    prefix &&
    `
			padding-left: 0 !important;
			margin-top: 0 !important;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		`}

  ${({ type }) =>
    type &&
    `
			margin-top: 0 !important;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		`}

  font-size: 1rem;
  color: #495057;
  outline: none;

  ::placeholder {
    font-style: normal;
    font-size: 1rem;
  }

  :focus {
    border: 1px solid #a3abb3;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }

  transition: border-color 0.25s ease-in-out;
`;

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  line-height: 1rem;
`;

const Cloaker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;

  background-color: #f4f4f4;
  color: rgba(154, 154, 154, 0.5);

  :hover {
    background-color: #ebebeb;
    color: rgba(154, 154, 154, 1);
  }

  border: 1px solid #ced4da;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none;

  cursor: pointer;

  transition: color 0.25s ease;
`;
