import { gql, useMutation } from '@apollo/client';
import { ErrorMessage, Form, Formik } from 'formik';
import styled from 'styled-components';

import { PasswordInput } from './ui/forms/PasswordInput';
import { Button, Flex, FormInputError } from './ui';

const CREATE_PASSWORD = gql`
  mutation SetPassword($password: String!) {
    setPassword(password: $password) {
      message
      errors
    }
  }
`;

type CreatePasswordFormData = {
  password: string;
};

type Props = {
  onPasswordCreated?: () => void;
};

export function CreatePasswordForm({ onPasswordCreated }: Props) {
  const [setPassword] = useMutation(CREATE_PASSWORD);

  const handleSubmit = async (
    { password }: CreatePasswordFormData,
    { setSubmitting }: any
  ) => {
    await setPassword({ variables: { password } });
    setSubmitting(false);
    onPasswordCreated && onPasswordCreated();
  };

  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <PasswordInput
            placeholder="Create password"
            name="password"
            autoFocus={true}
          />
          <ErrorMessage
            name="password"
            component={FormInputError}
            render={(props) => {
              return (
                <div style={{ color: 'red', marginTop: '0.5rem' }}>
                  <span>Password must have at least:</span>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{props}</div>
                </div>
              );
            }}
          />

          <Flex style={{ flexDirection: 'column', marginTop: '1rem' }}>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              CREATE PASSWORD
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
