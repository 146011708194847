import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ContactInfo, IAuthUser } from './model';
import { decodeToken, removeJwtToken, storeJwtToken } from './utils';
import authService, { LoginCredentials, SignupFormData } from './authService';
import {
  isMobileApp,
  mobileRemovePushRegistration,
  mobileSetBadge,
} from '../../mobile/mobile';

type AuthSliceState = {
  user: IAuthUser;
  loading: boolean;
  error: string;
  signupErrors: string[];
};

export const emptyUser: IAuthUser = {
  id: undefined,
  isLoggedIn: false,
  createdAt: null,

  explicitlyLoggedOut: false,
  verified: false,
  firstName: '',
  lastName: '',
  picture: '',
  roles: [],

  hasPassword: null,
  hasAddress: null,
};

const initialState: AuthSliceState = {
  user: emptyUser,
  loading: false,
  error: '',
  signupErrors: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoggedIn(state, { payload }: PayloadAction<IAuthUser>) {
      state.user = {
        ...state.user,
        ...payload,
        isLoggedIn: true,
      };
    },
    avatarUpdated(state, { payload }: PayloadAction<string>) {
      state.user = {
        ...state.user,
        picture: payload,
      };
    },
    contactInfoUpdated(state, { payload }: PayloadAction<Partial<IAuthUser>>) {
      state.user = {
        ...state.user,
        ...payload,
      };
    },
    errorCleared(state) {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMe.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.user = {
          ...state.user,
          ...payload,
        };
      })
      .addCase(updateContactInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.user = {
          ...state.user,
          ...payload,
        };
      })
      .addCase(signup.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        if (payload.accessToken) {
          storeJwtToken(payload.accessToken);
          const user: IAuthUser = decodeToken(payload.accessToken);
          state.user = {
            ...state.user,
            ...user,
            isLoggedIn: true,
          };
        }
      })
      .addCase(verifyAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        storeJwtToken(payload.accessToken);
        const user: IAuthUser = decodeToken(payload.accessToken);
        state.user = {
          ...state.user,
          ...user,
          isLoggedIn: true,
        };
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        storeJwtToken(payload.accessToken);
        const user: IAuthUser = decodeToken(payload.accessToken);
        state.user = {
          ...state.user,
          ...user,
          isLoggedIn: true,
        };
      })
      .addCase(loginWithMagicId.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        storeJwtToken(payload.accessToken);
        const user: IAuthUser = decodeToken(payload.accessToken);
        state.user = {
          ...state.user,
          ...user,
          isLoggedIn: true,
        };
      })

      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
        state.user = { ...emptyUser, explicitlyLoggedOut: true };
        removeJwtToken();
      })
      .addCase(signup.rejected, (state, action) => {
        state.error = action.error.message;
        if (action.payload) {
          state.signupErrors = action.payload as string[];
        }
      })

      // matchers
      .addMatcher(
        isPending(
          login,
          loginWithMagicId,
          verifyAccount,
          changeVerificationEmailAddress
        ),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(
          login,
          loginWithMagicId,
          verifyAccount,
          changeVerificationEmailAddress
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(
          login,
          loginWithMagicId,
          verifyAccount,
          changeVerificationEmailAddress
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export const {
  userLoggedIn,
  avatarUpdated,
  contactInfoUpdated,
  errorCleared,
} = authSlice.actions;

export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectSignupErrors = (state: RootState) => state.auth.signupErrors;

export const selectAuthUser = (state: RootState) => state.auth.user;
export const selectIsLoggedIn = (state: RootState) =>
  state.auth.user.isLoggedIn;

export default authSlice.reducer;

//#region Thunks

export const fetchMe = createAsyncThunk<ContactInfo, number>(
  'auth/fetchMe',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await authService.getMe(userId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.me;
  }
);

type SignupResult = {
  passToAccountVerification: boolean;
} & AuthResult;

export const signup = createAsyncThunk<SignupResult, SignupFormData>(
  'auth/signup',
  async (formData, { rejectWithValue }) => {
    const { data, errors } = await authService.signup(formData);

    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    const hasError =
      !data.createUserAccount.accessToken &&
      data.createUserAccount.errors.length;

    if (hasError) {
      return {
        ok: true,
        passToAccountVerification:
          data.createUserAccount.errors[0] === 'account.exists',
        accessToken: null,
      };
    }

    return {
      ok: true,
      passToAccountVerification: true,
      accessToken: data.createUserAccount.accessToken,
    };
  }
);

export const login = createAsyncThunk<AuthResult, LoginCredentials>(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    const { data, errors } = await authService.login(credentials);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }
    if (!data.login.accessToken) {
      return rejectWithValue(data.login.errors[0]);
    }

    return {
      ok: true,
      accessToken: data.login.accessToken,
    };
  }
);

export const loginWithMagicId = createAsyncThunk<AuthResult, string>(
  'auth/loginWithMagicId',
  async (magicId, { rejectWithValue }) => {
    const { data, errors } = await authService.loginWithMagicId(magicId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }
    if (!data.loginWithMagicId.accessToken) {
      return rejectWithValue(data.loginWithMagicId.errors[0]);
    }

    return {
      ok: true,
      accessToken: data.loginWithMagicId.accessToken,
    };
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    // cleanup local state
    if (isMobileApp()) {
      void mobileRemovePushRegistration();
      void mobileSetBadge(0);
    }
    // remove the Refresh Token Cookie on the Server
    const { errors } = await authService.logout();
    if (errors) {
      return rejectWithValue(errors);
    }
    // reset the root reducer to clear out state in all slices
    dispatch({ type: 'logout' });
  }
);

// account verification

export const verifyAccount = createAsyncThunk<VerificationResult, string>(
  'auth/verifyAccount',
  async (code, { rejectWithValue }) => {
    const { data, errors } = await authService.verifyAccount(code);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }
    if (!data.verifyAccount.accessToken) {
      return rejectWithValue(data.verifyAccount.errors[0]);
    }

    return {
      ok: true,
      accessToken: data.verifyAccount.accessToken,
    };
  }
);

type VerificationActionResult = {
  errors: string[];
  message: string;
};

export const sendAccountVerificationCode = createAsyncThunk<VerificationActionResult>(
  'auth/sendAccountVerificationCode',
  async (_, { rejectWithValue }) => {
    const { data, errors } = await authService.sendAccountVerificationCode();
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    const { errors: actionErrors } = data.sendAccountVerificationCode;
    if (actionErrors.length > 0) {
      return rejectWithValue(actionErrors[0]);
    }

    return data.sendAccountVerificationCode;
  }
);

export const changeVerificationEmailAddress = createAsyncThunk<
  VerificationActionResult,
  string
>('auth/changeVerificationEmailAddress', async (email, { rejectWithValue }) => {
  const { data, errors } = await authService.changeVerificationEmailAddress(
    email
  );

  if (!data && errors) {
    return rejectWithValue(errors[0]);
  }

  const { errors: actionErrors } = data.changeVerificationEmailAddress;
  if (actionErrors.length > 0) {
    return rejectWithValue(actionErrors[0]);
  }

  return data.changeVerificationEmailAddress;
});

export const updateContactInfo = createAsyncThunk<IAuthUser, ContactInfo>(
  'auth/updateContactInfo',
  async (info, { rejectWithValue }) => {
    const { data, errors } = await authService.updateContactInfo(info);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.updateContactInfo;
  }
);

type AuthResult = {
  ok: boolean;
  accessToken: string;
};

type VerificationResult = AuthResult;

//#endregion
