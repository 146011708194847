import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  FormikErrors,
  FormikHelpers,
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import { isMobileApp } from '../../mobile/mobile';
import { Input, FormInputError, TextArea, Button } from '../../components/ui';
import { RecaptchaFormControl } from '../../components/ui/forms/RecaptchaFormControl';
import { isEmail } from '../../utils/formValidation';
import { FormControl } from '../../components/ui/forms/Forms';
import { useAuthUser } from '../auth/useAuthUser';
import { useRef } from 'react';

const CONTACT = gql`
  mutation ContactUs($input: ContactInput!) {
    contact(input: $input) {
      errors
      message
    }
  }
`;

type ContactFormData = {
  name: string;
  email: string;
  subject: string;
  message: string;
  recaptcha: string;
};

type ContactFormProps = {
  onContactCompleted?: () => void;
};

export const ContactForm: React.FC<ContactFormProps> = ({
  onContactCompleted,
}) => {
  const authUser = useAuthUser();

  const recaptchaRef = useRef(null);

  const [contact] = useMutation(CONTACT);

  const initialValues: ContactFormData & { recaptcha: string } = {
    name: '',
    email: authUser.email || '',
    subject: '',
    message: '',
    recaptcha: null,
  };

  const validateForm = (values: ContactFormData & { recaptcha: string }) => {
    const { name, email, subject, message, recaptcha } = values;

    let errors: FormikErrors<ContactFormData & { recaptcha: string }> = {};
    if (!name) {
      errors.name = 'Please provide at least a First Name.';
    }
    if (!email || !isEmail(email)) {
      errors.email = 'Please provide valid Email Address.';
    }
    if (!subject) {
      errors.subject = 'Please provide a Message Subject.';
    }
    if (!message) {
      errors.message = 'Please provide a Message.';
    }
    if (!isMobileApp() && !recaptcha) {
      errors.recaptcha = 'Please complete the reCAPTCHA.';
    }
    return errors;
  };

  const handleSubmit = async (
    { email, name, subject, message, recaptcha }: ContactFormData,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) => {
    setSubmitting(true);

    const { data } = await contact({
      variables: {
        input: {
          email,
          name,
          subject,
          message,
          recaptcha,
        },
      },
    });

    setSubmitting(false);
    recaptchaRef.current?.reset();

    if (data.contact) {
      toast.success(data.contact.message);
      resetForm();
    }
    onContactCompleted && onContactCompleted();
  };

  return (
    <Formik<ContactFormData>
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting, dirty }) => {
        return (
          <Form>
            <FormControl>
              <Field type="text" name="name" as={Input} placeholder="Name" />
              <ErrorMessage name="name" component={FormInputError} />
            </FormControl>
            <FormControl>
              <Field type="email" name="email" as={Input} placeholder="Email" />
              <ErrorMessage name="email" component={FormInputError} />
            </FormControl>
            <FormControl>
              <Field
                type="text"
                name="subject"
                as={Input}
                placeholder="Subject"
              />
              <ErrorMessage name="subject" component={FormInputError} />
            </FormControl>
            <FormControl>
              <Field
                type="textarea"
                name="message"
                resize="vertical"
                as={TextArea}
                placeholder="Message"
              />
              <ErrorMessage name="message" component={FormInputError} />
            </FormControl>

            {!isMobileApp() && (
              <RecaptchaFormControl
                name="recaptcha"
                ref={recaptchaRef}
                style={{ marginTop: '1.5rem' }}
              />
            )}

            <FormControl
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
              }}
            >
              <Button
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
                loading={isSubmitting}
              >
                Send Message
              </Button>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};
