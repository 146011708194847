import styled from 'styled-components';

type CountIndicatorProps = {
  count: number;
  style?: React.CSSProperties;
};

export const CountIndicator: React.FC<CountIndicatorProps> = ({
  count,
  style,
}) => {
  if (count === 0) return null;
  return <IndicatorContainer style={style}>{count}</IndicatorContainer>;
};

const IndicatorContainer = styled.span`
  position: absolute;
  top: -3px;
  left: 23px;

  padding: 0 0.275rem;
  border-radius: 0.5rem;

  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  color: #fff;
`;
