import { useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type MediaModalProps = {
  ytPlayer?: {};
  iframeUrl?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const MediaModal: React.FC<MediaModalProps> = ({
  ytPlayer,
  iframeUrl,
  isOpen,
  onClose,
}) => {
  const modalRef = useRef(null);

  const mediaModal = (
    <AnimatePresence>
      {isOpen && (
        <ModalBackdrop
          onClick={(e) => {
            // close when clicked outside but not within modal
            // the click on the backdrop bubbles up
            if (modalRef.current && !modalRef.current.contains(e.target)) {
              // close only if clicked on the backdrop of THIS modal
              // it closes the modal of the backdrop being clicked
              // (if two modals are open it will close the right one)
              if (modalRef.current.parentNode.contains(e.target)) {
                onClose();
              }
            }
          }}
        >
          <ModalContainer
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -30, opacity: 0 }}
            ref={modalRef}
            layout
          >
            <MediaHeader>
              <FontAwesomeIcon icon="times" onClick={onClose} />
            </MediaHeader>
            <MediaWrapper>
              <MediaContainer>
                {ytPlayer && ytPlayer}

                {iframeUrl && (
                  <iframe
                    title="Media Iframe"
                    src={iframeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    width="100%"
                    height="100%"
                  />
                )}
              </MediaContainer>
            </MediaWrapper>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </AnimatePresence>
  );

  return createPortal(mediaModal, document.getElementById('modal-root'));
};

const ModalBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled(motion.div)`
  width: 90vw;
  max-height: 95vh;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 90vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 80vw;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 75vw;
  }
`;

const MediaHeader = styled.div`
  padding: 0 5px;
  font-size: 24px;
  font-weight: 200;
  text-align: right;
  color: #fff;
  max-width: 80vw;
  margin: 0 auto;

  svg {
    cursor: pointer;
  }
`;

const MediaWrapper = styled.div`
  background-color: #000;
  padding: 5px;
  max-height: 100%;
  max-width: 80vw;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 576px) {
    max-width: 95vw;
  }
`;

const MediaContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;
