import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query GetUserProfile($id: Int!) {
    profile(id: $id) {
      id
      firstName
      lastName
      email
      address
      location {
        lat
        lng
      }
      googlePlaceId
      picture
      phoneNum
      twitter
      instagram
      facebook
      linkedin
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $id: Int!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNum: String
    $picture: String
    $address: String
    $googlePlaceId: String
    $addressComponents: [AddressComponent]
    $location: LocationInput
    $twitter: String
    $instagram: String
    $facebook: String
    $linkedin: String
  ) {
    updateUserProfile(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNum: $phoneNum
        picture: $picture
        address: $address
        googlePlaceId: $googlePlaceId
        addressComponents: $addressComponents
        location: $location
        twitter: $twitter
        instagram: $instagram
        facebook: $facebook
        linkedin: $linkedin
      }
    ) {
      id
      firstName
      lastName
      email
      address
      location {
        lat
        lng
      }
      googlePlaceId
      picture
      phoneNum
      twitter
      instagram
      facebook
      linkedin
    }
  }
`;

export const UPDATE_PROFILE_PHOTO = gql`
  mutation UpdateProfilePhoto($picture: String!) {
    updateUserProfilePhoto(picture: $picture) {
      id
      picture
    }
  }
`;

export const DELETE_MY_ACCOUNT = gql`
  mutation DeleteMyAccount {
    deleteMyAccount {
      errors
      message
    }
  }
`;
