import Linkify from 'react-linkify';

type Props = {
  blank: boolean;
};

export const LinkParser: React.FunctionComponent<Props> = ({
  blank = false,
  children,
}) => {
  const blackList = ['facebook'];

  const blackListed = (href: string): boolean => {
    let passed = true;
    blackList.every((value) => (passed = !href.includes(value)));
    return !passed;
  };

  const linkRenderer = (href: string, text: string, key: string) => {
    if (blackListed(href)) return text;

    const normal = (
      <a href={href} key={key}>
        {text}
      </a>
    );

    const newWindow = (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );

    return blank ? newWindow : normal;
  };

  return <Linkify componentDecorator={linkRenderer}>{children}</Linkify>;
};
