import { useRef, useEffect } from 'react';

/**
 * Detects the click on the document and executes the callback if clicked outside of ref node
 * @param callback
 */
export const useClickOutside = (callback: () => void) => {
  const ref = useRef(null);

  useEffect(() => {
    const handler = (e) => {
      if (!ref.current?.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  }, [callback]);

  return ref;
};
