import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { Flex } from '../../../components/ui';
import { useThemeContext } from '../../../app/useThemeContext';
import { useMessenger } from '../useMessenger';
import { ThreadListItem } from './ThreadListItem';

export const ThreadList = () => {
  const theme = useThemeContext();
  const { threads } = useMessenger();

  if (!threads.length) {
    return (
      <Flex
        align="center"
        justify="center"
        direction="column"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <FontAwesomeIcon icon="comments" size="3x" color="#bdbdbd" />
        <div style={{ marginTop: '1rem', color: '#bdbdbd' }}>No Messages</div>
      </Flex>
    );
  }

  return (
    <Container>
      <ThreadListHeader height={theme.headerHeight}>Threads</ThreadListHeader>
      <Threads>
        <AnimatePresence initial={false}>
          {threads.map((thread) => (
            <ThreadListItem key={thread.id} thread={thread} />
          ))}
        </AnimatePresence>
      </Threads>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ThreadListHeader = styled.div<{ height: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  border-bottom: 1px solid #e0e0e0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
    flex: 1;
    background-color: #f7f7f7;
  }
`;

const Threads = styled.div`
  overflow-y: auto;
`;
