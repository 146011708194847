import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Site } from '../site/model';

import siteWizardService from './siteWizardService';
import { CreateSiteInput } from './model';

export const siteWizardSlice = createSlice({
  name: 'siteWizard',
  initialState: {},
  reducers: {},
});

export default siteWizardSlice.reducer;

export const createSoilSite = createAsyncThunk<
  Pick<Site, 'id' | 'name' | 'description'>,
  CreateSiteInput
>('siteWizard/createSoilSite', async (siteInput, { rejectWithValue }) => {
  const { data, errors } = await siteWizardService.createSite(siteInput);

  if (!data && errors) {
    return rejectWithValue(errors);
  }

  return data.createSite;
});
