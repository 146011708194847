import { useEffect } from 'react';
import { convertThreadId } from '../../../utils/url';

import { useRouteMatch } from 'react-router-dom';
import { useMessenger } from '../useMessenger';

/** Select thread from threadIdentifier in URL and fetch messages */
export function useSelectThreadFromUrl() {
  const match = useRouteMatch<{ threadIdentifier }>();

  const {
    currentThread,
    selectThread,
    fetchMessages,
    markRead,
  } = useMessenger();

  useEffect(() => {
    const threadIdentifier = match.params.threadIdentifier;
    const threadId = convertThreadId(threadIdentifier);
    if (!currentThread && threadIdentifier !== 'new') {
      selectThread(threadId);
    } else if (currentThread) {
      const shouldFetch = !currentThread.isNew && !currentThread.haveFetched;
      if (shouldFetch) fetchMessages(currentThread.id);
      if (currentThread?.unreadMessageCount > 0) {
        markRead(currentThread.id);
      }
    }
  }, [
    currentThread,
    fetchMessages,
    markRead,
    match.params.threadIdentifier,
    selectThread,
  ]);
}
