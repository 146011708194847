import { useEffect } from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isMobileApp, mobileAppReload } from '../../mobile/mobile';
import { useWindowFocus } from '../../hooks/useWindowFocus';
import {
  checkForUpdate,
  fetchInitialVersion,
  selectCurrentVersion,
  selectHasNewVersion,
  selectLastFetched,
} from './versionSlice';

const interval = 60_000;

export const NewVersionPopup = () => {
  const dispatch = useAppDispatch();
  const hasNewVersion = useAppSelector(selectHasNewVersion);
  const currentVersion = useAppSelector(selectCurrentVersion);
  const lastFetched = useAppSelector(selectLastFetched);

  const windowFocused = useWindowFocus();

  const isUnknown = currentVersion === 'unknown';

  useEffect(() => {
    dispatch(fetchInitialVersion());
  }, [dispatch]);

  useEffect(() => {
    const interv = setInterval(() => {
      /**
       * we're polling only if:
       * - enough time has passed
       * - we didn't already find new version
       * - the window/tab is in focus OR this is the mobile-app (=dedicated focus)
       *  */
      const enoughTimeHasPassed =
        !lastFetched || Date.now() >= lastFetched + interval;
      const shouldCheck =
        !isUnknown && !hasNewVersion && (windowFocused || isMobileApp());

      if (shouldCheck && enoughTimeHasPassed) {
        dispatch(checkForUpdate());
      }
    }, interval);
    return () => clearInterval(interv);
  }, [dispatch, hasNewVersion, isUnknown, lastFetched, windowFocused]);

  const handleRefresh = () => {
    if (isMobileApp()) {
      // send message to reload the iframe parent
      void mobileAppReload();
    } else {
      window.location.reload();
    }
  };

  if (!hasNewVersion) return null;

  return (
    <Container>
      New version available! <span onClick={handleRefresh}>Refresh</span>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius}px;

  background-color: ${({ theme }) => theme.colors.error};
  color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

  z-index: 900;
  cursor: pointer;

  span {
    text-decoration: underline;
  }
`;
