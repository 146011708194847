import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Flex } from '../../components/ui/Flex';
import { useFormikContext } from 'formik';
import { WizardForm } from './model';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { useHistory } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  steps: number;
  currentStep: number;
  canAdvance: boolean;
  onPrevStep: () => void;
  onNextStep: () => void;

  useSwipe?: boolean;

  style?: React.CSSProperties;
  className?: string;
};

export const Stepper = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    steps,
    currentStep,
    canAdvance,
    onPrevStep,
    onNextStep,
    useSwipe = false,
  } = props;
  const stepCount = React.Children.count(children);

  const renderChild = (children, key: number) => {
    return React.Children.toArray(children)[key];
  };

  const history = useHistory();
  const { values, validateForm, submitForm } = useFormikContext<WizardForm>();

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps - 1;

  const handleSwipe = (e: SwipeEventData) => {
    if (!useSwipe) return;

    if (e.dir === 'Left') {
      if (!isLastStep && canAdvance) {
        onNextStep();
      }
    } else if (e.dir === 'Right') {
      if (isFirstStep) {
        history.goBack();
      }

      if (!isFirstStep) {
        onPrevStep();
      }
    }
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
    delta: 30,
  });

  useEffect(() => {
    validateForm(values);
  }, [currentStep, validateForm, values, isLastStep]);

  return (
    <AnimatePresence>
      <>
        <StepperContainer ref={ref}>
          <ProgressBarOuter>
            <ProgressBarInner
              progress={(currentStep + 1) * (100 / stepCount)}
            />
          </ProgressBarOuter>
          <StepContent>
            <motion.div
              key={currentStep}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
              transition={{
                x: { type: 'spring', stiffness: 300, damping: 30 },
                opacity: { duration: 1 },
              }}
              {...handlers}
            >
              {renderChild(children, currentStep)}
            </motion.div>
          </StepContent>
        </StepperContainer>
        <ActionbarContainer>
          <hr />
          <Flex justify="space-between">
            <Button type="button" onClick={onPrevStep} disabled={isFirstStep}>
              Previous
            </Button>
            {isLastStep ? (
              <Button type="button" disabled={!canAdvance} onClick={submitForm}>
                Create Soil Site
              </Button>
            ) : (
              <Button type="button" disabled={!canAdvance} onClick={onNextStep}>
                Next
              </Button>
            )}
          </Flex>
        </ActionbarContainer>
      </>
    </AnimatePresence>
  );
});

const ProgressBarOuter = styled.div`
  background-color: rgba(114, 114, 114, 0.5);
`;

const ProgressBarInner = styled.div<{ progress: number }>`
  height: 15px;
  background-color: rgba(104, 183, 78, 0.8);
  width: ${(props) => props.progress}%;

  transition: width 0.25s ease-in;
`;

const actionbarHeight = '95px';

const StepperContainer = styled.div`
  height: calc(100% - ${actionbarHeight});
  overflow-y: auto;
`;

const StepContent = styled.div`
  padding: 0 1rem 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 2rem 2rem;
  }
`;

const ActionbarContainer = styled.div`
  background-color: #f4f4f4;

  position: absolute;
  height: ${actionbarHeight};
  width: 100%;

  padding: 0 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 2rem 1rem 2rem;
  }
`;
