import React, { useState } from 'react';
import styled from 'styled-components';

import { Flex, Modal, Revealer, Tabs } from '../../../components/ui';
import { generateInviteUrl } from '../../../utils/url';
import { Tab } from '../../../components/ui/Tabs';
import { QrCode } from '../../../components/QrCode';
import { useSoilSiteContext } from '../SoilSiteContext';
import { InviteByEmail } from '../invitation/InviteByEmail';
import { InviteByImage } from '../invitation/InviteByImage';
import { InviteByLink } from '../invitation/InviteByLink';
import { ShareOn } from '../invitation/ShareOn';

export type InviteModalTab = 'qr' | 'link' | 'poster' | 'email';

type InviteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  showInviteByEmail?: boolean;
  generalInvite?: boolean;
  tab: InviteModalTab;
};

export const InviteModal: React.FC<InviteModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    showInviteByEmail = true,
    generalInvite = false,
    tab = 'qr',
  } = props;
  const { soilSite, siteIdentifier } = useSoilSiteContext();
  const [selectedTab, setSelectedTab] = useState(tab);

  // we're not using this feature for now
  const showShareOn = false;
  const showInviteByLink = true;
  const inviteUrl = generateInviteUrl(soilSite.id, soilSite.name);

  return (
    <Modal
      header={`Add Soil Supporters to ${soilSite.name}`}
      isOpen={isOpen}
      onClose={onClose}
      noPadding
    >
      <div style={{ padding: '1rem 1rem 0' }}>
        <HelpText>
          Even adding just <strong>one Soil Supporter</strong> contributing
          their scraps and helping you to make soil, plays a vital part in
          repairing the Earth.
        </HelpText>
      </div>

      <StyledTabs
        selectedTab={selectedTab}
        onTabSelected={(tab: InviteModalTab) => setSelectedTab(tab)}
      >
        <Tab eventKey="qr" label="QR Code">
          <TabContent>
            <Header>
              <h3>Use your QR code</h3>
            </Header>
            <HelpText>
              When asked, people are usually happy to bring you their food
              scraps to help you make soil. Get them to scan this QR code to
              join your soil site.
            </HelpText>

            <Flex justify="center">
              <QrCode text={inviteUrl} />
            </Flex>
          </TabContent>
        </Tab>

        <Tab eventKey="link" label="Link">
          <TabContent>
            <Header>
              <h3>Share your link</h3>
            </Header>
            <HelpText>
              Directly with people you know via text or email. Also share on any
              local neighborhood WhatsApp, Facebook groups or email lists you're
              part of.
            </HelpText>

            <Revealer isOpen={showInviteByLink}>
              <InviteByLink inviteUrl={inviteUrl} />
              {showShareOn && (
                <ShareOn siteName={soilSite.name} inviteUrl={inviteUrl} />
              )}
            </Revealer>
          </TabContent>
        </Tab>

        <Tab eventKey="poster" label="Poster">
          <TabContent>
            <Header>
              <h3>Use your poster</h3>
            </Header>
            <HelpText>
              Hang at your soil site, or distribute as a flyer (e.g. at local
              coffee shops, grocery stores, farmers' markets), so people can
              scan the QR to join.
            </HelpText>
            <InviteByImage
              showCustomPoster={!generalInvite}
              site={soilSite}
              siteIdentifier={siteIdentifier}
            />
          </TabContent>
        </Tab>

        {/* <Tab eventKey="email" label="Email">
          <TabContent>
            // {showInviteByEmail && (
              <div>
                <Header>
                  <h3>Invite by email</h3>
                </Header>
                <InviteByEmail generalInvite={generalInvite} site={soilSite} />
              </div>
            )}
          </TabContent>
        </Tab> */}
      </StyledTabs>
    </Modal>
  );
};

const StyledTabs = styled(Tabs)`
  span.label {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const TabContent = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 0.5rem;

  h3 {
    margin: 0;
  }
`;

const HelpText = styled.p`
  margin-top: 0.25rem;
  font-size: 0.95rem;
  color: rgb(79, 72, 72);
`;
