import styled from 'styled-components';
import { SmartLink } from '../../../components/ui/SmartLink';
import { getHashFromIdentifier } from '../../../utils/url';
import { SitePoster } from '../components/SitePoster';
import { Site } from '../model';

type InviteByImageProps = {
  showCustomPoster: boolean;
  site: Site;
  siteIdentifier: string;
};

export const InviteByImage: React.FC<InviteByImageProps> = (props) => {
  const { showCustomPoster, site, siteIdentifier } = props;
  return (
    <ImageGallery>
      {!showCustomPoster ? (
        <ImageItem>
          <SmartLink href="https://drive.google.com/file/d/1kAV2LMGEn2jmjhyQPzHWekFiMO_a1uqU/view?usp=sharing">
            <img
              src="https://raw.githubusercontent.com/MakeSoil/public-pages/master/images/ms-tear-off-v1.png"
              alt="Tear-Off Flyer"
            />
          </SmartLink>
        </ImageItem>
      ) : (
        <SitePoster
          siteIdHash={getHashFromIdentifier(siteIdentifier)}
          siteName={site.name}
        />
      )}
    </ImageGallery>
  );
};

const ImageGallery = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: auto;
    border: 1px solid #cecece;
    :hover {
      border-color: black;
    }
  }
`;

const ImageItem = styled.div`
  cursor: pointer;
`;
