import { apolloClient } from '../../app/apolloClient';
import accessrequestService, {
  RequestAccessInput,
} from '../site/accessrequestService';
import { JOIN_SITE } from '../site/graphql';

class SiteOnboardService {
  joinSoilSite = (siteId: number) => {
    return apolloClient.mutate({
      mutation: JOIN_SITE,
      variables: { siteId },
    });
  };

  requestAccess = (request: RequestAccessInput) => {
    return accessrequestService.requestAccess(request);
  };
}

export default new SiteOnboardService();
