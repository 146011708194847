import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

type InputProps = {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  name?: string;
  placeholder?: string;
  rows?: number;
  value?: any;
  className?: string;
  style?: React.CSSProperties;
  prefix?: string;
  pattern?: string;
  inputMode?: any;
  onChange?: (e: any) => void;
};

export const Input: React.FC<InputProps> = (props) => {
  const { prefix, type, style } = props;

  const [inputType, setInputType] = useState(type);

  if (prefix) {
    return (
      <TextInputWrapper>
        {prefix && <InputPrefix>{prefix}</InputPrefix>}
        <StyledInput {...props} />
      </TextInputWrapper>
    );
  }

  // cloak/uncloak button
  if (
    inputType === 'password' ||
    (inputType === 'text' && type === 'password')
  ) {
    return (
      <TextInputWrapper style={style}>
        <StyledInput
          {...props}
          type={inputType}
          inputType={inputType}
          autoComplete="current-password"
        />
        <Cloaker>
          <FontAwesomeIcon
            icon={inputType === 'password' ? 'eye' : 'eye-slash'}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setInputType((prev) =>
                prev === 'password' ? 'text' : 'password'
              );
            }}
          />
        </Cloaker>
      </TextInputWrapper>
    );
  }

  return <StyledInput {...props} />;
};

const StyledInput = styled.input<{ inputType?: string }>`
  padding: 0.5rem;
  height: 2.25rem;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: ${({ theme }) => theme.borderRadius}px;

  ${({ prefix }) =>
    prefix &&
    `
			padding-left: 0 !important;
			margin-top: 0 !important;
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		`}

  ${({ inputType }) =>
    inputType &&
    `
			margin-top: 0 !important;
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		`}

  font-size: 1rem;
  color: #495057;
  outline: none;

  ::placeholder {
    font-style: normal;
    font-size: 1rem;
  }

  :focus {
    border: 1px solid #a3abb3;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }

  transition: border-color 0.25s ease-in-out;
`;

const TextInputWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  line-height: 1rem;
`;

const InputPrefix = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);

  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: thin solid rgb(138 138 138);
  padding-left: 8px;

  + ${StyledInput} {
    padding-left: 2px;
  }
`;

const Cloaker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5rem;
  color: rgba(255, 255, 255, 0.5);

  background: rgba(255, 255, 255, 0.1);
  border-bottom: thin solid rgb(138 138 138);

  :hover {
    color: rgba(255, 255, 255, 0.75);
  }

  transition: color 0.25s ease;
`;
