import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  deleteNotification,
  fetchMoreNotifications,
  loadNotifications,
  selectNotifications,
  selectUnreadCount,
  selectVisible,
  toggleReadState,
  toggleVisible,
} from './notificationCentreSlice';

export function useNotifications() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const unreadCount = useAppSelector(selectUnreadCount);
  const isDropDownOpen = useAppSelector(selectVisible);

  useEffect(() => {
    dispatch(loadNotifications());
  }, [dispatch]);

  function toggleDropDownVisibility() {
    dispatch(toggleVisible(!isDropDownOpen));
  }

  function fetchMore(cursor: Date) {
    dispatch(fetchMoreNotifications(cursor));
  }

  function toggleRead({
    notificationId,
    read,
    markReadAssociated,
  }: {
    notificationId: number;
    read: boolean;
    markReadAssociated: boolean;
  }) {
    dispatch(toggleReadState({ notificationId, read, markReadAssociated }));
  }

  function removeNotification(id: number) {
    dispatch(deleteNotification(id));
  }

  return {
    notifications,
    unreadCount,
    isDropDownOpen,
    toggleDropDownVisibility,
    fetchMoreNotifications: fetchMore,
    toggleReadState: toggleRead,
    deleteNotification: removeNotification,
  };
}
