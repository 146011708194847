import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the CSS

// prettier-ignore
import {
  faChevronLeft, faChevronRight, faChevronDown, faChevronUp,
  faComments,
  faUsers,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faLeaf,
  faBriefcase,
  faGraduationCap,
  faArchive,
  faEnvelope,
  faBell,
  faThumbtack,
  faSmile,
  faAt,
  faCamera,
  faPaperPlane,
  faPaperclip,
  faEllipsisH,
  faCheck,
  faStop,
  faBan,
  faInfoCircle,
  faLock,
  faUnlock,
  faHome,
  faClock,
  faColumns,
  faServer,
  faFileAlt,
  faKey,
  faBars,
  faTimes,
  faUserShield,
  faDonate,
  faRoad,
  faEnvelopeOpenText,
  faSeedling,
  faCogs,
  faChartLine,
  faHandshake,
  faBalanceScale,
  faDumpster,
  faSmog,
  faLevelUpAlt,
  faLevelDownAlt,
  faUsersCog,
  faCheckCircle,
  faEdit,
  faUserEdit,
  faSignOutAlt,
  faCog,
  faQuestionCircle,
  faLocationArrow,
  faSearch,
  faPlusCircle,
  faTrash,
  faEye,
  faEyeSlash,
  faSpinner,
  faPlus,
  faShareAlt,
  faHandsHelping,
  faUserSlash,
  faCrosshairs,
  faUser,
  faUserNinja,
  faUserPlus,
  faUserMinus,
  faGlobe,
  faComment,
  faThumbsUp,
  faThumbsDown,
  faBookDead,
  faArrowAltCircleDown,
  faCircle,
  faShieldAlt,
  faSignInAlt,
  faChartBar,
  faExclamation,
  faLongArrowAltRight,
  faPhone,
  faFileInvoice,
  faMobileAlt,
  faGlobeAmericas,
  faTools,
  faExclamationCircle,
  faDownload,
  faFileDownload,
  faSyncAlt,
  faUserLock,
  faTrashAlt,
  faSort,
  faUserTimes,
  faUserAltSlash,
  faLink,
  faFilePdf,
  faSearchPlus,
  faSearchMinus,
  faTimesCircle,
  faShare,
  faImages,
  faWalking,
  faCar,
  faSubway,
  faLongArrowAltLeft,
  faLongArrowAltUp,
  faExclamationTriangle,
  faDirections,
  faBiking,
  faArrowLeft,
  faArrowRight,
  faSortUp,
  faSortDown,
  faInfo,
  faQuestion,
  faHands,
  faAppleAlt,
  faHandHolding,
  faFileSignature,
  faFileImage,
  faQrcode,
  faSync,
  faCalendar,
  faTrophy,
  faHeart,
  faList,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebookSquare,
  faInstagramSquare,
  faSquareXTwitter,
  faTwitterSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

export const initializeFontAwesome = () => {
  // prettier-ignore
  library.add(
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faComment,
  faComments,
  faUsers,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faLocationArrow,
  faLeaf,
  faGraduationCap,
  faBriefcase,
  faArchive,
  faEnvelope,
  faBell,
  faThumbtack,
  faSmile,
  faAt,
  faCamera,
  faPaperPlane,
  faPaperclip,
  faEllipsisH,
  faCheck,
  faStop,
  faBan,
  faInfoCircle,
  faLock,
  faUnlock,
  faHome,
  faClock,
  faColumns,
  faServer,
  faFileAlt,
  faKey,
  faBars,
  faTimes,
  faUserShield,
  faDonate,
  faRoad,
  faSeedling,
  faCog,
  faCogs,
  faDumpster,
  faSmog,
  faHandshake,
  faLevelUpAlt,
  faLevelDownAlt,
  faChartLine,
  faBalanceScale,
  faUsersCog,
  faUserEdit,
  faEdit,
  faSearch,
  faPlusCircle,
  faSignOutAlt,
  faImages,
  faTrash,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faEnvelopeOpenText,
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faSpinner,
  faPlus,
  faPlusCircle,
  faShareAlt,
  faShare,
  faHandsHelping,
  faUserSlash,
  faUser,
  faCrosshairs,
  faUserNinja,
  faUserPlus,
  faUserMinus,
  faGlobe,
  faThumbsUp,
  faThumbsDown,
  faBookDead,
  faArrowAltCircleDown,
  faCircle, faShieldAlt, faSignInAlt, faChartBar,
  faExclamation,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faLongArrowAltUp,
  faPhone,
  faFileInvoice,
  faFileDownload,
  faFilePdf,
  faMobileAlt,
  faGlobeAmericas,
  faTools,
  faExclamationCircle,
  faDownload,
  faSyncAlt,
  faUserLock,
  faTrashAlt,
  faSort,
  faUserTimes,
  faUserAltSlash,
  faLink,
  faSearchPlus,
  faSearchMinus,
  faTimesCircle,
  faImages,
  faEnvelopeOpenText,
  faWalking,
  faBiking,
  faCar,
  faSubway,
  faExclamationTriangle,
  faDirections,
  faArrowLeft,
  faArrowRight,
  faSort,
  faSortUp,
  faSortDown,
  faInfo,
  faQuestion,
  faQuestionCircle,
  faHands,
  faHandHolding,
  faAppleAlt,
  faFileSignature,
  faFileImage,
  faQrcode,
  faSync,
  faCalendar,
  faTrophy,
  faHeart,
  faList,
  faCaretDown,
  faSquareXTwitter
);
};
