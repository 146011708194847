import styled from 'styled-components';
import { StaticPage } from '../../components/StaticPage';

const THESOILMAKERBOX_URL =
  'https://raw.githubusercontent.com/MakeSoil/public-pages/master/soil-maker-box.md';

const SoilMakerBox = () => (
  <StyleContainer>
    <StaticPage markdownURL={THESOILMAKERBOX_URL} pageTitle="DIY" />
  </StyleContainer>
);

const StyleContainer = styled.div`
  height: 100%;
  overflow: hidden;

  .lid-container {
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: row;
    }
  }
`;

export default SoilMakerBox;
