import { Field, ErrorMessage, useFormikContext } from 'formik';
import styled from 'styled-components';
import { Flex, TextArea, FormInputError } from '../../components/ui';
import { AddressField } from '../../components/ui/forms/AddressField';
import { Tooltip } from '../../components/ui/Tooltip';
import { WizardForm } from './model';

export const Step3 = ({ stepCount, goToStep }) => {
  const { values } = useFormikContext<WizardForm>();

  return (
    <div>
      <h1 className="step-title">
        Create a soil site: Step 3/
        {stepCount}
      </h1>
      <hr />
      <FormSection>
        <h3>Where is your soil site located?</h3>
        <HelpText>
          {values.isUnlisted && (
            <span>
              You made your soil site unlisted in a{' '}
              <span
                onClick={() => goToStep(0, values)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                previous step
              </span>
              , so this won't show on the public map.
            </span>
          )}

          {!values.isUnlisted && values.obfuscate && (
            <span>
              As you selected in a{' '}
              <span
                onClick={() => goToStep(0, values)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                previous step
              </span>
              , we will give your soil site a slightly random location on the
              public map (for privacy).
            </span>
          )}

          {!values.isUnlisted && !values.obfuscate && values.requiresApproval && (
            <span>
              Only people you approve to join would see your address, as you
              selected in a{' '}
              <span
                onClick={() => goToStep(0, values)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                previous step
              </span>
              .
            </span>
          )}

          {!values.isUnlisted && !values.obfuscate && !values.requiresApproval && (
            <span>
              Anyone can join your soil site at any time and see its address and
              location, as you selected in a{' '}
              <span
                onClick={() => goToStep(0, values)}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                previous step
              </span>
              .
            </span>
          )}
        </HelpText>

        <AddressField
          name="address"
          required
          placeholder="Enter address or drop a pin"
        />
      </FormSection>

      <FormSection>
        <Flex justify="space-between" align="baseline">
          <h3>
            How will Soil Supporters find your soil site once they arrive?
          </h3>
          <Tooltip style={{ marginLeft: '1rem' }}>
            E.g. Once you arrive, look for the apple tree in the front yard. The
            bin is under the shade of that tree. Please close the gate behind
            you when leaving.
          </Tooltip>
        </Flex>
        <Field
          as={TextArea}
          name="access"
          placeholder="Once you arrive, look for..."
          resize="vertical"
        />
        <ErrorMessage name="access" component={FormInputError} />
      </FormSection>

      <FormSection>
        <Flex justify="space-between" align="baseline">
          <h3>
            What guidance can you give them on how best to deposit their scraps?
          </h3>
        </Flex>
        <Field as={TextArea} name="dropOff" resize="vertical" rows={5} />
        <ErrorMessage name="dropOff" component={FormInputError} />
      </FormSection>

      <FormSection>
        <h3>When can Soil Supporters visit the soil site?</h3>
        <Field as={TextArea} name="hours" resize="vertical" />
        <ErrorMessage name="hours" component={FormInputError} />
      </FormSection>
    </div>
  );
};

const HelpText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.4;
  color: #838383;
`;

const FormSection = styled.section`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;
