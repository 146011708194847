import { gql } from '@apollo/client';

export const SUBSCRIBE = gql`
  subscription subscribe($userId: Int!) {
    notification(userId: $userId) {
      id
      text
      imageUrl
      linkUrl
      read
      category
      createdAt
      event
      associatedObject {
        id
        objectType
      }
    }
  }
`;

export const LOAD_NOTIFICATIONS = gql`
  query LoadNotifications {
    loadNotifications {
      notifications {
        id
        text
        imageUrl
        linkUrl
        read
        category
        createdAt
        associatedObject {
          id
          objectType
        }
      }
      unreadNotificationsCount
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query Notifications($cursor: String) {
    notifications(cursor: $cursor) {
      id
      text
      imageUrl
      linkUrl
      read
      category
      createdAt
      associatedObject {
        id
        objectType
      }
    }
  }
`;

export const UPDATE_READ_STATE = gql`
  mutation updateReadState($notificationId: Int!, $read: Boolean!) {
    updateReadState(notificationId: $notificationId, read: $read)
  }
`;

export const MARK_READ_MULTIPLE = gql`
  mutation markNotificationsRead($notifications: [Int!]!) {
    markNotificationsRead(notifications: $notifications)
  }
`;

export const DELETE = gql`
  mutation DeleteNotification($notificationId: Int!) {
    deleteNotification(notificationId: $notificationId)
  }
`;
