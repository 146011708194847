import styled from 'styled-components';

import { Collapsible, Flex, Avatar, Button } from '../../../components/ui';
import { TimeAgo } from '../../../components/ui/TimeAgo';
import { UserDisplayName } from '../../../components/ui/UserDisplayName';

type AccessRequestsProps = {
  accessRequests: any[];
  onReview: (accessRequest: any) => void;
};

export const AccessRequests = ({
  accessRequests,
  onReview,
}: AccessRequestsProps) => {
  if (accessRequests.length === 0) return null;

  const showDate = true;

  return (
    <Collapsible label={`Waiting to Join (${accessRequests.length})`}>
      <Flex direction="column" style={{ gap: '0.25rem' }}>
        {accessRequests.map((accessRequest: any) => (
          <AccessRequestBox
            key={accessRequest.id}
            onClick={() => onReview(accessRequest)}
          >
            <Avatar user={accessRequest.user} size="sm" />
            <div
              style={{
                display: 'flex',
                flex: 'auto',
                justifyContent: 'space-between',
              }}
            >
              <Content>
                <h5>
                  <UserDisplayName
                    firstName={accessRequest.user.firstName}
                    lastName={accessRequest.user.lastName}
                  />
                </h5>

                {showDate && (
                  <small>
                    asked to join <TimeAgo date={accessRequest.createdAt} />
                  </small>
                )}
              </Content>
            </div>
            <Button
              variant="link"
              style={{ fontSize: '0.825rem', alignSelf: 'flex-end' }}
            >
              Respond
            </Button>
          </AccessRequestBox>
        ))}
      </Flex>
    </Collapsible>
  );
};

const AccessRequestBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  background-color: #fff;
  padding: 0.5rem;
  padding-right: 0.5rem;

  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.25rem;

  h5,
  h6,
  p {
    margin: 0;
  }

  small {
    font-size: 0.775rem;
  }
`;
