import React from 'react';
import styled from 'styled-components';
import { trackEvent } from '../../../analytics';

import { isMobileApp, mobileOpenUrl } from '../../../mobile/mobile';

type SitePosterProps = {
  siteIdHash: string;
  siteName: string;
  style?: React.CSSProperties;
};

export const SitePoster: React.FC<SitePosterProps> = (props) => {
  const { siteIdHash, siteName, style } = props;

  return (
    <PosterContainer style={style}>
      <span
        onClick={async () => {
          trackEvent('Soil Site Poster', 'download', siteName);
          getPoster(siteIdHash);
        }}
      >
        <img alt="Soil site's custom poster" src="/images/poster.png" />
        {props.children}
      </span>
    </PosterContainer>
  );
};

export function getPoster(siteIdHash: string) {
  if (isMobileApp()) {
    void mobileOpenUrl(`${window.location.origin}/api/poster/${siteIdHash}`);
  } else {
    window.open(`/api/poster/${siteIdHash}`, '_blank');
  }
}

const PosterContainer = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  img {
    border: 1px solid #cecece;
    width: 100%;
    height: auto;
  }

  :hover {
    cursor: pointer;
  }
`;
