import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Counter } from '../../components/Counter';
import { useAuthUser } from '../auth/useAuthUser';
import { Button, Flex, Loader, Tabs } from '../../components/ui';
import { generateUserIdentifier } from '../../utils/url';
import { ImpactCounters } from '../impact/ImpactCounters';
import { ImpactTooltip } from '../impact/ImpactTooltip';
import { gql, useQuery } from '@apollo/client';
import { Tab } from '../../components/ui/Tabs';

const GET_DROP_OFFS = gql`
  query DropOffs {
    dropOffs {
      dropOffsAtMySoilSites {
        weight
      }
      myDropOffs {
        weight
      }
    }
  }
`;

type DropOffQuery = {
  dropOffs: {
    dropOffsAtMySoilSites: { weight: number }[];
    myDropOffs: { weight: number }[];
  };
};

type ImpactProps = {
  iAmMaker: boolean;
  iAmSupporter: boolean;
};

export function Impact({ iAmMaker, iAmSupporter }: ImpactProps) {
  const authUser = useAuthUser();
  const history = useHistory();

  const { data, loading } = useQuery<DropOffQuery>(GET_DROP_OFFS, {
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;

  const myDropOffs = data.dropOffs.myDropOffs;
  const dropOffsAtMySoilSites = data.dropOffs.dropOffsAtMySoilSites;

  const showImpactTooltip =
    myDropOffs.length + dropOffsAtMySoilSites.length > 0;
  const showShareImpactButton = false; //!iAmUnmatched;

  const iAmUnmatched = !iAmMaker && !iAmSupporter;
  const showTotalDropOffs = iAmMaker && !iAmUnmatched;
  const useTabs = showTotalDropOffs;

  const defaultTab = showTotalDropOffs ? 'total-drop-offs' : 'my-drop-offs';

  return (
    <Container>
      <Header align="center" justify="space-between">
        <Flex align="center" gap="0.5rem">
          <Icon>
            <FontAwesomeIcon icon="trophy" />
          </Icon>
          <h3>MY IMPACT</h3>
        </Flex>

        <ImpactTooltip visible={showImpactTooltip} />

        {showShareImpactButton && (
          <Button
            padding="0.35rem 0.75rem"
            onClick={() =>
              history.push(`/impact/${generateUserIdentifier(authUser.id)}`)
            }
          >
            <FontAwesomeIcon icon="share" /> Share Impact
          </Button>
        )}
      </Header>

      {useTabs ? (
        <TabView
          defaultTab={defaultTab}
          dropOffsAtMySoilSites={dropOffsAtMySoilSites}
          myDropOffs={myDropOffs}
        />
      ) : (
        <MyDropOffs dropOffs={myDropOffs} />
      )}
    </Container>
  );
}

function TabView({ defaultTab, dropOffsAtMySoilSites, myDropOffs }) {
  return (
    <StyledTabs selectedTab={defaultTab} placement={'top'}>
      <Tab eventKey="total-drop-offs" label="Total drop-offs">
        <DropOffsAtMySoilSites dropOffs={dropOffsAtMySoilSites} />

        <div style={{ padding: '0 0.75rem 0.75rem' }}>
          <small>
            To see more drop-offs, add more Soil Supporters to your soil sites.
          </small>
        </div>
      </Tab>

      <Tab eventKey="my-drop-offs" label="My drop-offs">
        <MyDropOffs dropOffs={myDropOffs} />
      </Tab>
    </StyledTabs>
  );
}

function MyDropOffs({ dropOffs }) {
  return (
    <div style={{ padding: '0.75rem' }}>
      <CounterContainer>
        <Counter count={dropOffs.length} text="My drop-offs recorded" />
        <ImpactCounters dropOffs={dropOffs} />
      </CounterContainer>
    </div>
  );
}

function DropOffsAtMySoilSites({ dropOffs }) {
  return (
    <div style={{ padding: '0.75rem' }}>
      <CounterContainer>
        <Counter
          count={dropOffs.length}
          text="Total drop-offs recorded at soil sites hosted by me"
        />
        <ImpactCounters dropOffs={dropOffs} />
      </CounterContainer>
    </div>
  );
}

const Header = styled(Flex)`
  border-bottom: 1px solid #dedede;
  padding: 0.75rem;

  h3 {
    margin: 0;
    font-weight: 400;
    white-space: nowrap;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

  margin-bottom: 2rem;

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const CounterContainer = styled.div`
  border: 1px solid #dedede;
  border-radius: 0.25rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div`
  padding: 0.25rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;

const StyledTabs = styled(Tabs)`
  ul {
    border-bottom: 1px solid #dbdbdb;
  }

  li span.label {
    font-size: 0.775rem;
    font-weight: 500;
  }

  li.active {
    border-bottom-color: ${({ theme }) => theme.colors.primary} !important;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top: none;
    border-radius: unset;
    background-color: rgba(69, 177, 57, 0.03);
  }

  li: not(.active) {
    border-bottom-color: transparent !important;

    &:hover {
      background-color: #ebebeb;
      border-radius: unset;
    }
  }
`;
