import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Avatar } from '../../../components/ui';
import { UserDisplayName } from '../../../components/ui/UserDisplayName';
import { useAuthUser } from '../../auth/useAuthUser';
import { IThread } from '../model';
import { useMessenger } from '../useMessenger';

export const ThreadListItem = ({ thread }) => {
  const authUser = useAuthUser();
  const { selectThread, sidebarOpen } = useMessenger();

  const {
    id,
    lastMessage,
    participants,
    unreadMessageCount,
    selected,
    isNew,
  } = thread;

  const handleSelectThread = (id: number) => {
    if (id) selectThread(id);
  };

  const getMessageExcerpt = ({ isNew, lastMessage }: Partial<IThread>) => {
    let excerpt;
    if (!lastMessage && isNew) {
      excerpt = 'Start chatting...';
    } else {
      const text = lastMessage.text.substring(0, 20);
      excerpt = lastMessage.authorId === authUser.id ? `You: ${text}` : text;
    }
    return excerpt;
  };

  const otherParticipant = participants.find((p) => p.id !== authUser.id);

  return (
    <ThreadPreview
      key={id}
      selected={selected}
      onClick={() => handleSelectThread(id)}
      hasUnreadMessages={unreadMessageCount > 0}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={{
        enter: {
          opacity: 1,
          height: 'auto',
        },
        exit: {
          opacity: 0,
          height: 0,
        },
      }}
    >
      <div style={{ position: 'relative' }}>
        <Avatar size="sm" user={otherParticipant} profileLink={false} />
      </div>

      <ThreadDetail show={sidebarOpen}>
        <ThreadTitle>
          <UserDisplayName
            firstName={otherParticipant.firstName}
            lastName={otherParticipant.lastName}
          />
        </ThreadTitle>
        <LastMessageExcerpt>
          {getMessageExcerpt({ isNew, lastMessage })}...
        </LastMessageExcerpt>
      </ThreadDetail>
      {unreadMessageCount > 0 && (
        <CountIndicator title="unread messages">
          {unreadMessageCount}
        </CountIndicator>
      )}
    </ThreadPreview>
  );
};

const ThreadPreview = styled(motion.div)<{
  selected: boolean;
  hasUnreadMessages?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;

  padding: 1rem;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected &&
    `
		background-color: #e7f3e3;
		border-left: 4px solid ${theme.colors.primary};
	`}

  transition: background, border-left 0.23s ease;
`;

const ThreadDetail = styled.div<{ show: boolean }>`
  // hide in mobile
  display: none;
  margin-left: 1rem;
  flex: auto;

  ${({ show }) => show && `display: block;`}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

const ThreadTitle = styled.h4`
  margin: 0;
  padding: 0;
`;

const LastMessageExcerpt = styled.p`
  margin: 0;
  margin-top: 0.25rem;

  font-size: 0.875rem;
`;

const CountIndicator = styled.span`
  position: absolute;
  top: 10px;
  right: 12px;

  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.875rem;
  color: #fff;

  transition: all ${({ theme }) => theme.timing}s ease;
`;
