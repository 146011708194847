import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SOIL_SITES_NEARBY } from '../site/graphql';
import { generateSiteIdentifier } from '../../utils/url';
import { Button, Loader, SmartImage } from '../../components/ui';
import { Card } from '../mySoilSites/Card';
import { TimeAgo } from '../../components/ui/TimeAgo';
import { Badge } from '../../components/ui/Badge';
import { Meta } from '../../components/Meta';
import { formatDistance } from '../../utils/formatting';
import { useAuthUser } from '../auth/useAuthUser';
import { AuthPageLayout } from '../../pages';

const SoilSitesNearYou = ({ history }) => {
  const authUser = useAuthUser();

  const location = authUser.location && {
    lat: authUser.location.lat,
    lng: authUser.location.lng,
  };

  const { data, loading } = useQuery(SOIL_SITES_NEARBY, {
    variables: {
      location,
    },
    skip: !location,
  });

  if (loading) return <Loader />;

  if (!data) {
    return (
      <Redirect
        to={{
          pathname: '/onboarding/your-location',
          state: history.location.state,
        }}
      />
    );
  }

  const { sites, address } = data.soilSitesNearby;

  const mapUrl = `/map?search=${encodeURIComponent(address)}&drawerOpen=true`;
  if (sites.length === 0) {
    return <Redirect to={mapUrl} />;
  }

  const goToMap = () => history.push(mapUrl);

  return (
    <AuthPageLayout
      onClose={goToMap}
      contentContainerStyle={{ padding: '0 2rem' }}
      contentContainerWidth="fit-content"
    >
      <Meta>
        <title>Soil Sites Near You | MakeSoil</title>
      </Meta>
      <Container>
        <div style={{ textAlign: 'center', color: '#fff' }}>
          <h2 style={{ marginBottom: 0 }}>Soil sites near you</h2>
          <AddressContainer>
            <small>Your address:</small>

            <strong>{address}</strong>
            <Button
              variant="link"
              onClick={() =>
                history.push('/onboarding/your-location?update=true')
              }
              padding="0.25rem 0.5rem"
              style={{ marginTop: '0.5rem' }}
            >
              (update)
            </Button>
          </AddressContainer>
        </div>

        <SiteListContainer>
          {sites.map((site) => (
            <SiteCard key={site.id} site={site} />
          ))}
        </SiteListContainer>
      </Container>
    </AuthPageLayout>
  );
};

const SiteCard = ({ site }) => {
  const { id, name, picture, createdAt, distance, participants } = site;

  const mapSiteUrl = `/map/site/${generateSiteIdentifier(id, name)}`;

  return (
    <StyledSiteCard
      mode="dark"
      image={
        <Link to={mapSiteUrl}>
          <SiteImage src={picture} width={400} type="site" asBackground />
        </Link>
      }
      content={
        <div>
          <h3>{site.name}</h3>
          <p style={{ fontSize: '0.925rem' }}>
            Registered <TimeAgo date={createdAt} />
          </p>
          <p style={{ fontSize: '0.925rem' }}>
            <Badge variant="primary">{formatDistance(distance)}</Badge> from you
          </p>
        </div>
      }
      actions={
        <>
          <Link
            to={mapSiteUrl}
            style={{
              textDecoration: 'none',
              textTransform: 'uppercase',
              fontWeight: 600,
            }}
          >
            View on Map
          </Link>
        </>
      }
      footer={
        <>
          <FontAwesomeIcon icon="users" /> Participants: {participants}
        </>
      }
    />
  );
};

export default SoilSitesNearYou;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 85vw;
`;

const AddressContainer = styled.div`
  display: inline-block;
  border: 1px solid #e7e7e7;
  border-radius 4px;
  margin-top: 1rem;
  padding: 0.5rem 1rem;

  small {
    margin: 0;
  }

  strong {
    display: block;
    margin-top: 0.25rem;
  }
`;

const SiteListContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding: 0 0 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    > div {
      width: 100%;
    }
  }
`;

const StyledSiteCard = styled(Card)`
  :hover {
    transform: scale(1.05);
  }
`;

const SiteImage = styled(SmartImage)<{ deactivated?: boolean }>`
  border-radius: 4px 4px 0 0;
  position: relative;

  ${({ deactivated }) =>
    deactivated &&
    `
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);

			font-size: 1.75rem;
			font-style: italic;
			color: #fff;
			text-shadow: 0 0 5px #000;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba(0, 0, 0, 0.5);
				height: 100%;
				width: 100%;

				position: absolute;
        bottom: 0;
			}
		`}
`;
