import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Button } from '../../../components/ui';

export const NoParticipants = ({ onGetMoreSupporters }) => {
  return (
    <NoParticipantsContainer>
      <p>There are no Soil Supporters yet.</p>
      <Button onClick={onGetMoreSupporters}>
        <FontAwesomeIcon icon="user-plus" /> Add Soil Supporters
      </Button>
    </NoParticipantsContainer>
  );
};

const NoParticipantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  text-align: center;

  h2,
  h3,
  h4 {
    margin: 0;
  }
`;
