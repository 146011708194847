import { toast } from 'react-toastify';
import { Modal, Button } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';
import { SiteMember } from '../model';

import { useSiteManager } from '../hooks';
import { useSoilSiteContext } from '../SoilSiteContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  participant: SiteMember;
};

export const TransferOwnershipModal: React.FC<Props> = ({
  isOpen,
  onClose,
  participant,
}) => {
  const { soilSite } = useSoilSiteContext();
  const { transfer: transferOwnership } = useSiteManager(soilSite.id);

  const handleTransferOwnership = async () => {
    try {
      const { data } = await transferOwnership(participant);

      if (data && data.transferOwnership.errors.length > 0) {
        toast.error(`${data.transferOwnership.errors[0]}`);
      }

      if (data && data.transferOwnership.soilSite) {
        onClose();
        toast.success(
          `You have successfuly transferred the soil site ownership to ${participant.firstName}.`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(handleTransferOwnership);

  return (
    <Modal
      header="Transfer Ownership"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          TRANSFER OWNERSHIP
        </Button>
      }
    >
      <p>
        Are you sure you want to make {participant.firstName} the Primary Soil
        Maker of this soil site?
      </p>
      <p>
        You can still remain as a Soil Maker. However, the new Primary Soil
        Maker will become the ultimate owner and admin of this soil site.
      </p>
    </Modal>
  );
};
