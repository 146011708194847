import React, { useContext } from 'react';
import { Site, SiteMember } from './model';
import { useAuthUser } from '../auth/useAuthUser';
import { isSoilMaker, isSoilSupporter } from '../../utils/site';

interface ISoilSiteContext {
  soilSite: Site;
  siteIdentifier: string;

  currentParticipant: SiteMember;
  isSupporter: boolean;
  isMaker: boolean;
}

const SoilSiteContext = React.createContext<ISoilSiteContext>(null);

type SoilSiteContextProps = {
  children: React.ReactNode;
  soilSite: Site;
  siteIdentifier: string;
};

export const SoilSiteContextProvider = ({
  children,
  soilSite,
  siteIdentifier,
}: SoilSiteContextProps) => {
  const authUser = useAuthUser();

  const currentParticipant = soilSite.members.find(
    (p) => p.userId === authUser.id
  );

  const isSupporter = isSoilSupporter(soilSite.members, authUser.id);
  const isMaker = isSoilMaker(soilSite.members, authUser.id);

  return (
    <SoilSiteContext.Provider
      value={{
        soilSite,
        siteIdentifier,
        currentParticipant,
        isSupporter,
        isMaker,
      }}
    >
      {children}
    </SoilSiteContext.Provider>
  );
};

export const useSoilSiteContext = () => useContext(SoilSiteContext);
