import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  appLog,
  mobileNotifyRefreshCompleted,
  mobileSetBadge,
  PushRegistration,
} from './mobile';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logout, userLoggedIn } from '../features/auth/authSlice';
import {
  loadNotifications,
  selectUnreadCount,
} from '../features/notifications/notificationCentreSlice';
import { getAuthUser, loginWithRefreshToken } from '../features/auth/utils';
import { checkForUpdate } from '../features/version/versionSlice';
import {
  fetchThreads,
  fetchUnreadMessageCount,
  selectUnreadMessagesCount,
} from '../features/messenger/messengerSlice';
import PushRegistrationService from '../features/pushRegistration/pushRegistrationService';
import { useAuthUser } from '../features/auth/useAuthUser';
export type { GeolocationPosition } from '../utils/geo';

export let mobileRedirectTo;
export let mobileReload;
export let mobileResume;
export let mobileGetAuthUser;

export const userAgent = 'MakeSoil';

export async function registerDevice(registration: PushRegistration) {
  return (await PushRegistrationService.register(registration)) as {
    data: { createPushRegistration: number };
  };
}

export async function unRegisterDevice(registrationId: number) {
  return await PushRegistrationService.remove(registrationId);
}

// Helper component to make hook based functions accessible to mobile.ts
export const MobileHelper: React.FC = () => {
  const [refreshing, setRefreshing] = useState(false);
  const dispatch = useAppDispatch();
  const unreadNotificationsCount = useAppSelector(selectUnreadCount);
  const unreadMessagesCount = useAppSelector(selectUnreadMessagesCount);
  const authUser = useAuthUser();
  const history = useHistory();

  useEffect(() => {
    const badgeCount =
      Math.max(0, unreadMessagesCount) + unreadNotificationsCount;
    appLog(
      `updatedUnread: notif=${unreadNotificationsCount} msgs=${unreadMessagesCount} => ${badgeCount}`
    );
    void mobileSetBadge(badgeCount);
  }, [unreadMessagesCount, unreadNotificationsCount]);

  useEffect(() => {
    if (refreshing) {
      void mobileNotifyRefreshCompleted();
      setRefreshing(false);
      // console.log(`refresh completed`);
    }
  }, [refreshing]);

  // called when the app bocomes active (on redirect or resume)
  const refreshData = async () => {
    // console.log(`start refresh...`);
    await dispatch(checkForUpdate());
    if (authUser && authUser.isLoggedIn) {
      await dispatch(loadNotifications());
      await dispatch(fetchThreads());
      await dispatch(fetchUnreadMessageCount());
    } else {
      mobileSetBadge(0);
    }
    setRefreshing(true);
  };

  mobileRedirectTo = async (path: string) => {
    // console.log(`redirecting to ${path}`);
    await refreshData();
    if (path !== 'refresh') {
      history.push(path);
    }
  };

  mobileGetAuthUser = () => {
    return authUser;
  };

  mobileResume = async () => {
    // console.log('resuming...');
    refreshData();
  };

  mobileReload = async () => {
    //    console.log('reloading...');
    let user = getAuthUser();
    if (!user) {
      try {
        //        console.log('try refresh...');
        user = await loginWithRefreshToken();
      } catch (error) {
        //        console.log('loginWithRefreshToken error', error);
        dispatch(logout());
      }
    }
    if (user) {
      //      console.log('user loaded');
      dispatch(userLoggedIn(user));
    }
    mobileRedirectTo('/');
  };

  return null;
};
