import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Meta = (props) => {
  const { children, pageTitle } = props;
  return (
    <Helmet>
      <title>{pageTitle}</title>
      {children}
    </Helmet>
  );
};
