import {
  MutationUpdaterFunction,
  OperationVariables,
  DefaultContext,
  ApolloCache,
} from '@apollo/client';
import { apolloClient } from '../../app/apolloClient';
import {
  APPROVE_ACCESS_REQUEST,
  CANCEL_ACCESS_REQUEST,
  DECLINE_ACCESS_REQUEST,
  REQUEST_ACCESS,
} from './graphql';
import { IAccessRequest, SiteMember } from './model';

export type MutationUpdateFunc = MutationUpdaterFunction<
  any,
  OperationVariables,
  DefaultContext,
  ApolloCache<any>
>;

export type RequestAccessInput = {
  siteId: number;
  message: string;
};

export type RequestAccessResult = {
  requestSiteAccess: {
    accessRequest: IAccessRequest;
    message: string;
    errors?: string[];
  };
};

type ApproveAccessrequestResult = {
  approveRequest: {
    soilSite: {
      accessRequests: IAccessRequest[];
      members: SiteMember[];
    };
    message: string;
    errors?: string[];
  };
};

export type CancelRequestResult = {
  cancelRequest: { message: string; errors?: string[] };
};

class AccessRequestService {
  requestAccess = ({ siteId, message }: RequestAccessInput) => {
    return apolloClient.mutate<RequestAccessResult>({
      mutation: REQUEST_ACCESS,
      variables: { siteId, message },
    });
  };

  cancelAccessRequest = (
    requestId: number,
    updateFunc?: MutationUpdateFunc
  ) => {
    return apolloClient.mutate<CancelRequestResult>({
      mutation: CANCEL_ACCESS_REQUEST,
      variables: { requestId },
      update: updateFunc,
    });
  };

  approveAccessRequest = (
    requestId: number,
    updateFunc?: MutationUpdateFunc
  ) => {
    return apolloClient.mutate<ApproveAccessrequestResult>({
      mutation: APPROVE_ACCESS_REQUEST,
      variables: { requestId },
      update: updateFunc,
    });
  };

  declineAccessRequest = (
    requestId: number,
    reason?: string,
    updateFunc?: MutationUpdateFunc
  ) => {
    return apolloClient.mutate({
      mutation: DECLINE_ACCESS_REQUEST,
      variables: { requestId, reason },
      update: updateFunc,
    });
  };
}

export default new AccessRequestService();
