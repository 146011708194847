export const Donorbox = ({ donorboxUrl }) => {
  return (
    <>
      <script
        src="https://donorbox.org/widget.js"
        // @ts-ignore
        paypalexpress="true"
      ></script>
      <iframe
        title="donorbox"
        src={donorboxUrl}
        name="donorbox"
        allowpaymentrequest="allowpaymentrequest"
        // @ts-ignore
        seamless="seamless"
        frameBorder="0"
        scrolling="no"
        height="900px"
        width="100%"
        style={{
          maxWidth: '500px',
          minWidth: '250px',
          maxHeight: 'none!important',
        }}
      ></iframe>
    </>
  );
};
