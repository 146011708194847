import { FormikHelpers, FormikErrors } from 'formik';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { validationMessages, isPhoneNumber } from '../../utils/formValidation';
import { hasEnoughWords } from '../../utils/helpers';
import { generateSiteIdentifier } from '../../utils/url';
import { useAuthUser } from '../auth/useAuthUser';
import { additionalContactInfoRequired } from '../auth/utils';
import { WizardForm } from './model';
import { createSoilSite } from './siteWizardSlice';
import { storage } from '../../utils/storage';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { fetchMe } from '../auth/authSlice';

const defaultValues: WizardForm = {
  accepted: [],
  address: '',
  addressComponents: [],
  access: 'Once you arrive, look for...',
  dropOff:
    'Spread your scraps evenly. Then cover with an equal amount of the leaves that have been provided. This prevents flies, and is a good recipe for healthy soil.',
  hours: 'Anytime during daylight hours.',
  active: true,
  description: '',
  googlePlaceId: '',
  location: undefined,
  picture: '',
  prohibited: [],
  name: '',
  isUnlisted: false,
  requiresApproval: true,
  obfuscate: false,
};

const getInitialValues = (userId: number) => {
  const wizard = storage.local.get(`wizard-${userId}`);
  if (!wizard || wizard === '' || wizard === 'null') return defaultValues;
  return wizard;
};

export function useSiteWizard() {
  const history = useHistory();
  const pageRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  const [currentStep, setCurrentStep] = useState(0);

  const [, setFormData] = useLocalStorage(
    `wizard-${authUser.id}`,
    getInitialValues(authUser.id)
  );

  const steps = useRef([
    {
      id: 1,
      image: {
        url: '/images/felixx.jpg',
        caption: `
          Image from
          <a
            href="https://www.felixx.nl/projects/isle-of-dikes.html"
            target="_blank"
            rel="noreferrer"
          >
            Felixx
          </a>`,
      },
    },
    {
      id: 2,
      image: { url: '/images/host-3.jpg', caption: 'Photo by Keana Parker' },
    },
    { id: 3, image: { url: '/images/earth.jpg', caption: '' } },
    { id: 4, image: { url: '/images/host-4-new.jpg', caption: '' } },
    { id: 5, image: { url: '/images/joshwithsoil.jpg', caption: '' } },
  ]);

  useEffect(() => {
    (function removeStep4() {
      if (
        !additionalContactInfoRequired(authUser, 'create') &&
        authUser.firstName
      ) {
        steps.current = steps.current.filter((s) => s.id !== 4);
      }
    })();
  }, [authUser]);

  /**
   * Form submission handler
   * @param values Form values
   * @param actions FormikHelpers
   */
  async function handleSubmit(
    values: WizardForm,
    actions: FormikHelpers<WizardForm>
  ) {
    try {
      const newSite = await dispatch(
        createSoilSite({ ...values, saveAddress: !authUser.hasAddress })
      ).unwrap();
      if (newSite) {
        actions.setSubmitting(false);
        setFormData(null);

        dispatch(fetchMe(authUser.id));

        const { id, name } = newSite;
        toast.success(
          'Congratulations! You just created a soil site. Now add your first Soil Supporter.'
        );
        history.push(
          `my-soil-sites/${generateSiteIdentifier(id, name)}?invite=true`
        );
      }
    } catch (error) {
      console.log(error);
      toast.error('Could not create a soil site at this time.');
    }
  }

  function validateForm(values: WizardForm) {
    let errors: FormikErrors<WizardForm> = {};

    const showStep4 =
      additionalContactInfoRequired(authUser, 'create') || !authUser.firstName;

    switch (currentStep) {
      case 0:
        if (!values.name) {
          errors.name = validationMessages.soilSite.name;
        }
        break;
      case 2:
        if (!values.address || !values.googlePlaceId) {
          errors.address = validationMessages.soilSite.address;
        }
        if (!values.access || !hasEnoughWords(values.access, 6)) {
          errors.access = validationMessages.soilSite.access;
        }
        if (!values.dropOff || !hasEnoughWords(values.dropOff, 3)) {
          errors.dropOff = validationMessages.soilSite.dropOff;
        }
        if (!values.hours) {
          errors.hours = validationMessages.soilSite.hours;
        }
        break;
      case 3:
        if (showStep4) {
          if (!values.firstName) {
            errors.firstName = validationMessages.firstName;
          }
          if (
            additionalContactInfoRequired(authUser, 'create') &&
            !isPhoneNumber(values.phoneNum)
          ) {
            errors.phoneNum = validationMessages.phoneNum;
          }
        } else {
          if (!values.description || !hasEnoughWords(values.description, 3)) {
            errors.description = validationMessages.soilSite.description;
          }
        }
        break;
      case 4:
        if (!values.description || !hasEnoughWords(values.description, 3)) {
          errors.description = validationMessages.soilSite.description;
        }
        break;
      default:
        break;
    }
    return errors;
  }

  function handleMoveStep(values: WizardForm, dir: 'prev' | 'next') {
    if (dir === 'prev') {
      setCurrentStep((prev) => prev - 1);
    } else if (dir === 'next') {
      setCurrentStep((prev) => prev + 1);
    }
    setFormData(values);
    scrollToTop();
  }

  function goToStep(step: number, values: WizardForm) {
    setFormData(values);
    setCurrentStep(step);
  }

  function scrollToTop() {
    pageRef.current.scrollTop = 0;
  }

  return {
    pageRef,
    steps,
    currentStep,
    handleMoveStep,
    goToStep,
    handleSubmit,
    validateForm,
    getInitialValues,
  };
}
