import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { screenSizeChanged } from '../features/layout/layoutSlice';
import { useBreakpoint } from './useBreakPoint';

/**
 * Detects when screen size changes.
 * Used to toggle the navigation
 */
export function useDetectScreenSizeChange() {
  const dispatch = useAppDispatch();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    dispatch(screenSizeChanged(breakpoint));
  }, [dispatch, breakpoint]);
}
