const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function getFormattedDate(date, prefomattedDate = '', hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day} ${month} at ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day} ${month} ${year} at ${hours}:${minutes}`;
}

// --- Main function
export function timeAgo(dateParam: string | Date) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today.getTime() - DAY_IN_MS);
  const daysAgo = getDaysAgo(date);

  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isLessThanWeek = daysAgo < 7;
  const isLessThanMonth = daysAgo < 31;
  const isLessThanYear = daysAgo <= 365;
  const isMoreThanYear = daysAgo > 365;

  if (isToday) {
    return getFormattedDate(date, 'today'); // Today at 10:20
  }

  if (isYesterday) {
    return getFormattedDate(date, 'yesterday'); // Yesterday at 10:20
  }

  if (isLessThanWeek) {
    return `${Math.floor(daysAgo)} days ago`;
  }

  if (isLessThanMonth) {
    const weeks = daysAgo / 7;
    return `${Math.floor(weeks)} ${
      Math.floor(weeks) > 1 ? 'weeks' : 'week'
    } ago`;
  }

  if (isLessThanYear) {
    const months = daysAgo / 31;
    return `${Math.floor(months)} ${
      Math.floor(months) > 1 ? 'months' : 'month'
    } ago`;
  }

  if (isMoreThanYear) {
    const years = today.getFullYear() - date.getFullYear();
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
}

// get the number of days ago
export function getDaysAgo(date1: Date, date2: Date = new Date()) {
  return Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
}

export const formatDate = (
  date: string | Date,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }
) => {
  if (!date) return '';
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString(undefined, options);
  }
  return date.toLocaleDateString(undefined, options);
};

export const formatMessageDate = (
  date: string | Date,
  options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }
) => {
  const theDate = typeof date === 'string' ? new Date(date) : date;

  if (getDaysAgo(theDate) > 365) {
    Object.assign(options, { year: 'numeric' });
  }

  return theDate.toLocaleString(undefined, options);
};

export function getTimeAgo(input: Date | string) {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat('en');
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };
  type RelativeTimeFormatUnit =
    | 'year'
    | 'years'
    | 'quarter'
    | 'quarters'
    | 'month'
    | 'months'
    | 'week'
    | 'weeks'
    | 'day'
    | 'days'
    | 'hour'
    | 'hours'
    | 'minute'
    | 'minutes'
    | 'second'
    | 'seconds';

  const secondsElapsed = (date.getTime() - Date.now()) / 1000;

  for (let key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(Math.round(delta), key as RelativeTimeFormatUnit);
    }
  }
  // date the same as now
  return '0 seconds ago';
}
