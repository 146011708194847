import Chart from 'react-apexcharts';
import { Box } from '../../components/ui';

export function ParticipationTimeline({ timeline }) {
  if (!timeline) return null;

  return (
    <Box height="400px" padding="1rem" mt="1rem">
      <Chart
        options={{
          chart: {
            id: 'participation-timeline',
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: true,
            },
          },
          colors: ['#5AB474', '#008FFB', '#FEB019'],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: 'smooth',
          },
          title: {
            text: 'Participation Timeline',
            align: 'left',
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            categories: timeline.months,
            title: {
              text: 'Month',
            },
          },
          yaxis: {
            title: {
              text: 'Participants',
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -15,
          },
        }}
        series={timeline.series}
        type="line"
        height="100%"
      />
    </Box>
  );
}
