import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { getAuthUser, loginWithRefreshToken } from './utils';
import { logout, userLoggedIn } from './authSlice';

/**
 * Attempts to auto login with refresh token
 * if no JWT available or if expired.
 */
export const useAuthenticate = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async function authenticate() {
      // try to get the auth user from the JWT token
      let user = getAuthUser();
      // try to login user with a refresh token
      if (!user) {
        try {
          user = await loginWithRefreshToken();
        } catch (error) {
          console.log('loginWithRefreshToken error', error);
          dispatch(logout());
        }
      }

      if (user) dispatch(userLoggedIn(user));
    })();
  }, [dispatch]);
};
