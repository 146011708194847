import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { AuthPageLayout } from '..';
import { Meta } from '../../components/Meta';
import { Button } from '../../components/ui';
import { getQueryParameter } from '../../utils/url';

const PageContainer = styled.div`
  button {
    margin-top: 1.5rem;
  }
`;

const CREATE_NEW_PASSWORD = gql`
  mutation CreateNewPassword(
    $verificationCode: String!
    $newPassword: String!
  ) {
    createNewPassword(
      input: { verificationCode: $verificationCode, newPassword: $newPassword }
    ) {
      message
      errors
    }
  }
`;

export default function CreateNewPassword({ location, history }) {
  const [password, setPassword] = useState('');

  const [createNewPassword] = useMutation(CREATE_NEW_PASSWORD);

  const verificationCode = getQueryParameter(location.search, 'code');
  if (!verificationCode) {
    return <Redirect to="/" />;
  }

  const createPassword = async () => {
    if (!password) {
      return toast.error('Please provide email address.');
    }

    try {
      const { data } = await createNewPassword({
        variables: {
          verificationCode,
          newPassword: password,
        },
      });

      if (data) {
        const { createNewPassword } = data;

        if (createNewPassword.errors.length) {
          toast.error(createNewPassword.errors[0]);
        } else {
          toast.success(createNewPassword.message);
          history.push('/login');
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <AuthPageLayout contentContainerStyle={{ justifyContent: 'center' }}>
      <PageContainer>
        <Meta>
          <title>Create New Password | MakeSoil</title>
        </Meta>
        <h2>Create New Password</h2>
        <p>Please enter the new password.</p>
        <form>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
            autoFocus
          />

          <Button type="button" onClick={createPassword}>
            CREATE NEW PASSWORD
          </Button>
        </form>
      </PageContainer>
    </AuthPageLayout>
  );
}
