import { ILocation } from '../../app/model/ILocation';
import { Site } from '../site/model';

export enum MapItemType {
  SoilSite = 'site' as any,
  SoilSupporter = 'supporter' as any,
  UnmatchedUser = 'unmatched' as any,
  AccessPending = 'pending' as any,
  CommunityGarden = 'garden' as any,
}
export interface IMapItem {
  id: number;
  name: string;
  type: MapItemType;
  location: ILocation;
}

export interface Bounds {
  southWest: ILocation;
  northEast: ILocation;
}

export type MarkerIcon = {
  url: string;
  scaledSize: google.maps.Size;
};

export interface MapMarker extends IMapItem {
  icon: string | MarkerIcon;
}

export interface IDrawerListItem extends IMapItem {
  createdAt: Date;
  picture?: string;
}

export type IDrawerItem = IMapItem;

export type IDrawerSiteItem = IDrawerItem &
  Omit<Site, 'address' | 'addressComponents' | 'googlePlaceId'>;

export type SiteDetails = Site & { type: MapItemType };

export interface ISearchResults {
  places: google.maps.places.AutocompletePrediction[];
  makesoil: IMapItem[];
}
