import { ILocation } from '../../app/model/ILocation';

export const googleService = () => {
  const autocomplete = new google.maps.places.AutocompleteService();
  const geocoder = new google.maps.Geocoder();

  /**
   * Autocomplete requests (per Request, no session) bill for (2.83 USD per 1000)
   * Autocomplete requests (per session, no Details Request) (17.00 USD per 1000)
   * Autocomplete requests (per session, with Details Request) (0.00 USD per 1000) but Places Details request (17.00 USD per 1000)
   * Places Details requests (17.00 USD per 1000)
   * Geocoding Requests bill for (5.00 USD per 1000)
   *
   * So possibly better to combine Autocomplete request with geocode request for location only
   *
   */

  return {
    places: {
      searchPlaces: (
        searchTerm: string
      ): Promise<google.maps.places.AutocompletePrediction[]> => {
        return new Promise((resolve, reject) => {
          autocomplete.getPlacePredictions(
            { input: searchTerm },
            (results, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK)
                return resolve(results);
              if (
                status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
              )
                return resolve([]);
              return reject(status);
            }
          );
        });
      },
    },

    geocoder: {
      getLocationDetails: (
        location: ILocation
      ): Promise<google.maps.GeocoderResult[]> => {
        return new Promise((resolve, reject) => {
          geocoder.geocode({ location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK)
              return resolve(results);
            if (status === google.maps.GeocoderStatus.ZERO_RESULTS)
              return resolve([]);
          });
        });
      },

      getLocationFromSearch: (
        search: string
      ): Promise<google.maps.GeocoderResult[]> => {
        return new Promise((resolve, reject) => {
          geocoder.geocode({ address: search }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK)
              return resolve(results);
            if (status === google.maps.GeocoderStatus.ZERO_RESULTS)
              return resolve(null);
          });
        });
      },

      getPlaceLocation: (
        placeId: string
      ): Promise<google.maps.GeocoderResult[]> => {
        return new Promise((resolve, reject) => {
          geocoder.geocode({ placeId }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK)
              return resolve(results);
            if (status === google.maps.GeocoderStatus.ZERO_RESULTS)
              return resolve([]);
          });
        });
      },
    },
  };
};
