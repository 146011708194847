import { gql } from '@apollo/client';

export const GET_SITE_POSTS = gql`
  query getSitePosts($siteId: Int!, $cursor: String) {
    sitePosts(siteId: $siteId, cursor: $cursor) {
      id
      text
      pinned
      attachments
      siteId
      createdAt
      author {
        id
        firstName
        lastName
        picture
        role
      }
      type
      dropOff {
        weight
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query getComments($postId: Int!) {
    comments(postId: $postId) {
      id
      text
      attachments
      postId
      author {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export const ADD_POST = gql`
  mutation addSitePost($post: NewPostInput!) {
    addSitePost(post: $post) {
      id
      text
      pinned
      attachments
      createdAt
      siteId
      type
      author {
        id
        firstName
        lastName
        picture
      }
    }
  }
`;

export const UPDATE_SITE_POST = gql`
  mutation updateSitePost($post: UpdatePostInput!) {
    updateSitePost(post: $post) {
      id
      text
      pinned
      attachments
    }
  }
`;

export const DELETE_SITE_POST = gql`
  mutation deleteSitePost($postId: Int!) {
    deleteSitePost(postId: $postId) {
      success
      deleteFileUrls
    }
  }
`;

export const DELETE_POST_ATTACHMENT = gql`
  mutation removePostAttachment($postId: Int!, $handle: String!) {
    removePostAttachment(postId: $postId, handle: $handle) {
      urls
    }
  }
`;

export const POST_SUBSCRIPTION = gql`
  subscription sitePost($siteId: Int!, $userId: Int!) {
    sitePost(siteId: $siteId, userId: $userId) {
      action
      sitePost {
        id
        text
        pinned
        attachments
        createdAt
        siteId
        author {
          id
          firstName
          lastName
          picture
        }
        type
        dropOff {
          weight
        }
      }
    }
  }
`;
