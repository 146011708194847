import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  RequestAccessInput,
  RequestAccessResult,
} from '../site/accessrequestService';
import siteOnboardService from './siteOnboardService';

export const siteOnboardingSlice = createSlice({
  name: 'siteOnboardingSlice',
  initialState: {},
  reducers: {},
});

export const requestSiteAccess = createAsyncThunk<
  RequestAccessResult,
  RequestAccessInput
>('siteOnboarding/requestSiteAccess', async (input, { rejectWithValue }) => {
  try {
    const result = await siteOnboardService.requestAccess(input);
    return result.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const joinSoilSite = createAsyncThunk<any, number>(
  'siteOnboarding/joinSoilSite',
  async (siteId, { rejectWithValue }) => {
    try {
      const result = await siteOnboardService.joinSoilSite(siteId);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
