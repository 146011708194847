import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Flex } from '../../components/ui';
import { ACCEPT_INVITATION, JOIN_SITE } from '../site/graphql';
import { generateSiteIdentifier } from '../../utils/url';
import { SiteOnboardLocationState } from './model';
import { AuthPageLayout } from '../../pages';

const StaySafe: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [joinSoilSite] = useMutation(JOIN_SITE);
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION);

  const state = location.state as SiteOnboardLocationState;

  const mapUrl = () => {
    if (
      state.action === 'join' ||
      state.action === 'request' ||
      state.action === 'invitation'
    ) {
      return `/map/site/${generateSiteIdentifier(
        state.site.siteId,
        state.site.siteName
      )}`;
    }

    return `/map`;
  };

  const handlePermofrmSiteOnboarding = async () => {
    if (state.action === 'request') {
      history.push(`/onboarding/request-access`, state);
    }

    if (state.action === 'join') {
      try {
        const { siteId, siteName } = state.site;
        await joinSoilSite({ variables: { siteId } });

        return history.push(
          `/my-soil-sites/${generateSiteIdentifier(siteId, siteName)}`
        );
      } catch (error) {
        toast.error(error.message);
      }
    }

    if (state.action === 'invitation' && state.invitation) {
      const { siteIdentifier, invitationCode } = state.invitation;
      const { siteId } = state.site;
      try {
        const { data } = await acceptInvitation({
          variables: { siteId, invitationCode },
        });
        if (!data) return;

        const {
          acceptInvitation: { errors, message },
        } = data;
        if (errors.length) {
          return toast.error(errors[0]);
        }
        // If invited by Soil Supporter, user might have been added to waitlist
        if (message.includes('waitlist')) {
          return history.push(
            `/invitation/${siteIdentifier}?invite=${invitationCode}&result=waitlist`
          );
        }
        return history.push(`/my-soil-sites/${siteIdentifier}`);
      } catch (error) {
        return toast.error(error.message);
      }
    }
  };

  return (
    <AuthPageLayout onClose={() => history.push(mapUrl())}>
      <h2>Stay safe</h2>
      <div style={{ textAlign: 'center' }}>
        <p>
          MakeSoil is a decentralized movement where{' '}
          <strong>anyone can list a soil site</strong>.
        </p>
        <p>Soil sites are not managed or reviewed by MakeSoil.</p>
        <p>
          When visiting a soil site, please take the same common sense
          precautions you would when meeting anyone new or visiting any
          unfamiliar place.
        </p>
      </div>

      <Flex direction="column" style={{ marginTop: '2rem' }}>
        <Button
          onClick={handlePermofrmSiteOnboarding}
          style={{ fontWeight: 600 }}
        >
          I UNDERSTAND
        </Button>
      </Flex>
    </AuthPageLayout>
  );
};

export default withRouter(StaySafe);
