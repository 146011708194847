import React, { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form, ErrorMessage, FormikErrors } from 'formik';

import { FormInputError, Button, Input } from '../../components/ui';
import { EmailField } from '../../components/ui/forms/EmailField';
import { RecaptchaFormControl } from '../../components/ui/forms/RecaptchaFormControl';
import { Meta } from '../../components/Meta';
import { AuthPageLayout } from '..';
import { trimFormValues } from '../../utils/helpers';
import { SignupFormData } from '../../features/auth/authService';
import { useAppDispatch } from '../../app/hooks';
import { signup } from '../../features/auth/authSlice';
import { isEmail, validationMessages } from '../../utils/formValidation';
import { isMobileApp } from '../../mobile/mobile';

import { resetLocationPicker } from '../../features/locationPicker/locationPickerSlice';
import { TermsOfUseModal } from './TermsOfUseModal';
import { logFailedSignup } from '../../features/auth/logFailedSignup';
import { getQueryParameter } from '../../utils/url';

const Signup: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useAppDispatch();
  const [tocModalOpen, setTocModalOpen] = useState(false);

  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  // user closes the tab or refreshes the page
  useEffect(() => {
    const handleLogFormData = (event?: any) => {
      // if we have email log it
      const email = formRef.current?.values.email;
      if (isEmail(email)) {
        logFailedSignup(email);
      }

      return '';
    };

    window.addEventListener('beforeunload', handleLogFormData);

    return () => window.removeEventListener('beforeunload', handleLogFormData);
  }, []);

  // user clicked the close x
  const handleClose = () => {
    history.push('/');
  };

  const validateForm = (values: SignupFormData & { recaptcha: string }) => {
    const { recaptcha } = values;

    let errors: FormikErrors<SignupFormData & { recaptcha: string }> = {};

    if (!isMobileApp() && !recaptcha) {
      errors.recaptcha = validationMessages.recaptcha;
    }

    return errors;
  };

  const handleSubmit = async (
    values: SignupFormData,
    { setSubmitting }: any
  ) => {
    try {
      values = trimFormValues(values);
      const result = await dispatch(signup(values)).unwrap();
      setSubmitting(false);

      recaptchaRef.current?.reset();

      if (result.ok && result.passToAccountVerification) {
        // reset the locationPicker state
        dispatch(resetLocationPicker());

        history.push(`/verify`, history.location.state);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthPageLayout
      contentContainerStyle={{ justifyContent: 'center' }}
      onClose={handleClose}
    >
      <Container>
        <TermsOfUseModal
          isOpen={tocModalOpen}
          onClose={() => setTocModalOpen(false)}
        />
        <Meta>
          <title>Sign Up | MakeSoil</title>
        </Meta>

        <div>
          <h2 style={{ marginBottom: 0 }}>Create an account</h2>
          <p style={{ marginTop: '0.5rem' }}>to join or create a soil site.</p>
          <p>
            <small>
              Already have an account? <Link to="/login">Log in</Link>
            </small>
          </p>
        </div>
        <Formik
          initialValues={{
            email: getQueryParameter(history.location.search, 'email') || '',
            recaptcha: null,
          }}
          validate={validateForm}
          validateOnChange={false}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ isSubmitting }) => {
            return (
              <Form autoComplete="off">
                <EmailField
                  type="email"
                  name="email"
                  as={Input}
                  placeholder="Email"
                  autoFocus={true}
                />

                <ErrorMessage name="email" component={FormInputError} />

                <FormFooter>
                  {!isMobileApp() && (
                    <RecaptchaFormControl ref={recaptchaRef} name="recaptcha" />
                  )}

                  <Button type="submit" disabled={isSubmitting}>
                    CREATE ACCOUNT
                  </Button>

                  <HelpText style={{ margin: 0, marginTop: '1rem' }}>
                    By clicking Create Account you agree to our{' '}
                    <a
                      onClick={(e) => {
                        console.log('showing ToU');
                        setTocModalOpen(true);
                        e.preventDefault();
                        return false;
                      }}
                      href={'/'}
                      className="text-underline"
                      style={{ whiteSpace: 'nowrap', lineHeight: '1.75' }}
                    >
                      Terms of Use
                    </a>
                    .
                  </HelpText>
                  <small style={{ marginTop: '1rem' }}>
                    Can't sign up? <Link to="/contact-us">Contact us</Link>
                  </small>
                </FormFooter>
                {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </AuthPageLayout>
  );
};

export default withRouter(Signup);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      input::placeholder {
        font-size: 0.85rem;
      }
    }
  }

  small {
    margin-top: 1rem;
    font-size: 0.7rem;
  }
`;

const HelpText = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.875rem;
  color: #a9a9a9;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  margin-top: 1rem;

  .checkmark {
    background: rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255);
    border: thin solid rgb(138 138 138);
  }

  button {
    margin-top: 1rem;
    width: 100%;
  }
`;
