import { useState, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import { debounce } from '../../../utils/debounce';
import { isEmail, validationMessages } from '../../../utils/formValidation';

export const EmailField = (props: any) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<{
    email: string;
  }>();

  const debouncedChangeHandler = useCallback(
    debounce(() => {
      setFieldTouched('email', true);
    }, 800),
    [setFieldTouched]
  );

  const changeHandler = ({ target: { value } }) => {
    setFieldValue('email', value, true);
    debouncedChangeHandler();
  };

  const validate = async (email: string) => {
    if (!email || !isEmail(email)) {
      return validationMessages.email;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Field {...props} validate={validate} onChange={changeHandler} />
    </div>
  );
};
