import styled from 'styled-components';
import { ShareButton } from '../../../components/ui/ShareButton';

export const InviteByLink = (props: any) => {
  const { inviteUrl, className } = props;

  return (
    <LinkBox className={className}>
      <LinkField>
        <InviteLink>{inviteUrl}</InviteLink>
      </LinkField>
      <ShareButton url={inviteUrl} text="Get Link" icon={null} />
    </LinkBox>
  );
};

const LinkBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 42px;

  p {
    margin: 0 !important;
  }

  button {
    border-radius: 0 4px 4px 0;
  }
`;

const LinkField = styled.div`
  flex: 1;
  background-color: #fff;
  border: solid 1px #d5d5d5;
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  overflow: hidden;
  text-align: left;
`;

const InviteLink = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
