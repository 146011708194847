import React from 'react';
import styled from 'styled-components';

import { ChangePassword } from './ChangePassword';
import { Collapsible } from '../../components/ui';
import { EmailPreference } from './EmailPreference';
import { PageHeader } from '../../components/ui/PageHeader';
import { DeleteAccount } from './DeleteMyAccount';
import { isMobileApp } from '../../mobile/mobile';
import { useAuthUser } from '../../features/auth/useAuthUser';
import { CreatePassword } from './CreatePassword';

type Props = {};

const UserAccount: React.FC<Props> = () => {
  const authUser = useAuthUser();

  return (
    <UserAccountContainer>
      <PageHeader pageTitle="Account Settings" />

      <Content>
        <Collapsible label="Update Email Preferences" open={false}>
          <EmailPreference />
        </Collapsible>

        {authUser.hasPassword ? (
          <Collapsible label="Update Account Password" open={false}>
            <ChangePassword />
          </Collapsible>
        ) : (
          <Collapsible label="Create Password" open={false}>
            <CreatePassword />
          </Collapsible>
        )}

        {isMobileApp() && (
          <Collapsible label="Delete User Account" open={false}>
            <DeleteAccount />
          </Collapsible>
        )}
      </Content>
    </UserAccountContainer>
  );
};

export default UserAccount;

const UserAccountContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  > div:first-child {
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 2rem;
  }
`;
