import styled from 'styled-components';
import { ParticipationMap } from './ParticipationMap';
import { ParticipationTimeline } from './ParticipationTimeline';
import { Impact } from './Impact';
import { Collapsible, Grid, Loader } from '../../components/ui';
import { ParticipationBreakdown } from './ParticipationBreakdown';
import { SoilSiteBreakdown } from './SoilSiteBreakdown';
import { gql, useQuery } from '@apollo/client';

const GET_INSIGHTS = gql`
  query Insights($region: String!) {
    insights(region: $region) {
      # region {
      #   displayName
      #   placeId
      # }
      dropOffs {
        siteId
        userId
        weight
      }
      soilSites {
        id
        location {
          lat
          lng
        }
      }
      siteBreakdown {
        total
        active
        deactivated
        unlisted
      }
      participation {
        users
        makers
        supporters
        unmatched
      }
      participationTimeline {
        months
        dates
        series {
          name
          data
        }
      }
    }
  }
`;

export function Regional({ region }) {
  const { loading, data } = useQuery(GET_INSIGHTS, {
    variables: { region: region.keyword },
  });

  if (loading) return <Loader />;

  const {
    //region,
    dropOffs,
    siteBreakdown,
    soilSites,
    participation,
    participationTimeline,
  } = data.insights;

  return (
    <>
      <Section>
        <Container>
          <h3>Impact of The Region</h3>
          <Impact dropOffs={dropOffs} />
        </Container>
      </Section>

      <Section bgColor="#fff">
        <Container>
          <Collapsible label="Soil Site & Participation Data">
            <Grid col="2fr 2fr" gap="1rem" style={{ marginTop: '1rem' }}>
              <SoilSiteBreakdown soilSites={siteBreakdown} />
              <ParticipationBreakdown participation={participation} />
            </Grid>
          </Collapsible>

          <ParticipationTimeline timeline={participationTimeline} />
        </Container>
      </Section>

      <Section>
        <Container>
          <ParticipationMap region={region} soilSites={soilSites} />
        </Container>
      </Section>
    </>
  );
}

const Section = styled.section<{ bgColor?: string }>`
  padding: 1rem 0 2rem;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 1rem;
  }
`;
