import styled from 'styled-components';
import { Avatar } from '../../../components/ui';
import { UserDisplayName } from '../../../components/ui/UserDisplayName';
import { useAuthUser } from '../../auth/useAuthUser';
import { useMessenger } from '../useMessenger';

export const ThreadHeader = () => {
  const authUser = useAuthUser();
  const { currentThread } = useMessenger();

  const otherParticipant = currentThread.participants.find(
    (p) => p.id !== authUser.id
  );

  return (
    <Container>
      <ThreadDetail>
        <Avatar size="sm" user={otherParticipant} />
        <ThreadTitle style={{ marginLeft: '0.5rem' }}>
          <UserDisplayName
            firstName={otherParticipant.firstName}
            lastName={otherParticipant.lastName}
          />
        </ThreadTitle>
      </ThreadDetail>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 0;

  min-height: ${({ theme }) => theme.headerHeight};

  background-color: #f7f7f7;
  border-bottom: 1px solid #dedede;
`;

const ThreadDetail = styled.div`
  display: flex;
  align-items: center;

  margin-left: 1rem;
`;

const ThreadTitle = styled.h4`
  margin: 0;
  padding: 0;
`;
