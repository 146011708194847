import { gql, useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import {
  Loader,
  Modal,
  Input,
  Flex,
  Checkbox,
  Button,
  Box,
} from '../../../components/ui';
import { Badge } from '../../../components/ui/Badge';
import { sortByOrder } from '../../../utils/helpers';

export const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates {
    emailTemplates {
      id
      name
      displayName
      subject
      active
      order
      category
    }
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($template: EmailTemplateInput!) {
    updateEmailTemplate(template: $template)
  }
`;

export const EmailManagement = () => {
  const { data, loading } = useQuery(GET_EMAIL_TEMPLATES);

  const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);

  const handleEditTemplate = (templateId: number) => {
    setSelectedTemplate(data.emailTemplates.find((t) => t.id === templateId));
    setShowModal(true);
  };

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (template, { setSubmitting }) => {
    const updatedTemplate = { ...template, id: selectedTemplate.id };

    // if order changed we need to update the one that changed
    // and the one that previously had the newly selected order
    // if (template.order !== selectedTemplate.order) {
    //   const oneToUpdate = data.emailTemplates.find(t => t.order === template.order);
    //   if (oneToUpdate) {
    //     oneToUpdate.order = selectedTemplate.order;
    //   }
    // }

    await updateEmailTemplate({
      variables: { template: updatedTemplate },
      update: (cache, result) => {
        const cachedTemplates: any = cache.readQuery({
          query: GET_EMAIL_TEMPLATES,
        });
        const cachedUpdatedTemplate = cachedTemplates.emailTemplates.find(
          (t) => t.id === updatedTemplate.id
        );

        cache.writeQuery({
          query: GET_EMAIL_TEMPLATES,
          data: {
            emailTemplates: [
              ...cachedTemplates.emailTemplates.map((t) => {
                if (t.id === updatedTemplate.id) {
                  return { ...cachedUpdatedTemplate, ...updatedTemplate };
                }
                return t;
              }),
            ],
          },
        });
      },
    });
    toast.success('Email Template updated sucessfuly!');
    setSubmitting(false);
    setShowModal(false);
  };

  loading && <Loader />;

  if (!data) {
    return null;
  }

  const templates = data.emailTemplates;

  return (
    <Container>
      <h3>Email Templates</h3>
      <h4>Mandatory:</h4>
      <ul>
        {templates
          .filter((t) => t.category === 'Mandatory')
          .sort(sortByOrder)
          .map((t) => (
            <li key={t.id}>
              <ListItem template={t} onEdit={() => handleEditTemplate(t.id)} />
            </li>
          ))}
      </ul>

      <h4>Messaging:</h4>
      <ul>
        {templates
          .filter((t) => t.category === 'Messaging')
          .sort(sortByOrder)
          .map((t) => (
            <li key={t.id}>
              <ListItem template={t} onEdit={() => handleEditTemplate(t.id)} />
            </li>
          ))}
      </ul>

      <h4>Soil Site Participation:</h4>
      <ul>
        {templates
          .filter((t) => t.category === 'Soil Site Participation')
          .sort(sortByOrder)
          .map((t) => (
            <li key={t.id}>
              <ListItem template={t} onEdit={() => handleEditTemplate(t.id)} />
            </li>
          ))}
      </ul>

      <Modal
        header={`Update Template: ${selectedTemplate?.name}`}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        hideFooter
      >
        <Formik
          initialValues={{
            name: selectedTemplate?.name,
            displayName: selectedTemplate?.displayName,
            subject: selectedTemplate?.subject,
            active: selectedTemplate?.active,
            order: selectedTemplate?.order,
          }}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form>
                <FormControl>
                  <FormLabel htmlFor="name">
                    Email Template Name (Admin only)
                  </FormLabel>
                  <Field as={Input} name="name" />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="name">
                    Display Name (visible in Email Preference)
                  </FormLabel>
                  <Field as={Input} name="displayName" />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="name">
                    What is the Email Subject?
                  </FormLabel>
                  <Field as={Input} name="subject" />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="name">Email Delivery Status:</FormLabel>{' '}
                  <br />
                  <Flex align="center" gap="0.5rem">
                    <Field type="checkbox" as={Checkbox} name="active" />{' '}
                    <span>Active</span>
                  </Flex>
                </FormControl>

                {/* <FormControl>
                  <FormLabel htmlFor="name">
                    What is the order of display?
                  </FormLabel>
                  <Field as={Input} type="number" name="order" />
                </FormControl> */}

                <Flex justify="flex-end" style={{ marginTop: '1rem' }}>
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting || !dirty}
                  >
                    Update Email Template
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </Container>
  );
};

export const ListItem = ({ template, onEdit }) => {
  const { name, displayName, subject, active } = template;

  return (
    <ListItemContainer padding="0.5rem" active={active}>
      <Flex justify="space-between" align="center">
        <div style={{ flex: 1 }}>
          <h4>Name: {name}</h4>
          <h5>Display Name: {displayName}</h5>
          <h5>Subject: {subject}</h5>
        </div>
        <div style={{ flex: 1 }}>
          <Badge variant={active ? 'primary' : 'error'}>
            {active ? 'active' : 'deactivated'}
          </Badge>
        </div>
        <div>
          <Button round={true} onClick={onEdit}>
            Edit
          </Button>
        </div>
      </Flex>
    </ListItemContainer>
  );
};

export const FormControl = ({ children }) => {
  return (
    <div
      style={{
        marginTop: '1rem',
      }}
    >
      {children}
    </div>
  );
};

const FormLabel = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  em,
  small {
    font-size: 0.825rem;
    color: #919191;
  }
`;

const ListItemContainer = styled(Box)<{ active: boolean }>`
  h4,
  h5 {
    margin: 0;
    line-height: 1.5;
  }

  ${({ theme, active }) =>
    active
      ? `border-left: 5px solid ${theme.colors.primary};`
      : `border-left: 5px solid ${theme.colors.error};`};
`;

const Container = styled.div`
  padding 0 1rem 2rem;

  ul {
    font-size: 0.875rem;

    li:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
`;
