import React from 'react';
import { PickerOverlay } from 'filestack-react';

import { cdn, credentials } from '../../utils/config';
import { toast } from 'react-toastify';

export interface FilePickerOptions {
  accept?: string | string[];
  maxFiles?: number;
  maxSize?: number;
  imageDim?: [number, number];
  transformations?: {
    crop: {
      aspectRatio: number;
      force: boolean;
    };
    rotate: boolean;
    circle: boolean;
  };
  storeTo?: {
    access?: 'public' | 'private';
    container?: string;
    location?: 'azure' | 's3' | 'gcs' | 'dropbox' | 'rackspace';
    path?: string;
    region?: 'us-east-1';
  };
}

export type PickerFileMetadata = {
  cropped?: {
    originalImageSize: number[];
    cropArea: { position: number[]; size: number[] };
  };
  filename: string;
  handle: string;
  mimetype: string;
  originalFile?: object | File;
  originalPath: string;
  size: number;
  source: string;
  status?: string;
  uploadId: string;
  url: string;
};

export interface FilestackUploadResponse {
  filesUploaded: PickerFileMetadata[];
  filesFailed: PickerFileMetadata[];
}

type Props = {
  type: 'siteImage' | 'profileImage';
  onSuccess: (response: FilestackUploadResponse) => void;
  onClose: () => void;
  onError?: (err: any) => void;
  onFileSelected?: (file: PickerFileMetadata) => void;
  options?: FilePickerOptions;
  open?: boolean;
};

export const FilePicker: React.FC<Props> = ({
  children,
  type,
  options,
  onFileSelected,
  onSuccess,
  onError,
  onClose,
  open = false,
}) => {
  const defaultOptions = {
    accept: 'image/*',
    maxFiles: 1,
    maxSize: cdn[type].MAX_FILE_SIZE,
    imageDim: [cdn[type].MAX_WIDTH, null],
    transformations: cdn[type].TRANSFORMATIONS,
    onClose,
  };

  const pickerOptions = Object.assign(defaultOptions, options);

  return (
    <>
      {open && (
        <PickerOverlay
          apikey={credentials.get('FILESTACK')}
          pickerOptions={pickerOptions}
          onFileSelected={onFileSelected}
          onError={(err) => (onError ? onError(err) : toast.error(err))}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
