import { useEffect } from 'react';
import { apolloClient } from '../../../app/apolloClient';
import { GET_SOIL_SITE } from '../graphql';
import {
  getMessageBoardObservable,
  newPost,
  postUpdated,
  postDeleted,
} from './messageBoardSlice';
import { PostType } from './model';
import { useAuthUser } from '../../auth/useAuthUser';
import { useAppDispatch } from '../../../app/hooks';

export function useMessageBoardSubscription(siteId: number) {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  useEffect(() => {
    const subscription = getMessageBoardObservable(
      siteId,
      authUser.id
    ).subscribe(
      ({
        data: {
          sitePost: { action, sitePost },
        },
      }) => {
        if (action === 'new') {
          dispatch(newPost(sitePost));

          if (
            [
              PostType.DROPOFF,
              PostType.USER_JOINED,
              PostType.USER_LEFT,
            ].includes(sitePost.type)
          ) {
            apolloClient.refetchQueries({
              include: [GET_SOIL_SITE],
            });
          }
        }
        if (action === 'update') dispatch(postUpdated(sitePost));
        if (action === 'delete') {
          dispatch(postDeleted(sitePost.id));
          if (sitePost.type === PostType.DROPOFF) {
            apolloClient.refetchQueries({
              include: [GET_SOIL_SITE],
            });
          }
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [dispatch, siteId, authUser.id]);
}
