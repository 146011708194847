import { gql } from '@apollo/client';

export const CREATE_PUSH_REGISTRATION = gql`
  mutation createPushRegistration($registration: JSONObject!) {
    createPushRegistration(registration: $registration)
  }
`;

export const REMOVE_PUSH_REGISTRATION = gql`
  mutation removePushRegistration($registrationId: ID!) {
    removePushRegistration(registrationId: $registrationId)
  }
`;
