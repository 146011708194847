import { WeightUnit, getWeightUnit } from '../../utils/formatting';

const defaultWeightUnit: WeightUnit = 'pound';
const averageDropOffWeightInLbs = 9.4;
const ghgMulitplier = 0.7;
const soilMultiplier = 0.1;

export const impactCalculator = {
  impact(dropOffs: { weight?: number }[]) {
    const weightUnit = getWeightUnit();
    const useDefaultWeightUnit = weightUnit.unit === defaultWeightUnit;

    let foodWasteSaved: number = this.foodWasteSaved(
      dropOffs,
      averageDropOffWeightInLbs
    );
    foodWasteSaved = useDefaultWeightUnit
      ? foodWasteSaved
      : this.lbsToKg(foodWasteSaved);

    const ghg: number = this.ghg(foodWasteSaved);
    const soilCreated: number = this.soilCreated(foodWasteSaved);

    return {
      foodWasteSaved,
      ghg,
      soilCreated,
    };
  },

  foodWasteSaved(
    dropOffs: { weight?: number }[],
    averageDropOffWeight: number
  ): number {
    return dropOffs.reduce(
      (accumulator, dropOff) =>
        accumulator + (dropOff.weight || averageDropOffWeight),
      0
    );
  },

  ghg(foodWasteSaved: number): number {
    return foodWasteSaved * ghgMulitplier;
  },

  soilCreated(foodWasteSaved: number): number {
    return foodWasteSaved * soilMultiplier;
  },

  kgToLbs(weight: number): number {
    return weight * 2.2046;
  },

  lbsToKg(weight: number): number {
    return weight / 2.2046;
  },
};
