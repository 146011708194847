import React from 'react';
import styled from 'styled-components';
import { Avatar, Flex } from '../../../components/ui';
import { UserDisplayName } from '../../../components/ui/UserDisplayName';
import { pluralize } from '../../../utils/helpers';

type UserBoxProps = {
  id: number;
  firstName: string;
  lastName?: string;
  role: { id: number; name: string };
  picture: string;
  createdAt: Date;
  dropOffs: number;
  badge?: React.ReactElement;
};

export const UserBox: React.FC<UserBoxProps> = (props) => {
  const {
    id,
    firstName,
    lastName,
    role,
    picture,
    children,
    badge,
    dropOffs,
  } = props;

  //const isSupporter = role.name === 'Soil Supporter';
  const showDropOffs = dropOffs > 0;

  return (
    <UserBoxContainer>
      <Avatar user={{ id, firstName, lastName, picture }} size="sm" />
      <div
        style={{
          display: 'flex',
          flex: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Content direction="column" gap="0.25rem">
          <Flex>
            <h5>
              <UserDisplayName firstName={firstName} lastName={lastName} />
            </h5>
            {badge}
          </Flex>

          {showDropOffs && (
            <small>
              has recorded{' '}
              <strong>
                {dropOffs} {pluralize(dropOffs, 'drop-off')}
              </strong>
            </small>
          )}
        </Content>
        {children}
      </div>
    </UserBoxContainer>
  );
};

const UserBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  background-color: #fff;
  padding: 0.5rem;

  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const Content = styled(Flex)`
  h5,
  h6,
  p {
    margin: 0;
  }
  h6 {
    margin-top: 0.35rem;
  }
  small {
    font-size: 0.775rem;
  }
`;
