import React from 'react';
import styled, { css } from 'styled-components';

type BoxContainerProps = {
  backgroundColor?: string;
  padding?: string;
  height?: string;
  mt?: string;
};

const BoxContainer = styled.div<BoxContainerProps>`
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

	${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

		${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt};
    `}
`;

type BoxProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  /** height ex. 1rem */
  height?: string;
  /** padding ex. 1rem */
  padding?: string;
  /** margin-top ex. 1rem */
  mt?: string;
  /** background-color ex. #fff */
  backgroundColor?: string;
};

export const Box: React.FC<BoxProps> = ({ children, ...props }) => {
  return <BoxContainer {...props}>{children}</BoxContainer>;
};

export const ShadowBox = styled(Box)`
  box-shadow: 0 40px 81px -16px rgba(50, 50, 93, 0.25),
    0 24px 48px -24px rgba(0, 0, 0, 0.3);

  transition: 0.25s ease;
`;
