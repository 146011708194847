import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { injectFilestackOptions } from '../../../utils/url';
import { Attachment } from './model';

type Props = {
  attachments: Attachment[];
  onRemoveAttachment: (attachment: Attachment) => void;
  style?: React.CSSProperties;
};

export const MessageAttachmentPreview = ({
  attachments,
  onRemoveAttachment,
  style,
}: Props) => {
  if (!attachments?.length) return null;

  return (
    <Container style={style}>
      {attachments.map((attachment, i) => {
        return (
          <FilePreviewContainer key={i}>
            <CloseButton onClick={() => onRemoveAttachment(attachment)}>
              <FontAwesomeIcon icon="times" />
            </CloseButton>
            {renderAttachmentPreview(attachment)}
          </FilePreviewContainer>
        );
      })}
    </Container>
  );
};

function renderAttachmentPreview(attachment: Attachment) {
  if (attachment.fileType.includes('image/')) {
    const url = injectFilestackOptions(attachment.url, [`resize=height:250`]);
    return <img src={url} alt="attachment preview" />;
  }

  if (attachment.fileType.includes('application/pdf')) {
    return (
      <FileAttachment>
        <FontAwesomeIcon icon="file-pdf" size="2x" />
        <span>{attachment.fileName}</span>
      </FileAttachment>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 50vh;
  overflow-y: auto;

  border-top: 1px solid #f3f3f3;
`;

const FilePreviewContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  height: 250px;
  cursor: pointer;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
`;

const FileAttachment = styled.div`
  background-color: rgba(51, 51, 51, 0.7);
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: #fff;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  color: #fff;

  cursor: pointer;
`;
