import { gql } from '@apollo/client';

export const GET_ME = gql`
  query GetMe($userId: Int!) {
    me(userId: $userId) {
      id
      firstName
      lastName
      picture
      email
      phoneNum
      twitter
      instagram
      facebook
      linkedin
      createdAt
      location {
        lat
        lng
      }
      address
      hasPassword
      hasAddress
    }
  }
`;

export const SIGNUP = gql`
  mutation CreateUserAccount($input: CreateAccountInput!) {
    createUserAccount(input: $input) {
      accessToken
      errors
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      accessToken
      errors
    }
  }
`;

export const MAGIC_LOGIN = gql`
  mutation MagicLogin($magicId: String!) {
    loginWithMagicId(magicId: $magicId) {
      accessToken
      errors
    }
  }
`;

export const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($code: String!) {
    verifyAccount(code: $code) {
      errors
      accessToken
    }
  }
`;

export const SEND_ACCOUNT_VERIFICATION_CODE = gql`
  mutation {
    sendAccountVerificationCode {
      errors
      message
    }
  }
`;

export const CHANGE_VERIFICATION_EMAIL_ADDRESS = gql`
  mutation ChangeAddress($email: String!) {
    changeVerificationEmailAddress(email: $email) {
      errors
      message
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
  mutation UpdateContactInfo($info: AdditionalContactInfoInput!) {
    updateContactInfo(info: $info) {
      phoneNum
      twitter
      instagram
      facebook
      linkedin
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;
