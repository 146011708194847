import React from 'react';
import { toast } from 'react-toastify';
import { Modal, Button } from '../../../components/ui';

import { useSiteManager } from '../hooks';
import { useSoilSiteContext } from '../SoilSiteContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ReactivateSiteModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { soilSite } = useSoilSiteContext();
  const { reactivate: reactivateSite } = useSiteManager(soilSite.id);

  const handleReactivate = async () => {
    try {
      const { data } = await reactivateSite();

      if (data && data.reactivateSite.errors.length > 0) {
        toast.error(`${data.reactivateSite.errors[0]}`);
      }

      if (data && data.reactivateSite.soilSite) {
        toast.success(`Your soil site is now active.`);
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      header="Reactivate your soil site"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={<Button onClick={handleReactivate}>REACTIVATE SITE</Button>}
    >
      <h3>This soil site is currently deactivated</h3>
      <p>
        Reactivate the soil site to update your information, manage and
        communicate with participants. For example, post on the message board to
        let them all know of any changes and that you are back open.
      </p>
    </Modal>
  );
};
