import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';
import { Button, SmartImage } from '../../../components/ui';
import {
  FilePicker,
  FilestackUploadResponse,
} from '../../../components/ui/FilePicker';

type TriggerVisibility = 'always' | 'hover';
type TriggerType = 'overlay' | 'button';

export type SiteImageProps = {
  siteId: number;
  image: string;
  visibility?: TriggerVisibility;
  triggerType?: TriggerType;
  showTrigger: boolean;
  onFileUploaded: (response: FilestackUploadResponse) => void;
};

export const SiteImage: React.FC<SiteImageProps> = ({
  image,
  siteId,
  visibility = 'always',
  triggerType = 'overlay',
  showTrigger,
  onFileUploaded,
}) => {
  const [filePickerOpen, setFilePickerOpen] = useState<boolean>(() => false);

  const renderTrigger = () => {
    if (triggerType === 'overlay') {
      return (
        <FilePickerTrigger
          visibility={visibility}
          onClick={() => setFilePickerOpen(true)}
        >
          <FontAwesomeIcon icon="camera" size="2x" />
          Update Image
        </FilePickerTrigger>
      );
    }

    if (triggerType === 'button') {
      return (
        <TriggerButton
          padding="0.5rem 0.75rem"
          onClick={() => setFilePickerOpen(true)}
        >
          <FontAwesomeIcon icon="camera" /> Edit
        </TriggerButton>
      );
    }
  };

  return (
    <SiteImageContainer>
      <StyledSmartImage src={image} width={400} />
      {showTrigger && renderTrigger()}
      <FilePicker
        type="siteImage"
        open={filePickerOpen}
        options={{
          storeTo: {
            path: `/site/${siteId}/`,
          },
        }}
        onSuccess={onFileUploaded}
        onClose={() => setFilePickerOpen(false)}
      />
    </SiteImageContainer>
  );
};

const StyledSmartImage = styled(SmartImage)`
  min-height: 250px;
  max-height: 300px;
  border-radius: 4px;
  width: 100%;
`;

const SiteImageContainer = styled.div`
  position: relative;
  height: 100%;
`;

const FilePickerTrigger = styled.div<{ visibility: TriggerVisibility }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.85);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;

  ${({ visibility }) =>
    visibility === 'hover' &&
    `
    opacity: 0;
    :hover {
      opacity: 1;
      z-index: 10;
    }
  
  `}

  :hover {
    background: rgba(0, 0, 0, 0.25);
    color: #fff;
  }

  transition: 0.25s ease-in;
`;

const TriggerButton = styled(Button)`
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  border-radius: 8px;

  background-color: rgba(0, 0, 0, 0.5);
  color: #e8e8e8;

  :hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
  }

  font-size: 0.825rem;

  transition: all 0.25s ease;
`;
