import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppRole } from '../../../app/model/AppRole';
import { User } from '../../../app/model/User';
import { RootState } from '../../../app/store';

import userManagementService, {
  AccountActivity,
  AssignRoleResponse,
  ParticipationDetails,
  PremiumFeatureManage,
} from './userManagementService';

type UserManagementSliceState = {
  users: User[];
  roles: AppRole[];
  features: PremiumFeatureManage[];
  selectedUser: User;
  verificationCode: { code: string; updatedAt: Date };

  loading: boolean;
  error: string;
};

const initialState: UserManagementSliceState = {
  users: [],
  roles: [],
  features: [],
  selectedUser: null,
  verificationCode: null,

  loading: false,
  error: '',
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    userSelected(state, { payload }: PayloadAction<User>) {
      state.selectedUser = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersByKeywords.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = payload;
      })
      .addCase(fetchRoles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.roles = payload;
      })
      .addCase(assignRole.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.selectedUser.roles = payload.assignRole.roles;
        state.users = state.users.map((user) => {
          if (user.id === payload.assignRole.id) {
            user.roles = payload.assignRole.roles;
          }
          return user;
        });
      })
      .addCase(fetchFeatures.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.features = payload;
      })
      .addCase(enableFeature.fulfilled, (state, { payload }) => {
        state.loading = false;

        const feature = state.features.find((f) => f.id === payload.featureId);
        feature.enabled = true;
        feature.enabledAt = new Date();
      })
      .addCase(disableFeature.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.features.find((f) => f.id === payload.featureId).enabled = false;
      })
      .addCase(fetchVerificationCode.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.verificationCode = payload;
      })
      .addCase(regenerateVerificationCode.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.verificationCode = payload;
      })
      .addCase(verifyUserAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = state.users.map((user) => {
          if (user.id === payload.userId) {
            user.verified = true;
          }
          return user;
        });
      })
      .addCase(deleteUserAccount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = state.users.filter((u) => u.id !== payload);
      })

      // matchers
      .addMatcher(
        isPending(
          fetchUsersByKeywords,
          fetchRoles,
          fetchVerificationCode,
          regenerateVerificationCode,
          assignRole,
          deleteUserAccount
        ),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(
          fetchUsersByKeywords,
          fetchRoles,
          fetchVerificationCode,
          regenerateVerificationCode,
          assignRole,
          deleteUserAccount
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(
          fetchUsersByKeywords,
          fetchRoles,
          fetchVerificationCode,
          regenerateVerificationCode,
          assignRole,
          deleteUserAccount
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export default userManagementSlice.reducer;

export const { userSelected } = userManagementSlice.actions;

export const selectUsers = (state: RootState) =>
  state.admin.userManagement.users;
export const selectRoles = (state: RootState) =>
  state.admin.userManagement.roles;
export const selectFeatures = (state: RootState) =>
  state.admin.userManagement.features;

export const selectSelectedUser = (state: RootState) =>
  state.admin.userManagement.selectedUser;

export const selectVerificationCode = (state: RootState) =>
  state.admin.userManagement.verificationCode;

export const selectLoading = (state: RootState) =>
  state.admin.userManagement.loading;
export const selectError = (state: RootState) =>
  state.admin.userManagement.error;

export const fetchUsersByKeywords = createAsyncThunk<User[], string>(
  'userManagement/fetchUsersByKeywords',
  async (keywords, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.fetchUsersByKeywords(
      keywords
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.usersByKeywords;
  }
);

export const fetchRoles = createAsyncThunk<AppRole[]>(
  'userManagement/fetchRoles',
  async (_, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.fetchRoles();
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.userRoles;
  }
);

export const fetchFeatures = createAsyncThunk<PremiumFeatureManage[], number>(
  'userManagement/fetchFeatures',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.fetchFeatures(userId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.premiumFeatures;
  }
);

export const fetchParticipationDetails = createAsyncThunk<
  ParticipationDetails,
  number
>(
  'userManagement/fetchParticipationDetails',
  async (userId, { rejectWithValue }) => {
    const {
      data,
      errors,
    } = await userManagementService.fetchParticipationDetails(userId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.participationDetails;
  }
);

export const fetchAccountActivity = createAsyncThunk<AccountActivity, number>(
  'userManagement/fetchAccountActivity',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.fetchAccountActivity(
      userId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.accountActivity;
  }
);

export const fetchVerificationCode = createAsyncThunk<
  { code: string; updatedAt: Date },
  number
>(
  'userManagement/fetchVerificationCode',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.fetchVerificationCode(
      userId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.verificationCode;
  }
);

export const regenerateVerificationCode = createAsyncThunk<
  { code: string; updatedAt: Date },
  number
>(
  'userManagement/regenerateVerificationCode',
  async (userId, { rejectWithValue }) => {
    const {
      data,
      errors,
    } = await userManagementService.regenerateVerificationCode(userId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.regenerateVerificationCode;
  }
);

export const verifyUserAccount = createAsyncThunk<
  { status: string; userId: number },
  { userId: number; code: string }
>(
  'userManagement/verifyUserAccount',
  async ({ userId, code }, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.verifyUserAccount(
      userId,
      code
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return { status: data.status, userId };
  }
);

export const assignRole = createAsyncThunk<
  AssignRoleResponse,
  { userId: number; roleId: number }
>(
  'userManagement/assignRole',
  async ({ userId, roleId }, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.assignRole(
      userId,
      roleId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data;
  }
);

export const enableFeature = createAsyncThunk<
  { userId: number; featureId: number; enabled: boolean },
  { userId: number; featureId: number }
>(
  'userManagement/enableFeature',
  async ({ userId, featureId }, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.enableFeature(
      userId,
      featureId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return { featureId, userId, enabled: true };
  }
);

export const disableFeature = createAsyncThunk<
  { userId: number; featureId: number; enabled: boolean },
  { userId: number; featureId: number }
>(
  'userManagement/disableFeature',
  async ({ userId, featureId }, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.disableFeature(
      userId,
      featureId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return { featureId, userId, enabled: true };
  }
);

export const deleteUserAccount = createAsyncThunk<number, number>(
  'userManagement/deleteUserAccount',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await userManagementService.deleteUserAccount(
      userId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return userId;
  }
);
