import { Formik, Form, FormikErrors } from 'formik';
import { Content } from '../../components/GreenShell';
import { Button } from '../../components/ui';
import { AddressField } from '../../components/ui/forms/AddressField';
import { validationMessages } from '../../utils/formValidation';
import { AddressComponents } from '../../app/model/AddressComponents';
import { ILocation } from '../../app/model/ILocation';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchMe } from '../auth/authSlice';
import { useAuthUser } from '../auth/useAuthUser';
import { resetLocationPicker } from '../locationPicker/locationPickerSlice';
import { AuthPageLayout } from '../../pages';
import styled from 'styled-components';
import { Meta } from '../../components/Meta';
import { SiteOnboardLocationState } from './model';
import { generateSiteIdentifier } from '../../utils/url';

const SET_USER_ADDRESS = gql`
  mutation SetUserAddress($input: UserAddressInput!) {
    setUserAddress(input: $input) {
      message
    }
  }
`;

type AddressFormData = {
  address: string;
  addressComponents: AddressComponents[];
  googlePlaceId: string;
  location: ILocation;
};

export default function YourLocation() {
  const history = useHistory();

  const handleAfterSubmit = () => {
    const state = history.location.state as SiteOnboardLocationState;
    return history.push('/onboarding/how-did-you-hear', state);
  };

  const mapUrl = () => {
    const state = history.location.state as SiteOnboardLocationState;
    if (
      state.action === 'join' ||
      state.action === 'request' ||
      state.action === 'invitation'
    ) {
      return `/map/site/${generateSiteIdentifier(
        state.site.siteId,
        state.site.siteName
      )}`;
    }

    return `/map`;
  };

  const isSiteOnboardingScreen = !!history.location.state;

  return (
    <AuthPageLayout
      contentContainerStyle={{ overflowY: 'unset' }}
      onClose={() => history.push(mapUrl())}
      hideClose={!history.location.state}
    >
      <Meta>
        <title>Your location | MakeSoil</title>
      </Meta>

      <SetLocationForm
        heading={
          isSiteOnboardingScreen
            ? 'Please confirm your location'
            : 'Your location'
        }
        paragraph={
          isSiteOnboardingScreen
            ? 'So we can match you with soil sites that are right for you.'
            : 'So we can connect you with locals to make soil with.'
        }
        onAfterSubmit={handleAfterSubmit}
      />
    </AuthPageLayout>
  );
}

function SetLocationForm({ heading, paragraph, onAfterSubmit }) {
  const authUser = useAuthUser();
  const dispatch = useDispatch();

  const [setUserAddress] = useMutation<{ ok: boolean; message: string }>(
    SET_USER_ADDRESS
  );

  const handleSubmit = async (
    values: AddressFormData,
    { setSubmitting }: any
  ) => {
    const { address, addressComponents, googlePlaceId, location } = values;

    await setUserAddress({
      variables: {
        input: {
          address: {
            address,
            addressComponents,
            placeId: googlePlaceId,
          },
          location,
        },
      },
    });

    await dispatch(fetchMe(authUser.id));
    setSubmitting(false);
    dispatch(resetLocationPicker());

    onAfterSubmit();
  };

  const validateForm = (values: AddressFormData) => {
    const { address, googlePlaceId } = values;

    let errors: FormikErrors<AddressFormData> = {};

    if (!googlePlaceId || !address) {
      errors.address = validationMessages.address;
    }

    return errors;
  };

  return (
    <>
      <Content style={{ paddingTop: 0 }}>
        <h2>{heading}</h2>
        <p>{paragraph}</p>
      </Content>

      <Formik
        initialValues={{
          address: '',
          addressComponents: [],
          googlePlaceId: '',
          location: undefined,
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setSubmitting, values }) => (
          <StyledForm>
            <AddressField
              name="address"
              addressType="home"
              required
              placeholder="Your primary location or address"
              onEnterPressed={() => handleSubmit(values, { setSubmitting })}
            />

            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              style={{ width: '100%', marginTop: '1.5rem' }}
            >
              CONFIRM LOCATION
            </Button>

            {/* <HelpText>
              This will be registered (privately) as your primary location.
            </HelpText> */}
          </StyledForm>
        )}
      </Formik>
    </>
  );
}

const HelpText = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  font-style: italic;
  font-size: 0.875rem;
  color: #a9a9a9;
`;

const StyledForm = styled(Form)`
  div.geosuggest__suggests-wrapper {
    color: #333;
  }
`;
