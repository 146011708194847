import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DropMenu } from './DropMenu';
import { Tooltip } from './Tooltip';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation('translation');

  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
  };

  return (
    <Tooltip
      trigger={'mouseenter focus'}
      triggerElement={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '1rem',
            color: '#fff',
          }}
        >
          <DropMenu
            triggerElement={
              <>
                <FontAwesomeIcon icon="globe" /> {i18n.language.toUpperCase()}
              </>
            }
          >
            <span onClick={() => changeLanguage('en')}>
              {t('translation:en')}
            </span>
            <span onClick={() => changeLanguage('pl')}>
              {t('translation:pl')}
            </span>
          </DropMenu>
        </div>
      }
    >
      Change Language
    </Tooltip>
  );
};
