import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { AuthPageLayout } from '..';
import { Meta } from '../../components/Meta';
import { Button, FormInputError, Input } from '../../components/ui';
import { isEmail, validationMessages } from '../../utils/formValidation';
import { theme } from '../../app/theme';
import { RecaptchaFormControl } from '../../components/ui/forms/RecaptchaFormControl';
import { isMobileApp } from '../../mobile/mobile';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';

const SEND_MAGIC_LINK = gql`
  mutation SendMagicLink($email: String!, $recaptcha: String) {
    sendMagicLink(email: $email, recaptcha: $recaptcha) {
      message
    }
  }
`;

export default function LoginWithoutPassword() {
  const [showForm, setShowForm] = useState(true);
  const [email, setEmail] = useState('');

  const recaptchaRef = useRef(null);

  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK);

  const handleSendMagicLink = async (
    { email, recaptcha }: any,
    { setSubmitting }: any
  ) => {
    try {
      const result = await sendMagicLink({
        variables: { email, recaptcha },
      });

      recaptchaRef.current?.reset();

      if (result.data.sendMagicLink.message) {
        toast.success(result.data.sendMagicLink.message);
        setShowForm(false);
        setSubmitting(false);
        setEmail(email);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const validateForm = (values: { email: string; recaptcha: string }) => {
    const { email, recaptcha } = values;

    let errors: FormikErrors<{ email: string; recaptcha: string }> = {};

    if (!isEmail(email)) {
      errors.email = validationMessages.email;
    }

    if (!isMobileApp() && !recaptcha) {
      errors.recaptcha = validationMessages.recaptcha;
    }

    return errors;
  };

  const content = showForm ? (
    <>
      <p>We'll send you an email with a link that will log you in.</p>

      <Formik
        initialValues={{
          email: email || '',
          recaptcha: '',
        }}
        onSubmit={handleSendMagicLink}
        validate={validateForm}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Field
                as={Input}
                type="email"
                name="email"
                placeholder="Email address"
                autoFocus
              />
              <ErrorMessage name="email" component={FormInputError} />

              {!isMobileApp() && (
                <div style={{ marginTop: '1rem' }}>
                  <RecaptchaFormControl ref={recaptchaRef} name="recaptcha" />
                  <ErrorMessage name="recaptcha" component={FormInputError} />
                </div>
              )}

              <Button type="submit" disabled={isSubmitting}>
                GET LINK
              </Button>
            </Form>
          );
        }}
      </Formik>

      <Link
        style={{ marginTop: '1.5rem', display: 'inline-block' }}
        to="/login"
      >
        <small>
          <FontAwesomeIcon icon="chevron-left" /> Type password instead
        </small>
      </Link>
    </>
  ) : (
    <>
      <p>We just sent an email, with a link to log in, to:</p>
      <strong>{email}</strong> (
      <BackToEmailLink
        onClick={() => setShowForm(true)}
        style={{ color: theme.colors.primary }}
      >
        change
      </BackToEmailLink>
      )
      <Info>
        <p>
          The link to log in is valid for <strong>4 hours</strong> only. Check
          your spam folder if you don't see the email within a few minutes.
        </p>
      </Info>
      <BackToEmailLink
        onClick={() => setShowForm(true)}
        style={{ marginTop: '1rem' }}
      >
        <small>
          <FontAwesomeIcon icon="chevron-left" /> Back to email address
        </small>
      </BackToEmailLink>
    </>
  );

  return (
    <AuthPageLayout contentContainerStyle={{ justifyContent: 'center' }}>
      <PageContainer>
        <Meta>
          <title>Log In Without Password | MakeSoil</title>
        </Meta>
        <h2>Log In Without Password</h2>
        {content}
      </PageContainer>
    </AuthPageLayout>
  );
}

const PageContainer = styled.div`
  button {
    margin-top: 1.5rem;
  }
`;

const BackToEmailLink = styled.span`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`;

const Info = styled.div`
  margin-top: 2rem;
  padding: 0.75rem 1rem;

  border: 1px solid #5c5c5c;
  border-radius: 4px;

  p {
    margin: 0;
    font-size: 0.825rem;
    color: #b1b1b1;
    line-height: 1.5;
  }

  strong {
    background-color: #5c5c5c;
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
  }
`;
