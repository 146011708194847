import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { Sparkline } from '../../features/insights/SparkLine';
import { Box, Button, Flex, Grid } from '../../components/ui';
import { Container } from '../../components/ui/Container';
import { participation, totalImpact } from './data';
import Chart from 'react-apexcharts';

const PageContainer = styled.div`
  height: 100%;
  overflow: auto;

  header {
    display: flex;
    justify-content: space-between;
    padding: 2rem;

    svg {
      g {
        fill: #fff;
      }
    }
  }
`;

const Hero = styled.section<{ backgroundColor: string }>`
  padding: 5rem 0 2rem;
  background: url('/map.png') center no-repeat;
  background-size: cover;
  background-color: ${(props) => props.backgroundColor};

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 5rem 5rem;

    > div {
      flex: 1;
    }
  }

  color: #fff;
`;

const Image = styled.div`
  background: url('/onboarding-earth.png') center no-repeat;
  background-size: contain;
  height: 400px;
`;

const Section = styled.section<{ backgroundColor?: string }>`
  padding: 5rem 3rem;
  background-color: ${(props) => props.backgroundColor};
`;

const List = styled.ul`
  margin-left: 1.5rem;
  li {
    margin-top: 0.75rem;
  }
`;

const ShadowBox = styled(Box)`
  box-shadow: 0 40px 81px -16px rgba(50, 50, 93, 0.25),
    0 24px 48px -24px rgba(0, 0, 0, 0.3);
`;

const Partners = () => {
  return (
    <PageContainer>
      <Hero backgroundColor="#5AB474">
        <div>
          <div>
            <h1>Introducing MakeSoil</h1>
            <p>
              MakeSoil is the most cost-effective way to keep the most food
              waste out of the landfill, while also reducing greenhouse gas
              emissions and capturing carbon locally in the form of soil.
            </p>
            <p>
              MakeSoil creates a well-distributed network of local ‘Soil Sites’
              - managed by citizens.
            </p>
            <p>
              And gives you a detailed, data-rich picture of the results being
              achieved by your community.
            </p>
          </div>
          <Image />
        </div>
      </Hero>

      <Section>
        <Container px="2rem">
          <Grid col="3fr 2fr" gap="1rem">
            <div style={{ padding: '4rem 2rem  3rem 0' }}>
              <h3 style={{ color: '#68B74E' }}>IMPACT</h3>
              <h2>MakeSoil Insights</h2>
              <p style={{ fontSize: '1.2rem' }}>
                Discover rich, comprehensive data about the impact your
                community is making
              </p>
              <List className="fa-ul">
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />
                  See the weight of organics you’re diverting from the landfill.
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See the greenhouse gas reductions you are achieving.
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See how much money you are saving by using MakeSoil.
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See the volume of soil that is being produced by your
                  community.
                </li>
              </List>
              <br />
              <Button onClick={() => console.log('click')}>
                BECOME A MAKESOIL PARTNER
              </Button>
            </div>
            <Flex justify="center" align="center">
              <ShadowBox
                backgroundColor="#f7f7f7"
                padding="1rem"
                style={{ width: '100%' }}
              >
                <Grid col="1fr" gap="1rem">
                  <Box>
                    <Sparkline {...totalImpact.waste} type="area" />
                  </Box>
                  <Box>
                    <Sparkline {...totalImpact.ghg} type="area" />
                  </Box>
                  <Box>
                    <Sparkline {...totalImpact.soilProduced} type="area" />
                  </Box>
                </Grid>
              </ShadowBox>
            </Flex>
          </Grid>
        </Container>
      </Section>

      <Section backgroundColor="#fefefe;">
        <Container px="2rem">
          <Grid col="3fr 3fr" gap="5rem">
            <div>
              <ShadowBox height="400px" padding="1rem" mt="1rem">
                <Chart
                  options={{
                    chart: {
                      id: 'participation-donut',
                      type: 'donut',
                    },
                    colors: ['#5AB474', '#008FFB', '#FEB019'],
                    title: {
                      text: 'Participation Details',
                      style: {
                        fontSize: '18px',
                      },
                    },
                    subtitle: {
                      text: `Total Participants: ${participation.participants.total}`,
                      style: {
                        fontSize: '14px',
                      },
                    },
                    labels: [
                      'Soil Makers',
                      'Soil Supporters',
                      'Potential Soil Supporters',
                    ],
                    legend: {
                      position: 'left',
                      offsetY: 60,
                    },
                    responsive: [
                      {
                        breakpoint: 576,
                        options: {
                          chart: {
                            height: '85%',
                          },
                          legend: { show: false },
                        },
                      },
                    ],
                  }}
                  series={[
                    participation.participants.makers,
                    participation.participants.supporters,
                    participation.participants.orphans,
                  ]}
                  type="donut"
                  height="100%"
                />
              </ShadowBox>
            </div>

            <div style={{ padding: '3rem 0 4rem 0' }}>
              <h3 style={{ color: '#68B74E' }}>PARTICIPATION</h3>
              <h2>MakeSoil Insights</h2>
              <p style={{ fontSize: '1.2rem' }}>
                Monitor the state of the engagement in your community:
              </p>
              <List className="fa-ul">
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See how many citizens are participating
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See how many Soil Sites are being created
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See a participation mao of each neighbourhood
                </li>
              </List>
              <br />
              <Button onClick={() => console.log('click')}>
                GET MAKESOIL INSIGHTS NOW
              </Button>
            </div>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container px="2rem">
          <Grid col="3fr 3fr" gap="1rem">
            <div style={{ padding: '4rem 2rem  3rem 0' }}>
              <h3 style={{ color: '#68B74E' }}>GROWTH</h3>
              <h2>MakeSoil Insights</h2>
              <p style={{ fontSize: '1.2rem' }}>
                Monitor the growth of your community:
              </p>
              <List className="fa-ul">
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See the growth in participation over time.
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See the growth in Soil Sites in your community.
                </li>
                <li>
                  <FontAwesomeIcon
                    color="#68B74E"
                    icon="check-circle"
                    listItem
                  />{' '}
                  See which Soil Sites are making the most impact.
                </li>
              </List>
              <br />
              <Button onClick={() => console.log('click')}>
                GET MAKESOIL INSIGHTS NOW
              </Button>
            </div>
            <div>
              <ShadowBox height="400px" padding="1rem" mt="1rem">
                <Chart
                  options={{
                    chart: {
                      id: 'participation-timeline',
                      type: 'line',
                      dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                      },
                      toolbar: {
                        show: true,
                      },
                    },
                    colors: ['#5AB474', '#008FFB', '#FEB019'],
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    title: {
                      text: 'Participation Timeline',
                      align: 'left',
                    },
                    grid: {
                      borderColor: '#e7e7e7',
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5,
                      },
                    },
                    markers: {
                      size: 1,
                    },
                    xaxis: {
                      categories: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                      ],
                      title: {
                        text: 'Month',
                      },
                    },
                    yaxis: {
                      title: {
                        text: 'Participants',
                      },
                    },
                    legend: {
                      position: 'top',
                      horizontalAlign: 'right',
                      floating: true,
                      offsetY: -15,
                    },
                  }}
                  series={participation.timeline.series}
                  type="line"
                  height="100%"
                />
              </ShadowBox>
            </div>
          </Grid>
        </Container>
      </Section>
    </PageContainer>
  );
};

export default Partners;
