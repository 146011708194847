import { Meta } from '../components/Meta';
import { SiteWizard } from '../features/siteWizard/SiteWizard';

const SoilSiteWizard = () => {
  return (
    <>
      <Meta>
        <title>Create soil site | MakeSoil</title>
      </Meta>
      <SiteWizard />
    </>
  );
};

export default SoilSiteWizard;
