import React, { useCallback, useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { AuthPageLayout } from '..';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Meta } from '../../components/Meta';
import { Button, Flex, FormError, Input } from '../../components/ui';
import {
  changeVerificationEmailAddress,
  selectAuthError,
  sendAccountVerificationCode,
  verifyAccount,
} from '../../features/auth/authSlice';
import { isEmail } from '../../utils/formValidation';
import { useAuthUser } from '../../features/auth/useAuthUser';

const VERIFY_CODE_LENGTH = 6;

const VerifyAccount: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();
  const verificationError = useAppSelector(selectAuthError);

  const [showEmail, setShowEmail] = useState(false);
  const [code, setCode] = useState('');
  const [email, setEmail] = useState(authUser.email);

  const changeEmail = async () => {
    const trimmedEmail = email.trim();
    if (trimmedEmail && isEmail(trimmedEmail)) {
      const result = await dispatch(
        changeVerificationEmailAddress(trimmedEmail)
      ).unwrap();
      if (result.message) {
        setEmail(trimmedEmail);
        setShowEmail(false);
        toast.success(result.message);
      }
    }
  };

  const handleSetCode = (code: string) => {
    if (code.length === VERIFY_CODE_LENGTH) {
      return verifyCode(code);
    }
    setCode(code);
  };

  const verifyCode = async (code: string) => {
    if (code && code.trim()) {
      const result = await dispatch(verifyAccount(code.trim())).unwrap();
      setCode('');
      setShowEmail(false);
      if (result.ok) {
        history.push(`/onboarding/your-location`, history.location.state);
      }
    }
  };

  const resendCode = useCallback(async () => {
    const result = await dispatch(sendAccountVerificationCode()).unwrap();
    if (result.message) {
      setCode('');
    }
  }, [dispatch]);

  const handleClose = useCallback(() => {
    if (showEmail) {
      // if we're on the "change email" view, hide it
      setShowEmail(false);
    } else {
      history.push('/map');
    }
  }, [showEmail, history]);

  return (
    <AuthPageLayout
      contentContainerStyle={{ justifyContent: 'center' }}
      onClose={handleClose}
    >
      <Meta>
        <title>Verify Account | MakeSoil</title>
      </Meta>
      <div>
        {verificationError && (
          <FormError style={{ marginTop: '1rem' }}>
            {verificationError}
          </FormError>
        )}
      </div>
      {showEmail ? (
        <ChangeEmail
          email={email}
          onSetEmail={(email) => setEmail(email)}
          onSend={changeEmail}
        />
      ) : (
        <VerifyWithCode
          code={code}
          onSetCode={(code) => handleSetCode(code)}
          onVerify={() => verifyCode(code)}
          email={email}
          setShowEmail={setShowEmail}
          onResendCode={resendCode}
        />
      )}
    </AuthPageLayout>
  );
};

const VerifyWithCode = ({
  code,
  onSetCode,
  onVerify,
  email,
  setShowEmail,
  onResendCode,
}) => {
  const location = useLocation();

  return (
    <Container>
      <p>We just sent an email with a verification code to:</p>
      <strong>{email}</strong> (
      <Button variant="link" onClick={() => setShowEmail(true)}>
        change
      </Button>
      )
      <Info>
        <p>
          If you don’t see the email soon, check your spam, junk or “Promotions”
          folder.
        </p>
      </Info>
      <Input
        type="text"
        name="code"
        placeholder="Enter verification code"
        value={code}
        onChange={(e) => onSetCode(e.target.value)}
      />
      <Flex direction="column">
        <ActionButton
          onClick={onVerify}
          disabled={!code}
          style={{ marginTop: '1rem' }}
        >
          VERIFY
        </ActionButton>

        <p>
          <Button variant="link" onClick={onResendCode}>
            Send new code
          </Button>
        </p>
      </Flex>
      <small style={{ marginTop: '1rem' }}>
        Can't verify?{' '}
        <Link to={{ pathname: '/contact-us', state: location.state }}>
          Contact us
        </Link>
      </small>
    </Container>
  );
};

const ChangeEmail = ({ email, onSetEmail, onSend }) => {
  return (
    <Container>
      <p>
        Enter the new email where you want to receive the verification code.
      </p>
      <Input
        type="text"
        name="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => onSetEmail(e.target.value)}
      />

      <ActionButton
        onClick={onSend}
        disabled={!email}
        style={{ width: '100%', marginTop: '1rem' }}
      >
        SEND CODE
      </ActionButton>
    </Container>
  );
};

export default withRouter(VerifyAccount);

const Container = styled.div`
  input {
    margin-top: 2rem;
    font-size: 1rem;
    padding-left: 8px;
    height: 2.25rem;
    width: 100%;
  }
`;

const Info = styled.div`
  margin-top: 1rem;
  padding: 0.75rem 1rem;

  border: 1px solid #5c5c5c;
  border-radius: 4px;

  p {
    margin: 0;
    font-size: 0.875rem;
    color: #b1b1b1;
    line-height: 1.5;
  }

  strong {
    background-color: #5c5c5c;
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
  }
`;

const ActionButton = styled.button`
  border: none;
  border-radius: 4px;
  padding: 0.5rem 3rem;
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  display: block;
  background-color: ${(props) => props.theme.colors.primary};
  cursor: pointer;

  &[disabled] {
    background-color: #6c757d;
    opacity: 0.65;
  }
`;
