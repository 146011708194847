import { Elements } from '@stripe/react-stripe-js';

import { useLoadStripe } from './useLoadStripe';
import { ReactNode } from 'react';
import { StripeElementsOptions } from '@stripe/stripe-js';

type Props = {
  children: ReactNode;
  options: StripeElementsOptions;
};

export function Stripe({ options, children }: Props) {
  const { stripePromise } = useLoadStripe();

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}
