import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';
import { trackEvent } from '../../analytics';

import { copyToClipboard, isMobile } from '../../utils/helpers';
import { Button } from './Button';

import { isMobileApp, mobileShare } from '../../mobile/mobile';

const shareIcon = <FontAwesomeIcon icon="share" />;
const linkIcon = <FontAwesomeIcon icon="link" />;

const useShare = isMobile() || isMobileApp();

type ShareProps = {
  url: string;
  text?: string;
  copiedText?: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export const ShareButton: React.FC<ShareProps> = (props) => {
  const {
    url,
    text = useShare ? 'Share' : 'Get Link',
    copiedText = 'Link copied!',
    icon = useShare ? shareIcon : linkIcon,
    className,
  } = props;

  const [copied, setCopied] = useState(false);

  const share = async () => {
    if (useShare && navigator.share) {
      await navigator.share({
        title: document.title,
        url,
      });
    } else if (isMobileApp()) {
      mobileShare(document.title, url);
    } else {
      try {
        await copyToClipboard(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      } catch (err) {
        console.error(err);
      }
    }
    trackEvent('Share', `mobile: ${useShare}`, url);
  };

  return (
    <Button className={className} onClick={share}>
      <Span>
        {!copied && <span>{icon}</span>} {copied ? copiedText : text}
      </Span>
    </Button>
  );
};

const Span = styled.span`
  cursor: pointer;

  display: flex;
  gap: 0.275rem;
`;
