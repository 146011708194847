import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../../../components/ui/Tooltip';
import styled from 'styled-components';

/**
 * A Badge with a Tooltip
 * @param param0
 * @returns
 */
export const UserBadge = ({ text, color }) => {
  return (
    <Tooltip
      trigger={'mouseenter focus'}
      triggerElement={
        <Icon className="fa-layers fa-fw" style={{ marginLeft: '0.25rem' }}>
          <FontAwesomeIcon icon="circle" color={color} />
          <FontAwesomeIcon icon="shield-alt" inverse transform="shrink-6" />
        </Icon>
      }
    >
      {text}
    </Tooltip>
  );
};

const Icon = styled.span`
  font-size: 1.125rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: inherit;
  }
`;
