import React from 'react';
import styled from 'styled-components';

const RadioGroupContainer = styled.div<{ align?: 'column' | 'row' }>`
  display: flex;
  flex-direction: ${({ align }) => align};

  div:not(:first-child) {
    margin-top: 0.75rem;
  }
`;

type RadioGroupProps = {
  children: React.ReactElement[];
  name: string;
  align?: 'column' | 'row';
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  children,
  name,
  align = 'column',
}) => {
  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        name: name,
      });
    });
  };

  return (
    <RadioGroupContainer align={align}>{renderChildren()}</RadioGroupContainer>
  );
};
