import { Field, ErrorMessage, useFormikContext } from 'formik';
import styled from 'styled-components';
import {
  Input,
  FormInputError,
  Flex,
  Checkbox,
  Revealer,
} from '../../components/ui';
import { WizardForm } from './model';

export const Step1 = ({ stepCount }) => {
  const { values, setFieldValue } = useFormikContext<WizardForm>();

  return (
    <div>
      <h1 className="step-title">Create a soil site: Step 1/{stepCount}</h1>
      <hr />

      <FormSection>
        <h3>What do you want to call your soil site?</h3>
        <HelpText>
          This is the public name others will see on the platform.
        </HelpText>

        <Field
          name="name"
          type="text"
          placeholder="Your soil site's name"
          as={Input}
        />
        <ErrorMessage name="name" component={FormInputError} />
      </FormSection>

      <FormSection>
        <h3>Privacy options:</h3>

        <Flex gap="0.75rem" align="center">
          <Field
            as={Checkbox}
            name="isUnlisted"
            id="isUnlisted"
            value={values.isUnlisted}
            checked={!values.isUnlisted}
            onChange={(e) => {
              setFieldValue('isUnlisted', !e.target.checked);
              setFieldValue('obfuscate', !e.target.checked);
              // unlisted sites need approval
              if (!e.target.checked) {
                setFieldValue('requiresApproval', true);
              }
            }}
          />
          <label htmlFor="isUnlisted" style={{ cursor: 'pointer' }}>
            Show soil site on map
          </label>
        </Flex>
        <Revealer isOpen={!values.isUnlisted}>
          <HelpText
            style={{
              margin: '0.25rem 0 0 2.15rem',
              fontSize: '0.925rem',
            }}
          >
            Uncheck to make your soil site unlisted.
          </HelpText>
        </Revealer>

        <Revealer isOpen={!values.isUnlisted}>
          <Flex gap="0.75rem" align="center" style={{ marginTop: '1rem' }}>
            <Field
              as={Checkbox}
              name="obfuscate"
              id="obfuscate"
              value={values.obfuscate}
              checked={!values.obfuscate}
              onChange={(e) => {
                setFieldValue('obfuscate', !e.target.checked);
                if (!e.target.checked) {
                  setFieldValue('requiresApproval', true);
                }
              }}
            />
            <label htmlFor="obfuscate" style={{ cursor: 'pointer' }}>
              Show exact location
            </label>
          </Flex>

          <Revealer isOpen={!values.obfuscate}>
            <HelpText
              style={{
                margin: '0.25rem 0 0 2.15rem',
                fontSize: '0.925rem',
              }}
            >
              Uncheck to give your soil site a slightly random location.
            </HelpText>
          </Revealer>

          <Flex
            gap="0.75rem"
            align="center"
            style={{
              marginTop: '1rem',
            }}
          >
            <Field
              as={Checkbox}
              name="requiresApproval"
              id="requiresApproval"
              value={values.requiresApproval}
              checked={values.requiresApproval}
              disabled={values.obfuscate}
            />
            <label
              htmlFor="requiresApproval"
              style={
                values.obfuscate
                  ? { color: '#979797', cursor: 'pointer' }
                  : { cursor: 'pointer' }
              }
            >
              Require approval to join
            </label>
          </Flex>
          <Revealer isOpen={!values.requiresApproval}>
            <HelpText
              style={{
                margin: '0.25rem 0 0 2.15rem',
                fontSize: '0.925rem',
              }}
            >
              Anyone can join at any time and see the soil site location or
              address.
            </HelpText>
          </Revealer>
          <Revealer isOpen={values.requiresApproval}>
            <HelpText
              style={{
                margin: '0.25rem 0 0 2.15rem',
                fontSize: '0.925rem',
              }}
            >
              You’ll approve Soil Supporters before they can see the soil site
              location or address.
            </HelpText>
          </Revealer>
        </Revealer>
      </FormSection>
    </div>
  );
};

const HelpText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.4;
  color: #838383;
`;

const FormSection = styled.section`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;
