import { useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Box } from '../../components/ui';
import { mapStyles } from '../map/map.styles';

export function ParticipationMap({ region, soilSites }) {
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      /**
       * Instead of geocoding every time we could store the region data in DB
       * bounds, location, placeId, address
       */
      if (!region) {
        return map.setCenter({ lat: 37.773972, lng: -122.431297 });
      }

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId: region.placeId }, (result, status) => {
        if (status === 'OK') {
          const { bounds, location } = result[0].geometry;
          map.setCenter(location);
          map.fitBounds(bounds);
          map.setOptions({
            restriction: {
              latLngBounds: bounds,
              strictBounds: false,
            },
          });
        }
      });
    },
    [region]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    //map = null;
  }, []);

  const styles = [...mapStyles];

  return (
    <Box height="400px" padding="0.001rem">
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        zoom={6}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          styles,
        }}
      >
        {soilSites.map((marker) => (
          <Marker
            position={{ lat: marker.location.lat, lng: marker.location.lng }}
            key={marker.id}
            icon="/icons/soil-site-icon-exact.svg"
          />
        ))}
      </GoogleMap>
    </Box>
  );
}
