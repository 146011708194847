import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type ScreenSize = 'sm' | 'md' | 'lg' | 'xl';

type LayoutSliceState = {
  sideNavigationOpen: boolean;
  screenSize: ScreenSize;
};

const initialState: LayoutSliceState = {
  sideNavigationOpen: true,
  screenSize: null,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    navigationToggled(
      state,
      { payload }: PayloadAction<{ userTriggered: boolean }>
    ) {
      state.sideNavigationOpen = payload.userTriggered
        ? !state.sideNavigationOpen
        : state.screenSize !== 'sm';
    },
    screenSizeChanged(state, { payload }: PayloadAction<ScreenSize>) {
      state.screenSize = payload;
      if (payload === 'sm') state.sideNavigationOpen = false;
    },
  },
});

export const { navigationToggled, screenSizeChanged } = layoutSlice.actions;

export const selectScreenSize = (state: RootState) => state.layout.screenSize;
export const selectNavigationOpen = (state: RootState) =>
  state.layout.sideNavigationOpen;

export default layoutSlice.reducer;
