import { createAsyncThunk, createSlice, isPending } from '@reduxjs/toolkit';
import { IAccessRequest, Site, SiteMember } from './model';
import { RootState } from '../../app/store';
import soilSiteService from './soilSiteService';

type SoilSiteStoreState = {
  info: Omit<Site, 'members' | 'accessRequests'>;
  members?: SiteMember[];
  accessRequests?: IAccessRequest[];

  loading: boolean;
};

const initialState: SoilSiteStoreState = {
  info: undefined,
  members: [],
  accessRequests: [],

  loading: false,
};

export const soilSiteSlice = createSlice({
  name: 'soilSite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSite.fulfilled, (state, { payload }) => {
        state.info = payload;
        state.loading = false;
      })
      .addCase(updateSiteInfo.fulfilled, (state, { payload }) => {
        state.info = payload;
        state.loading = false;
      })
      .addCase(updateSiteImage.fulfilled, (state, { payload }) => {
        state.info.picture = payload;
        state.loading = false;
      })
      .addCase(transferOwnership.fulfilled, (state, { payload }) => {
        state.info = payload;
        state.loading = false;
      })

      .addMatcher(isPending(fetchSite), (state) => {
        state.loading = true;
      });
  },
});

export const {} = soilSiteSlice.actions;

export const selectSiteInfo = (state: RootState) => state.site.management.info;
export const selectSiteParticipants = (state: RootState) =>
  state.site.management.members;
export const selectSiteAccessRequests = (state: RootState) =>
  state.site.management.accessRequests;

export const selectIsLoading = (state: RootState) =>
  state.site.management.loading;

export default soilSiteSlice.reducer;

// thunks

export const fetchSite = createAsyncThunk<Site, number>(
  'soilSite/fetchSite',
  async (siteId, { rejectWithValue }) => {
    const { data, errors } = await soilSiteService.fetchSite(siteId);
    if (!data && errors) return rejectWithValue(errors);

    return data.soilSite;
  }
);

export const updateSiteInfo = createAsyncThunk<Site, Partial<Site>>(
  'soilSite/updateSiteInfo',
  async (site, { rejectWithValue }) => {
    const { data, errors } = await soilSiteService.updateSiteInfo(site);
    if (!data && errors) return rejectWithValue(errors);

    return data.soilSite;
  }
);

export const updateSiteImage = createAsyncThunk<
  string,
  string,
  { state: RootState }
>(
  'soilSite/updateSiteImage',
  async (picture, { rejectWithValue, getState }) => {
    const siteId = getState().site.management.info.id;
    const { data, errors } = await soilSiteService.updateSiteImage(
      siteId,
      picture
    );
    if (!data && errors) return rejectWithValue(errors);

    return picture;
  }
);

export const transferOwnership = createAsyncThunk<
  Site,
  number,
  { state: RootState }
>(
  'soilSite/transferOwnership',
  async (newOwnerId, { rejectWithValue, getState }) => {
    const siteId = getState().site.management.info.id;
    const { data, errors } = await soilSiteService.transferOwnership(
      siteId,
      newOwnerId
    );
    if (!data && errors) return rejectWithValue(errors);

    return data.soilSite;
  }
);

export const promoteToSoilMaker = createAsyncThunk<
  Partial<Site>,
  number,
  { state: RootState }
>(
  'soilSite/promoteToSoilMaker',
  async (userId, { rejectWithValue, getState }) => {
    const siteId = getState().site.management.info.id;
    const { data, errors } = await soilSiteService.promoteToSoilMaker(
      siteId,
      userId
    );
    if (!data && errors) return rejectWithValue(errors);

    return data.soilSite;
  }
);

export const demoteSoilMaker = createAsyncThunk<
  Partial<Site>,
  number,
  { state: RootState }
>('soilSite/demoteSoilMaker', async (userId, { rejectWithValue, getState }) => {
  const siteId = getState().site.management.info.id;
  const { data, errors } = await soilSiteService.demoteSoilMaker(
    siteId,
    userId
  );
  if (!data && errors) return rejectWithValue(errors);

  return data.soilSite;
});

export const removeParticipant = createAsyncThunk<
  number,
  number,
  { state: RootState }
>(
  'soilSite/removeParticipant',
  async (userId, { rejectWithValue, getState }) => {
    const siteId = getState().site.management.info.id;
    const { data, errors } = await soilSiteService.removeParticipant(
      siteId,
      userId
    );
    if (!data && errors) return rejectWithValue(errors);

    return userId;
  }
);

export const approveAccessRequest = createAsyncThunk<Partial<Site>, number>(
  'soilSite/approveAccessRequest',
  async (requestId, { rejectWithValue }) => {
    const { data, errors } = await soilSiteService.approveAccessRequest(
      requestId
    );
    if (!data && errors) return rejectWithValue(errors);

    return data.approveRequest.soilSite;
  }
);

export const declineAccessRequest = createAsyncThunk<any, number>(
  'soilSite/declineAccessRequest',
  async (requestId, { rejectWithValue }) => {
    const { data, errors } = await soilSiteService.declineAccessRequest(
      requestId
    );
    if (!data && errors) return rejectWithValue(errors);

    return data;
  }
);

// export const deactivateSoilSite = createAsyncThunk<
//   number,
//   string,
//   { state: RootState }
// >(
//   'soilSite/deactivateSoilSite',
//   async (reason, { rejectWithValue, getState }) => {
//     const siteId = getState().site.management.info.id;
//     const { data, errors } = await soilSiteService.deactivate(
//       siteId, reason
//     );
//     if (!data && errors) return rejectWithValue(errors);

//     return data;
//   }
// );
