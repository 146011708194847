import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div<SectionProps>`
  background-color: ${(props) => props.background || 'inherit;'}

  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: 400px;
  background-position: center 450px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-size: 500px;
    background-position: center 380px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-size: 700px;
    background-position: center 350px;
  }

  @media (min-width: 1440px) {
    background-size: 800px;
    background-position: center 400px;
  }

  padding: ${(props) => props.padding || '5rem 0;'}

  ${(props) => props.center && 'text-align: center;'}
  
  color: ${(props) => props.textColor || '#666;'}

  p {
    margin-top: 1rem;
  }
`;

type SectionProps = {
  background?: string;
  backgroundImage?: string;
  textColor?: string;
  padding?: string;
  center?: boolean;
};

export const Section: React.FC<SectionProps> = (props) => {
  return <SectionContainer {...props}>{props.children}</SectionContainer>;
};
