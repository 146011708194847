import { apolloClient } from '../../app/apolloClient';
import accessrequestService from './accessrequestService';
import {
  DEACTIVATE_SITE,
  DEMOTE_MEMBER,
  GET_SOIL_SITE,
  LEAVE_SITE,
  PROMOTE_SOIL_MAKER,
  REACTIVATE_SITE,
  REMOVE_MEMBER,
  REPORT_USER,
  TRANSFER_OWNERSHIP,
  UPDATE_SITE,
  UPDATE_SITE_IMAGE,
} from './graphql';
import { Site, SiteMember } from './model';

type FetchSiteResult = {
  soilSite: Site;
};

type MetaInfo = {
  message: string;
  errors: string[];
};

type SiteActionResult = {
  soilSite: Site;
} & MetaInfo;

type UpdateSiteResult = SiteActionResult;
type TransferOwnershipResult = SiteActionResult;

type ActiveStateUpdateResult = {
  soilSite: {
    id: number;
    active: boolean;
  };
} & MetaInfo;

type ParticipantRoleUpdateResult = {
  soilSite: {
    id: number;
    members: SiteMember[];
  };
} & MetaInfo;

type RemoveParticipantResult = MetaInfo;

class SoilSiteService {
  fetchSite = (id: number) => {
    return apolloClient.query<FetchSiteResult>({
      query: GET_SOIL_SITE,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  };

  updateSiteInfo = (siteData: Partial<Site>) => {
    return apolloClient.mutate<UpdateSiteResult>({
      mutation: UPDATE_SITE,
      variables: siteData,
    });
  };

  updateSiteImage = (siteId: number, picture: string) => {
    return apolloClient.mutate({
      mutation: UPDATE_SITE_IMAGE,
      variables: { siteId, picture },
    });
  };

  transferOwnership = (siteId: number, newOwnerId: number) => {
    return apolloClient.mutate<TransferOwnershipResult>({
      mutation: TRANSFER_OWNERSHIP,
      variables: { siteId, newOwnerId },
    });
  };

  promoteToSoilMaker = (siteId: number, userId: number) => {
    return apolloClient.mutate<ParticipantRoleUpdateResult>({
      mutation: PROMOTE_SOIL_MAKER,
      variables: { siteId, userId },
    });
  };

  demoteSoilMaker = (siteId: number, userId: number) => {
    return apolloClient.mutate<ParticipantRoleUpdateResult>({
      mutation: DEMOTE_MEMBER,
      variables: { siteId, userId },
    });
  };

  removeParticipant = (siteId: number, userId: number, reason?: string) => {
    return apolloClient.mutate<RemoveParticipantResult>({
      mutation: REMOVE_MEMBER,
      variables: { siteId, userId, reason },
    });
  };

  approveAccessRequest = (requestId: number) => {
    return accessrequestService.approveAccessRequest(requestId);
  };

  declineAccessRequest = (requestId: number, reason?: string) => {
    return accessrequestService.declineAccessRequest(requestId, reason);
  };

  deactivate = (siteId: number, reason: string) => {
    return apolloClient.mutate<ActiveStateUpdateResult>({
      mutation: DEACTIVATE_SITE,
      variables: { id: siteId, reason },
    });
  };

  reactivate = (siteId: number) => {
    return apolloClient.mutate<ActiveStateUpdateResult>({
      mutation: REACTIVATE_SITE,
      variables: { id: siteId },
    });
  };

  reportUser = (userId: number, flaggedContent: string, reason: string) => {
    return apolloClient.mutate<MetaInfo>({
      mutation: REPORT_USER,
      variables: { userId, flaggedContent, reason },
    });
  };

  leaveSite = (id: number) => {
    return apolloClient.mutate<ParticipantRoleUpdateResult>({
      mutation: LEAVE_SITE,
      variables: { id },
    });
  };
}

export default new SoilSiteService();
