import { Bounds, IMapItem, MapItemType, MapMarker, MarkerIcon } from './model';
import { isMobileApp, mobileGetCurrentGeoPosition } from '../../mobile/mobile';

export interface GeolocationPosition {
  coords: GeolocationCoordinates;
  timestamp: number;
}

export interface GeolocationCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export const mapSettings = {
  minZoom: 2,
  maxZoom: 18,
};

export const getCurrentLocation = () => {
  if (isMobileApp()) {
    return mobileGetCurrentGeoPosition();
  }

  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (!navigator.geolocation)
      reject('Geolocation is not supported by your browser');

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      },
      { enableHighAccuracy: true }
    );
  });
};

export const getMarkerIcon = (isHighlighted: boolean, marker: IMapItem) => {
  const small = new google.maps.Size(16, 16);
  let icon: MarkerIcon = { url: '', scaledSize: new google.maps.Size(50, 50) };
  switch (marker.type) {
    case MapItemType.SoilSite:
      icon.url = isHighlighted
        ? '/icons/soil-site-icon-highlighted.png'
        : '/icons/soil-site-icon.png';
      break;
    case MapItemType.SoilSupporter:
      icon.scaledSize = small;
      icon.url = isHighlighted
        ? '/icons/soil-supporter-icon-highlighted.png'
        : '/icons/soil-supporter-icon.png';
      break;
    case MapItemType.UnmatchedUser:
      icon.scaledSize = small;
      icon.url = isHighlighted
        ? '/icons/unmatched-user-icon-highlighted.png'
        : '/icons/unmatched-user-icon.png';
      break;
    case MapItemType.AccessPending:
      icon.scaledSize = small;
      icon.url = isHighlighted
        ? '/icons/pending-user-icon-highlighted.png'
        : '/icons/pending-user-icon.png';
      break;
  }
  return icon;
};

/**
 * Map MakeSoil Items (Soil Site, Soil Supporter etc) to Map Markers
 */
export const mapMarkers = (
  mapItems: IMapItem[],
  highlightedMapMarker?: number
): MapMarker[] => {
  return mapItems.map((item) => {
    const { id, location, type, name } = item;
    const isHighlighted = highlightedMapMarker
      ? highlightedMapMarker === id
      : false;
    return {
      id,
      name,
      location,
      type,
      icon: getMarkerIcon(isHighlighted, item),
    };
  });
};

export function boundsEqual(boundsA: Bounds, boundsB: Bounds) {
  return (
    boundsA.northEast.lat === boundsB.northEast.lat &&
    boundsA.northEast.lng === boundsB.northEast.lng &&
    boundsA.southWest.lat === boundsB.southWest.lat &&
    boundsA.southWest.lng === boundsB.southWest.lng
  );
}

/** Returns a bounds object based on the map passed in or
 * A default empty bounds is returned with null latitudes and longitudes
 * @param map the map to extract bounds from
 */
export function getBoundsFromMap(map: google.maps.Map): Bounds {
  if (!map) return emptyBounds();

  const bounds = map.getBounds();
  if (!bounds) return emptyBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return {
    southWest: { lat: sw.lat(), lng: sw.lng() },
    northEast: { lat: ne.lat(), lng: ne.lng() },
  };
}

export function emptyBounds() {
  return {
    southWest: { lat: null, lng: null },
    northEast: { lat: null, lng: null },
  };
}

export function isEmptyBounds(bounds) {
  if (bounds === undefined || bounds === null) {
    return true;
  }
  return (
    bounds.southWest.lat === null &&
    bounds.southWest.lng === null &&
    bounds.northEast.lat === null &&
    bounds.northEast.lng === null
  );
}
