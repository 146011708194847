import Chart from 'react-apexcharts';
import { Box } from '../../components/ui';

type Props = {
  soilSites: {
    total: number;
    active: number;
    deactivated: number;
    unlisted: number;
  };
};

export function SoilSiteBreakdown({ soilSites }: Props) {
  const { total, active, deactivated, unlisted } = soilSites;

  return (
    <Box padding="1rem">
      <div style={{ height: '400px' }}>
        <Chart
          options={{
            chart: {
              id: 'soil-sites-donut',
              type: 'donut',
            },
            colors: ['#5AB474', '#008FFB', '#FEB019'],
            title: {
              text: 'Soil Site Details',
              style: {
                fontSize: '18px',
              },
            },
            subtitle: {
              text: `Total soil sites: ${total}`,
              style: {
                fontSize: '14px',
              },
            },
            labels: ['Active', 'Deactivated', 'Unlisted'],
            legend: {
              position: 'left',
              offsetY: 60,
            },
            responsive: [
              {
                breakpoint: 576,
                options: {
                  chart: {
                    height: '85%',
                  },
                  legend: { show: false },
                },
              },
            ],
          }}
          series={[active, deactivated, unlisted]}
          type="donut"
          height="100%"
        />
      </div>
    </Box>
  );
}
