import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSoilSiteContext } from '../SoilSiteContext';
import { isMobile } from '../../../utils/helpers';
import { isMobileApp } from '../../../mobile/mobile';

const mobile = isMobile() || isMobileApp();

/** Control edit mode */
export function useEditMode() {
  const match = useRouteMatch();
  const history = useHistory();
  const { siteIdentifier } = useSoilSiteContext();

  const [editMode, setEditMode] = useState(match.path.includes('edit'));

  useEffect(() => {
    if (match.path.includes('edit')) {
      setEditMode(true);
    }
  }, [match.path]);

  const cancelEditMode = () => {
    setEditMode(false);
    history.push(
      `/my-soil-sites/${siteIdentifier}${mobile ? '?tab=info' : ''}`
    );
  };

  const enterEditMode = () => {
    history.push(
      `/my-soil-sites/${siteIdentifier}/edit${mobile ? '?tab=info' : ''}`
    );
  };

  return { editMode, enterEditMode, cancelEditMode };
}
