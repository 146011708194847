import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useMediaQuery } from '../../hooks/useMediaQuery';
import { queries } from '../../app/theme';
import { Tooltip } from './Tooltip';

export const IconButtonWithTooltip = ({
  tooltipText,
  icon,
  onClick,
  iconColor = '#68b74e',
}) => {
  const isMobile = useMediaQuery(queries.sm);

  const TheButton = (
    <IconButton onClick={onClick}>
      <FontAwesomeIcon icon={icon} color={iconColor} />
    </IconButton>
  );

  return isMobile ? (
    TheButton
  ) : (
    <Tooltip trigger={'mouseenter focus'} triggerElement={TheButton}>
      {tooltipText}
    </Tooltip>
  );
};

const IconButton = styled.span`
  border: none;
  outline: none;
  background-color: transparent !important;

  display: flex;
  align-items: center;

  img {
    height: 1rem;
  }

  cursor: pointer;
`;
