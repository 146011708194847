import styled from "styled-components";

export const Credits = styled.div<{
  align: "left" | "right";
  hide?: "sm" | "md" | "lg";
}>`
  position: absolute;
  bottom: 1rem;
  ${({ align }) => align}: 1rem;

  padding: 1rem;
  background-color: #24242494;
  border-radius: 4px;

  color: #dedede;

  a:hover {
    text-decoration: underline;
    color: #efefef;
  }

  strong {
    font-weight: bold;
  }

  ${({ hide }) =>
    hide &&
    hide === "sm" &&
    `
      @media (max-width: 576px) {
        display: none;
      }
  `};

  ${({ hide }) =>
    hide &&
    hide === "md" &&
    `
      @media (max-width: 768px) {
        display: none;
      }
  `};

  ${({ hide }) =>
    hide &&
    hide === "lg" &&
    `
      @media (max-width: 992px) {
        display: none;
      }
  `};

  @media (max-width: 992px) {
    font-size: 0.875rem;
    padding: 0.875rem;
  }
`;
