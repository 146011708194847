import styled from 'styled-components';

export function DropOffSuccess({ dropOffs = 1 }) {
  return (
    <>
      <div style={{ marginTop: '2rem', padding: '2rem', textAlign: 'center' }}>
        <SuccessBadge className="green-dark">
          <div className="circle">
            <span>
              {dropOffs}
              <span style={{ fontSize: '1.75rem' }}>st</span>
            </span>
          </div>
          <div className="ribbon">DROP-OFF{dropOffs > 1 && `S`}</div>
        </SuccessBadge>
      </div>

      <div style={{ marginTop: '2rem' }}>
        {dropOffs > 1 ? (
          <XDropOffsMessage count={dropOffs} />
        ) : (
          <FirstDropOffMessage />
        )}
      </div>
    </>
  );
}

function FirstDropOffMessage() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>
        Congratulations! You have recorded your first drop-off at this soil
        site.
      </h3>
      <p>Thank you for helping to repair the Earth, one drop-off at a time.</p>
      <p>
        Your nutrient-rich food scraps will soon become the healthy new soil we
        need to grow more local food.
      </p>
    </div>
  );
}

function XDropOffsMessage({ count }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Congratulations!</h2>
      <h3>You have now recorded {count} drop-offs at this soil site.</h3>
      <p>Thank you for helping to repair the Earth, one drop-off at a time.</p>
    </div>
  );
}

const SuccessBadge = styled.div`
  position: relative;
  width: 10rem;
  height: 6.2rem;
  border-radius: 10px;
  display: inline-block;
  top: 0;
  transition: all 0.2s ease;

  animation: main 1s;

  &:before,
  &:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  &:before {
    animation: rotate 1.5s;
    transform: rotate(60deg);
  }
  &:after {
    transform: rotate(-60deg);
    animation: rotate2 2s;
  }
  &:hover {
    top: -4px;
  }

  .circle {
    width: 6rem;
    height: 6rem;
    position: absolute;
    background: #fff;
    z-index: 10;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2.75rem;
    font-weight: bold;

    animation: circle 3s;
  }
  .font {
    display: inline-block;
    margin-top: 1em;
  }

  .ribbon {
    position: absolute;
    border-radius: 4px;
    padding: 5px 5px 4px;
    width: 7rem;
    z-index: 11;
    color: #fff;
    bottom: 0;
    left: 50%;
    margin-left: -3.5rem;
    text-align: center;
    font-size: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    cursor: default;

    animation: ribbon 4s;
  }

  &.green-dark {
    background: linear-gradient(
      to bottom right,
      ${({ theme }) => theme.colors.primary} 0%,
      #1b5e20 100%
    );
    color: #00944a;
  }

  @keyframes main {
    0% {
      transform: rotate(-60deg) scale(0);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }

  @keyframes circle {
    0% {
      transform: rotate(-180deg) scale(0);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(0.25);
    }
    100% {
      transform: rotate(60deg) scale(1);
    }
  }

  @keyframes rotate2 {
    0% {
      transform: rotate(0deg) scale(0.25);
    }
    100% {
      transform: rotate(-60deg) scale(1);
    }
  }

  @keyframes ribbon {
    0% {
      transform: rotate(60deg) scale(0);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;
