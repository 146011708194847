import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';

import { FormError } from '../../components/ui';
import { Meta } from '../../components/Meta';
import { AuthPageLayout } from '..';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  errorCleared,
  loginWithMagicId,
  selectAuthError,
} from '../../features/auth/authSlice';
import { useAuthUser } from '../../features/auth/useAuthUser';

type RouteParams = {
  magicId: string;
};

const Login: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();
  const loginError = useAppSelector(selectAuthError);
  const history = useHistory();

  useEffect(() => {
    (async function tryLoginWithMagicId() {
      if (authUser.isLoggedIn) {
        history.replace('/');
        return;
      } else {
        if (match.params.magicId) {
          const { magicId } = match.params;

          const result = await dispatch(loginWithMagicId(magicId)).unwrap();
          if (result.ok) {
            history.replace('/');
            return;
          }
        }
      }
    })();
  }, [authUser.isLoggedIn, dispatch, history, match.params]);

  const handleGoToLogin = () => {
    dispatch(errorCleared());
    history.push('/login');
  };

  return (
    <AuthPageLayout contentContainerStyle={{ justifyContent: 'center' }}>
      <Container>
        <Meta>
          <title>Log In | MakeSoil</title>
        </Meta>

        {loginError ? (
          <>
            <FormError style={{ marginTop: '1rem', width: 'unset' }}>
              {loginError}
            </FormError>
            <span
              onClick={handleGoToLogin}
              style={{
                display: 'inline-block',
                marginTop: '2.5rem',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              <small>Go to Log In</small>
            </span>
          </>
        ) : (
          <div style={{ marginBottom: '1rem' }}>
            <p>Logging you in...</p>
            {/* <Loader /> */}
          </div>
        )}
      </Container>
    </AuthPageLayout>
  );
};

export default withRouter(Login);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    padding: 0 !important;
    margin-top: 1.5rem;
  }
`;
