import Rollbar from 'rollbar';
import { credentials } from './config';

const ROLLBAR_CONFIG = {
  // Rollbar permits public visibility of this token
  accessToken: credentials.get('ROLLBAR'),
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_BRANCH,
  },
};

let _rollbar: Rollbar = null;

export const initializeRollbar = () => {
  const init_rollbar = Rollbar.init(ROLLBAR_CONFIG);
  _rollbar = init_rollbar;
  return ((window as any).Rollbar = init_rollbar);
};

export const rollbar = _rollbar;
