import React from "react";
import {GoogleMap, GoogleMapProps} from "@react-google-maps/api";
import {isMobileApp} from "../mobile/mobile";
import styled from "styled-components";

// puts a transparent overlay at the bottom of the map
// to prevent google urls from opening within the app
const GoogleMapNoLinks: React.FC<GoogleMapProps> = (props) => {
  return (<>
    <GoogleMap {...props}/>
    {isMobileApp() && <MapOverlay/>}
  </>);
};

const MapOverlay = styled.div`
  background-color: transparent;
  position:relative;
  width:100%;
  height:35px;
  top:-35px;
`;

export default GoogleMapNoLinks;


