import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Flex, FormInputError, Input } from '../components/ui';
import { MobileAppBadges } from '../components/MobileAppBadges';
import { Credits } from '../components/ui/Credits';
import { isMobileApp } from '../mobile/mobile';
import { Meta } from '../components/Meta';
import { useAuthUser } from '../features/auth/useAuthUser';
import { useEffect, useState } from 'react';
import { isEmail, validationMessages } from '../utils/formValidation';
import { logFailedSignup } from '../features/auth/logFailedSignup';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';

const Home = () => {
  const authUser = useAuthUser();

  if (authUser.isLoggedIn) return <Redirect to="/my-soil-sites" />;

  return (
    <PageContainer>
      <Meta>
        <title>Get started | MakeSoil</title>
      </Meta>
      <Hero>
        <BoxContainer>
          <h1>REPAIR THE EARTH</h1>

          <p>Give your food scraps to a Soil Maker near you.</p>

          <p>Or become a Soil Maker and compost your neighbors' food scraps.</p>

          <GetStarted />

          <AppBadges />
        </BoxContainer>

        <Credits align="right" hide="sm">
          Image from{' '}
          <a
            href="https://www.felixx.nl/projects/isle-of-dikes.html"
            target="_blank"
            rel="noreferrer"
          >
            Felixx
          </a>
        </Credits>
      </Hero>
    </PageContainer>
  );
};

function AppBadges({ style }: { style?: any }) {
  if (isMobileApp()) return null;

  return (
    <StyledAppBadges
      style={style}
      badgeStyle={{ width: '150px' }}
      popupStyle={{
        width: '150px',
        height: '150px',
        top: '-150px',
        right: '0',
      }}
    />
  );
}

export default Home;

const PageContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Hero = styled.div`
  position: relative;
  min-height: calc(100vh - 61px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 1rem;

  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01)),
    url('/images/felixx.jpg') center no-repeat;

  // background: url('/images/map.png') center no-repeat,
  //   ${({ theme }) => theme.colors.primary};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  text-align: center;
  color: #fff;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.25rem;
  }

  p {
    margin: 0;
    margin-top: 1rem;
    font-size: 1.25rem;
    line-height: 1.35;
    font-weight: 500;

    max-width: 510px;
  }

  h1,
  p {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    h1 {
      margin-top: 2rem;
      font-size: 3.5rem;
    }

    p {
      font-size: 1.5rem;
      padding: 0;
    }
  }
`;

const StyledAppBadges = styled(MobileAppBadges)`
  margin: 2rem 0 0;

  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 3rem 0 0;
  }
`;

function GetStarted() {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const handleSubmit = ({ email }) => {
    history.push(`/signup?email=${encodeURIComponent(email)}`);
  };

  const validateForm = ({ email }: { email: string }) => {
    let errors: FormikErrors<{ email: string }> = {};
    if (!isEmail(email)) {
      errors.email = validationMessages.email;
    }

    return errors;
  };

  // user closes the tab or refreshes the page
  useEffect(() => {
    const handleLogFormData = (event?: any) => {
      // if we have email log it
      if (isEmail(email)) {
        logFailedSignup(email);
      }

      return '';
    };

    window.addEventListener('beforeunload', handleLogFormData);

    return () => window.removeEventListener('beforeunload', handleLogFormData);
  }, [email]);

  return (
    <GetStartedContainer>
      <Formik
        initialValues={{
          email: '',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => {
          return (
            <Form>
              <Flex direction="column">
                <Field
                  as={Input}
                  name="email"
                  placeholder="Type your email"
                  style={{ height: '2.5rem' }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setFieldValue('email', e.target.value);
                  }}
                />

                <ErrorMessage name="email" component={FormInputError} />

                <Button
                  type="submit"
                  style={{
                    marginTop: '1rem',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                  }}
                >
                  GET STARTED
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>

      <FlexContainer style={{ marginTop: '1rem' }}>
        <Divider>OR</Divider>

        <Button
          style={{
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
          variant="secondary"
          onClick={() => history.push('/map')}
        >
          EXPLORE THE MAP
        </Button>
      </FlexContainer>
    </GetStartedContainer>
  );
}

const GetStartedContainer = styled.div`
  margin-top: 2rem;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 2rem;
  }

  input {
    ::placeholder {
      font-style: normal;
    }
  }
`;

const BoxContainer = styled.div`
  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2rem 3rem;
  }

  background-color: rgba(36, 36, 36, 0.7);
  border-radius: 4px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Divider = styled.div`
  font-size: 0.825rem;
  font-weight: bold;
  white-space: nowrap;
  // color: ${({ theme }) => theme.colors.primary};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  ::before,
  ::after {
    content: '';
    width: 50%;
    height: 1px;
    background-color: #fff; //${({ theme }) => theme.colors.primary};
  }

  ::before {
    left: 0;
  }

  ::after {
    right: 0;
  }
`;
