import { useLoadScript } from '@react-google-maps/api';
import { credentials } from '../utils/config';

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

/**
 * Loads the Google Maps script
 * @returns
 */
export const useGoogleMaps = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: credentials.get('MAPS_API_KEY'),
    libraries,
  });

  return {
    isMapLoaded: isLoaded,
    mapLoadError: loadError,
  };
};
