import React from 'react';
import styled, { CSSProperties } from 'styled-components';

type LoaderProps = {
  absolute?: boolean;
  wrapperStyles?: CSSProperties;
};

export const Loader: React.FC<LoaderProps> = ({ absolute, wrapperStyles }) => {
  return (
    <LoaderWrapper absolute={absolute} style={{ ...wrapperStyles }}>
      <LoaderGrid>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoaderGrid>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div<{ absolute?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 20;

  ${({ absolute }) =>
    absolute &&
    `
      position: absolute;
      top: 0;
      left: 0;
    `}
`;

const LoaderGrid = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #68b74e;
    animation: grid 1.2s linear infinite;
  }
  div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;
