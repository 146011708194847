export const isMobile = () =>
  window.matchMedia('only screen and (max-width: 576px)').matches &&
  (window.matchMedia('(any-pointer:coarse)').matches ||
    'maxTouchPoints' in Navigator);

export const getMobileDevice = () => {
  const ua = navigator.userAgent;
  const regexAndroid = /Android/i;
  const regexIOS = /iPhone|iPad|iPod/i;

  if (regexAndroid.test(ua)) return 'Android';
  if (regexIOS.test(ua)) return 'iOS';
};

export interface IOrderable {
  order: number;
}

export const getPercentage = (amount: number, total: number) =>
  (100 * (amount / total)).toFixed(2);

export const sortByOrder = (a: IOrderable, b: IOrderable) => {
  return a.order - b.order;
};

export function splitCamelCase(text: string): string {
  // Capitalize the first letter
  text = text.charAt(0).toUpperCase() + text.slice(1);
  // Add space between camel casing
  return text.replace(/([0-9A-Z])/g, ' $&');
}

export const sortByProperty = (
  a: object,
  b: object,
  property: string,
  asc: boolean = true
) => {
  let reverse = asc ? 1 : -1;
  if (a[property] > b[property]) {
    return 1 * reverse;
  } else if (b[property] > a[property]) {
    return -1 * reverse;
  } else {
    return 0;
  }
};

export const getUserDisplayName = (firstName?: string, lastName?: string) => {
  if (lastName) return `${firstName} ${lastName.split('').shift()}.`;
  return firstName || 'Anonymous';
};

export const highlightChunkOfText = (textToHighlight: string, text: string) => {
  const startIndex = text.toLowerCase().indexOf(textToHighlight.toLowerCase());
  const startString = text.substring(0, startIndex);
  const highlighted = text.substring(
    startIndex,
    startIndex + textToHighlight.length
  );
  const endString = text.substring(
    startIndex + textToHighlight.length,
    startIndex + textToHighlight.length + text.length
  );

  return {
    startString,
    highlighted,
    endString,
  };
};

export const copyToClipboard = (text) => {
  return new Promise(async (resolve, reject) => {
    try {
      await navigator.clipboard.writeText(text);
      resolve(true);
    } catch (e) {
      // fallback code for older browsers.
      const textArea = document.createElement('textarea');
      textArea.contentEditable = 'true';
      textArea.value = text;
      document.body.appendChild(textArea);

      // older ios
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
      try {
        document.execCommand('copy');
        resolve(true);
      } catch (e) {
        reject(e);
      } finally {
        document.body.removeChild(textArea);
      }
    }
  });
};

export const isElementOffScreen = (element: Element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.x + rect.width < 0 ||
    rect.y + rect.height < 0 ||
    rect.x > window.innerWidth ||
    rect.y > window.innerHeight
  );
};

export const hasEnoughWords = (text: string, minimumWordCount: number) => {
  return (
    text.split(' ').filter((element) => Boolean(element)).length >=
    minimumWordCount
  );
};

export const isTextLongEnough = (text: string, minimumLength: number) =>
  text.length >= minimumLength;

/**
 * Trim the string values on an object
 * @param values object to trim string properties on
 * @returns object wit trimmed values
 */
export const trimFormValues = <T extends object>(values: T) => {
  Object.keys(values).map((k) => {
    if (typeof values[k] === 'string') {
      values[k] = values[k].trim();
    }
    return values[k];
  });
  return values;
};

export const capitalizeWord = (word: string) => {
  return word[0].toUpperCase() + word.substring(1);
};

export const capitalizeSentence = (sentence: string) => {
  const words = sentence.split(' ');
  return words.map((word) => word[0].toUpperCase() + word.substr(1)).join(' ');
};

type PluralizeOptions = {
  singular: string;
  plural: string;
};

export function pluralize(count: number, word: string);
export function pluralize(count: number, options: PluralizeOptions);
export function pluralize(count: number, options: PluralizeOptions | string) {
  const isPlural = count > 1 || count === 0;

  if (typeof options === 'string') {
    return isPlural ? `${options}s` : options;
  }

  return isPlural ? options.plural : options.singular;
}

export const viewportToPixels = (value) => {
  var parts = value.match(/([0-9\.]+)(vh|vw)/);
  var q = Number(parts[1]);
  var side =
    window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]];
  return side * (q / 100);
};

export function containsPhoneNumber(text: string) {
  const reg = /\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m;
  return reg.test(text);
}

export function containsEmailAddress(text: string) {
  const reg = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return reg.test(text);
}
