import React, { Fragment } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IMapItem } from '../model';

import { useAppSelector } from '../../../app/hooks';
import {
  selectSearchResults,
  selectSearchTerm,
  selectResultsVisible,
} from '../mapSlice';

import { highlightChunkOfText } from '../../../utils/helpers';

export type ResultListSelectionState = {
  resultType: 'makesoil' | 'places';
  selectedIndex: number;
};

type SearchResultsProps = {
  handleGooglePlaceSelected: (
    place: google.maps.places.AutocompletePrediction
  ) => void;
  handleMakeSoilSearchItemSelected: (resultItem: IMapItem) => void;
  resultSelection: ResultListSelectionState;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  handleGooglePlaceSelected,
  handleMakeSoilSearchItemSelected,
  resultSelection,
}) => {
  const searchTerm = useAppSelector(selectSearchTerm);
  const results = useAppSelector(selectSearchResults);
  const resultsVisible = useAppSelector(selectResultsVisible);

  if (!resultsVisible) return null;
  const { predictions: places, makesoil } = results;

  const reorderedPlaces = reorderPlaces(places.slice());

  return (
    <SearchResultsContainer
      layout
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        open: { opacity: 1, height: 'auto' },
        collapsed: { opacity: 0, height: 0 },
      }}
      transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
    >
      {!!reorderedPlaces.length && (
        <div>
          <h5>Locations</h5>
          <ul>
            {reorderedPlaces.map((place, i) => (
              <ResultContainer
                key={place.place_id}
                onClick={() => handleGooglePlaceSelected(place)}
                onKeyDown={() => handleGooglePlaceSelected(place)}
                active={
                  resultSelection.resultType === 'places' &&
                  resultSelection.selectedIndex === i
                }
              >
                {getIcon('map-marker-alt')}{' '}
                <span className="mapItemName">{place.description}</span>
              </ResultContainer>
            ))}
          </ul>
        </div>
      )}
      {!!makesoil.length && (
        <div style={{ overflowY: 'auto', borderTop: '1px solid #dedede' }}>
          <h5>Soil Sites</h5>
          <ul>
            {makesoil.map((result, i) => (
              <ResultContainer
                key={result.id}
                onClick={() => handleMakeSoilSearchItemSelected(result)}
                onKeyDown={() => handleMakeSoilSearchItemSelected(result)}
                active={
                  resultSelection.resultType === 'makesoil' &&
                  resultSelection.selectedIndex === i
                }
              >
                <span className="mapItemName">
                  {searchTerm
                    ? highlightSearchQueryInName(searchTerm, result.name)
                    : result.name}
                </span>
              </ResultContainer>
            ))}
          </ul>
        </div>
      )}
    </SearchResultsContainer>
  );
};

function reorderPlaces(
  places: google.maps.places.AutocompletePrediction[],
  searchTerm?: string
) {
  // check if the exact match is not moved up the array
  // in case of "India" search it's at the second place [1]
  if (!searchTerm) return places;

  const indexOfExactMatch = places.findIndex(
    (p) =>
      p.structured_formatting.main_text.toLowerCase() ===
      searchTerm.toLowerCase()
  );
  if (indexOfExactMatch > 0) {
    // remove the exact match and put it back at the beginning
    const exactMatch = places.splice(indexOfExactMatch, 1)[0];
    return places.splice(0, 0, exactMatch);
  }
  return places;
}

function highlightSearchQueryInName(query: string, name: string) {
  if (!query) return <span>{name}</span>;

  const { startString, highlighted, endString } = highlightChunkOfText(
    query,
    name
  );
  return (
    <Fragment>
      <span>{startString}</span>
      <span style={{ fontWeight: 'bold' }}>{highlighted}</span>
      <span>{endString}</span>
    </Fragment>
  );
}

function getIcon(icon: IconProp) {
  // if we passed the icon lets return fontawesome Icon
  if (icon) return <FontAwesomeIcon icon={icon} />;

  // otherwise it's a Soil Site Image for now
  // could be different depending on MapItemType
  return <img src="/icons/soil-site-icon.svg" alt="" />;
}

const SearchResultsContainer = styled(motion.div)`
  position: absolute;
  margin-top: 1rem;
  width: 315px;
  max-height: 70vh;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02);

  font-size: 0.875rem;
  h5 {
    margin: 0;
    margin-top: 1rem;
    padding: 0 1rem;
    font-size: 0.7rem;
    color: #888;
  }

  hr {
    border: none;
    border-bottom: 1px solid #e2e2e2;
  }
`;

export const ResultContainer = styled(motion.li)<{ active: boolean }>`
  display: flex;
  align-items: center;

  padding: 0.5rem 1rem;
  line-height: 30px;

  :hover {
    background-color: rgb(242, 242, 242) !important;
    cursor: pointer;
  }

  ${({ active }) =>
    active && `background-color: rgb(242, 242, 242) !important;`}

  img {
    height: 1.15rem;
  }

  span.mapItemName {
    margin-left: 0.5rem;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
