import React, { Fragment } from 'react';
import { Grid } from '../../components/ui';
import { InsightBox } from './InsightBox';
import { SparklineProps } from './SparkLine';
import { impactCalculator } from '../impact/impactCalculator';
import { DropOff } from '../site/dropoff/model';
import { formatWeight } from '../../utils/formatting';

interface ImpactData {
  ghg: SparklineProps;
  waste: SparklineProps;
  soilProduced: SparklineProps;
}

export interface ImpactProps {
  dropOffs: DropOff[];
}

export const Impact: React.FC<ImpactProps> = ({ dropOffs }) => {
  const { foodWasteSaved, ghg, soilCreated } = impactCalculator.impact(
    dropOffs
  );

  return (
    <Fragment>
      <Grid col="repeat(2, 1fr)" gap="1rem" style={{ marginTop: '1rem' }}>
        <InsightBox
          title="Drop-offs recorded"
          value={dropOffs.length}
          icon="file-signature"
          color="#5AB474"
        />
        <InsightBox
          title="Nutrient-rich scraps saved from landfill"
          value={formatWeight(foodWasteSaved)}
          icon="dumpster"
          color="#5AB474"
        />
        <InsightBox
          title="GHG emissions prevented"
          value={formatWeight(ghg)}
          icon="smog"
          color="#008FFB"
        />
        <InsightBox
          title="New soil created (or that will be)"
          value={formatWeight(soilCreated)}
          icon="seedling"
          color="#82671F"
        />
        {/* <Box>
            <Sparkline {...total.waste} type="area" />
          </Box>
          <Box>
            <Sparkline {...total.ghg} type="area" />
          </Box>
          <Box>
            <Sparkline {...total.soilProduced} type="area" />
          </Box> */}
      </Grid>
    </Fragment>
  );
};
