import { useState, useEffect } from 'react';

/**
 * Debounces the specified value
 * @param value a value to return at the specified delay
 * @param delay delay in milliseconds
 */
export const useDebounce = <T>(value: T, delay: number = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};
