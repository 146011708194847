import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { logout } from '../auth/authSlice';
import { IUserProfile } from './model';
import userProfileService, {
  DeleteUserAccountResponse,
} from './userProfileService';

type ProfileSliceState = {
  profile: IUserProfile;
  loading: boolean;
  error: string;
};

const initialState: ProfileSliceState = {
  profile: null,
  loading: true,
  error: '',
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.profile = {
          ...state.profile,
          ...payload,
        };
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.profile = {
          ...state.profile,
          ...payload,
        };
      })
      .addCase(updateUserProfilePhoto.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.profile = {
          ...state.profile,
          picture: payload,
        };
      })
      // clear the profile when user logs out
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
        state.profile = null;
      })
      .addCase(deleteMyAccount.fulfilled, (state) => {
        state.loading = false;
        state.error = '';
        state.profile = null;
      })

      .addMatcher(
        isPending(fetchUserProfile, updateUserProfile, updateUserProfilePhoto),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(fetchUserProfile, updateUserProfile, updateUserProfilePhoto),
        (state, action) => {
          console.log('rejected', action);
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(
          fetchUserProfile,
          updateUserProfile,
          updateUserProfilePhoto
        ),
        (state, action) => {
          console.log('rejected', action);
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export const selectUserProfile = (state: RootState) =>
  state.userProfile.profile;
export const selectProfileLoading = (state: RootState) =>
  state.userProfile.loading;
export const selectProfileError = (state: RootState) => state.userProfile.error;

export default userProfileSlice.reducer;

export const fetchUserProfile = createAsyncThunk<IUserProfile, number>(
  'userProfile/fetchUserProfile',
  async (userId, { rejectWithValue }) => {
    const { data, errors } = await userProfileService.getUserProfile(userId);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.profile;
  }
);

export const updateUserProfile = createAsyncThunk<IUserProfile, IUserProfile>(
  'userProfile/updateUserProfile',
  async (profile, { rejectWithValue }) => {
    const { data, errors } = await userProfileService.updateUserProfile(
      profile
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.updateUserProfile;
  }
);

export const updateUserProfilePhoto = createAsyncThunk<string, string>(
  'userProfile/updateUserProfilePhoto',
  async (photo, { rejectWithValue }) => {
    const { data, errors } = await userProfileService.updateUserProfilePhoto(
      photo
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.picture;
  }
);

export const deleteMyAccount = createAsyncThunk<DeleteUserAccountResponse>(
  'userProfile/deleteMyAccount',
  async (_, { rejectWithValue }) => {
    const { data, errors } = await userProfileService.deleteMyAccount();
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }
    return data;
  }
);
