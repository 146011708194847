import { toast } from 'react-toastify';
import { Modal, Button } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';
import { SiteMember } from '../model';

import { useSiteManager } from '../hooks';
import { useSoilSiteContext } from '../SoilSiteContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  participant: SiteMember;
};

export const DemoteSoilMaker: React.FC<Props> = ({
  isOpen,
  onClose,
  participant,
}) => {
  const { soilSite } = useSoilSiteContext();
  const { demote: demoteSoilMaker } = useSiteManager(soilSite.id);

  const handleDemoteSoilMaker = async () => {
    try {
      const { data } = await demoteSoilMaker(participant);

      if (data && data.demoteSoilMaker.errors.length > 0) {
        toast.error(`${data.promoteToSoilMaker.errors[0]}`);
      }

      if (data && data.demoteSoilMaker.soilSite) {
        toast.success(
          `User ${participant.firstName} has been successfuly demoted.`
        );
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(handleDemoteSoilMaker);

  return (
    <Modal
      header="Demote Soil Maker"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          DEMOTE SOIL MAKER
        </Button>
      }
    >
      <p>
        Are you sure you want to remove {participant.firstName} as a Soil Maker?
      </p>
      <p>
        They will go back to being a Soil Supporter, and no longer be able to
        manage your soil site and its participants alongside you.
      </p>
    </Modal>
  );
};
