/*
 The mobileApp loads the React Web App and passes messages via PostMessage to the main cordova window.

 Message flow:
 - cordova ready => mobile app load in browser
 - react app loaded and 'load' route dispatched => react app send 'loaded'
 - mob receive 'loaded' => send 'storage-set' and 'app-loaded'
 - react receive 'storage-set' => check for initial load, reload on true (using updated localStorage)
 - react receive 'app-loaded' => initialize the app (logi, register push, ...)
 - on app initialized react send 'app-ready' => mobile app hides splash
 - on user login react send 'push-start' => mobile app start push registration
 - mob app completes push registration => send 'push-register' passing push token
 - react receive 'push-register' => store push token on server

*/

import {
  mobileGetAuthUser,
  mobileRedirectTo,
  mobileReload,
  mobileResume,
  registerDevice,
  unRegisterDevice,
  GeolocationPosition,
  userAgent,
} from './MobileHelper';
import { DeferredPromise } from '../utils/deferred-promise';
import { storage } from '../utils/storage';

const msgTag = 'appmsg:';
const msgTagLength = msgTag.length;
let detectedMobileApp: boolean | undefined = undefined;
let lastActiveTime = 0;

const STORAGE_KEY_PUSH_REGISTRATION_ID = 'pushRegistrationId';

// for now, assume the mobile app is running if the web app was loaded via the /load uri
export function isMobileApp() {
  if (detectedMobileApp === undefined) {
    detectedMobileApp = navigator.userAgent.includes(userAgent);
  }
  return detectedMobileApp;
}

const postMessage = (msg) => {
  // prettier-ignore
  // @ts-ignore
  const target = typeof cordova_iab === 'object' ? cordova_iab : webkit.messageHandlers.cordova_iab;
  target.postMessage(JSON.stringify({ msg: msgTag + msg }));
};

export type PushRegistration = {
  type: string;
  token: string;
};

type MobileEventAppLog = {
  event: 'app-log';
  log: string;
};

type MobileEventAppLoaded = {
  event: 'app-loaded';
  key: string;
};

type MobileEventAppReady = {
  event: 'app-ready';
};

type MobileEventAppResuming = {
  event: 'app-resuming';
};

type MobileEventPushStart = {
  event: 'push-start';
};

type MobileEventPushRegistration = {
  event: 'push-register';
  registration: PushRegistration;
};

type MobileEventPushNotificationTapped = {
  event: 'push-tapped';
  payload: string;
};

type MobileEventSetBadge = {
  event: 'badge-set';
  count: number;
};

type MobileEventGetStorage = {
  event: 'storage-get';
  storage: unknown;
};

type MobileEventSetStorage = {
  event: 'storage-set';
  storage: unknown;
};

type MobileEventGeoLoationGet = {
  event: 'geolocation-get';
};

type MobileEventGeoLoationSet = {
  event: 'geolocation-set';
  geoposition?: GeolocationPosition;
  error?: string;
};

type MobileEventOpenUrl = {
  event: 'open-url';
  url: string;
};

type MobileEventAppReload = {
  event: 'app-reload';
};

type MobileEventRefreshDone = {
  event: 'refresh-done';
};

type MobileEventShare = {
  event: 'share';
  url: string;
  title: string;
};

type MobileEvent =
  | MobileEventAppLog
  | MobileEventAppLoaded
  | MobileEventAppReady
  | MobileEventAppResuming
  | MobileEventPushStart
  | MobileEventPushRegistration
  | MobileEventPushNotificationTapped
  | MobileEventSetBadge
  | MobileEventGetStorage
  | MobileEventSetStorage
  | MobileEventGeoLoationGet
  | MobileEventGeoLoationSet
  | MobileEventOpenUrl
  | MobileEventAppReload
  | MobileEventRefreshDone
  | MobileEventShare;

// called on App start
export function initializeMobile() {
  // install an eventListener to receive messages from the parent mobile app
  if (typeof window !== 'undefined') {
    window.addEventListener('message', async (event) => {
      if (typeof event.data === 'string' && event.data.startsWith(msgTag)) {
        const data = event.data.substring(msgTagLength);
        //        console.log(`Message from ${event.origin} data=${data}`);
        try {
          const msg = JSON.parse(decodeURIComponent(data));
          mobileDispatchMessage(msg);
        } catch (e) {
          console.log(
            `Invalid message from ${event.origin} => ${event.data} err=`,
            e
          );
        }
      }
    });

    // disable pinch to zoom, https://github.com/apache/cordova-ios/issues/1054
    document.addEventListener(
      'gesturestart',
      (event) => {
        event.preventDefault();
      },
      false
    );
  }
}

// called on app load (<baseurl>:/load/:key)
export async function mobileSetToken(_token: string) {
  //  console.log("Received token: " + token);
  postMessage('loaded');
}

// dispatch a received message
function mobileDispatchMessage(msg: MobileEvent) {
  // console.log(`AppRcv: ${msg.event}`);
  switch (msg.event) {
    case 'app-loaded':
      void mobileReceivedAppLoaded(msg);
      break;
    case 'app-resuming':
      void mobileReceivedAppResuming(msg);
      break;
    case 'push-register':
      void mobileReceivedPushRegistration(msg);
      break;
    case 'push-tapped':
      void mobileReceivedPushNotificationTapped(msg);
      break;
    case 'storage-set':
      void mobileReceivedStorageSet(msg);
      break;
    case 'geolocation-set':
      void mobileReceivedGeoLocation(msg);
      break;
  }
}

// log a message to the app console, these show in xcode-debug
export function appLog(_msg) {
  // console.log(msg);
  // void sendAppMessage({event: 'app-log', log: msg});
}

// mobile app has loaded
async function mobileReceivedAppLoaded(_msg: MobileEventAppLoaded) {
  //  console.log("App loaded key: " + msg.key);
  void sendAppMessage({ event: 'app-ready' });
  const authUser = mobileGetAuthUser();
  if (authUser && authUser.isLoggedIn) {
    void mobilePushStart();
  } else {
    console.log('No logged in user => not starting MobPush');
  }
}

// mobile app has resumed, update local badge count
async function mobileReceivedAppResuming(_msg: MobileEventAppResuming) {
  //  console.log("App resuming");
  lastActiveTime = new Date().getTime();
  appLog(`Set lastActiveTime=${lastActiveTime}`);
  mobileResume();
}

// register push target
async function mobileReceivedPushRegistration(
  msg: MobileEventPushRegistration
) {
  const result = await registerDevice(msg.registration);
  const registrationId = result?.data?.createPushRegistration;
  if (registrationId) {
    storage.local.set(STORAGE_KEY_PUSH_REGISTRATION_ID, registrationId);
    appLog(
      `Register push token:${msg.registration.token} => ${registrationId}`
    );
  } else {
    appLog(
      `Register push token:${msg.registration.token} => ERROR: ${JSON.stringify(
        result
      )}`
    );
  }
}

export async function mobileRemovePushRegistration() {
  const registrationId = storage.local.get(STORAGE_KEY_PUSH_REGISTRATION_ID);
  if (registrationId) {
    await unRegisterDevice(registrationId);
    appLog(`Removed push token ${registrationId}`);
    storage.local.remove(STORAGE_KEY_PUSH_REGISTRATION_ID);
  }
}

// perform push action
async function mobileReceivedPushNotificationTapped(
  msg: MobileEventPushNotificationTapped
) {
  // delay processing of push notification to check if the app will become active
  setTimeout(() => {
    // redirect only when the app recently became active
    const appRecentlyBecameActive =
      new Date().getTime() < lastActiveTime + 5000;
    appLog(
      `Tapped pushed notification (redirect=${
        appRecentlyBecameActive ? 'YES' : 'NO'
      }): ${JSON.stringify(msg.payload)}`
    );
    if (msg.payload === 'refresh' || appRecentlyBecameActive) {
      mobileRedirectTo(msg.payload);
    }
  }, 50);
}

// set storage received from mobile
async function mobileReceivedStorageSet(msg) {
  //  console.log("Set storage: " + JSON.stringify(msg.storage));
  // make sure any existing (cached) storage is removed
  //  console.log("Set storage: " + JSON.stringify(msg.storage));
  window.localStorage.clear();
  window.sessionStorage.clear();
  // set localstorage
  Object.keys(msg.storage).forEach((k) => {
    window.localStorage[k] = msg.storage[k];
  });
  mobileReload();
}

// save current local storage
export async function mobileSaveStorage() {
  const msg: MobileEventSetStorage = {
    event: 'storage-set',
    storage: { ...window.localStorage },
  };
  //  console.log("Saving storage: " + JSON.stringify(msg.storage));
  void sendAppMessage(msg);
}

let geoPromise: DeferredPromise<GeolocationPosition> = undefined;

export async function mobileGetCurrentGeoPosition(
  sendMsg = true
): Promise<GeolocationPosition> {
  appLog(
    `Get currentLocation... (geoPromise=${geoPromise ? 'set' : 'undefined'})`
  );
  if (!geoPromise || !geoPromise.pending) {
    appLog('Get currentLocation => new promise');
    geoPromise = new DeferredPromise<GeolocationPosition>(
      5000,
      'Geolocation could not be determined'
    );
  } else {
    appLog('Get currentLocation => pending promise');
  }
  if (sendMsg) {
    void sendAppMessage({ event: 'geolocation-get' });
  }
  return geoPromise.promise;
}

async function mobileReceivedGeoLocation(msg) {
  appLog(
    `Receive currentLocation (geoPromise=${geoPromise ? 'set' : 'undefined'})`
  );
  if (!geoPromise || !geoPromise.pending) {
    void mobileGetCurrentGeoPosition(false);
  }
  if (msg.geoposition) {
    appLog(`Got location: ${JSON.stringify(msg.geoposition)}`);
    geoPromise.resolve(msg.geoposition);
  } else {
    appLog(`Failed getting location: ${JSON.stringify(msg)}`);
    geoPromise.reject(msg.error);
  }
}

/**
 * notify that the app is ready to register for push notifications
 */
export function mobilePushStart() {
  return sendAppMessage({ event: 'push-start' });
}

// set the badge count
export function mobileSetBadge(count: number) {
  return sendAppMessage({ event: 'badge-set', count: count });
}

// notify that background refresh is completed
export function mobileNotifyRefreshCompleted() {
  return sendAppMessage({ event: 'refresh-done' });
}

// open a URL in the default browser
export function mobileOpenUrl(url: string) {
  return sendAppMessage({ event: 'open-url', url });
}

// open a URL in the default browser
export function mobileAppReload() {
  return sendAppMessage({ event: 'app-reload' });
}

// webShare
export function mobileShare(title: string, url: string) {
  return sendAppMessage({ event: 'share', title, url });
}

// send a message to the mobile app
async function sendAppMessage(data: MobileEvent) {
  if (isMobileApp()) {
    postMessage(encodeURIComponent(JSON.stringify(data)));
  }
}
