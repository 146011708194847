import { apolloClient } from '../../app/apolloClient';

import { Site } from '../site/model';
import { CREATE_SOIL_SITE } from './graphql';
import { CreateSiteInput } from './model';

type CreateSiteResult = {
  createSite: Site;
};

class SiteWizardService {
  createSite(input: CreateSiteInput) {
    const {
      address,
      addressComponents,
      googlePlaceId,
      location,
      description,
      access,
      dropOff,
      hours,

      firstName,
      lastName,

      phoneNum,
      twitter,
      instagram,
      facebook,
      linkedin,
    } = input;

    return apolloClient.mutate<CreateSiteResult>({
      mutation: CREATE_SOIL_SITE,
      variables: {
        ...input,
        description: description.trim(),
        access: access.trim(),
        dropOff: dropOff.trim(),
        hours: hours.trim(),

        firstName: firstName.trim(),
        lastName: lastName.trim(),

        phoneNum: phoneNum.trim(),
        twitter: twitter.trim(),
        instagram: instagram.trim(),
        facebook: facebook.trim(),
        linkedin: linkedin.trim(),
        address: {
          address,
          addressComponents,
          placeId: googlePlaceId,
        },
        location,
      },
    });
  }
}

export default new SiteWizardService();
