import React, { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { TermsOfUseModal } from '../../../pages/auth/TermsOfUseModal';
import { SiteMember, IAccessRequest } from '../model';

import { DeactivateSiteModal } from '../modals/DeactivateSiteModal';
import { DemoteSoilMaker } from '../modals/DemoteSoilMakerModal';
import { InviteModal, InviteModalTab } from '../modals/InviteModal';
import { LeaveSiteModal } from '../modals/LeaveSiteModal';
import { PromoteSoilMakerModal } from '../modals/PromoteSoilMakerModal';
import { ReactivateSiteModal } from '../modals/ReactivateSiteModal';
import { RemoveMember } from '../modals/RemoveMemberModal';
import { ReviewAccessRequestModal } from '../modals/ReviewAccessRequestModal';
import { TransferOwnershipModal } from '../modals/TransferOwnershipModal';
import { RegisterDropOffModal } from '../dropoff/DropOffModal';
import { ILocation } from '../../../app/model/ILocation';
import { DirectionsModal } from '../modals/DirectionsModal';
import { EditDropOffPayload } from '../dropoff/model';
import { EditDropOffModal } from '../dropoff/EditDropOffModal';

type ActionType =
  | {
      type: 'TRANSFER_OWNERSHIP';
      participant: SiteMember;
    }
  | {
      type: 'PROMOTE_MEMBER';
      participant: SiteMember;
    }
  | {
      type: 'DEMOTE_MEMBER';
      participant: SiteMember;
    }
  | {
      type: 'REMOVE_MEMBER';
      participant: SiteMember;
    }
  | {
      type: 'DEACTIVATE_SITE';
    }
  | {
      type: 'REACTIVATE_SITE';
    }
  | {
      type: 'LEAVE_SITE';
    }
  | {
      type: 'INVITE';
      tab?: InviteModalTab;
    }
  | {
      type: 'REGISTER_DROPOFF';
    }
  | {
      type: 'EDIT_DROPOFF';
      dropOff: EditDropOffPayload;
    }
  | {
      type: 'REVIEW_ACCESS_REQUEST';
      accessRequest: IAccessRequest;
    }
  | {
      type: 'GET_DIRECTIONS';
      destination: ILocation;
      destinationName: string;
    }
  | {
      type: 'TERMS_OF_USE';
    }
  | { type: 'CLOSE_MODAL' };

export function useSiteModal() {
  const history = useHistory();

  const modalReducer = (modal: React.ReactNode, action: ActionType) => {
    const onClose = () => {
      dispatch({ type: 'CLOSE_MODAL' });
    };

    switch (action.type) {
      case 'TRANSFER_OWNERSHIP':
        return (
          <TransferOwnershipModal
            isOpen={true}
            onClose={onClose}
            participant={action.participant}
          />
        );
      case 'PROMOTE_MEMBER':
        return (
          <PromoteSoilMakerModal
            isOpen={true}
            onClose={onClose}
            participant={action.participant}
          />
        );
      case 'DEMOTE_MEMBER':
        return (
          <DemoteSoilMaker
            isOpen={true}
            onClose={onClose}
            participant={action.participant}
          />
        );
      case 'REMOVE_MEMBER':
        return (
          <RemoveMember
            isOpen={true}
            onClose={onClose}
            participant={action.participant}
          />
        );
      case 'LEAVE_SITE':
        return <LeaveSiteModal isOpen={true} onClose={onClose} />;
      case 'DEACTIVATE_SITE':
        return <DeactivateSiteModal isOpen={true} onClose={onClose} />;
      case 'REACTIVATE_SITE':
        return (
          <ReactivateSiteModal
            isOpen={true}
            onClose={() => {
              history.push('/my-soil-sites');
            }}
          />
        );
      case 'INVITE':
        return <InviteModal isOpen={true} onClose={onClose} tab={action.tab} />;
      case 'REVIEW_ACCESS_REQUEST':
        return (
          <ReviewAccessRequestModal
            isOpen={true}
            onClose={onClose}
            accessRequest={action.accessRequest}
          />
        );

      case 'REGISTER_DROPOFF':
        return <RegisterDropOffModal isOpen={true} onClose={onClose} />;
      case 'EDIT_DROPOFF':
        return (
          <EditDropOffModal
            isOpen={true}
            onClose={onClose}
            dropOff={action.dropOff}
          />
        );
      case 'TERMS_OF_USE':
        console.log('dispatched ToU!');
        return <TermsOfUseModal isOpen={true} onClose={onClose} />;

      case 'GET_DIRECTIONS':
        return (
          <DirectionsModal
            destination={action.destination}
            destinationName={action.destinationName}
            isOpen={true}
            onClose={onClose}
          />
        );

      case 'CLOSE_MODAL':
        return null;
    }
  };

  const [modal, dispatch] = useReducer(modalReducer, null);

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const transferOwnership = useCallback((participant: SiteMember) => {
    dispatch({ type: 'TRANSFER_OWNERSHIP', participant });
  }, []);

  const promoteToSoilMaker = useCallback((participant: SiteMember) => {
    dispatch({ type: 'PROMOTE_MEMBER', participant });
  }, []);

  const demoteMember = useCallback((participant: SiteMember) => {
    dispatch({ type: 'DEMOTE_MEMBER', participant });
  }, []);

  const removeMember = useCallback((participant: SiteMember) => {
    dispatch({ type: 'REMOVE_MEMBER', participant });
  }, []);

  const deactivateSite = useCallback(() => {
    dispatch({ type: 'DEACTIVATE_SITE' });
  }, []);

  const reactivateSite = useCallback(() => {
    dispatch({ type: 'REACTIVATE_SITE' });
  }, []);

  const leaveSite = useCallback(() => {
    dispatch({ type: 'LEAVE_SITE' });
  }, []);

  const invite = useCallback((tab?: InviteModalTab) => {
    dispatch({ type: 'INVITE', tab });
  }, []);

  const reviewAccessRequest = useCallback((accessRequest: IAccessRequest) => {
    dispatch({
      type: 'REVIEW_ACCESS_REQUEST',
      accessRequest,
    });
  }, []);

  const registerDropOff = useCallback(() => {
    dispatch({ type: 'REGISTER_DROPOFF' });
  }, []);

  const editDropOff = useCallback((dropOff: EditDropOffPayload) => {
    dispatch({ type: 'EDIT_DROPOFF', dropOff });
  }, []);

  const getDirections = useCallback((destination, destinationName) => {
    dispatch({ type: 'GET_DIRECTIONS', destination, destinationName });
  }, []);

  const termsOfUse = useCallback(() => {
    dispatch({ type: 'TERMS_OF_USE' });
  }, []);

  return {
    modal,
    closeModal,
    openModal: {
      transferOwnership,
      promoteToSoilMaker,
      demoteMember,
      removeMember,
      deactivateSite,
      reactivateSite,
      leaveSite,
      invite,
      reviewAccessRequest,
      termsOfUse,
      registerDropOff,
      editDropOff,
      getDirections,
    },
  };
}
