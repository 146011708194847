import styled from 'styled-components';
import { Button } from '../../../components/ui';
import { mapMarkers, mapSettings } from '../../map/utils';
import { MapItemType } from '../../map/model';
import { StaticMap } from '../../../components/StaticMap';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useHistory } from 'react-router-dom';
import { generateSiteIdentifier } from '../../../utils/url';

export const ParticipationMap = () => {
  const history = useHistory();

  const { soilSite } = useSoilSiteContext();
  const { id, location, isUnlisted } = soilSite;

  const showViewOnMap = !isUnlisted;

  const markers = mapMarkers([
    { id, location, type: MapItemType.SoilSite, name: '' },
  ]);

  return (
    <StaticMapContainer>
      <StaticMap
        markers={markers}
        center={location}
        style={{ borderRadius: '8px' }}
      />
      {showViewOnMap && (
        <ViewOnMap
          variant="outlined"
          icon="map-marked-alt"
          onClick={() =>
            history.push(
              `/map/site/${generateSiteIdentifier(id, soilSite.name)}?z=${
                mapSettings.maxZoom
              }`
            )
          }
        >
          View on Map
        </ViewOnMap>
      )}
    </StaticMapContainer>
  );
};

const StaticMapContainer = styled.div`
  min-height: 250px;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
`;

const ViewOnMap = styled(Button)`
  position: absolute;
  bottom: 1rem;
  right: 0.65rem;
  background-color: #fff !important;

  // z-index: 5;
`;
