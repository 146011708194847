import { toast } from 'react-toastify';
import { CreatePasswordForm } from '../../components/CretePasswordForm';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { fetchMe } from '../../features/auth/authSlice';
import { useAuthUser } from '../../features/auth/useAuthUser';

export function CreatePassword() {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  const onPasswordCreated = () => {
    dispatch(fetchMe(authUser.id));
    toast.success('Your password has been created successfuly.');
  };

  return (
    <PasswordFormContainer>
      <CreatePasswordForm onPasswordCreated={onPasswordCreated} />
    </PasswordFormContainer>
  );
}

const PasswordFormContainer = styled.div`
  padding 1rem;

  form {
    label {
      display: inline-block;
      margin-bottom: 0.25rem;
    }
  }
`;
