import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 1.5rem;
  width: 1.5rem;

  cursor: pointer;
  font-size: 22px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // :hover input ~ .checkmark {
  //   background-color: #f4f4f4;
  // }

  input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    z-index: 2;

    :checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    :disabled ~ .checkmark {
      background-color: grey;
    }

    :disabled {
      cursor: default !important;
    }

    :checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark {
    display: block;
    height: 1.5rem;
    width: 1.5rem;

    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: ${({ theme }) => theme.borderRadius}px;
    color: #495057;

    :after {
      left: 0.5rem;
      top: 0.25rem;
      width: 0.5rem;
      height: 0.875rem;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    transition: border-color 0.25s ease-in-out;
  }
`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  value?: any;
  onChange?: (e: any) => void;
};

export const Checkbox: React.FC<Props> = (props) => {
  const { className, style } = props;
  return (
    <CheckboxContainer className={className} style={style}>
      <input type="checkbox" {...props} />
      <span className="checkmark" />
    </CheckboxContainer>
  );
};
