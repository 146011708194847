import { useState } from 'react';
import { apolloClient } from '../../../app/apolloClient';
import { isSiteOwner, isSoilMaker } from '../../../utils/site';
import { useAuthUser } from '../../auth/useAuthUser';
import { isAdmin } from '../../auth/utils';
import { useSoilSiteContext } from '../SoilSiteContext';
import { GET_SOIL_SITE } from '../graphql';
import { PostType, SitePost } from './model';
import { useMessageBoard } from './useMessageBoard';

export function usePost(post) {
  const authUser = useAuthUser();
  const { soilSite } = useSoilSiteContext();

  const {
    clearUnusedAttachments,
    updateSitePost,
    deleteSitePost,
  } = useMessageBoard();

  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const isAuthor = post.author.id === authUser.id;
  const isSoilSiteOwner = isSiteOwner(soilSite.members, authUser.id);
  const isMaker = isSoilMaker(soilSite.members, authUser.id);
  const isAuthorAnOwner = isSiteOwner(soilSite.members, post.author.id);
  // const isAuthorAdmin = author.role === 'Admin';

  const isSystemEventPost = [PostType.USER_JOINED, PostType.USER_LEFT].includes(
    post.type
  );

  const canEdit = isAuthor && !isSystemEventPost;
  // const canPin = isOwner;
  // Owner can delete any message and Soil Maker can delete except Owner's messages
  const canDelete =
    !isSystemEventPost &&
    (isAuthor ||
      isSoilSiteOwner ||
      (isMaker && !isAuthorAnOwner) ||
      isAdmin(authUser));

  const handleUpdateMessage = async (message: SitePost) => {
    await updateSitePost(message);
    toggleEditMode();
  };

  const handleDeleteMessage = async (id: number) => {
    await deleteSitePost(id);
    if (post.type === PostType.DROPOFF) {
      apolloClient.refetchQueries({
        include: [GET_SOIL_SITE],
      });
    }
  };

  const handleCancelEdit = () => {
    toggleEditMode();
    clearUnusedAttachments();
  };

  return {
    editMode,
    toggleEditMode,
    handleCancelEdit,
    handleUpdateMessage,
    handleDeleteMessage,

    canEdit,
    canDelete,
  };
}
