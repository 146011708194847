import { toast } from 'react-toastify';
import { NotificationToast } from './components/NotificationToast';
import { notify } from './notificationCentreSlice';

export const toaster = (store) => (next) => (action) => {
  switch (action.type) {
    case notify.type:
      toast.success(<NotificationToast notification={action.payload} />, {
        closeOnClick: false,
        pauseOnHover: false,
      });

      break;

    default:
      break;
  }

  return next(action);
};
