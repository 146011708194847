import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { SoilSiteView } from '../features/site/SiteView';
import { getIdFromIdentifier } from '../utils/url';
import { useQuery } from '@apollo/client';
import { Loader, ErrorComponent } from '../components/ui';
import { GET_SOIL_SITE } from '../features/site/graphql';
import { SoilSiteContextProvider } from '../features/site/SoilSiteContext';

type RouteParams = {
  siteIdentifier: string;
};

const SoilSite: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { siteIdentifier } = match.params;

  const siteId = getIdFromIdentifier(siteIdentifier);

  const { data, loading, error } = useQuery(GET_SOIL_SITE, {
    variables: { id: siteId },
    fetchPolicy: 'network-only',
  });

  if (loading && !data) return <Loader />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <PageContainer>
      <SoilSiteContextProvider
        soilSite={data.soilSite}
        siteIdentifier={siteIdentifier}
      >
        <SoilSiteView />
      </SoilSiteContextProvider>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export default SoilSite;
