import { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  generateSiteIdentifier,
  generateSiteUrl,
  getHashFromId,
  getIdFromIdentifier,
  getQueryParameter,
} from '../utils/url';
import { Meta } from '../components/Meta';
import { Button } from '../components/ui';
import { Section } from '../components/ui/Section';
import { SITE_ROLE } from '../features/site/model';
import { SEND_INVITATION } from '../features/site/graphql';
import { SiteRoleSelector } from '../features/site/invitation/SiteRoleSelector';
import { getPoster, SitePoster } from '../features/site/components/SitePoster';
import { InviteByEmail } from '../features/site/invitation/InviteByEmail';
import { InviteByLink } from '../features/site/invitation/InviteByLink';

const GetPeopleInvolved = (props) => {
  const [siteRole, setSiteRole] = useState(SITE_ROLE.SUPPORTER);

  const [sendEmailInvitation] = useMutation(SEND_INVITATION);

  const { location, history } = props;

  // coming from a welcome email
  const siteIdHash = getQueryParameter(location.search, 'hash');
  const siteName = getQueryParameter(location.search, 'name');

  // coming from the create site wizard
  let site = location.state?.site;
  if (!site) {
    if (!siteIdHash && !siteName) {
      return <Redirect to="/" />;
    }
    site = {
      id: getIdFromIdentifier(siteIdHash),
      name: siteName,
    };
  }

  const inviteUrl = site
    ? `${generateSiteUrl(site.id, site.name)}`
    : window.location.origin;

  const sendInvitation = async (emails, message) => {
    try {
      if (emails && emails.length > 0) {
        const { data } = await sendEmailInvitation({
          variables: {
            emails,
            message,
            siteRole,
            siteId: site.id,
          },
        });

        const { error } = data.sendEmailInvitation;
        if (error) return toast.error(error);

        return toast.success(
          `Invitation${emails.length > 1 ? 's' : ''} sent succesfully!`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //
  // should we rather use a Link component?
  const goToSoilSite = () => {
    history.push(
      `/my-soil-sites/${generateSiteIdentifier(site.id, site.name)}`
    );
  };

  return (
    <PageWrapper>
      <Meta>
        <title>{site.name} | MakeSoil</title>
      </Meta>
      <Header>
        <Container>
          <h1>Congratulations!</h1>
          <h3>
            You've just become a <strong>Soil Maker</strong>
          </h3>
          <button onClick={() => goToSoilSite()}>GO TO YOUR SOIL SITE</button>
        </Container>
      </Header>

      <Row background="#fefefe">
        <Column order={2}>
          <div>
            <Step>
              STEP <span>1</span>
            </Step>
            <h3>Print your customized soil site poster</h3>
            <p>
              Laminate if needed, then put up somewhere nice and visible at your
              soil site. This turns visitors and passers-by into Soil
              Supporters.
            </p>
            <p>
              You can also print two to a page and use it as a flyer to hand out
              in your neighborhood.
            </p>
            <Button
              onClick={() => getPoster(siteIdHash || getHashFromId(site.id))}
            >
              GET POSTER
            </Button>
          </div>
        </Column>
        <BackgroundImage order={1} image={'/images/blurred_garden.jpg'}>
          <SitePoster
            siteIdHash={siteIdHash || getHashFromId(site.id)}
            siteName={site.name}
          />
        </BackgroundImage>
      </Row>

      <Row background="#f2f2f2">
        <Column>
          <div>
            <ScreenshotImage
              src="/images/screenshot.png"
              title="Soil Site Image"
            />
          </div>
        </Column>
        <Column pt="0rem">
          <div
            style={{
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Step>
              STEP <span>2</span>
            </Step>
            <h3>Share the link to your soil site</h3>
            <p>
              E.g. In local Facebook groups and on neighborhood sites like{' '}
              <a href="https://nextdoor.com" target="_blank" rel="noreferrer">
                Nextdoor
              </a>
              .
            </p>
            <p className="mb-5">
              Invite family, neighbors, colleagues and friends - plus local
              coffee shops, juice bars and restaurants - to contribute scraps.
            </p>
            <InviteByLink inviteUrl={inviteUrl} />
            <HelpText>
              You can also share the link to your soil site anytime by copying
              the browser's URL, as the image shows.
            </HelpText>
          </div>
        </Column>
      </Row>

      <Row background="#fefefe">
        <Column pt="0rem" order={2}>
          <div style={{ flex: '0 0 100%' }}>
            <Step>
              STEP <span>3</span>
            </Step>
            <h3>Invite people by email</h3>
            <br />
            <InviteByEmail onSend={sendInvitation}>
              <SiteRoleSelector
                siteRole={siteRole}
                onSiteRoleChange={(ut) => setSiteRole(ut)}
              />
            </InviteByEmail>
          </div>
        </Column>
        <Column order={1}>
          <CommunityImage
            src="/images/onboarding-join-ms-community.svg"
            title=""
          />
        </Column>
      </Row>

      <Section center background="#f2f2f2" padding="2rem 0;">
        <Container>
          <Button onClick={() => goToSoilSite()}>GO TO YOUR SOIL SITE</Button>
        </Container>
      </Section>
    </PageWrapper>
  );
};

export default GetPeopleInvolved;

//#region styled components

const PageWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

const Container = styled.div`
  max-width: 50rem;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 26rem;
  }

  h3 {
    font-weight: lighter;
  }
`;

const Header = styled.div`
  padding-top: 4rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-top: 2rem;
  }

  position: relative;

  background-color: #55a46c;
  background-image: url('/images/onboarding-join-ms-transformation.svg');
  background-repeat: no-repeat;
  background-size: 950px;
  background-position: center 130px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-position: center 175px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    background-position: center 150px;
  }

  height: 360px;

  text-align: center;
  color: #fff;

  h1,
  h3 {
    font-weight: lighter;
    margin: 1rem 0;
  }

  h1 {
    font-size: 2.75rem;
  }

  button {
    display: none;
    cursor: pointer;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      padding: 0.75rem 1.5rem;
      background-color: rgba(256, 256, 256, 0.25);
      font-size: 0.9rem;
      color: #fff;
      border: none;
      border-radius: 4px;
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  }
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 1rem;
  font-size: 3rem;

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-left: 0.75rem;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    font-size: 2rem;
    height: 2.5rem;
    width: 2.5rem;
    font-weight: bold;
    color: #fff;
  }
`;

const Row = styled.div<{ background?: string; backgroundImage?: string }>`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
  background-color: ${(props) => props.background || 'inherit;'};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: 50%;
`;

const Column = styled.div<{ center?: boolean; order?: number; pt?: string }>`
  ${(props) => props.center && 'text-align: center;'}
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50%;
  max-width: 50%;
  padding: 5rem;
  color: #676767;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    max-width: 100%;
    order: ${(props) => props.order};
    padding: 4rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 4rem 2rem;
    ${(props) => props.pt && `padding-top: ${props.pt}`};
  }

  p {
    margin-top: 1rem;
  }

  a {
    text-decoration: underline;
  }
`;

const BackgroundImage = styled(Column)<{ image: string }>`
  background-color: #f2f2f2;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScreenshotImage = styled.img`
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: auto;
`;

const CommunityImage = styled.img`
  width: 120%;
  height: auto;
`;

const HelpText = styled.p`
  font-size: 0.8rem;
  padding-top: 1rem;
  font-style: italic;
`;

//#endregion
