import { gql } from '@apollo/client';

export const PREMIUM_ENQUIRY = gql`
  mutation PremiumEnquiry($input: PremiumEnquiryInput!) {
    premiumEnquiry(input: $input) {
      errors
      message
    }
  }
`;
