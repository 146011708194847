import styled from 'styled-components';
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useRef, useState } from 'react';
import { _isProd } from '../../../utils/url';
import { usePaymentIntent } from './usePaymentIntent';

type Donation = {
  label: string;
  currency: string;
  amount: number;
};

const paymentLabel =
  'MakeSoil Donation' + (!_isProd ? " (test/staging: won't be charged)" : '');

type Props = {
  amounts: number[];
  defaultAmount?: number;
};

export function DonateForm({ amounts, defaultAmount = 100 }: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeVisible, setStripeVisible] = useState(false);
  const [didPay, setDidPay] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentAmount, setCurrentAmount] = useState(defaultAmount);

  const donationRef = useRef<Donation>({
    label: paymentLabel,
    currency: 'usd',
    amount: currentAmount * 100,
  });

  const { createPaymentIntent } = usePaymentIntent();

  const handleUpdateCurrentAmount = (amount: number) => {
    donationRef.current = {
      ...donationRef.current,
      amount: amount * 100,
    };
    elements.update({ amount: amount * 100, mode: 'payment' });
    setCurrentAmount(amount);
  };

  const handleOtherAmountInputKeyPress = (e: any) => {
    const length = e.currentTarget.value.length;
    if (
      isNaN(parseInt(e.key)) ||
      (length === 0 && e.key === '0') ||
      length >= 5
    ) {
      e.preventDefault();
    }
  };

  const handleOtherAmountInputChange = (e: any) => {
    const value = parseInt(e.target.value);
    const newValue = Math.min(
      99999,
      Math.max(0, Number.isNaN(value) ? 0 : value)
    );
    if (newValue !== value) {
      document.querySelector('#input-other')['value'] =
        newValue === 0 ? '' : newValue.toString();
    }

    handleUpdateCurrentAmount(newValue > 0 ? newValue : defaultAmount);
    setCurrentAmount(newValue > 0 ? newValue : defaultAmount);
  };

  const handleStripeReady = ({ availablePaymentMethods }) => {
    if (!availablePaymentMethods) {
      // No buttons will show
    } else {
      setStripeVisible(true);
    }
  };

  const handleConfirmPayment = async () => {
    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }
    const { client_secret } = await createPaymentIntent(donationRef.current);

    // Confirm the PaymentIntent using the details collected by the Express Checkout Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: client_secret,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.host + '/soil-sponsors',
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      setErrorMessage(error.message);
    } else {
      // The payment UI automatically closes with a success animation.
      // Your customer is redirected to your `return_url`.
      setDidPay(true);
    }
  };

  return (
    <AmountPickerContainer
      style={{ display: stripeVisible ? 'block' : 'none' }}
    >
      <AmountsGrid>
        {amounts.map((amount) => (
          <label
            className={
              currentAmount === amount
                ? 'amount-preset selected'
                : 'amount-preset'
            }
            key={`donate-${amount}`}
          >
            <input
              type="radio"
              name="amount"
              value={amount}
              checked={currentAmount === amount}
              onChange={() => handleUpdateCurrentAmount(amount)}
            />
            <span>$ {amount}</span>
          </label>
        ))}
      </AmountsGrid>

      <CustomAmountContainer>
        <span>$</span>
        <input
          id="input-other"
          type="number"
          min={5}
          max={99999}
          step={5}
          placeholder="Custom amount"
          onKeyPress={handleOtherAmountInputKeyPress}
          onChange={handleOtherAmountInputChange}
        />
      </CustomAmountContainer>

      <div>
        {didPay && (
          <span className={'payment-done'}>Thank you for your donation!</span>
        )}

        <ExpressCheckoutElement
          onConfirm={handleConfirmPayment}
          onReady={handleStripeReady}
          options={{ buttonType: { applePay: 'donate', googlePay: 'donate' } }}
        />
        {errorMessage && <div>{errorMessage}</div>}
      </div>
    </AmountPickerContainer>
  );
}

const AmountPickerContainer = styled.div`
  width: 90vw;
  margin-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 425px;
  }

  padding: 2rem;
  background-color: #fff;
  border-radius: 4px;

  .payment-state {
    transition: opacity 0.25s ease-in-out;
  }

  .payment-done {
    color: black;
  }

  .payment-failed {
    color: red;
  }

  .payment-button {
    width: 100%;
    margin-top: 0.5rem;
    transition: opacity 0.25s ease-in-out;
  }

  input[type='number'] {
    border: none;
    text-align: center;
    background-color: #0000;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    width: 100%;
  }

  input[type='number']:focus {
    outline: none;
  }

  input[type='radio'] {
    display: none;
  }
`;

const AmountsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.75rem;

  .amount-preset {
    display: inline-block;
    text-align: center;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1em;

    cursor: pointer;

    &.selected {
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }
`;

const CustomAmountContainer = styled.div`
  display: flex;
  margin: 0.75rem 0 1rem;
  padding: 10px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1em;
`;
