import { apolloClient } from '../../app/apolloClient';
import { CREATE_PUSH_REGISTRATION, REMOVE_PUSH_REGISTRATION } from './graphql';

class PushRegistrationService {
  register(registration: object) {
    return apolloClient.mutate({
      mutation: CREATE_PUSH_REGISTRATION,
      variables: {
        registration,
      },
      fetchPolicy: 'no-cache',
    });
  }

  remove(registrationId: number) {
    return apolloClient.mutate({
      mutation: REMOVE_PUSH_REGISTRATION,
      variables: {
        registrationId,
      },
      fetchPolicy: 'no-cache',
    });
  }
}

export default new PushRegistrationService();
