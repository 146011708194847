import { useEffect, useRef, useState } from 'react';
import { useMessenger } from '../useMessenger';
import { fetchMoreMessages } from '../messengerSlice';
import { useAppDispatch } from '../../../app/hooks';

/**
 * Handle thread scrolling behaviors
 * @param messageLoadLimit
 */
export function useScrollHandler({ messageLoadLimit }) {
  const dispatch = useAppDispatch();
  const { currentThread: thread } = useMessenger();

  const [threadWindowHeight, setThreadWindowHeight] = useState(0);
  const [moreFetched, setMoreFetched] = useState(false);

  const threadRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const fetchMoreWhenScrolledToTop = (e) => {
    // check whether the user scrolled to top
    // and whether we already loaded enough messages to trigger loadMore
    if (
      e.target.scrollTop === 0 &&
      thread.messages.length >= messageLoadLimit
    ) {
      dispatch(
        fetchMoreMessages({
          threadId: thread.id,
          cursor: thread.messages[thread.messages.length - 1].createdAt,
        })
      );
      setMoreFetched(true);
    }
  };

  // handle scroll on load and thread change
  const scrollToBottom = (behavior = 'smooth' as 'smooth' | 'auto') => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior });
      setThreadWindowHeight(threadRef.current?.scrollHeight);
    }, 50);
  };

  useEffect(() => {
    (function scrollOnMessageReceived() {
      if (moreFetched) {
        // loaded more messages (user scrolled to the top)
        setTimeout(() => {
          const heightBeforeRender = threadWindowHeight;
          const currentScrollHeight = threadRef.current?.scrollHeight;
          // scroll to where the first message would be
          // (to the height of the container before messages got added)
          if (currentScrollHeight && currentScrollHeight > heightBeforeRender) {
            threadRef.current.scroll({
              top: currentScrollHeight - heightBeforeRender,
              behavior: 'auto',
            });
          }
        }, 100);
      } else {
        // we just sent/received a message
        scrollToBottom();
      }
    })();
  }, [thread, moreFetched, threadWindowHeight]);

  return {
    threadRef,
    bottomRef,
    fetchMoreWhenScrolledToTop,
  };
}
