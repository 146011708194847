import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';

import { Meta } from '../../components/Meta';
import { Button, Flex, Input, TextArea } from '../../components/ui';
import { Footer, GreenShell } from '../../components/GreenShell';
import { AuthPageLayout } from '../../pages';
import { SecondaryButton } from '../../pages/auth/AuthPageLayout';
import { Field, Form, Formik } from 'formik';

const HOW_DID_YOU_LEARN = gql`
  mutation SetHowDidYouLearn($text: String!) {
    setHowDidYouLearn(text: $text) {
      message
    }
  }
`;

export default function HowDidYouHearPage() {
  const history = useHistory();

  const [setHowDidYouLearn] = useMutation(HOW_DID_YOU_LEARN);

  const nextStep = () => {
    history.push('/onboarding/create-password', history.location.state);
  };

  const handleSubmit = async ({ howDidYouLearn }, { setSubmitting }: any) => {
    if (!howDidYouLearn) return;

    await setHowDidYouLearn({ variables: { text: howDidYouLearn } });

    setSubmitting(false);
    nextStep();
  };

  return (
    <AuthPageLayout
      contentContainerStyle={{ justifyContent: 'center' }}
      onClose={() => history.push('/map')}
    >
      <Meta>
        <title>How did you hear? | MakeSoil</title>
      </Meta>

      <Container>
        <h2>How did you hear about MakeSoil?</h2>
        <p>So we know who to thank.</p>
      </Container>

      <Formik
        initialValues={{ howDidYouLearn: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              as={TextArea}
              placeholder="How did you hear?"
              maxLength={250}
              resize="vertical"
              name="howDidYouLearn"
            />

            <Flex direction="column" gap="1rem" style={{ marginTop: '1.5rem' }}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                CONTINUE
              </Button>

              <SecondaryButton onClick={nextStep}>SKIP</SecondaryButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </AuthPageLayout>
  );
}

const Container = styled.div`
  padding: 0 1rem 2rem;
`;
