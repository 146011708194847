import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';

import { Flex } from './Flex';

type DropMeduProps = {
  trigger?: 'mouseenter focus' | 'click' | 'mouseenter click' | 'focusin';
  triggerElement: React.ReactElement;
  hideOnClickInside?: boolean;
  hideOnClickOutside?: boolean;
  placement?: 'top' | 'right' | 'bottom' | 'left';
};

export const DropMenu: React.FC<DropMeduProps> = (props) => {
  const {
    children,
    triggerElement,
    trigger = 'click',
    hideOnClickInside = true,
    hideOnClickOutside = true,
    placement = 'bottom',
  } = props;

  const triggerElementRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const handleInsideClick = () => {
    if (hideOnClickInside) hide();
  };

  const handleOutsideClick = () => {
    if (hideOnClickOutside) hide();
  };

  // fix the width of the MenuContainer when the width of the trigger element is set
  // see https://trello.com/c/HpSE6DKi
  if (menuWidth === 0 && triggerElementRef?.current) {
    const triggerWidth = triggerElementRef.current.getBoundingClientRect()
      .width;
    if (triggerWidth) {
      setMenuWidth(triggerWidth);
    }
  }

  // Either set trigger or visible property on Tippy. Both causes a warning.
  const controlProps = props.trigger ? { trigger } : { visible };

  return (
    <MenuContainer width={menuWidth}>
      <Tippy
        interactive={true}
        animation="shift-away"
        content={
          <div onClick={handleInsideClick}>
            <TipWrapper direction="column">{children}</TipWrapper>
          </div>
        }
        placement={placement}
        theme="light"
        onClickOutside={handleOutsideClick}
        {...controlProps}
      >
        <span
          ref={triggerElementRef}
          onClick={visible ? hide : show}
          style={{ cursor: 'pointer' }}
        >
          {triggerElement}
        </span>
      </Tippy>
    </MenuContainer>
  );
};

const TipWrapper = styled(Flex)`
  width: 100%;
  font-weight: 100;
  font-size: 0.875rem;

  cursor: pointer;
  font-size: 0.875rem;
  color: #727272;
  transition: background ${({ theme }) => theme.timing}s ease;

  > span {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0.675rem 1rem;

    svg {
      width: 1rem !important;
      margin-right: 0.5rem;
      color: #ababab;

      transition: color ${({ theme }) => theme.timing}s ease;
    }

    :first-child {
      border-radius: 4px 4px 0 0;
    }

    :last-child {
      border-radius: 0 0 4px 4px;
    }

    :not(:first-child) {
      border-top: 1px solid #e4e4e4;
    }

    :hover {
      background-color: #f7f7f7;
      color: #484848;

      svg {
        color: #8a8a8a;
      }
    }
  }
`;

interface MenuContainerProps {
  width: number;
}

const MenuContainer = styled.div`
  width: ${(props: MenuContainerProps) =>
    props.width ? `${props.width}px` : 'auto'};
  .tippy-content {
    padding: 0;
  }
`;

type DropMenuItemProps = {
  onClick?: (e) => void;
  className?: string;
};

export const DropMenuItem: React.FC<DropMenuItemProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <span onClick={onClick} className={className}>
      <span style={{ pointerEvents: 'none' }}>{children}</span>
    </span>
  );
};
