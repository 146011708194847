import React from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// height: calc(100% - ${({ theme }) => theme.headerHeight});
const DrawerContainer = styled(motion.div)<VariantProp>`
  position: absolute;
  z-index: 10;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  height: 100%;
  ${({ variant }) =>
    variant === 'right' &&
    css`
      right: 0;
    `}
`;

const Content = styled(motion.div)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.contentBackground};
  overflow: hidden;
`;

const DrawerToggle = styled.div<VariantProp>`
  position: absolute;
  top: 8rem;
  right: -2.5rem;
  border-radius: 0 4px 4px 0;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.2);

  font-size: 1.5rem;
  color: #fff;

  ${({ variant }) =>
    variant === 'right' &&
    css`
      right: 0;
      left: -2.5rem;
      border-radius: 4px 0 0 4px;
      box-shadow: -1px 0 7px rgba(0, 0, 0, 0.5);
    `}

  width: 2.5rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.primary};

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

type VariantProp = {
  variant?: 'left' | 'right';
};

type DrawerProps = {
  open: boolean;
  width?: string;
  onToggle?: () => void;
  style?: React.CSSProperties;
} & VariantProp;

export const Drawer: React.FC<DrawerProps> = (props) => {
  const {
    children,
    width = '350px',
    variant = 'left',
    open,
    onToggle,
    style,
  } = props;

  const toggleIcon = () => {
    let icon: IconProp = open ? 'chevron-left' : 'chevron-right';
    if (variant === 'right') {
      icon = open ? 'chevron-right' : 'chevron-left';
    }
    return icon;
  };

  return (
    <AnimatePresence initial={false}>
      {open && (
        <DrawerContainer variant={variant} style={style}>
          {onToggle && (
            <DrawerToggle variant={variant} onClick={onToggle}>
              <FontAwesomeIcon icon={toggleIcon()} />
            </DrawerToggle>
          )}
          <Content
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                width,
                maxWidth: '350px',
              },
              collapsed: { width: 0 },
            }}
            transition={{
              duration: 0.25,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            {children}
          </Content>
        </DrawerContainer>
      )}
    </AnimatePresence>
  );
};
