import styled from 'styled-components';
import { ImageSlider } from '../../../components/ImageSlider';

const IMAGE_SLIDER_INTERVAL = 7000;

export function SponsorList() {
  return (
    <Container>
      <CardContainer>
        <CardImageContainer>
          <img src={'/images/josh-garden.webp'} alt="Josh Whiton" />
        </CardImageContainer>

        <CardContent>
          <h1>Josh Whiton</h1>

          <p className="bio">
            Regenerative technologist{' '}
            <a
              href="https://www.joshwhiton.com/"
              target="_blank"
              rel="noreferrer"
            >
              Josh Whiton
            </a>{' '}
            set up MakeSoil in 2018 as a nonprofit that exists 100% for the
            public, planetary good.
          </p>
        </CardContent>
      </CardContainer>

      <CardContainer>
        <CardImageContainer>
          <ImageSlider
            images={[
              '/images/garden/ft_home.png',
              '/images/garden/ft_team.png',
            ]}
            interval={IMAGE_SLIDER_INTERVAL}
          />
        </CardImageContainer>

        <CardContent>
          <h1>Flourish Trust</h1>

          <p className="bio">
            <a
              href="https://www.flourishtrust.org/"
              target="_blank"
              rel="noreferrer"
            >
              Flourish Trust
            </a>{' '}
            - co-founded by Kimbal & Christiana Musk - is dedicated to
            catalyzing the regeneration of humanity and our planet.
          </p>
        </CardContent>
      </CardContainer>

      <CardContainer>
        <CardImageContainer>
          <ImageSlider
            images={[
              '/images/garden/coongie.webp',
              '/images/garden/niraj.webp',
            ]}
            interval={IMAGE_SLIDER_INTERVAL}
          />
        </CardImageContainer>

        <CardContent>
          <h1>Coongie Research Institute</h1>
          <p className="bio">
            <a href="https://www.coongie.org/" target="_blank" rel="noreferrer">
              Coongie Research Institute
            </a>{' '}
            - founded by philanthropist Niraj Mehta - is a research & teaching
            facility in the Santa Cruz mountains.
          </p>
        </CardContent>
      </CardContainer>

      <CardContainer>
        <CardImageContainer>
          <ImageSlider
            images={[
              '/images/garden/grimes.webp',
              '/images/garden/grimesx.webp',
            ]}
            interval={IMAGE_SLIDER_INTERVAL}
          />
        </CardImageContainer>

        <CardContent>
          <h1>Grimes</h1>

          <p className="bio">
            <a href="https://x.com/grimezsz" target="_blank" rel="noreferrer">
              Grimes
            </a>{' '}
            is a musical artist and record producer, who famously open-sourced
            her voice - letting it be used to create AI-generated songs.
          </p>
        </CardContent>
      </CardContainer>

      {/* <CardContainer>
          <CardImageContainer>
            <img
              src={'/images/garden/dlm.webp'}
              alt="Daniel with wife Katie"
            />
          </CardImageContainer>

          <CardContent>
            <h1>Daniel</h1>

            <p className="bio">
              <a
                href="https://www.dlloydmiller.com/"
                target="_blank"
                rel="noreferrer"
              >
                Daniel
              </a>{' '}
              (pictured with wife Katie) is a painter who was a Soil Supporter
              using MakeSoil before also becoming a Soil Sponsor.
            </p>
          </CardContent>
        </CardContainer> */}

      <CardContainer>
        <CardImageContainer>
          <img src={'/images/garden/maearth.webp'} alt="Ma Earth" />
        </CardImageContainer>

        <CardContent>
          <h1>Ma Earth</h1>

          <p className="bio">
            <a href="https://maearth.com/" target="_blank" rel="noreferrer">
              Ma Earth
            </a>{' '}
            is a community-led movement to align economic incentives with
            planetary health and regeneration.
          </p>
        </CardContent>
      </CardContainer>

      <CardContainer>
        <CardImageContainer>
          <img
            src={'/images/garden/tttnpf.webp'}
            alt="Ten to the Ninth Plus Foundation"
          />
        </CardImageContainer>

        <CardContent>
          <h1>Ten to the Ninth Plus Foundation</h1>

          <p className="bio">
            <a
              href="https://www.linkedin.com/company/ten-to-the-ninth-plus-foundation/"
              target="_blank"
              rel="noreferrer"
            >
              Ten to the Ninth Plus Foundation
            </a>{' '}
            is an organization focused on empowering exponential technological
            change worldwide.
          </p>
        </CardContent>
      </CardContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;

  margin-top: 2.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    // width: 900px;
    margin: 2.5rem auto;
  }
`;

const CardContainer = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 325px;
    max-width: 400px;
  }

  background-color: #fff;
  border: 1px solid #dfdfdf;

  border-top: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;

  box-shadow: 0 40px 81px -16px rgba(50, 50, 93, 0.25),
    0 24px 48px -24px rgba(0, 0, 0, 0.3);

  :hover {
    transform: scale(1.05);

    box-shadow: 0 40px 81px -16px rgba(50, 50, 93, 0.35),
      0 24px 48px -24px rgba(0, 0, 0, 0.45);
  }

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1 {
    font-size: 1.725rem;
    font-weight: 400;
  }

  p {
    margin: 0;
    margin-top: 0.75rem;
    line-height: 1.25;
  }

  transition: 0.25s ease;
`;

const CardImageContainer = styled.div`
  height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardContent = styled.div`
  padding: 2rem;

  .bio {
    line-height: 1.5;

    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
