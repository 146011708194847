import { gql } from '@apollo/client';
import { ACCESSREQUEST_FRAGMENT } from '../site/graphql';

const MEMBER_FRAGMENT = `
	fragment Member on ParticipantOT {
		userId
    firstName
    lastName
		picture
		location {
			lat
			lng
		}
		role {
			id
			name
		}
		createdAt
	}
`;

export const GET_SITE_MARKERS = gql`
  query sitesWithinBounds($bounds: BoundsInput!) {
    sitesWithinBounds(bounds: $bounds) {
      id
      name
      createdAt
      picture
      location {
        lat
        lng
      }
    }
  }
`;

export const GET_SOIL_SITE_DETAILS = gql`
  query getSoilSiteDetails($id: Int!) {
    soilSiteDetails(id: $id) {
      id
      name
      description
      createdAt
      location {
        lat
        lng
      }
      accepted
      prohibited
      picture
      active
      access
      dropOff
      hours
      requiresApproval
      accessRequests {
        ...AccessRequest
      }
      members {
        userId
        role {
          id
          name
        }
      }
    }
  }
  ${ACCESSREQUEST_FRAGMENT}
`;

export const GET_USER_DETAILS = gql`
  query getUserDetails($id: Int!) {
    userDetails(id: $id) {
      id
      email
      firstName
      lastName
      picture
      createdAt
      location {
        lat
        lng
      }
    }
  }
  ${ACCESSREQUEST_FRAGMENT}
  ${MEMBER_FRAGMENT}
`;

export const SEARCH_BY_KEYWORDS = gql`
  query searchByKeywords($keywords: String!) {
    sitesByKeywords(keywords: $keywords) {
      id
      name
      location {
        lat
        lng
      }
    }
  }
`;

export const GET_UNMATCHED_USERS = gql`
  query unmatchedUsersWithinBounds($bounds: BoundsInput!) {
    unmatchedUsersWithinBounds(bounds: $bounds) {
      id
      firstName
      lastName
      location {
        lat
        lng
      }
      createdAt
      hasPendingRequests
    }
  }
`;

export const GET_SUPPORTERS = gql`
  query supportersWithinBounds($bounds: BoundsInput!) {
    supportersWithinBounds(bounds: $bounds) {
      id
      firstName
      lastName
      location {
        lat
        lng
      }
      createdAt
    }
  }
`;
