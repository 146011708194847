import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { Flex } from './Flex';

type Props = {
  images: string[];
  state: LightRoomState;
  setState: React.Dispatch<React.SetStateAction<LightRoomState>>;
  onClose: () => void;
};

export const useLightRoom = (state?: LightRoomState) =>
  useState<LightRoomState>(
    (state = {
      open: false,
      activeIndex: 0,
    })
  );

type LightRoomState = {
  activeIndex: number;
  open: boolean;
};

export const LightRoom: React.FC<Props> = ({
  images,
  setState,
  onClose,
  state,
}) => {
  const [zoom, setZoom] = useState(1);

  const handlers = useSwipeable({
    delta: 30,
    onSwiped: (e: SwipeEventData) => {
      // stop from the parent picking up the swipe and changing the tab
      e.event.stopPropagation();
      if (e.dir === 'Left') {
        next();
      } else if (e.dir === 'Right') {
        previous();
      }
    },
  });

  const handleOverlayClick = (e) => {
    if (e.target.className.includes('no-close')) return;
    close();
  };

  if (!state.open) return null;

  const close = () => {
    onClose();
  };

  const next = () => {
    // close if last one
    if (state.activeIndex === images.length - 1) {
      return close();
    }
    //setActiveIndex((i) => i + 1);
    setState((state) => ({ ...state, activeIndex: state.activeIndex + 1 }));
  };

  const previous = () => {
    // close if first one
    if (state.activeIndex === 0) {
      return close();
    }
    //setActiveIndex((i) => i - 1);
    setState((state) => ({ ...state, activeIndex: state.activeIndex - 1 }));
  };

  const zoomIn = () => {
    if (zoom < 3) {
      setZoom((z) => z + 0.25);
    }
  };

  const zoomOut = () => {
    if (zoom > 1) {
      setZoom((z) => z - 0.25);
    }
  };

  const showHandles = images.length > 1;

  return (
    <Container id="container" onClick={handleOverlayClick} {...handlers}>
      <div className="header">
        <Icon onClick={zoomIn} disabled={zoom === 3} className="no-close">
          <FontAwesomeIcon icon="search-plus" />
        </Icon>
        <Icon onClick={zoomOut} disabled={zoom === 1} className="no-close">
          <FontAwesomeIcon icon="search-minus" />
        </Icon>
        <Icon onClick={close}>
          <FontAwesomeIcon icon="times" />
        </Icon>
      </div>

      <Flex
        justify="space-between"
        align="center"
        gap="1rem"
        style={{ height: '100%' }}
      >
        {showHandles && (
          <Icon onClick={previous} className="no-close">
            <FontAwesomeIcon icon="chevron-left" />
          </Icon>
        )}

        <ImageContainer zoom={zoom}>
          <img src={images[state.activeIndex]} alt="" className="no-close" />
        </ImageContainer>

        {showHandles && (
          <Icon onClick={next} className="no-close">
            <FontAwesomeIcon icon="chevron-right" />
          </Icon>
        )}
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  transition: all 0.15s;

  padding: 1rem;

  .header {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    color: #fff;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 2rem;
  }
`;

const Icon = styled.div<{ disabled?: boolean }>`
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background-color: rgba(0, 0, 0, 0.7);
  color: #a1a1a1;
  cursor: pointer;

  z-index: 202;

  :hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  svg {
    pointer-events: none;
  }
`;

const ImageContainer = styled.div<{ zoom?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  margin: auto;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 80%;

    z-index: 201;

    -webkit-transform: scale(${({ zoom }) => zoom || 1});
    -moz-transform: scale(${({ zoom }) => zoom || 1});
    -o-transform: scale(${({ zoom }) => zoom || 1});
    -ms-transform: scale(${({ zoom }) => zoom || 1});
    transform: scale(${({ zoom }) => zoom || 1});

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;
