import styled from 'styled-components';
import { Meta } from '../components/Meta';
import { MakeSoilMap } from '../features/map/components/MakesoilMap';

export default function MapPage() {
  return (
    <PageContainer>
      <Meta>
        <title>Find Soil Site | MakeSoil</title>
      </Meta>
      <MakeSoilMap />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;
