import { Field, ErrorMessage, useFormikContext } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { TextArea, FormInputError, Button } from '../../components/ui';
import {
  FilePicker,
  FilestackUploadResponse,
} from '../../components/ui/FilePicker';
import { WizardForm } from './model';

export const Step5 = ({ stepCount, currentStep }) => {
  const { setFieldValue } = useFormikContext<WizardForm>();

  const [filePickerOpen, setFilePickerOpen] = useState<boolean>(() => false);
  const [filename, setFilename] = useState('');

  return (
    <div>
      <h1 className="step-title">
        Create a soil site: Step {currentStep + 1}/{stepCount}
      </h1>
      <hr />
      <FormSection>
        <h3>
          Please say a little bit about who you are, why you're hosting a soil
          site, and anything else you'd like to share.
        </h3>
        <HelpText>
          Feel free to include a link to your website or social media pages at
          the end.
        </HelpText>

        <Field
          as={TextArea}
          name="description"
          placeholder="A few words about you"
          resize="vertical"
        />

        <ErrorMessage name="description" component={FormInputError} />
      </FormSection>

      <FormSection>
        <h3>Add a photo so people can see what your soil site looks like.</h3>
        <HelpText>
          For best results, use a landscape image with a 4:3 width-to-height
          ratio e.g. 800wx600h pixels. Maximum upload file size is 5MB.
        </HelpText>

        <Button onClick={() => setFilePickerOpen(true)}>Upload Photo</Button>
        {filename && <HelpText>Uploaded: {filename}</HelpText>}
        <FilePicker
          type="siteImage"
          open={filePickerOpen}
          onSuccess={(res: FilestackUploadResponse) => {
            const [upload] = res.filesUploaded;
            setFieldValue('picture', upload.url);
            setFilename(upload.filename);
            toast.success('Your soil site image has been uploaded.');
          }}
          onClose={() => setFilePickerOpen(false)}
        />
      </FormSection>
    </div>
  );
};

const HelpText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.4;
  color: #838383;
`;

const FormSection = styled.section`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;
