import { useEffect } from 'react';
import { isSiteOwner } from '../../../utils/site';
import { getQueryParameter } from '../../../utils/url';
import { isAdmin } from '../../auth/utils';
import { useSiteModal } from './useSiteModal';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthUser } from '../../auth/useAuthUser';

/**
 * Auto opens specified modals.
 * Exposes the useSiteModal hook
 * @param soilSite
 * @returns
 */
export function useAutoOpenModal(soilSite) {
  const location = useLocation();
  const history = useHistory();

  const authUser = useAuthUser();

  const { modal, openModal } = useSiteModal();

  useEffect(() => {
    if (!soilSite.active) {
      // show modal only to owner
      if (isSiteOwner(soilSite.members, authUser.id)) {
        openModal.reactivateSite();
        return;
      }
      // allow admin to stay on deactivated site
      if (!isAdmin(authUser)) {
        history.push('/my-soil-sites');
      }
    }
  }, [authUser, soilSite, history]);

  // handle user coming from email with invite modal to be opened
  useEffect(() => {
    (function openInvitationModal() {
      if (getQueryParameter(location.search, 'invite')) {
        openModal.invite();
        history.push(location.pathname);
      }
    })();
  }, [history, location]);

  // handle user coming with request access review modal to be opened
  useEffect(() => {
    (function openReviewAcceessRequestModal() {
      const arId = getQueryParameter(location.search, 'arid');
      if (arId) {
        const accessRequest = soilSite.accessRequests.find(
          (ar) => ar.id === parseInt(arId)
        );
        if (!accessRequest) return;
        openModal.reviewAccessRequest(accessRequest);
        history.push(location.pathname + `?tab=participants`);
      }
    })();
  }, [history, location, soilSite]);

  // handle user coming with drop off modal to be opened
  useEffect(() => {
    (function openDropOffModal() {
      if (getQueryParameter(location.search, 'drop')) {
        openModal.registerDropOff();
        history.push(location.pathname + `?tab=activity`);
      }
    })();
  }, [history, location, soilSite]);

  // handle user coming with leave site modal to be opened
  useEffect(() => {
    (function openLeaveSite() {
      if (getQueryParameter(location.search, 'leave')) {
        openModal.leaveSite();
      }
    })();
  }, [history, location, soilSite]);

  useEffect(() => {
    (function openDeactivateModal() {
      if (getQueryParameter(location.search, 'deactivate')) {
        openModal.deactivateSite();
        history.push(location.pathname);
      }
    })();
  }, [history, location, soilSite]);

  return { modal, openModal };
}
