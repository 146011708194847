import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components';

type StyledButtonProps = {
  color?: string;
  variant?: 'primary' | 'secondary' | 'outlined' | 'link' | 'danger';
  icon?: IconProp;
  round?: boolean;
  circle?: boolean;
  loading?: boolean;
  padding?: string;
};

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & StyledButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    onClick,
    type = 'button',
    className,
    style,
    color,
    disabled = false,
    variant = 'primary',
    round = true,
    padding,
    circle = false,
    icon,
    loading = false,
  } = props;

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      variant={variant}
      icon={icon}
      round={round}
      circle={circle}
      className={className}
      style={style}
      color={color}
      disabled={disabled}
    >
      <TextContainer
        icon={icon}
        variant={variant}
        circle={circle}
        padding={padding}
      >
        {children} {loading && <FontAwesomeIcon icon="spinner" spin />}
      </TextContainer>
      {icon && (
        <IconContainer variant={variant}>
          <FontAwesomeIcon icon={icon} />
        </IconContainer>
      )}
    </StyledButton>
  );
};

//#region styled components

const StyledButton = styled.button<StyledButtonProps>`
  border: none;

  ${({ round }) =>
    round &&
    css`
      border-radius: ${(props) => props.theme.borderRadius}px;
    `}

  ${({ circle }) =>
    circle &&
    css`
      border-radius: 50%;
    `}

  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;

  outline: none;

  :disabled {
    cursor: default;
  }

  padding: 0;

  ${({ icon }) =>
    icon &&
    css`
      display: flex;
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: #fff;

      :hover {
        background-color: ${(props) => props.theme.colors.primaryDark};
      }

      :active {
        background-color: #498036;
      }

      :disabled {
        // background-color: #68b74eab;
        background-color: #ccc;
      }
    `}

${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: #fff;

      color: ${(props) => props.theme.colors.primary};

      :hover {
        background-color: #efefef;
      }
    `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.colors.error};
      border: 1px solid ${({ theme }) => theme.colors.error};
      color: #fff;
    `}

${({ variant, theme }) =>
    variant === 'outlined' &&
    css`
      background-color: transparent !important;
      border: 1px solid ${theme.colors.primary} !important;
      color: ${theme.colors.primary} !important;
    `}

${({ variant }) =>
    variant === 'link' &&
    css`
      background-color: transparent;
      border: none;
      font-size: inherit;
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;

      :hover {
        opacity: 0.7;
      }
    `}



transition: all ${({ theme }) => theme.timing}s ease;
`;

const TextContainer = styled.div<StyledButtonProps>`
  padding: 0.75rem 1rem;
  ${({ padding }) => padding && `padding: ${padding};`}
  margin: 0 !important;

  ${({ circle }) =>
    circle &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75rem;
    `}

  ${({ variant }) =>
    variant === 'link' &&
    css`
      padding: 0;
    `}

${({ icon }) =>
    icon &&
    css`
      padding: 0.5rem;
      display: flex;
    `}
`;

const IconContainer = styled.div<{
  variant?: 'primary' | 'secondary' | 'outlined' | 'link' | 'danger';
}>`
  padding: 0.5rem;

  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
    `};

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.colors.error};
      color: #fff;
    `};
`;

//#endregion
