import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '../../../components/ui/Tooltip';
import { SITE_ROLE } from '../model';

type SiteRoleSelectorProps = {
  siteRole: SITE_ROLE;
  onSiteRoleChange: (siteRole: SITE_ROLE) => void;
};

export const SiteRoleSelector: React.FC<SiteRoleSelectorProps> = (props) => {
  const { siteRole, onSiteRoleChange } = props;
  return (
    <SiteRoleSelectorContainer>
      <div>Invite to be:</div>
      <SiteRoleContainer>
        <RadioButton
          selected={siteRole === SITE_ROLE.SUPPORTER}
          onClick={() => onSiteRoleChange(SITE_ROLE.SUPPORTER)}
        />
        <SiteRoleName>Soil Supporter</SiteRoleName>
        <Tooltip>
          Someone who wants to give their scraps to this soil site.
        </Tooltip>
      </SiteRoleContainer>
      <SiteRoleContainer>
        <RadioButton
          selected={siteRole === SITE_ROLE.MAKER}
          onClick={() => onSiteRoleChange(SITE_ROLE.MAKER)}
        />
        <SiteRoleName>Soil Maker</SiteRoleName>
        <Tooltip>
          Someone who wants to join you as a fellow Soil Maker at this soil
          site, and share responsibility for managing the soil site and its
          participants.
        </Tooltip>
      </SiteRoleContainer>
    </SiteRoleSelectorContainer>
  );
};

//#region styled components

const SiteRoleSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
  }

  span.makesoil-tooltip {
    color: rgb(158, 158, 158) !important;
  }
`;

const SiteRoleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SiteRoleName = styled.div`
  margin-right: 0.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 5px;
  }
`;

const RadioButton = styled.div<{ selected: boolean }>`
  border-radius: 100%;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border: solid 2px #cecece;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : '#fff'};
  margin-left: 24px;
  margin-right: 8px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0px;
  }
`;

//#endregion
