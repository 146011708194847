import React from 'react';
import styled from 'styled-components';
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
} from '@react-google-maps/api';
import { ILocation } from '../app/model/ILocation';
import { mapSettings } from '../features/map/utils';
import { MapMarker } from '../features/map/model';

const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

type MapProps = {
  markers?: MapMarker[];
  center?: ILocation;
  mapOptions?: google.maps.MapOptions;
  origin?: ILocation;
  destination?: ILocation;
  style?: React.CSSProperties;
};

export const StaticMap: React.FC<MapProps> = React.memo((props) => {
  const {
    markers = [],
    center = {
      lat: 27.7676,
      lng: -82.6043,
    },
    mapOptions = {
      styles: [
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      restriction: {
        latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
        strictBounds: true,
      },
      minZoom: mapSettings.minZoom,
      maxZoom: mapSettings.maxZoom,
    },
    origin,
    destination,
    style,
  } = props;

  const [
    response,
    setResponse,
  ] = React.useState<google.maps.DirectionsResult | null>(null);
  const [travelMode, setTravelMode] = React.useState(
    google.maps.TravelMode.WALKING
  );

  const directionsCallback = React.useCallback((res) => {
    if (res && res.status === 'OK') {
      setResponse(res);
    }
  }, []);

  const directionsServiceOptions = React.useMemo(() => {
    return {
      destination:
        destination && new google.maps.LatLng(destination.lat, destination.lng),
      origin: origin && new google.maps.LatLng(origin.lat, origin.lng),
      travelMode: travelMode,
    };
  }, []);

  return (
    <MapContainer style={style}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%', ...style }}
        center={center}
        zoom={18}
        options={mapOptions}
      >
        {markers.map((marker) => (
          <Marker
            position={{ lat: marker.location.lat, lng: marker.location.lng }}
            key={marker.id}
            icon="/icons/soil-site-icon-exact.svg"
          />
        ))}

        {destination && origin && (
          <DirectionsService
            options={directionsServiceOptions}
            callback={directionsCallback}
          />
        )}

        {response && (
          <DirectionsRenderer
            directions={response}
            options={{ directions: response }}
          />
        )}
      </GoogleMap>
    </MapContainer>
  );
});
