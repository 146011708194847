import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { INotification } from './model/INotification';
import { NotificationCategory } from './model/NotificationCategory';
import notificationService from './NotificationCentreService';

type ReadState = {
  notificationId: number;
  read: boolean;
  markReadAssociated?: boolean;
};

interface NotificationCentreState {
  notifications: INotification[];
  unreadNotificationsCount: number;
  visible: boolean;
  error: string;
  loading: boolean;
}

// Define the initial state using that type
const initialState: NotificationCentreState = {
  notifications: [],
  unreadNotificationsCount: null,
  visible: false,
  error: '',
  loading: false,
};

export const notificationCentreSlice = createSlice({
  name: 'notificationCentre',
  initialState,
  reducers: {
    notificationAdded: (state, action: PayloadAction<INotification>) => {
      state.notifications.unshift(action.payload);
      state.unreadNotificationsCount++;
    },
    notify: (state, { payload }: PayloadAction<INotification>) => {
      // we use middleware to notify
    },
    toggleVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadNotifications.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.notifications = payload.notifications;
        state.unreadNotificationsCount = payload.unreadNotificationsCount;
      })
      .addCase(fetchMoreNotifications.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.length > 0) {
          state.notifications.push(...payload);
        }
      })
      .addCase(toggleReadState.fulfilled, (state, { payload }) => {
        state.loading = false;

        // mark current notification as read
        const notification = state.notifications.find(
          (n) => n.id === payload.notificationId
        );
        notification.read = payload.read;

        // mark associated notifications as read
        if (payload.markReadAssociated) {
          const associatedNotifications = getAssociatedNotifications(
            payload.notificationId,
            state
          );

          state.notifications = state.notifications.map((n) => {
            if (associatedNotifications.map((an) => an.id).includes(n.id)) {
              return {
                ...n,
                read: true,
              };
            }
            return n;
          });

          // update the count associated + the one that was marked as read
          state.unreadNotificationsCount -= associatedNotifications.length + 1;
        } else {
          // update the count
          payload.read
            ? state.unreadNotificationsCount--
            : state.unreadNotificationsCount++;
        }
      })
      .addCase(deleteNotification.fulfilled, (state, { payload }) => {
        state.loading = false;

        // if deleted notification was unread update the count
        const notification = state.notifications.find((n) => n.id === payload);
        if (!notification.read) state.unreadNotificationsCount--;

        state.notifications = state.notifications.filter(
          (n) => n.id !== payload
        );
      })

      .addMatcher(
        isPending(
          loadNotifications,
          fetchMoreNotifications,
          toggleReadState,
          deleteNotification
        ),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(
          loadNotifications,
          fetchMoreNotifications,
          toggleReadState,
          deleteNotification
        ),
        (state, action) => {
          console.log('rejected', action);
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(
          loadNotifications,
          fetchMoreNotifications,
          toggleReadState,
          deleteNotification
        ),
        (state, action) => {
          console.log('rejected', action);
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export const {
  toggleVisible,
  notificationAdded,
  notify,
} = notificationCentreSlice.actions;

//
// Selectors

export const selectNotifications = (state: RootState) =>
  state.notificationCentre.notifications;
export const selectUnreadCount = (state: RootState) =>
  state.notificationCentre.unreadNotificationsCount;

export const selectIsLoading = (state: RootState) =>
  state.notificationCentre.loading;

export const selectError = (state: RootState) => state.notificationCentre.error;
export const selectVisible = (state: RootState) =>
  state.notificationCentre.visible;

export default notificationCentreSlice.reducer;

/**
 * Notifications subscription
 * @param userId
 * @returns
 */
export const getNotificationObservable = (userId) => {
  return notificationService.subscribe(userId);
};

//
// Thunks

type InitialNotificationsLoad = {
  notifications: INotification[];
  unreadNotificationsCount: number;
};

export const loadNotifications = createAsyncThunk<
  InitialNotificationsLoad,
  void
>('notificationCentre/load', async (_, { rejectWithValue }) => {
  const { data, error } = await notificationService.load();
  if (!data && error) {
    return rejectWithValue(error);
  }

  return data.loadNotifications;
});

export const fetchMoreNotifications = createAsyncThunk<INotification[], Date>(
  'notificationCentre/fetchMore',
  async (cursor, { rejectWithValue }) => {
    const { data, error } = await notificationService.getNotifications(cursor);
    if (!data && error) {
      return rejectWithValue(error);
    }

    return data.notifications;
  }
);

export const toggleReadState = createAsyncThunk<
  ReadState,
  ReadState,
  { state: RootState }
>(
  'notificationCentre/toggleReadState',
  async (readState: ReadState, { rejectWithValue, getState }) => {
    const { notificationId, read, markReadAssociated = false } = readState;

    let result;
    if (markReadAssociated) {
      const associatedNotifications = getAssociatedNotifications(
        notificationId,
        getState().notificationCentre
      );
      result = await notificationService.markRead(
        associatedNotifications.map((n) => n.id)
      );
    } else {
      result = await notificationService.updateReadState(notificationId, read);
    }

    const { data, errors } = result;
    if (!data && errors) {
      return rejectWithValue(errors);
    }

    return readState;
  }
);

export const deleteNotification = createAsyncThunk<number, number>(
  'notificationCentre/delete',
  async (notificationId, { rejectWithValue }) => {
    const { data, errors } = await notificationService.delete(notificationId);
    if (!data && errors) {
      return rejectWithValue(errors);
    }

    return notificationId;
  }
);

/**
 * We want to mark read all soil site message board notifications
 * when just one of them is clicked
 */
export function shouldMarkAssociated(notification: INotification) {
  const { category } = notification;
  return (
    category === NotificationCategory[NotificationCategory[category]] &&
    notification.associatedObject?.objectType === 'site' &&
    !notification.read
  );
}

function getAssociatedNotifications(
  notificationId: number,
  state: NotificationCentreState
) {
  const notifications = state.notifications;
  const notification = notifications.find((n) => n.id === notificationId);
  return notifications.filter(
    (n) =>
      n.associatedObject?.objectType === 'site' &&
      n.associatedObject?.id === notification.associatedObject?.id &&
      !n.read
  );
}
