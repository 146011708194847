import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Button, TextArea } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';
import { SiteMember } from '../model';

import { useSiteManager } from '../hooks';
import { useSoilSiteContext } from '../SoilSiteContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  participant: SiteMember;
};

export const RemoveMember: React.FC<Props> = ({
  isOpen,
  onClose,
  participant,
}) => {
  const { soilSite } = useSoilSiteContext();
  const [reason, setReason] = useState('');

  const { removeParticipant: removeMember } = useSiteManager(soilSite.id);

  const handleRemoveMember = async (member: SiteMember, reason?: string) => {
    try {
      const { data } = await removeMember(member, reason);
      if (data && data.removeMember.errors.length > 0) {
        toast.error(`${data.removeMember.errors[0]}`);
      } else {
        toast.success(
          `${member.firstName} has been successfuly removed from the soil site.`
        );
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(() =>
    handleRemoveMember(participant, reason)
  );

  return (
    <Modal
      header="Remove Participant"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          REMOVE PARTICIPANT
        </Button>
      }
    >
      <p>
        Are you sure you want to remove {participant.firstName} as a participant
        of this soil site?
      </p>
      <p>
        Let {participant.firstName} know why you're removing them (optional).
      </p>

      <TextArea value={reason} onChange={(e) => setReason(e.target.value)} />
    </Modal>
  );
};
