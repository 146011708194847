import { useEffect } from 'react';
import { useMessenger } from './useMessenger';

/**
 * Fetch threads on load and cleanup on leave
 */
export function useFetchThreads() {
  const { fetchThreads, cleanup } = useMessenger();

  useEffect(() => {
    fetchThreads();
    return () => {
      cleanup();
    };
  }, [cleanup, fetchThreads]);
}
