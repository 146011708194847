import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';

import { Meta } from '../../components/Meta';
import { Button, Flex, FormInputError, Revealer } from '../../components/ui';
import { AuthPageLayout } from '../../pages';
import { SecondaryButton } from '../../pages/auth/AuthPageLayout';
import { PREMIUM_ENQUIRY } from './graphql';
import { Select } from '../../components/ui/forms/Select';
import { FormControl } from '../../components/ui/forms/Forms';
import { useAuthUser } from '../auth/useAuthUser';

type FormData = {
  email: string;
  inWhatCapacity: string;
  website: string;
  soilSites: number;
};

const options = {
  personal: 'In a personal capacity',
  organization: 'On behalf of an organization',
};

export default function Premium() {
  const history = useHistory();
  const authUser = useAuthUser();

  const [sendEnquiry] = useMutation(PREMIUM_ENQUIRY);

  const nextStep = () => {
    history.push('/my-soil-sites');
  };

  const handleSubmit = async (
    { inWhatCapacity, website, soilSites },
    { setSubmitting }: any
  ) => {
    const { data } = await sendEnquiry({
      variables: {
        input: { email: authUser.email, inWhatCapacity, website, soilSites },
      },
    });

    if (data.premiumEnquiry) {
      toast.success(data.premiumEnquiry.message);
    }

    setSubmitting(false);
    nextStep();
  };

  const validateForm = ({ inWhatCapacity, website, soilSites }: FormData) => {
    let errors: FormikErrors<FormData> = {};

    if (!inWhatCapacity) {
      errors.inWhatCapacity = 'Please select an option from the dropdown.';
    }

    if (!website && inWhatCapacity === options.organization) {
      errors.website = 'Please provide a link.';
    } else if (website) {
      try {
        let url = null;
        const hassProtocol = website.startsWith('http');
        if (!hassProtocol) {
          url = 'http://' + website;
        }
        url = new URL(url);
      } catch (error) {
        errors.website = 'Please provide a link.';
      }
    }

    if (!soilSites) {
      errors.soilSites = 'Please enter a number.';
    }

    return errors;
  };

  return (
    <AuthPageLayout
      contentContainerStyle={{ justifyContent: 'center' }}
      onClose={() => history.push('/map')}
    >
      <Meta>
        <title>MakeSoil Pro | MakeSoil</title>
      </Meta>

      <Container>
        <h2>Introducing MakeSoil Pro</h2>

        <p>Thank you for your interest in hosting an additional soil site.</p>

        <p>
          To do so, we invite you to upgrade to <strong>MakeSoil Pro</strong>.
        </p>

        <p>
          We offer flexible pricing to meet a variety of different needs. Please
          inquire via the form and we'll get back to you.
        </p>
      </Container>

      <Formik
        initialValues={{ inWhatCapacity: '', website: '', soilSites: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validate={validateForm}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <FormControl>
              <Field as={Select} name="inWhatCapacity">
                <option value={null} hidden>
                  How are you using MakeSoil?
                </option>
                <option value={options.personal}>{options.personal}</option>
                <option value={options.organization}>
                  {options.organization}
                </option>
              </Field>

              <ErrorMessage name="inWhatCapacity" component={FormInputError} />
            </FormControl>

            <Revealer isOpen={values.inWhatCapacity === options.organization}>
              <Field
                type="text"
                placeholder="Please provide a link to the website of your organization"
                name="website"
              />
              <ErrorMessage name="website" component={FormInputError} />
            </Revealer>

            <FormControl>
              <Field
                type="number"
                min={1}
                placeholder="How many more soil sites do you expect to host?"
                name="soilSites"
              />
              <ErrorMessage name="soilSites" component={FormInputError} />
            </FormControl>

            <Flex direction="column" gap="1rem" style={{ marginTop: '1.5rem' }}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                SEND INQUIRY
              </Button>

              <SecondaryButton onClick={nextStep}>CANCEL</SecondaryButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </AuthPageLayout>
  );
}

const Container = styled.div`
  padding: 0 1rem 2rem;
`;
