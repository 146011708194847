import { isMobileApp, mobileSaveStorage } from '../mobile/mobile';

// when running within the mobile container, all storage is synced to persistent native storage
// session storage is not used to make sure the app keeps it complete state while loaded and/or restarted
const _localStorage = window.localStorage;
const _sessionStorage = isMobileApp() ? _localStorage : window.sessionStorage;

// console.log("INIT STORAGE: " + JSON.stringify(localStorage));

// on mobile, these keys will be removed on sessionClear();
const _sessionKeys = ["jwt", "qr-popup", "video-popup", "landing-popup"];

const mobileSave = () => {
  if (_sessionStorage === _localStorage) {
    mobileSaveStorage();
  }
};

const localSet = (key: string, value: any) => {
  let finalValue = value;
  if (typeof value === 'object') finalValue = JSON.stringify(value);
  _localStorage.setItem(key, finalValue);
  mobileSave();
};

const localGet = (key: string) => {
  let value = _localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

const localRemove = (key: string) => {
  _localStorage.removeItem(key);
  mobileSave();
};

const localClear = () => {
  _localStorage.clear();
  mobileSave();
};

const sessionSet = (key: string, value: any) => {
  let finalValue = value;
  if (typeof value === 'object') finalValue = JSON.stringify(value);
  _sessionStorage.setItem(key, finalValue);
  mobileSave();
};

const sessionGet = (key: string) => {
  let value = _sessionStorage.getItem(key);
  try {
    // TODO: this introduces a potential bug.
    // now we can only store explicit strings in session
    // typeof value always is a string since storage stores everything as string
    // the moment you put an object to it and read from it here it will not parse it since it's a string
    // it will return string
    //
    // right now we store the JWT token here
    // we should change the token to be an object like { token: 'value' }
    // the problem is that would log everyone out
    // we still have to do this at some point

    if (typeof value === 'string') return value;
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

const sessionRemove = (key: string) => {
  _sessionStorage.removeItem(key);
  mobileSave();
};

const sessionClear = () => {
  // on mobile, delete known session keys from persistent storage
  if (_sessionStorage === _localStorage) {
    _sessionKeys.forEach((key) => {
      _sessionStorage.removeItem(key);
      delete _sessionKeys[key];
    });
    mobileSave();
  } else {
    _sessionStorage.clear();
  }
};

export const storage = {
  local: {
    set: localSet,
    get: localGet,
    remove: localRemove,
    clear: localClear,
  },
  session: {
    set: sessionSet,
    get: sessionGet,
    remove: sessionRemove,
    clear: sessionClear,
  },
};
