import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isMobileApp } from '../../../mobile/mobile';
import { Flex, Button } from '../../../components/ui';
import { Badge } from '../../../components/ui/Badge';
import { LinkParser } from '../../../components/ui/LinkParser';
import { isMobile } from '../../../utils/helpers';
import {
  generateSiteIdentifier,
  getHashFromIdentifier,
} from '../../../utils/url';
import { mapSettings } from '../../map/utils';
import { SitePoster } from '../components/SitePoster';
import { SiteImage } from '../components/SiteImage';
import { FilestackUploadResponse } from '../../../components/ui/FilePicker';
import { removeOldFilestackImage } from '../../filestack/utils';
import { TimeAgo } from '../../../components/ui/TimeAgo';
import { useSoilSiteContext } from '../SoilSiteContext';
import { useThemeContext } from '../../../app/useThemeContext';
import { useCurrentSiteUser, useSiteManager } from '../hooks';

type SiteInfoProps = {
  onLeaveSite: () => void;
  onEdit: () => void;
  onGetDirections?: () => void;
};

export const SiteInfo: React.FC<SiteInfoProps> = ({
  onEdit,
  onLeaveSite,
  onGetDirections,
}) => {
  const { soilSite, siteIdentifier } = useSoilSiteContext();
  const theme = useThemeContext();
  const { updateSoilSiteImage } = useSiteManager(soilSite.id);
  const { permissions } = useCurrentSiteUser(soilSite);

  const {
    name,
    address,
    description,
    createdAt,
    hours,
    access,
    dropOff,
    requiresApproval,
    obfuscate,
    accepted,
    prohibited,
    isUnlisted,
    picture,
  } = soilSite;

  const handleFileUpload = async (response: FilestackUploadResponse) => {
    const [upload] = response.filesUploaded;
    removeOldFilestackImage(picture);
    updateSoilSiteImage(upload.url);
  };

  const showDirections = false; //isAdmin(authUser);

  const getDirections = async () => {
    onGetDirections && onGetDirections();
  };

  return (
    <SiteInfoContainer>
      <div style={{ position: 'relative', maxHeight: '250px' }}>
        <SiteImage
          image={picture}
          siteId={soilSite.id}
          showTrigger={permissions.canEdit}
          triggerType="button"
          onFileUploaded={handleFileUpload}
        />
        <DownloadPosterThumbnail>
          <SitePoster
            siteIdHash={getHashFromIdentifier(siteIdentifier)}
            siteName={soilSite.name}
          >
            <DownloadPosterThumbnailOverlay>
              <FontAwesomeIcon icon="download" />
              <span>Get Poster</span>
            </DownloadPosterThumbnailOverlay>
          </SitePoster>
        </DownloadPosterThumbnail>
      </div>

      <SiteInfoHeader>
        <Flex direction="column">
          <SiteName>
            <h3>{name}</h3>
          </SiteName>
          <Flex justify="space-between" align="flex-end">
            <div style={{ flex: 1 }}>
              {/* shown only to soil maker */}
              {permissions.canEdit && (
                <>
                  {!isUnlisted ? (
                    <>
                      <SitePrivacy>
                        <FontAwesomeIcon icon="map-marked-alt" />
                        <h5>
                          {obfuscate
                            ? 'Approximate location shown on map'
                            : 'Exact location shown on map'}
                        </h5>
                      </SitePrivacy>
                      <SitePrivacy>
                        <FontAwesomeIcon
                          icon={requiresApproval ? 'lock' : 'unlock'}
                        />
                        <h5>
                          {requiresApproval
                            ? 'Requires approval to join'
                            : 'Anyone can join'}
                        </h5>
                      </SitePrivacy>
                    </>
                  ) : (
                    <SitePrivacy>
                      <FontAwesomeIcon icon="eye-slash" />
                      <h5>Not shown on map</h5>
                    </SitePrivacy>
                  )}
                </>
              )}
              <Flex align="flex-end" justify="space-between">
                <SitePrivacy>
                  <FontAwesomeIcon icon="seedling" />
                  <h5>
                    Registered <TimeAgo date={createdAt} />
                  </h5>
                </SitePrivacy>

                {permissions.canEdit && (
                  <small
                    onClick={onEdit}
                    style={{
                      cursor: 'pointer',
                      color: theme.colors.primary,
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Edit Soil Site
                  </small>
                )}
              </Flex>
            </div>
          </Flex>
        </Flex>
      </SiteInfoHeader>
      <SiteInfoContent>
        <SiteDetails>
          <Info>
            <FontAwesomeIcon
              color={theme.colors.primary}
              icon="map-marker-alt"
            />
            <Flex direction="column" style={{ flex: 1 }}>
              <Flex justify="space-between" style={{ flex: 1 }}>
                <h5>Location:</h5>
                {(isMobile() || isMobileApp()) && !isUnlisted && (
                  <small style={{ display: 'inline-block' }}>
                    <Link
                      to={`/map/site/${generateSiteIdentifier(
                        soilSite.id,
                        soilSite.name
                      )}?z=${mapSettings.maxZoom}&drawerOpen=false`}
                    >
                      View On Map
                    </Link>
                  </small>
                )}
              </Flex>

              <p>{address}</p>
              {showDirections && (
                <Button
                  onClick={getDirections}
                  variant="link"
                  style={{ alignSelf: 'flex-start' }}
                >
                  <FontAwesomeIcon icon="directions" />
                  <small>Get Directions</small>
                </Button>
              )}
            </Flex>
          </Info>

          <Info>
            <FontAwesomeIcon color={theme.colors.primary} icon="info-circle" />
            <Flex direction="column">
              <h5>About soil site:</h5>
              <LinkParser blank>
                <p>{description.trim()}</p>
              </LinkParser>
            </Flex>
          </Info>

          <Info>
            <FontAwesomeIcon color={theme.colors.primary} icon="clock" />
            <Flex direction="column">
              <h5>Opening Times:</h5>
              <LinkParser blank>
                <p>{hours}</p>
              </LinkParser>
            </Flex>
          </Info>

          <Info>
            <FontAwesomeIcon color={theme.colors.primary} icon="key" />
            <Flex direction="column">
              <h5>
                {dropOff
                  ? `Access Information`
                  : `Access & Drop-off Instructions`}
                :
              </h5>
              <LinkParser blank>
                <p>{access}</p>
              </LinkParser>
            </Flex>
          </Info>

          {dropOff && (
            <Info>
              <FontAwesomeIcon color={theme.colors.primary} icon="trash-alt" />
              <Flex direction="column">
                <h5>Drop-off Instructions:</h5>
                <LinkParser blank>
                  <p>{dropOff}</p>
                </LinkParser>
              </Flex>
            </Info>
          )}
        </SiteDetails>

        <SiteMaterials>
          <Info>
            <FontAwesomeIcon color={theme.colors.primary} icon="check" />
            <Flex direction="column">
              <h5>Accepts:</h5>
              <div>
                {accepted.map((a: any) => (
                  <Tag className="accepted" key={a}>
                    {a}
                  </Tag>
                ))}
              </div>
            </Flex>
          </Info>

          <Info>
            <FontAwesomeIcon color={theme.colors.error} icon="ban" />
            <Flex direction="column">
              <h5>Does NOT Accept:</h5>
              <div>
                {prohibited.map((p: any) => (
                  <Tag className="prohibited" key={p}>
                    {p}
                  </Tag>
                ))}
              </div>
            </Flex>
          </Info>
        </SiteMaterials>
        {permissions.canLeaveSite && (
          <>
            <hr />
            <Flex
              align="flex-end"
              justify="flex-end"
              style={{ paddingBottom: '1rem' }}
            >
              <small
                onClick={() => onLeaveSite()}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                <FontAwesomeIcon icon="ban" /> Leave Soil Site
              </small>
            </Flex>
          </>
        )}
      </SiteInfoContent>
    </SiteInfoContainer>
  );
};

const SiteInfoContainer = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: auto;

  // padding: 1rem 1rem 0 1rem;

  border-radius: 0 0 4px 4px;

  overflow: auto;
`;

const SiteInfoHeader = styled.div`
  // background-color: #fff;
  border-bottom: 1px solid #e0e0e0;

  padding: 1rem;

  h3,
  h4,
  h5 {
    margin: 0;
  }
`;

const SiteName = styled.div`
  svg {
    margin-right: 0.25rem;
  }
`;

const SitePrivacy = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: #8a8a8a;

  svg {
    min-width: 1.25rem;
    margin-right: 0.35rem;
  }
`;

const SiteInfoContent = styled.div`
  border-radius: 0 0 4px 4px;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-wrap: wrap;

  padding: 0 1rem;

  > div {
    min-width: 33%;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: calc(400px * 999 - 100% * 999);
  }
`;

const SiteDetails = styled.div`
  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.primary};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex: 1;
  }
`;

const SiteMaterials = styled.div`
  padding: 0 0.25rem 1rem;
  border-top: 1px solid #dedede;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex: 2;
    padding: 0 0.25rem 1rem;
    background-color: #f3f3f3;
    border: none;
    // border-left: 1px solid #dedede;
  }
`;

const Info = styled.div`
  display: flex;
  margin-top: 1rem;

  word-break: break-word;

  h5 {
    margin: 0;
  }

  i,
  svg {
    // color: ${(props) => props.theme.colors.primary};
    min-width: 1.25rem;
    margin-right: 0.35rem;
  }

  p {
    margin: 0.5rem 0;
    color: #7b7b7b;
    font-size: 0.875rem;
    white-space: pre-wrap;
    word-break: break-word;
  }
`;

const Tag = styled(Badge)`
  margin: 0.5rem 0.5rem 0 0;

  &.accepted {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }
  &.prohibited {
    border: 1px solid ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.error};
  }
`;

const DownloadPosterThumbnail = styled.div`
  position: absolute;
  bottom: 0.25rem;
  left: 0.25rem;
  width: 6rem;

  img {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const DownloadPosterThumbnailOverlay = styled.div`
  position: absolute;
  padding: 0.5rem;
  width: 100%;

  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  font-size: 0.825rem;
  color: #fff;

  background-color: rgba(0, 0, 0, 0.575) !important;
`;
