import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Logo } from '../../components/ui';
import { Credits } from '../../components/ui/Credits';

type BackgroundImage = {
  url: string;
  credits: {
    url: string;
    name: string;
  };
};

const defaultImage: BackgroundImage = {
  url: '/images/felixx.jpg',
  credits: {
    url: 'https://www.felixx.nl/projects/isle-of-dikes.html',
    name: 'Felixx',
  },
};

type AuthPageLayoutProps = {
  image?: BackgroundImage;
  contentContainerStyle?: React.CSSProperties;
  contentContainerWidth?: string;
  hideClose?: boolean;
  onClose?: () => void;
};

export const AuthPageLayout: React.FunctionComponent<AuthPageLayoutProps> = (
  props
) => {
  const {
    children,
    image = defaultImage,
    contentContainerStyle,
    contentContainerWidth,
    hideClose = false,
    onClose,
  } = props;

  return (
    <ContainerWithBackgroundImage imageUrl={image.url}>
      <Credits align="right" hide="md">
        Image from{' '}
        <a href={image.credits.url} target="_blank" rel="noreferrer">
          {image.credits.name}
        </a>
      </Credits>
      <ContentContainer
        style={contentContainerStyle}
        width={contentContainerWidth}
      >
        {!hideClose &&
          (onClose ? (
            <CloseButton onClick={onClose}>
              <FontAwesomeIcon icon="times" className="close" />
            </CloseButton>
          ) : (
            <Link to="/">
              <CloseButton>
                <FontAwesomeIcon icon="times" className="close" />
              </CloseButton>
            </Link>
          ))}

        <FormContainer>{children}</FormContainer>
      </ContentContainer>
    </ContainerWithBackgroundImage>
  );
};

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 1rem;
  right: 1rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    top: 2rem;
    right: 2rem;
  }

  width: 2.5rem;
  height: 2.5rem;

  cursor: pointer;
  border-radius: 50%;

  background-color: rgba(0, 0, 0, 0.1);

  :hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ContainerWithBackgroundImage = styled.div<{ imageUrl: string }>`
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  background: url('${({ imageUrl }) => imageUrl}') center no-repeat;
  background-size: cover;

  padding: 0.5rem 0;
`;

const ContentContainer = styled.div<{ width?: string }>`
  position: relative;
  padding: 2rem 1rem;
  overflow-y: auto;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 95%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${({ width }) => (width ? width : `clamp(400px, 40vw, 550px)`)};
  }

  background-color: rgba(36, 36, 36, 0.83);
  border-radius: ${({ theme }) => theme.borderRadius}px;

  text-align: center;
  color: #e1e1e1;

  a {
    text-decoration: underline;
  }
`;

const FormContainer = styled.div`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      outline: none;
    }

    input:not([type='checkbox']) {
      margin-top: 1rem;
      font-size: 1rem;
      border: none;
      border-radius: 4px;
      padding-left: 8px;
      height: 2.5rem !important;
      width: 100%;
    }

    button {
      font-weight: 600;
      display: block;

      background-color: ${(props) => props.theme.colors.primary};

      cursor: pointer;

      &[disabled] {
        background-color: grey;
      }
    }
  }

  small {
    display: block;
    margin-top: 1rem;
    font-size: 0.7rem;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: transparent !important;
  border: 1px solid #d0d0d0 !important;
  color: #d0d0d0 !important;

  text-transform: uppercase;

  @media (hover: hover) {
    :hover {
      background-color: #d0d0d0 !important;
      border-color: #d0d0d0 !important;
      color: #333 !important;
    }
  }
`;
