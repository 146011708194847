import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject, createRef, useState } from 'react';
import styled from 'styled-components';

import { isMobileApp } from '../../../mobile/mobile';
import { TextAreaExpandable } from '../../../components/ui';
import { isMobile } from '../../../utils/helpers';
import { IMessageInput } from '../model';
import { toast } from 'react-toastify';
import { useMessenger } from '../useMessenger';

const initMessage = { text: '' };

type MessageInputProps = {
  forwardedRef?: RefObject<HTMLTextAreaElement>;
};

export const MessageInput: React.FC<MessageInputProps> = ({ forwardedRef }) => {
  const textareaRef = forwardedRef || createRef<HTMLTextAreaElement>();
  const [message, setMessage] = useState<IMessageInput>(() => initMessage);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { sendMessage } = useMessenger();

  const handleChange = (e) => {
    setMessage((prev) => ({ ...prev, text: e.target.value }));
  };

  const handleSendMessage = async () => {
    if (!message) return;

    try {
      setIsSubmitting(true);
      await sendMessage({
        ...message,
        text: message.text.trim(),
      });
      setIsSubmitting(false);
      setMessage(initMessage);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <MessageInputContainer>
      <TextAreaExpandable
        value={message.text}
        onChange={handleChange}
        placeholder={`Write a message...`}
        forwardedRef={textareaRef}
        autoFocus={!isMobileApp() && !isMobile()}
      />
      <InputControl
        onClick={handleSendMessage}
        disabled={message.text.length < 1 || isSubmitting}
        title="Post Message"
      >
        {!isSubmitting ? (
          <FontAwesomeIcon icon="paper-plane" />
        ) : (
          <FontAwesomeIcon icon="spinner" spin />
        )}
      </InputControl>
    </MessageInputContainer>
  );
};

const MessageInputContainer = styled.div`
  display: flex;
  flex: 0;

  position: relative;
  width: 100%;
  padding: 1rem 0.55rem 1rem 1rem;

  // background-color: #e6e6e6;
  border-top: 1px solid #dedede;

  textarea {
    padding: 0.5rem;
    display: block;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dedede;
    background-color: #f7f7f7;
    font-family: inherit;
    font-size: 0.925rem;

    ::placeholder {
      font-style: normal;
      color: #a7a7a7;
    }

    color: #777;

    outline: none;
    &:focus {
      border: 1px solid #d0d0d0;
    }

    resize: none;
  }
`;

const InputControl = styled.button<{ active?: boolean }>`
  background: none;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;

  height: 2rem;
  width: 2rem;

  font-size: 1.25rem;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  &[disabled] {
    svg {
      color: #bbb !important;
    }
    cursor: auto;
    &:hover {
      background: none;
    }
  }

  &:hover {
    background: #dadada;
  }
`;
