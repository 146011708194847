import styled from 'styled-components';

export function GridLayout({
  siteInfoEditComponent,
  messageBoardComponent,
  participationComponent,
}) {
  return (
    <Grid>
      {siteInfoEditComponent}
      {messageBoardComponent}
      {participationComponent}
    </Grid>
  );
}

const Grid = styled.div`
  display: flex;
  height: 100%;

  // SiteInfo/SiteEdit
  > section:nth-child(1) {
    flex: 1 0 3rem;
  }

  // MessageBoard
  > div:nth-child(2) {
    flex: 2;

    //overflow: auto;

    > div:nth-child(3) {
      overflow: auto;
    }
  }

  // Participants
  > div:nth-child(3) {
    flex: 1 0 5rem;
  }
`;
