import styled from 'styled-components';

export const FormContainer = styled.div`
  height: 100%;

  hr {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  h3 {
    margin-top: 2.5rem;
    font-weight: 500;
  }

  input {
    font-size: 1rem;
    font-weight: 400;
    color: #495057;
  }
`;

export const FormControl = styled.div`
  padding-top: 0.25rem;

  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.95rem;
    font-weight: bold;
  }
`;

export const HelpText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.4;
  color: #a7a7a7;
`;

export const FormSection = styled.section`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;
