import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  ::after,
  ::before {
    content: '';
    border-top: 1px solid #d0d0d0;
    flex: 1;
  }

  :not(:empty)::before {
    margin-right: 0.75rem;
  }

  :not(:empty)::after {
    margin-left: 0.75rem;
  }
`;
