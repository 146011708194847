import {
  isMember,
  getSitePermissionsForMember,
  isSecondarySoilMaker,
  isSiteOwner,
  isSoilSupporter,
} from '../../../utils/site';
import { useAuthUser } from '../../auth/useAuthUser';
import { isAdmin } from '../../auth/utils';
import { Site } from '../model';

/**
 * Attaches some useful permissions to the currently logged in user
 * @param soilSite: Site
 * @returns
 */
export function useCurrentSiteUser({ members }: Site) {
  const authUser = useAuthUser();

  /** User must be a member of this site or Admin */
  const canAccessSite = isMember(members, authUser.id) || isAdmin(authUser);

  const iAmAdmin = isAdmin(authUser);
  const iAmPrimarySoilMaker = isSiteOwner(members, authUser.id);
  const iAmSecondarySoilMaker = isSecondarySoilMaker(members, authUser.id);
  const iAmSoilMaker = iAmPrimarySoilMaker || iAmSecondarySoilMaker;
  const iAmSupporter = isSoilSupporter(members, authUser.id);

  const permissions = getSitePermissionsForMember(
    members,
    authUser.id,
    isAdmin(authUser)
  );

  return {
    canAccessSite,
    iAmAdmin,
    iAmPrimarySoilMaker,
    iAmSecondarySoilMaker,
    iAmSoilMaker,
    iAmSupporter,
    permissions,
  };
}
