import { SiteMember, SITE_ROLE, SitePermissions } from '../features/site/model';
import { generateSiteIdentifier } from './url';

const getMember = (members: SiteMember[], userId: number) => {
  if (!Array.isArray(members)) {
    throw Error('getMember: members must be an Array');
  }
  return members.find((m) => m.userId === userId);
};

export const isMember = (members: SiteMember[], userId: number) => {
  return !!getMember(members, userId);
};

export const getSiteOwner = (members: SiteMember[]) => {
  return members.find((m) => m.role.name === SITE_ROLE.OWNER);
};

export const isSiteOwner = (members: SiteMember[], userId: number) => {
  const member = getMember(members, userId);
  if (!member) return false;
  return member.role.name === SITE_ROLE.OWNER;
};

export const isSoilMaker = (members: SiteMember[], userId: number) => {
  const member = getMember(members, userId);
  if (!member) return false;
  return [SITE_ROLE.OWNER.toString(), SITE_ROLE.MAKER.toString()].includes(
    member.role.name
  );
};

export const isSecondarySoilMaker = (members: SiteMember[], userId: number) => {
  const member = getMember(members, userId);
  if (!member) return false;
  return [SITE_ROLE.MAKER.toString()].includes(member.role.name);
};

export const getSoilMaker = (members: SiteMember[]) => {
  return members.find(({ role }) => SITE_ROLE.MAKER.toString() === role.name);
};

export const getSoilMakers = (members: SiteMember[]) => {
  const makers = members.filter(
    ({ role }) => SITE_ROLE.MAKER.toString() === role.name
  );
  const owner = members.find((m) => m.role.name === SITE_ROLE.OWNER.toString());
  return [owner, ...makers];
};

export const isSoilSupporter = (members: SiteMember[], userId: number) => {
  const member = getMember(members, userId);
  if (!member) return false;
  return member.role.name === SITE_ROLE.SUPPORTER;
};

export const getSupporters = (members: SiteMember[]) => {
  return members.filter(({ role }) => role.name === SITE_ROLE.SUPPORTER);
};

export const getSitePermissionsForMember = (
  members: SiteMember[],
  userId: number,
  isAdmin: boolean
) => {
  const isMaker = isSoilMaker(members, userId);
  const isOwner = isSiteOwner(members, userId);

  const permissions: SitePermissions = {
    // Owner is also a maker but maker is not an Owner
    canEdit: isMaker || isAdmin,
    canPromote: isMaker || isAdmin,
    canDemote: isOwner || isAdmin,
    canRemove: isMaker || isAdmin,
    canTransferOwnership: isOwner || isAdmin,
    canDeactivate: isOwner || isAdmin,
    canLeaveSite: !isOwner,
  };

  return permissions;
};

/**
 * Send the request the Soil Site Poster
 * @param siteData
 */
export const downloadPoster = (
  siteId: number,
  siteName: string
): Promise<Blob> => {
  return fetch('/api/get-poster', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      siteName,
      siteIdentifier: generateSiteIdentifier(siteId, siteName),
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    });
};
