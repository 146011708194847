import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import {
  filterPanelToggled,
  filterSelected,
  selectFilters,
  selectFilterPanelOpen,
} from '../mapSlice';

import { FilterControlIcon } from './FilterControlIcon';

const container = {
  collapsed: {
    opacity: 0,
    height: 0,
  },
  open: {
    opacity: 1,
    height: '80px',

    transition: {
      duration: 0.8,
      ease: [0.04, 0.62, 0.23, 0.98],
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
};

export const FilterPanel = ({ filterVisible }) => {
  const dispatch = useDispatch();
  const open = useAppSelector(selectFilterPanelOpen);
  const filters = useAppSelector(selectFilters);

  const toggleFilterPanel = () => {
    dispatch(filterPanelToggled(!open));
  };

  const onFilterSelected = (filterId: number) => {
    dispatch(filterSelected(filterId));
  };

  if (!filterVisible) return null;

  return (
    <FilterPanelContainer layout>
      {open && (
        <motion.div style={{ marginTop: '0.5rem' }} layout>
          {/* <small style={{ marginLeft: '1rem' }}>Filter: </small> */}
          <FilterControls
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={container}
            layout
          >
            {filters.map((f) => (
              <FilterControl
                key={f.id}
                filter={f}
                onFilterSelected={onFilterSelected}
              />
            ))}
          </FilterControls>
        </motion.div>
      )}
      <FilterToggle layout>
        <FontAwesomeIcon
          icon={!open ? 'chevron-down' : 'chevron-up'}
          style={{ cursor: 'pointer' }}
          color="#68b74e"
          onClick={toggleFilterPanel}
        />
      </FilterToggle>
    </FilterPanelContainer>
  );
};

const FilterControlContainer = styled(motion.div)<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  text-align: center;
  font-size: 0.75rem;
  cursor: pointer;

  img {
    height: 42px;
  }

  ${({ active }) =>
    active &&
    css`
      svg {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}
`;

const FilterControl = ({ filter, onFilterSelected }) => {
  const { id, active, icon, name } = filter;

  const variants = {
    collapsed: { opacity: 0 },
    open: { opacity: 1 },
  };

  return (
    <FilterControlContainer
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      active={active}
      onClick={() => onFilterSelected(id)}
    >
      <FilterControlIcon icon={icon} active={active} />
      <small style={{ marginTop: '0.25rem' }}>{name.toUpperCase()}</small>
    </FilterControlContainer>
  );
};

const FilterPanelContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  width: 100%;

  border-radius: 0 0 8px 8px;
  background-color: #fff;
`;

const FilterControls = styled(motion.div)`
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;

  flex-wrap: wrap;
`;

const FilterToggle = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 0.25rem;
`;
