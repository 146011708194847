import { useHistory } from 'react-router-dom';

/**
 * Get the url the user was trying to get to if exists
 */
export const useReturnUrl = (defaultPath = '/my-soil-sites') => {
  const history = useHistory();
  const search = history.location.search;
  const returnPart = '?returnUrl=';
  let path = '';
  if (search.includes(returnPart)) {
    path = search.split(returnPart)[1];
  } else {
    path = defaultPath;
  }
  return path;
};
