import { gql, useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Checkbox, Flex, Loader } from '../../components/ui';
import { sortByOrder } from '../../utils/helpers';

export const GET_EMAIL_PREFERENCE = gql`
  query GetEmailPreference {
    emailPreference {
      id
      name
      displayName
      active
      order
      category
      optedOut
    }
  }
`;

export const UPDATE_EMAIL_PREFERENCE = gql`
  mutation UpdateEmailPreference($preference: [PreferenceInput!]!) {
    updateEmailPreference(preference: $preference)
  }
`;

export const EmailPreference = () => {
  const { data, loading } = useQuery(GET_EMAIL_PREFERENCE, {
    fetchPolicy: 'network-only',
  });

  const [updateEmailPreference] = useMutation(UPDATE_EMAIL_PREFERENCE);

  const getInitialValues = (preference: any[]) => {
    let shouldSend = [];

    shouldSend.push(
      ...preference.filter((p) => !p.optedOut).map((e) => e.id.toString())
    );

    return {
      shouldSend,
    };
  };

  const handleSubmit = async (selectedValues: any, { setSubmitting }) => {
    const preference = data.emailPreference.map((p) => ({
      id: p.id,
      optedOut: !selectedValues.shouldSend.includes(p.id.toString()),
    }));

    try {
      await updateEmailPreference({
        variables: {
          preference,
        },
      });

      toast.success('Your email preferences have been updated.');
    } catch (error) {
      toast.error('Something went wrong!');
    }

    setSubmitting(false);
  };

  loading && <Loader />;

  if (!data) {
    return null;
  }

  return (
    <Container>
      <Formik
        initialValues={getInitialValues(data.emailPreference)}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <Section>
                <h4>Messaging</h4>
                <hr />
                <h5>Send me an email when:</h5>

                <EmailPreferencesList
                  emailPreference={data.emailPreference}
                  category="Messaging"
                />
              </Section>

              <Section>
                <h4>Soil Site Participation</h4>
                <hr />
                <h5>Send me an email when:</h5>
                <EmailPreferencesList
                  emailPreference={data.emailPreference}
                  category="Soil Site Participation"
                />

                <h5 style={{ marginTop: '1.5rem' }}>
                  Send me an email with a:
                </h5>
                <EmailPreferencesList
                  emailPreference={data.emailPreference}
                  category="Scheduled"
                />
              </Section>
              <Flex>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ flex: 'auto' }}
                >
                  Save Email Preferences
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

function EmailPreferencesList({ emailPreference, category }) {
  return (
    <ul>
      {emailPreference
        .filter((p) => p.category === category)
        .sort(sortByOrder)
        .map((e) => {
          return (
            <li key={e.id}>
              <Field
                type="checkbox"
                as={Checkbox}
                name="shouldSend"
                value={e.id.toString()}
              />{' '}
              <span>{e.displayName}</span>
            </li>
          );
        })}
    </ul>
  );
}

const Container = styled.div`
  padding 1rem;
`;

const Section = styled.div`
  margin-bottom: 3rem;

  hr {
    margin-top: 0.5rem;
  }

  h4,
  h5 {
    margin: 0;
  }

  h5 {
    margin-top: 0.5rem;
  }

  ul {
    margin-top: 1rem;

    li {
      margin-top: 0.5rem;
      font-size: 0.925rem;

      display: flex;
      align-items: center;

      gap: 0.5rem;
    }
  }
`;
