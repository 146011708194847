import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocation } from '../../app/model/ILocation';
import { RootState } from '../../app/store';
import { GeocoderResult } from '../../components/ui/forms/GeoSuggestInput';
import { googleService } from '../map/googleService';

type InitializePayloadType = {
  existingAddressLocation: {
    location?: ILocation;
    address?: string;
  };
  homeLocation?: ILocation;
};

type LocationPickerState = {
  mapCenter: ILocation;
  mapZoom: number;

  address: string;
  pinLocation: ILocation;

  geolocationResult: ILocation;
  geocoderResult: GeocoderResult;
};

const initialState: LocationPickerState = {
  mapCenter: {
    lat: 39.2027073,
    lng: -38.3693131,
  },
  mapZoom: 12,

  address: '',
  pinLocation: null,

  geolocationResult: null,
  geocoderResult: null,
};

const locationPickerSlice = createSlice({
  name: 'locationPicker',
  initialState,
  reducers: {
    /**
     * Scenarios:
     * Signup: default: mapCenter = defaultMapCenter, zoom = 2
     * Signup/Site Wizard: we have user's shared coordinates => mapCenter = coords, zoom = 12
     * Site Edit/User Profile/Site Wizard: there's already a location set => center map on location, zoom = 12
     */
    initialize(state, { payload }: PayloadAction<InitializePayloadType>) {
      state.mapCenter =
        payload.existingAddressLocation.location ||
        payload.homeLocation ||
        initialState.mapCenter;
      state.mapZoom =
        !payload.existingAddressLocation.location && !payload.homeLocation
          ? 2
          : 12;

      // only drop a pin if we have existing location
      if (
        payload.existingAddressLocation.location &&
        payload.existingAddressLocation.address
      ) {
        state.pinLocation = payload.existingAddressLocation.location;
        state.address = payload.existingAddressLocation.address;
      }
    },
    mapZoomUpdated(state, { payload }: PayloadAction<number>) {
      state.mapZoom = payload;
    },
    mapCenterUpdated(state, { payload }: PayloadAction<ILocation>) {
      state.mapCenter = payload;
    },
    setPinLocation(state, { payload }: PayloadAction<ILocation>) {
      state.pinLocation = payload;
    },
    setGeocoderResult(state, { payload }: PayloadAction<GeocoderResult>) {
      state.geocoderResult = payload;
      state.address = payload.address;
    },

    resetLocationPicker(state) {
      return initialState;
    },
  },
});

export const {
  initialize,
  mapZoomUpdated,
  mapCenterUpdated,
  setPinLocation,
  setGeocoderResult,
  resetLocationPicker,
} = locationPickerSlice.actions;

export const selectGeocoderResult = (state: RootState) =>
  state.locationPicker.geocoderResult;
export const selectPinnedLocation = (state: RootState) =>
  state.locationPicker.pinLocation;
export const selectAddress = (state: RootState) => state.locationPicker.address;
export const selectMapZoom = (state: RootState) => state.locationPicker.mapZoom;
export const selectMapCenter = (state: RootState) =>
  state.locationPicker.mapCenter;

export default locationPickerSlice.reducer;

export const geocodeLocation = createAsyncThunk<
  google.maps.GeocoderResult[],
  ILocation
>('locationPicker/geocodeLocation', async (location) => {
  return googleService().geocoder.getLocationDetails(location);
});
