import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../app/hooks';
import { Button, Modal } from '../../components/ui';
import { logout } from '../../features/auth/authSlice';
import { deleteMyAccount } from '../../features/userProfile/userProfileSlice';
import { useSubmitting } from '../../hooks/useSubmitting';

export const DeleteAccount = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div style={{ padding: '1rem' }}>
      <DeleteAccountModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <Button variant="danger" onClick={() => setModalOpen(true)}>
        <FontAwesomeIcon icon="ban" /> Delete User Account
      </Button>
    </div>
  );
};

export const DeleteAccountModal = ({ open, onClose }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleDeleteAccount = async () => {
    const result = await dispatch(deleteMyAccount()).unwrap();

    if (result.deleteMyAccount.message) {
      toast.success(result.deleteMyAccount.message);
      dispatch(logout());
      history.replace('/');
    } else {
      toast.error(result.deleteMyAccount.errors[0]);
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(handleDeleteAccount);

  return (
    <Modal
      header="Delete User Account"
      isOpen={open}
      onClose={onClose}
      actionButton={
        <Button variant="danger" disabled={isSubmitting} onClick={handleSubmit}>
          CONFIRM ACCOUNT DELETION
        </Button>
      }
    >
      <h3>Are you sure you want to delete your account?</h3>
      <p>This action cannot be undone.</p>
    </Modal>
  );
};
