import { useEffect } from 'react';

import { apolloClient } from '../../app/apolloClient';
import { useAppDispatch } from '../../app/hooks';
import { unreadMessageCountIncremented } from '../messenger/messengerSlice';
import {
  getNotificationObservable,
  notificationAdded,
  notify,
} from './notificationCentreSlice';
import { GET_SOIL_SITE } from '../site/graphql';
import { useAuthUser } from '../auth/useAuthUser';

/**
 * Subscribe to in-app notifications
 */
export const useNotificationSubscription = () => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  useEffect(() => {
    let subscription = null;

    if (authUser.isLoggedIn) {
      subscription = getNotificationObservable(authUser.id).subscribe(
        ({ data }) => {
          if (data.notification) {
            const { notification } = data;
            if (notification.event) {
              switch (notification.event) {
                case 'newDirectMessage':
                  /**
                   * This is a hack and once we make the Messages a top level feature
                   * we won't need this anymore.
                   *
                   * We're notifying the user and incrementing the count
                   * only if the user is not already in Messages viewing the thread
                   */
                  if (
                    !window.location.pathname.includes(notification.linkUrl)
                  ) {
                    dispatch(unreadMessageCountIncremented());
                    dispatch(notify(notification));
                  }
                  break;
                case 'newParticipant':
                case 'newAccessRequest':
                case 'userAddedToWaitlist':
                  // this only runs the query on a mounted component
                  // ie: (if the user is already on the soil site the notification is for)
                  apolloClient.refetchQueries({
                    include: [GET_SOIL_SITE],
                  });
                  dispatch(notificationAdded(notification));
                  dispatch(notify(notification));
                  break;
                default:
                  dispatch(notificationAdded(notification));
                  dispatch(notify(notification));
                  break;
              }
            }
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [authUser.id, authUser.isLoggedIn, dispatch]);
};
