import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button, Modal, Flex, TextArea } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';

import { useSiteManager } from '../hooks';
import { getUserDisplayName } from '../../../utils/helpers';

export const ReviewAccessRequestModal = ({
  accessRequest,
  isOpen,
  onClose,
}) => {
  const history = useHistory();

  const [showDecline, setShowDecline] = useState(false);
  const [declineReason, setDeclineReason] = useState('');

  const [showReport, setShowReport] = useState(false);
  const [reportText, setReportText] = useState('');

  const {
    siteId,
    message,
    user: { firstName },
  } = accessRequest;

  const {
    approveAccessRequest: approveRequest,
    declineAccessRequest: declineRequest,
    report,
  } = useSiteManager(siteId);

  const handleReviewAccessRequest = async (
    approved: boolean,
    reason?: string
  ) => {
    try {
      if (approved) {
        const { data } = await approveRequest(accessRequest.id);
        if (data && data.approveRequest.errors.length > 0) {
          toast.error(`${data.approveRequest.errors[0]}`);
          return;
        }
        toast.success(data.approveRequest.message);
      } else {
        const { data } = await declineRequest(accessRequest.id, reason);
        if (data && data.declineRequest.errors.length > 0) {
          toast.error(`${data.declineRequest.errors[0]}`);
          return;
        }
        toast.success(data.declineRequest.message);
      }

      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendDirectMessage = async () => {
    const participants = [{ ...accessRequest.user, isAnonymous: false }];

    history.push(`/messages/t/new`, { recipients: [...participants] });
  };

  const handleReportUser = async (reason: string) => {
    const { data } = await report(
      accessRequest.user.id,
      accessRequest.message,
      reason
    );
    if (data && data.reportUser.errors.length > 0) {
      toast.error(`${data.reportUser.errors[0]}`);
      return;
    }
    toast.success(data.reportUser.message);
    onClose();
    return;
  };

  const {
    handleSubmit: handleAccept,
    isSubmitting: isAccepting,
  } = useSubmitting(() => handleReviewAccessRequest(true));

  const {
    handleSubmit: handleDecline,
    isSubmitting: isDeclining,
  } = useSubmitting(() => handleReviewAccessRequest(false, declineReason));

  const onDecline = () => setShowDecline(true);
  const onReportUser = () => setShowReport(true);

  const variants = {
    enter: {
      x: 1000,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
    },
    exit: {
      zIndex: 0,
      x: -1000,
      opacity: 0,
    },
  };

  const renderActionButton = () => {
    if (showDecline) {
      return (
        <Button
          onClick={handleDecline}
          loading={isDeclining}
          disabled={isDeclining}
        >
          DECLINE
        </Button>
      );
    }

    if (showReport) {
      return (
        <Button
          onClick={() => handleReportUser(reportText)}
          disabled={reportText.length === 0}
        >
          REPORT USER
        </Button>
      );
    }
  };

  const closeModal = () => {
    if (showDecline) {
      return setShowDecline(false);
    }

    if (showReport) {
      return setShowReport(false);
    }

    return onClose();
  };

  return (
    <Modal
      header={!showReport ? 'Respond to request' : 'Report user'}
      isOpen={isOpen}
      onClose={closeModal}
      actionButton={renderActionButton()}
    >
      {!showDecline && !showReport && (
        <motion.div animate="visible" variants={variants} key={1}>
          <p>{getUserDisplayName(firstName)} wants to join your soil site.</p>

          {message !== 'autogenerated' && (
            <Quote>&ldquo;{message}&ldquo;</Quote>
          )}

          <p>Are you happy for {getUserDisplayName(firstName)} to join?</p>

          <Flex direction="column" style={{ gap: '1rem' }}>
            <Button
              onClick={handleAccept}
              loading={isAccepting}
              disabled={isAccepting}
            >
              YES
            </Button>
            <Button variant="danger" onClick={onDecline}>
              NO
            </Button>
          </Flex>

          <Flex justify="space-between" style={{ marginTop: '1.5rem' }}>
            <Button
              variant="link"
              style={{ color: 'red' }}
              onClick={onReportUser}
            >
              Report user
            </Button>
            <Button variant="link" onClick={handleSendDirectMessage}>
              Message user
            </Button>
          </Flex>
        </motion.div>
      )}

      {showDecline && (
        <DeclineReasonContainer animate="visible" variants={variants} key={2}>
          <Button variant="outlined" onClick={() => setShowDecline(false)}>
            <FontAwesomeIcon icon="chevron-left" /> Back
          </Button>

          <label style={{ margin: '1rem 0 0.5rem', display: 'block' }}>
            Let {getUserDisplayName(firstName)} know why you're declining their
            request
          </label>
          <TextArea
            value={declineReason}
            placeholder="Please enter the reason."
            onChange={({ target }) => setDeclineReason(target.value)}
          />
        </DeclineReasonContainer>
      )}

      {showReport && (
        <motion.div animate="visible" variants={variants} key={3}>
          <Button variant="outlined" onClick={() => setShowReport(false)}>
            <FontAwesomeIcon icon="chevron-left" /> Back
          </Button>

          <label style={{ margin: '1rem 0 0.5rem', display: 'block' }}>
            Have you received unwanted communication from this user?
          </label>
          <TextArea
            value={reportText}
            placeholder="Please provide us with the details."
            onChange={({ target }) => setReportText(target.value)}
          />
        </motion.div>
      )}
    </Modal>
  );
};

const Quote = styled.p`
  padding: 0.5rem 1rem;
  border-left: 3px solid #cecece;
  font-style: italic;
  color: #969696;
`;

const DeclineReasonContainer = styled(motion.div)``;
