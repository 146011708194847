import { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { IAuthUser } from '../model';
import { getAuthUser, loginWithRefreshToken } from '../utils';
import { userLoggedIn } from '../authSlice';
import { useAuthUser } from '../useAuthUser';

type AuthCondition = (authUser: IAuthUser) => boolean;

type PrivateRouteProps = {
  condition?: AuthCondition;
} & RouteProps;

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  condition,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  const history = useHistory();

  useEffect(() => {
    (async function authorize() {
      const returnQueryParam = `returnUrl=${history.location.pathname}`;
      const signupUrl = `/signup?${returnQueryParam}`;

      if (authUser.isLoggedIn) {
        // user always has to also have verified account
        if (!authUser.verified) {
          return history.push(
            `/verify?${returnQueryParam}`,
            history.location.state
          );
        }

        // check for condition
        if (condition && !condition(authUser)) {
          // unauthorized
          return history.push(`/`);
        }
      }

      // user NOT logged in scenarios
      if (!authUser.isLoggedIn) {
        // user explicitly logged out just redirect to the index route
        if (authUser.explicitlyLoggedOut) {
          return history.replace(signupUrl, history.location.state);
        }

        // get the user from the stored JWT token
        // user is null if no token present ot token is expired
        let user = getAuthUser();

        // try to login user with a refresh token
        if (!user) {
          let refreshedUser = await loginWithRefreshToken();
          if (refreshedUser) {
            user = { ...refreshedUser, isLoggedIn: true };
          }

          if (!user) {
            /**
             * covering scenario where user might get a direct link to a soil site
             * that only works for participants. In this case redirect to the map.
             *  */
            const { pathname } = history.location;
            const segment = '/my-soil-sites/';
            if (pathname.includes(segment)) {
              return history.replace(
                `/map/site/${pathname.replace(segment, '')}`
              );
            }

            return history.replace(signupUrl, history.location.state);
          }
        }

        // user always has to also have verified account
        if (!user.verified) {
          return history.push(
            `/verify?${returnQueryParam}`,
            history.location.state
          );
        }

        dispatch(userLoggedIn(user));

        // user is now auto logged in so check the condition if set
        if (condition && !condition(user)) {
          // unauthorized
          return history.push(`/`);
        }
      }
    })();
  }, [authUser, condition, dispatch, history]);

  if (condition && condition(authUser)) {
    <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  if (!authUser.isLoggedIn) {
    return null;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
