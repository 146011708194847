import React from 'react';

import { RouteComponentProps } from 'react-router';

import { useSwipeable } from 'react-swipeable';
import { Meta } from '../components/Meta';
import { Profile } from '../features/userProfile/Profile';
import styled from 'styled-components';

const UserProfile: React.FC<RouteComponentProps<{ userHashId: string }>> = ({
  match,
  history,
}) => {
  const handlers = useSwipeable({
    delta: 30,
    onSwiped: (e) => {
      if (e.dir === 'Right') history.goBack();
    },
  });

  return (
    <Container {...handlers}>
      <Meta>
        <title>Personal Info | MakeSoil</title>
      </Meta>
      <Profile userHashId={match.params.userHashId} />
    </Container>
  );
};

export default UserProfile;

const Container = styled.div`
  height: 100%;
  padding: 3rem 1rem;
  overflow-y: auto;
`;
