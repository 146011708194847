import QRCode from 'qrcode';

type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export type QROptions = {
  errorCorrectionLevel?: 'L' | 'M' | 'Q' | 'H';
  version?: IntRange<1, 40>;
  width?: number;
  margin?: number;
};

export const QR = {
  generate: async (text: string, options?: QROptions): Promise<string> => {
    return new Promise((resolve, reject) => {
      return QRCode.toDataURL(text, options)
        .then((url: string) => {
          resolve(url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
