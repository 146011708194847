import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Flex } from '../../components/ui';
import { RouteDetails } from './RouteDetails';
import { RouteSelector } from './RouteSelector';
import { TravelModePicker } from './TravelModePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useCycle } from 'framer-motion';
import { theme } from '../../app/theme';
import { useDirectionsContext } from './DirectionsContext';

const panel = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(1.5rem at 2.15rem 2.15rem)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

export const DirectionsPanel = () => {
  const [isOpen, toggleOpen] = useCycle(true, false);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  const {
    originSource,
    setOriginSource,
    directions,
    selectedRouteIndex,
    routeDetailsVisible,
  } = useDirectionsContext();

  if (!directions) return null;

  const { routes } = directions;

  return (
    <DirectionsPanelContainer
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
      variants={panel}
    >
      <DirectionsPanelContent>
        <Flex align="center">
          <Toggle onClick={() => toggleOpen()} isOpen={isOpen}>
            <FontAwesomeIcon icon={isOpen ? 'times' : 'directions'} />
          </Toggle>

          <Spacer />
          <TravelModePicker />
        </Flex>

        <DestinationDetailsContainer>
          <div
            style={{
              padding: '1rem',
              borderBottom: '1px solid #ebebeb',
            }}
          >
            {originSource === 'home' && (
              <GeolocationWarning>
                <FontAwesomeIcon
                  icon="info-circle"
                  color={theme.colors.primary}
                />
                <small>
                  Starting point set using your <strong>home address</strong>.
                  To use your current location, please{' '}
                  <strong>enable location access</strong> and{' '}
                  <span
                    onClick={() => setOriginSource('geo')}
                    style={{
                      color: theme.colors.primary,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    click here
                  </span>
                  .
                </small>
              </GeolocationWarning>
            )}

            <small>Destination address</small>
            <p style={{ marginTop: '0.125rem' }}>
              {routes[selectedRouteIndex].legs[0].end_address}
            </p>
          </div>
        </DestinationDetailsContainer>

        {!routeDetailsVisible && <RouteSelector />}

        <RouteDetails route={routes[selectedRouteIndex]} />
      </DirectionsPanelContent>
    </DirectionsPanelContainer>
  );
};

const DirectionsPanelContainer = styled(motion.div)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  bottom: 0.5rem;
  z-index: 999;

  width: 95%;
  max-width: 350px;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
`;

const DirectionsPanelContent = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: hidden;
`;

const DestinationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #ebebeb;
  border-bottom: 5px solid #ebebeb;

  p {
    margin: 0;
  }
`;

const Spacer = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  margin: 0 1rem;
`;

const Toggle = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 2.25rem;
  width: 2.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  ${({ isOpen }) =>
    isOpen &&
    `
    box-shadow: revert;
    font-size: 1rem;
    color: revert;
  `}

  :hover {
    cursor: pointer;
    background-color: #ebebeb;
  }
`;

const GeolocationWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  padding-bottom: 1rem;
`;

function useDimensions(ref) {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = ref.current?.offsetWidth;
    dimensions.current.height = ref.current?.offsetHeight;
  }, [ref]);

  return dimensions.current;
}
