import { toast } from 'react-toastify';
import { Modal, Button } from '../../../components/ui';
import { useSubmitting } from '../../../hooks/useSubmitting';
import { SiteMember } from '../model';

import { useSiteManager } from '../hooks';
import { useSoilSiteContext } from '../SoilSiteContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  participant: SiteMember;
};

export const PromoteSoilMakerModal: React.FC<Props> = ({
  isOpen,
  onClose,
  participant,
}) => {
  const { soilSite } = useSoilSiteContext();
  const { promote: promoteToSoilMaker } = useSiteManager(soilSite.id);

  const handlePromoteToSoilMaker = async () => {
    try {
      const { data } = await promoteToSoilMaker(participant);

      if (data && data.promoteToSoilMaker.errors.length > 0) {
        toast.error(`${data.promoteToSoilMaker.errors[0]}`);
      }

      if (data && data.promoteToSoilMaker.soilSite) {
        onClose();
        toast.success(
          `${participant.firstName} has been successfuly promoted to a soil maker.`
        );
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const { handleSubmit, isSubmitting } = useSubmitting(
    handlePromoteToSoilMaker
  );

  return (
    <Modal
      header={`Promote ${participant.firstName} to a Soil Maker`}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          PROMOTE
        </Button>
      }
    >
      <p>
        Are you sure you want to promote{' '}
        <strong>{participant.firstName}</strong> to <strong>Soil Maker</strong>?
      </p>
      <p>
        They will be able to edit the information about this soil site and
        manage the participants.
      </p>
      <p>
        You will still remain as the primary Soil Maker unless you transfer
        ownership.
      </p>
    </Modal>
  );
};
