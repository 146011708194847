export interface Theme {
  colors: {
    primary: string;
    primaryDark: string;
    secondary: string;
    accent: string;
    grey: string;
    greyLight: string;
    background: string;
    contentBackground: string;
    success: string;
    warning: string;
    error: string;
    info: string;
    brown: string;
  };
  borderRadius: number;
  timing: number;
  breakpoints: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  headerHeight: string;
}

export const theme: Theme = {
  colors: {
    primary: '#45B139',
    primaryDark: '#5ba044',
    secondary: '',
    accent: '',
    grey: '#727272',
    greyLight: '#ccc',
    background: '#242424',
    contentBackground: '#f4f4f4',
    success: '#45B139',
    warning: '#bd7021',
    error: '#d44e4e',
    info: '#2f6fb1',
    brown: '#82671F',
  },
  borderRadius: 4,
  timing: 0.35,
  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  headerHeight: '60px',
};

export interface MediaQuerySet {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

export const queries: MediaQuerySet = {
  xs: `(max-width: ${theme.breakpoints.xs})`,
  sm: `(max-width: ${theme.breakpoints.sm})`,
  md: `(max-width: ${theme.breakpoints.md})`,
  lg: `(max-width: ${theme.breakpoints.lg})`,
  xl: `(max-width: ${theme.breakpoints.xl})`,
};
