import { FormikErrors } from 'formik';
import isValidEmail from 'validator/lib/isEmail';

export const validationMessages = {
  firstName: 'Please provide a first name.',
  email: 'Please provide a valid email address.',
  emailTaken: 'There is already a MakeSoil account with this email address.',
  phoneNum: 'Please enter a valid phone number.',
  address: 'Type and select an address, or use the map.',
  terms: 'Please accept terms and conditions.',
  recaptcha: 'Please complete the reCAPTCHA.',
  password: 'Please provide a password.',

  location: 'Please enable location access in your settings.',

  soilSite: {
    name: 'Please provide a soil site name.',
    description: 'Please say more about your soil site.',
    address: 'Type and select an address, or use the map.',
    access: 'Please say more about how to find your soil site.',
    dropOff: 'Please say more about how to drop off scraps.',
    hours: 'Please say when your soil site is open.',
  },
};

export const isEmail = (email: string) => {
  if (!email) return false;

  return isValidEmail(email);
  //return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const isPhoneNumber = (phoneNum: string) => {
  if (!phoneNum) return false;

  const validPhoneNumberLength = 9;
  const phonePattern = new RegExp(/^[0-9-+\s()\b]+$/);

  if (phoneNum.length < validPhoneNumberLength) return false;
  return phonePattern.test(phoneNum);
};

export const scrollToFormError = (errors: FormikErrors<any>) => {
  const err = Object.keys(errors);
  if (err.length) {
    const element = document.querySelector(`[name=${err[0]}]`)
      .previousElementSibling;
    element.scrollIntoView({
      block: 'start',
    });
  }
};
