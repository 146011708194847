import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../../../app/hooks';
import { SmartImage } from '../../../components/ui';
import { generateMapItemIdentifier } from '../../../utils/url';
import { drawerItemHighlighted, mapItemSelected } from '../mapSlice';
import { IDrawerListItem, MapItemType } from '../model';
import { TimeAgo } from '../../../components/ui/TimeAgo';

export type DrawerListItemProps = {
  item: IDrawerListItem;
};

export const DrawerListItem: React.FC<DrawerListItemProps> = (props) => {
  const { item } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const dateText =
    item.type === MapItemType.SoilSite ? 'Registered:' : 'Member since:';

  const name = `${item.name.substring(0, 21)}${
    item.name.length > 21 ? '...' : ''
  }`;

  const handleSelected = () => {
    dispatch(
      mapItemSelected({
        id: item.id,
        name: item.name,
        type: item.type,
        location: item.location,
      })
    );

    const identifier = generateMapItemIdentifier(item.id, name);
    history.push(`/map/${item.type}/${identifier}?drawerOpen=true`);
  };

  return (
    <DrawerListItemContainer
      onMouseEnter={() => dispatch(drawerItemHighlighted(item.id))}
      onMouseLeave={() => dispatch(drawerItemHighlighted(null))}
      onClick={handleSelected}
      key={item.id}
    >
      <ItemImage>
        <SmartImage
          src={item.picture}
          type="site"
          width={100}
          asBackground={true}
        />
      </ItemImage>
      <ItemContent>
        <h4>{name}</h4>
        <small>
          {dateText} <TimeAgo date={item.createdAt} />
        </small>
      </ItemContent>
    </DrawerListItemContainer>
  );
};

export const DrawerListItemContainer = styled.div`
  display: flex;
  // padding: 0.25rem;
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;

  :not(:first-child) {
    margin-top: 0.5rem;
  }

  :hover {
    background-color: #e0f6d9;
    transition: background-color 0.2s ease-in;
  }
`;

export const ItemImage = styled.div`
  flex: 1;

  div,
  img {
    height: 100%;
  }
`;

export const ItemContent = styled.div`
  flex: 3;
  margin-left: 1rem;
  padding: 0.75rem 0;

  h4 {
    margin: 0 0 0.25rem;
  }
`;
