import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';

import { initializeFontAwesome } from './fontAwesome';
import { initializeRollbar } from './utils/rollbar';
import { initializeMobile } from './mobile/mobile';

import { store } from './app/store';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './app/apolloClient';
import { ThemeProvider } from 'styled-components';
import { theme } from './app/theme';

initializeFontAwesome();
initializeRollbar();
initializeMobile();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
