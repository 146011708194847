import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { theme } from '../../app/theme';

const FlexContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  gap: ${({ gap }) => gap};

  @media (max-width: ${({ theme, collapse }) => theme.breakpoints[collapse]}) {
    ${({ collapse }) => collapse && `flex-direction: column;`}
  }
`;

type ContainerProps = {
  direction?: 'column' | 'row';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial';
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'initial';
  gap?: string;
  /**
   * changes the flex direction to column at given breakpoint
   */
  collapse?: keyof typeof theme.breakpoints;
};

type FlexProps = {
  className?: string;
  style?: CSSProperties;
} & ContainerProps;

export const Flex: React.FC<FlexProps> = (props) => {
  const {
    children,
    className,
    style,
    direction = 'row',
    justify = 'initial',
    align = 'initial',
    gap,
    collapse,
  } = props;
  return (
    <FlexContainer
      direction={direction}
      justify={justify}
      align={align}
      gap={gap}
      collapse={collapse}
      className={className}
      style={style}
    >
      {children}
    </FlexContainer>
  );
};
