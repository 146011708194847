import { ILocation } from '../../app/model/ILocation';
import { User } from '../../app/model/User';
import { apolloClient } from '../../app/apolloClient';
import { Site } from '../site/model';
import { googleService } from './googleService';
import {
  GET_SITE_MARKERS,
  GET_SOIL_SITE_DETAILS,
  GET_SUPPORTERS,
  GET_UNMATCHED_USERS,
  GET_USER_DETAILS,
  SEARCH_BY_KEYWORDS,
} from './graphql';
import { Bounds, IMapItem, MapItemType } from './model';

import accessrequestService from '../site/accessrequestService';
import { getUserDisplayName } from '../../utils/helpers';

interface SiteWithinBounds {
  id: number;
  name: string;
  createdAt: Date;
  picture?: string;
  location: {
    lat: number;
    lng: number;
  };
}

interface SiteWithinBoundsData {
  sitesWithinBounds: SiteWithinBounds[];
}

interface SiteByKeywords {
  id: number;
  name: string;
  location: {
    lat: number;
    lng: number;
  };
}

interface SiteByKeywordsData {
  sitesByKeywords: SiteByKeywords[];
}

interface SiteDetailsResult {
  soilSiteDetails: Site;
}

interface UserDetailsResult {
  userDetails: User;
}

interface UserMapItem {
  id: number;
  firstName: string;
  lastName?: string;
  location: ILocation;
  createdAt: Date;
  hasPendingRequests: boolean;
}

interface UnmatchedUsersData {
  unmatchedUsersWithinBounds: UserMapItem[];
}

interface SupportersResult {
  supportersWithinBounds: UserMapItem[];
}

export interface SearchResults {
  makesoil: IMapItem[];
  predictions: google.maps.places.AutocompletePrediction[];
}

class MapService {
  async getSoilSitesWithinBounds(bounds: Bounds) {
    const { data } = await apolloClient.query<SiteWithinBoundsData>({
      query: GET_SITE_MARKERS,
      variables: { bounds },
    });

    return data.sitesWithinBounds.map((s) => ({
      ...s,
      type: MapItemType.SoilSite,
    }));
  }

  async getUnmatchedUsersWithinBounds(bounds: Bounds) {
    const { data } = await apolloClient.query<UnmatchedUsersData>({
      query: GET_UNMATCHED_USERS,
      variables: { bounds },
    });

    if (data) {
      return data.unmatchedUsersWithinBounds.map(
        ({
          id,
          location,
          firstName,
          lastName,
          hasPendingRequests,
          createdAt,
        }) => ({
          id,
          location,
          name: getUserDisplayName(firstName, lastName),
          createdAt,
          type: !hasPendingRequests
            ? MapItemType.UnmatchedUser
            : MapItemType.AccessPending,
        })
      );
    }
  }

  async getSupportersWithinBounds(bounds: Bounds) {
    const { data } = await apolloClient.query<SupportersResult>({
      query: GET_SUPPORTERS,
      variables: { bounds },
    });
    if (data) {
      return data.supportersWithinBounds.map(
        ({ id, location, firstName, lastName, createdAt }) => ({
          id,
          location,
          name: getUserDisplayName(firstName, lastName),
          createdAt,
          type: MapItemType.SoilSupporter,
        })
      );
    }
  }

  fetchMapItemDetailsById = () => {};

  async fetchSoilSitesBySearchTerm(searchTerm: string) {
    const { data: sitesData } = await apolloClient.query<SiteByKeywordsData>({
      query: SEARCH_BY_KEYWORDS,
      variables: { keywords: searchTerm },
    });
    let results = [];
    // Add the SoilSite type property to the returned soil site result
    if (sitesData) {
      results = [
        ...sitesData.sitesByKeywords.map((s) => ({
          ...s,
          type: MapItemType.SoilSite,
        })),
      ];
    }
    return results;
  }

  async fetchPlacesBySearchTerm(searchParam: string) {
    const predictions = await googleService().places.searchPlaces(searchParam);

    // check if the exact match is not moved up the array
    // in case of "India" search it's at the second place [1]
    const indexOfExactMatch = predictions.findIndex(
      (p) =>
        p.structured_formatting.main_text.toLowerCase() ===
        searchParam.toLowerCase()
    );
    if (indexOfExactMatch > 0) {
      // remove the exact match and put it back at the beginning
      const exactMatch = predictions.splice(indexOfExactMatch, 1)[0];
      predictions.splice(0, 0, exactMatch);
    }

    return predictions;
  }

  /**
   * Needs to search for place predictions first by serahc term in order to get place_id
   * Then searches for place details using geocoder
   * @param searchParam
   * @returns
   */
  async fetchPlaceLocationBySearchTerm(
    searchParam: string
  ): Promise<{
    details: google.maps.GeocoderResult;
    predictions: google.maps.places.AutocompletePrediction[];
  }> {
    if (searchParam) {
      const predictions = await googleService().places.searchPlaces(
        searchParam
      );

      const indexOfExactMatch = predictions.findIndex(
        (p) =>
          p.structured_formatting.main_text.toLowerCase() ===
          searchParam.toLowerCase()
      );
      if (indexOfExactMatch > 0) {
        // remove the exact match and put it back at the beginning
        const exactMatch = predictions.splice(indexOfExactMatch, 1)[0];
        predictions.splice(0, 0, exactMatch);
      }

      const exactMatch = predictions.find(
        (p) =>
          p.structured_formatting.main_text.toLowerCase() ===
          searchParam.toLowerCase()
      );

      const [placeDetails] = await googleService().geocoder.getPlaceLocation(
        exactMatch?.place_id || predictions[0].place_id
      );
      return JSON.parse(JSON.stringify({ predictions, details: placeDetails }));
    }
  }

  /**
   * Fetches place details using geocoder
   * @param place
   * @returns
   */
  async fetchPlaceLocation(
    place: google.maps.places.AutocompletePrediction
  ): Promise<google.maps.GeocoderResult> {
    if (place) {
      const [placeDetails] = await googleService().geocoder.getPlaceLocation(
        place.place_id
      );
      return JSON.parse(JSON.stringify(placeDetails));
    }
  }

  async geocode(searchTerm: string): Promise<google.maps.GeocoderResult[]> {
    if (searchTerm) {
      const results = await googleService().geocoder.getLocationFromSearch(
        searchTerm
      );
      return JSON.parse(JSON.stringify(results));
    }
  }

  async getSoilSiteById(id: number) {
    return apolloClient.query<SiteDetailsResult>({
      query: GET_SOIL_SITE_DETAILS,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  }

  async fetchUserById(id: number) {
    return apolloClient.query<UserDetailsResult>({
      query: GET_USER_DETAILS,
      variables: { id },
      fetchPolicy: 'network-only',
    });
  }

  cancelAccessRequest = (requestId: number) => {
    return accessrequestService.cancelAccessRequest(requestId);
  };
}

export default new MapService();
