import { Messenger } from '../features/messenger/components/Messenger';
import { Meta } from '../components/Meta';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

const Messages = ({ history }) => {
  const handlers = useSwipeable({
    delta: 30,
    onSwiped: (e) => {
      if (e.dir === 'Right') history.go(-2);
    },
  });

  return (
    <PageContainer {...handlers}>
      <Meta>
        <title>Messages | MakeSoil</title>
      </Meta>
      <Messenger />
    </PageContainer>
  );
};

export default Messages;

const PageContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;
