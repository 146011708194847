import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import styled from 'styled-components';

const CollapsibleContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  > hr {
    flex: 1;
    margin: 0 1rem;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    padding: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .collapsible-header {
      flex: 10;
    }
  }

  svg,
  i {
    color: ${(props) => props.theme.colors.primary};
  }

  cursor: pointer;
`;

const Content = styled(motion.div)``;

type CollapsibleProps = {
  label: string;
  open?: boolean;
  icon?: React.ReactElement;
  className?: string;
  style?: React.CSSProperties;
};

export const Collapsible: React.FC<CollapsibleProps> = (props) => {
  const { children, label, icon, open = true, className, style } = props;

  const [isOpen, setIsOpen] = useState(open);

  return (
    <CollapsibleContainer className={className} style={style}>
      <Header onClick={() => setIsOpen(!isOpen)}>
        {icon && <span style={{ marginRight: '0.357rem' }}>{icon}</span>}
        <span className="collapsible-header">{label}</span>
        <hr />
        <FontAwesomeIcon icon={isOpen ? 'chevron-down' : 'chevron-right'} />
      </Header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <Content
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </Content>
        )}
      </AnimatePresence>
    </CollapsibleContainer>
  );
};
