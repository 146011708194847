import { toast } from 'react-toastify';

import { Modal } from '../../../components/ui';
import { useDropOff } from './useDropOff';
import { DropOffForm } from './DropOffForm';
import { DropOffPayload, EditDropOffPayload } from './model';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  dropOff: EditDropOffPayload;
};

export const EditDropOffModal: React.FC<Props> = ({
  isOpen,
  onClose,
  dropOff,
}) => {
  const { updateDropOff } = useDropOff();

  const handleUpdateDropOff = async (payload: DropOffPayload) => {
    try {
      const { comment, weight, attachments } = payload;

      await updateDropOff({
        comment,
        weight,
        siteId: dropOff.siteId,
        sitePostId: dropOff.sitePostId,
        attachments,
      });

      toast.success('Your drop-off has been updated.');
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal header="Edit drop-off" isOpen={isOpen} onClose={onClose} hideFooter>
      <DropOffForm
        editMode
        useDropOffAs={false}
        useWeight={true}
        dropOff={dropOff}
        onRegisterDropOff={handleUpdateDropOff}
        onCancel={onClose}
      />
    </Modal>
  );
};
