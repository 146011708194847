import styled from 'styled-components';
import { Route, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Flex } from '../../../components/ui';
import { ThreadList } from '../thread/ThreadList';
import { Thread } from '../thread/Thread';
import { MessageInput } from './MessageInput';
import { useMessenger } from '../useMessenger';
import { useMessengerSubscription } from '../useMessengerSubscription';
import { useToggleSidebar } from '../useToggleSidebar';
import { useFetchThreads } from '../useFetchThreads';
import { useSelectFirstThreadOnInit } from '../thread/useSelectFirstThreadOnInit';

export const Messenger = () => {
  const match = useRouteMatch();

  const { threads, sidebarOpen } = useMessenger();
  useFetchThreads();
  useToggleSidebar();
  useMessengerSubscription();
  useSelectFirstThreadOnInit();

  if (match.isExact && !threads.length) {
    return <NoMessages />;
  }

  return (
    <>
      <Sidebar isOpen={sidebarOpen}>
        <ThreadList />
      </Sidebar>

      <Route
        exact
        path="/messages/t/:threadIdentifier"
        render={() => (
          <ThreadContainer>
            <Thread />
            <MessageInput />
          </ThreadContainer>
        )}
      />
    </>
  );
};

const NoMessages = () => {
  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <FontAwesomeIcon icon="comments" size="3x" color="#bdbdbd" />
      <div style={{ marginTop: '1rem', color: '#bdbdbd' }}>No Messages</div>
    </Flex>
  );
};

const Sidebar = styled.div<{ isOpen: boolean }>`
  flex: 1;
  max-width: 69px;
  min-width: 69px;

  ${({ isOpen }) =>
    isOpen &&
    `
    flex: none;
    width: 100%;
    max-width: 100%;`}

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 69px;

    ${({ isOpen }) =>
      isOpen &&
      `
			flex: none;
			width: 100%;
			max-width: 100%;`}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 2;
    max-width: 100%;
    min-width: 260px;
  }

  background-color: #f1f1f1;
  border-right: 1px solid #e0e0e0;
`;

const ThreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
`;
