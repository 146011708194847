import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type PlaceholderProps = {
  type: 'Maker' | 'Supporter';
  first: boolean;
};

export const Placeholder: React.FC<PlaceholderProps> = ({ type, first }) => {
  let text: string, url: string, icon: any;

  switch (type) {
    case 'Maker':
      text = 'Could you start another soil site?';
      url = '/create-soil-site';
      icon = 'plus-circle';
      break;
    case 'Supporter':
      text = 'Could you join another soil site?';
      url = '/map';
      icon = 'search';
      break;
    default:
      break;
  }

  if (first) {
    text = `Could you become a Soil ${type}?`;
  }

  return (
    <PlaceholderContainer>
      <Link to={url} style={{ height: '100%' }}>
        <p>{text}</p>
        <FontAwesomeIcon icon={icon} size="2x" style={{ opacity: '0.5' }} />
        <p>{type === 'Maker' ? 'Create' : 'Find'} a soil site</p>
      </Link>
    </PlaceholderContainer>
  );
};

const PlaceholderContainer = styled.div`
  min-width: 270px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 300px;
  }

  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.primary};

  a {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p:last-child {
    text-decoration: underline;
  }
`;
