import React, { useEffect } from 'react';
import styled from 'styled-components';

const Player = styled.div`
  width: 100%;
  height: auto;
`;

type Props = {
  videoId: string;
  onPlayerStateChange?: any;
  onPlayerReady?: any;
  autoplay?: boolean;
};

export const YTPlayer: React.FC<Props> = (props) => {
  const {
    videoId,
    onPlayerStateChange,
    onPlayerReady,
    autoplay = false,
  } = props;

  let player;

  const onReady = () => {
    if (autoplay) {
      player.playVideo();
    }
    if (onPlayerReady) {
      onPlayerReady();
    }
  };

  const loadVideo = () => {
    // the Player object is created uniquely based on the id in props
    player = new (window as any).YT.Player(`yt-player-${videoId}`, {
      videoId,
      events: {
        onReady,
        onStateChange: onPlayerStateChange,
      },
    });
  };

  useEffect(() => {
    // On mount, check to see if the API script is already loaded
    if (!(window as any).YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      (window as any).onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      loadVideo();
    }
  }, []);

  return <Player id={`yt-player-${videoId}`} />;
};
