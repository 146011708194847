import React, { useEffect, useState, useContext } from 'react';
import { getCurrentLocation } from '../map/utils';
import { useAuthUser } from '../auth/useAuthUser';

const useDirections = () => {
  const authUser = useAuthUser();

  const [origin, setOrigin] = useState<google.maps.LatLng | null>(null);
  const [originSource, setOriginSource] = useState<'home' | 'geo'>(null);

  const [travelMode, setTravelMode] = useState(google.maps.TravelMode.WALKING);
  const [
    directions,
    setDirections,
  ] = useState<google.maps.DirectionsResult | null>(null);

  const [selectedRouteIndex, setSelectedRouteIndex] = useState<number>(0);
  const [routeDetailsVisible, setRouteDetailsVisible] = useState(false);

  const [directionsPanelExpanded, setDirectionsPanelExpanded] = useState(true);

  useEffect(() => {
    (async function setDirectionsOrigin() {
      try {
        const location = await getCurrentLocation();
        const { latitude: lat, longitude: lng } = location.coords;
        setOrigin(new google.maps.LatLng(lat, lng));
        setOriginSource('geo');
      } catch (error) {
        setOrigin(new google.maps.LatLng(authUser.location));
        setOriginSource('home');
      }
    })();
  }, [authUser.location, originSource]);

  return {
    origin,
    originSource,
    setOriginSource,

    travelMode,
    setTravelMode,

    directions,
    setDirections,

    selectedRouteIndex,
    setSelectedRouteIndex,

    routeDetailsVisible,
    setRouteDetailsVisible,

    directionsPanelExpanded,
    setDirectionsPanelExpanded,
  };
};

const DirectionsContext = React.createContext<ReturnType<typeof useDirections>>(
  null
);

export const DirectionsContextProvider = ({ children }) => {
  return (
    <DirectionsContext.Provider value={useDirections()}>
      {children}
    </DirectionsContext.Provider>
  );
};

export const useDirectionsContext = () => useContext(DirectionsContext);
