import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ErrorComponent = ({ error }) => {
  return <PageContainer>{`Error! ${error.message}`}</PageContainer>;
};
