import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import messengerService from './messengerService';
import {
  IDirectMessage,
  IMessageInput,
  IThread,
  IThreadParticipant,
} from './model';

interface IMessengerState {
  threads: IThread[];
  selectedThreadId: number;
  unreadMessagesCount: number;

  sidebarOpen: boolean;
  loading: boolean;
  error: string;
}

const initialState: IMessengerState = {
  threads: [],
  selectedThreadId: null,
  unreadMessagesCount: 0,

  sidebarOpen: true,

  loading: false,
  error: '',
};

export const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    threadSelected(state, { payload }: PayloadAction<number>) {
      state.threads = state.threads.map((thread) => {
        return {
          ...thread,
          // select the right one and unselect the others
          selected: thread.id === payload ? true : false,
        };
      });
      state.selectedThreadId = payload;
    },
    // user created new fresh thread
    threadCreated(state, { payload }: PayloadAction<IThreadParticipant[]>) {
      state.threads.unshift({
        id: null,
        title: '',
        lastMessage: null,
        participants: payload,
        messages: [],

        isNew: true,
        selected: true,
        unreadMessageCount: 0,
        haveFetched: false,
        fetchedMore: false,
      });
      state.selectedThreadId = null;
    },
    // a new incoming thread has been received by a subscribtion
    threadReceived(state, { payload }: PayloadAction<IThread>) {
      state.threads.unshift({
        ...payload,
        unreadMessageCount: 1,
        selected: false,
        haveFetched: false,
        fetchedMore: false,
      });
    },
    // a new incoming message received
    messageReceived(state, { payload }: PayloadAction<IDirectMessage>) {
      const thread = state.threads.find(
        (t) => t.id === payload.directMessageThreadId
      );
      if (!thread) return;

      // update the unread message count
      let unreadMessageCount = thread.unreadMessageCount;
      if (payload.directMessageThreadId !== state.selectedThreadId) {
        unreadMessageCount = unreadMessageCount + 1;
      }

      thread.unreadMessageCount = unreadMessageCount;
      thread.messages.unshift(payload);
      thread.lastMessage = payload;
    },
    sidebarToggled(state, { payload }: PayloadAction<boolean>) {
      state.sidebarOpen = payload;
    },
    unreadMessageCountIncremented(state) {
      state.unreadMessagesCount++;
    },
    unmounted(state) {
      state.selectedThreadId = null;
      // remove the new thread if user has not sent a message
      state.threads = state.threads.filter((t) => !!t.id);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUnreadMessageCount.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.unreadMessagesCount = payload;
      })
      .addCase(fetchThreads.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.threads = payload.map((thread) => ({
          ...thread,
          // adding the 'utility' props to all threads
          haveFetched: false,
          fetchedMore: false,
        }));
      })
      .addCase(fetchMessages.fulfilled, (state, { payload }) => {
        state.loading = false;

        const thread = state.threads.find((t) => t.id === payload.threadId);
        if (!thread) return;

        thread.selected = true;
        thread.haveFetched = true;
        thread.fetchedMore = false;
        thread.messages = payload.messages;
      })
      .addCase(fetchMoreMessages.fulfilled, (state, { payload }) => {
        state.loading = false;
        // update the state only if we actually fetched more messages
        if (payload.messages.length > 0) {
          const thread = state.threads.find((t) => t.id === payload.threadId);
          if (!thread) return;

          thread.fetchedMore = true;
          thread.messages.push(...payload.messages);
        }
      })
      .addCase(sendMessage.fulfilled, (state, { payload }) => {
        state.loading = false;

        const thread = state.threads.find(
          (t) => t.id === payload.directMessageThreadId
        );
        thread.messages.unshift(payload);
        thread.lastMessage = payload;
      })
      .addCase(sendMessageInNewThread.fulfilled, (state, { payload }) => {
        state.loading = false;

        const thread = state.threads.find((t) => !t.id && t.isNew);
        if (!thread) return;

        thread.id = payload.id;
        thread.messages = payload.messages;
        thread.lastMessage = payload.lastMessage;
        thread.participants = payload.participants;
        thread.selected = true;
        thread.isNew = true;

        state.selectedThreadId = payload.id;
      })
      .addCase(markRead.fulfilled, (state, { payload }) => {
        state.loading = false;

        const thread = state.threads.find((t) => t.id === payload);
        if (!thread) return;

        // update the total unread message count
        state.unreadMessagesCount -= thread.unreadMessageCount;
        // update thread's unread message count
        thread.unreadMessageCount = 0;
      })

      .addMatcher(
        isPending(
          fetchUnreadMessageCount,
          fetchThreads,
          fetchMessages,
          fetchMoreMessages,
          sendMessage,
          sendMessageInNewThread,
          markRead
        ),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(
          fetchUnreadMessageCount,
          fetchThreads,
          fetchMessages,
          fetchMoreMessages,
          sendMessage,
          sendMessageInNewThread,
          markRead
        ),
        (state, action) => {
          console.log('rejected', action);
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(
          fetchUnreadMessageCount,
          fetchThreads,
          fetchMessages,
          fetchMoreMessages,
          sendMessage,
          sendMessageInNewThread,
          markRead
        ),
        (state, action) => {
          console.log('rejected', action);
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export const {
  threadCreated,
  threadSelected,
  threadReceived,
  messageReceived,
  unreadMessageCountIncremented,

  sidebarToggled,
  unmounted,
} = messengerSlice.actions;

//
// selectors

export const selectThreads = (state: RootState) => state.messenger.threads;
export const selectUnreadMessagesCount = (state: RootState) =>
  state.messenger.unreadMessagesCount;

export const selectSelectedThread = (state: RootState) =>
  state.messenger.threads.find(
    (t) => t.id === state.messenger.selectedThreadId
  );

export const selectSidebarOpen = (state: RootState) =>
  state.messenger.sidebarOpen;

export default messengerSlice.reducer;

/**
 * Messages subscription
 * @param userId
 * @returns
 */
export const getMessengerObservable = (userId) => {
  return messengerService.subscribeToMessages(userId);
};

//#region thunks

export const fetchUnreadMessageCount = createAsyncThunk<number>(
  'messenger/fetchUnreadMessageCount',
  async (_, { rejectWithValue }) => {
    const { data, error } = await messengerService.getUnreadMessageCount();
    if (!data && error) return rejectWithValue(error);

    return data.unreadMessageCount;
  }
);

export const fetchThreads = createAsyncThunk(
  'messenger/fetchThreads',
  async (_, { rejectWithValue }) => {
    const { data, error } = await messengerService.getThreads();
    if (!data && error) return rejectWithValue(error);

    return data.threads;
  }
);

export const fetchMessages = createAsyncThunk<FetchMessagesResponse, number>(
  'messenger/fetchMessages',
  async (threadId, { rejectWithValue }) => {
    if (threadId) {
      const { data, error } = await messengerService.getThreadMessages(
        threadId
      );
      if (!data && error) return rejectWithValue(error);

      return { threadId, messages: data.threadMessages };
    }
  }
);

type FetchMessagesArgs = {
  threadId: number;
  cursor?: Date;
};

type FetchMessagesResponse = {
  threadId: number;
  messages: IDirectMessage[];
};

export const fetchMoreMessages = createAsyncThunk<
  FetchMessagesResponse,
  FetchMessagesArgs
>(
  'messenger/fetchMoreMessages',
  async ({ threadId, cursor }, { rejectWithValue }) => {
    const { data, error } = await messengerService.getThreadMessages(
      threadId,
      cursor
    );
    if (!data && error) return rejectWithValue(error);

    return { threadId, messages: data.threadMessages };
  }
);

export const sendMessage = createAsyncThunk<
  any,
  { threadId: number; message: IMessageInput }
>(
  'messenger/sendMessage',
  async ({ threadId, message }, { rejectWithValue }) => {
    const { data, errors } = await messengerService.sendMessage(
      message,
      threadId
    );
    if (!data && errors) return rejectWithValue(errors);

    return data.sendMessage;
  }
);

export const sendMessageInNewThread = createAsyncThunk<
  IThread,
  {
    message: IMessageInput;
    participants: Pick<IThreadParticipant, 'id' | 'isAnonymous'>[];
  }
>(
  'messenger/sendMessageInNewThread',
  async ({ message, participants }, { rejectWithValue }) => {
    const { data, errors } = await messengerService.startThread(
      message,
      participants
    );
    if (!data && errors) return rejectWithValue(errors);

    return data.startThread;
  }
);

export const markRead = createAsyncThunk<any, number>(
  'messenger/markRead',
  async (threadId, { rejectWithValue }) => {
    const { data, errors } = await messengerService.markRead(threadId);
    if (!data && errors) return rejectWithValue(errors);

    return threadId;
  }
);

//#endregion
