import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Flex, TagInput, Button } from '../../../components/ui';
import { Tooltip } from '../../../components/ui/Tooltip';
import { isMobile } from '../../../utils/helpers';

const SUGGESTIONS = {
  accepted: [
    'Fruit',
    'Vegetables',
    'Plant-based foods',
    'Cooked foods',
    'Grease',
    'Egg shells',
    'Dairy',
    'Meat',
    'Bones',
    'Tea bags',
    'Coffee grounds',
    'Coffee filters',
    'Paper',
    'Dry leaves and flowers',
    'Grass clippings',
    'Hair',
    'Natural fibers (e.g. cotton, wool)',
    'Paper towels',
    'Potted plants',
  ],
  prohibited: [
    'Plastic',
    'Produce Stickers',
    'Junk mail',
    'Printed paper',
    'Bulky yard-waste',
    'Pet waste',
    'Anything treated with Roundup',
    'Metal',
    'Glass',
  ],
};

type Props = {
  accepted?: string[];
  prohibited?: string[];
  acceptedHeader?: string;
  prohibitedHeader?: string;
  showResetToDefaults?: boolean;
  onMaterialsChange: (materials: {
    accepted: string[];
    prohibited: string[];
  }) => void;
  style?: any;
};

const delimiters = ['Enter', 'Comma'];

export const MaterialsPicker: React.FC<Props> = (props) => {
  const {
    accepted,
    prohibited,
    acceptedHeader = 'Accepted Materials',
    prohibitedHeader = 'Prohibited Materials',
    showResetToDefaults = true,
    onMaterialsChange,
    style,
  } = props;

  const [materials, setMaterials] = useState(() => ({
    // use materials from props (ex. if form was saved) or suggestions
    accepted: accepted.length ? accepted : SUGGESTIONS.accepted,
    prohibited: prohibited.length ? prohibited : SUGGESTIONS.prohibited,
  }));

  const acceptedInputRef = useRef(null);

  // Used only for initialization of the outer form field values.
  // Do not use this to pass values out on each change. Rather, see the
  // onMaterialsChange call at the end of handleMaterialChange
  useEffect(() => {
    onMaterialsChange({
      accepted: materials.accepted,
      prohibited: materials.prohibited,
    });
  }, []);

  function handleMaterialChange(
    material: string,
    type: 'accepted' | 'prohibited',
    action: 'add' | 'delete'
  ) {
    const addHandler = (materials: string[]) => [...materials, material];
    const removeHandler = (materials: string[]) => [
      ...materials.filter((m) => m !== material),
    ];

    let acceptedMaterials, prohibitedMaterials;

    if (action === 'add') {
      if (type === 'accepted') {
        setMaterials(({ accepted, prohibited }) => {
          acceptedMaterials = addHandler(accepted);
          prohibitedMaterials = prohibited;
          return {
            accepted: acceptedMaterials,
            prohibited,
          };
        });
        /**
         * Prevent jumping to the prohibited field after adding a tag and pressing "Next"
         * on Android devices
         */
        if (isMobile()) {
          // refocus on self
          acceptedInputRef.current?.focus();
        }
      }
      if (type === 'prohibited') {
        setMaterials(({ accepted, prohibited }) => {
          acceptedMaterials = accepted;
          prohibitedMaterials = addHandler(prohibited);
          return {
            accepted,
            prohibited: prohibitedMaterials,
          };
        });
      }
    }

    if (action === 'delete' && type === 'accepted') {
      setMaterials(({ accepted, prohibited }) => {
        acceptedMaterials = removeHandler(accepted);
        // when user removes one of accepted materials we move it to prohibited
        // but only when it's a default (NOT user created)
        prohibitedMaterials = SUGGESTIONS.accepted.includes(material)
          ? addHandler(prohibited)
          : prohibited;

        return {
          accepted: acceptedMaterials,
          prohibited: prohibitedMaterials,
        };
      });
    }

    if (action === 'delete' && type === 'prohibited') {
      setMaterials(({ accepted, prohibited }) => {
        acceptedMaterials = accepted;
        prohibitedMaterials = removeHandler(prohibited);
        return {
          accepted,
          prohibited: prohibitedMaterials,
        };
      });
    }

    onMaterialsChange({
      accepted: acceptedMaterials,
      prohibited: prohibitedMaterials,
    });
  }

  function restoreDefaults(type: 'accepted' | 'prohibited') {
    if (type === 'accepted') {
      setMaterials((materials) => ({
        accepted: SUGGESTIONS.accepted,
        prohibited: materials.prohibited,
      }));
    }
    if (type === 'prohibited') {
      setMaterials((materials) => ({
        accepted: materials.accepted,
        prohibited: SUGGESTIONS.prohibited,
      }));
    }
  }

  const inputPlaceholder = `Type item, press ${isMobile() ? 'Go' : 'Enter'}`;

  return (
    <MaterialsPickerContainer style={style}>
      <Flex justify="space-between" align="center">
        <h3>{acceptedHeader}:</h3>
        <Flex align="center">
          {showResetToDefaults && (
            <RestoreButton
              variant="link"
              onClick={() => restoreDefaults('accepted')}
            >
              Restore Defaults
            </RestoreButton>
          )}

          <Tooltip style={{ marginLeft: '1rem' }}>
            What compostable materials do you accept?
          </Tooltip>
        </Flex>
      </Flex>
      <TagInput
        forwardedRef={acceptedInputRef}
        delimiters={delimiters}
        style={{ marginTop: '0.5rem' }}
        name="accepted"
        defaultTags={materials.accepted}
        placeholder={inputPlaceholder}
        className="accepted"
        onAdd={(material) => handleMaterialChange(material, 'accepted', 'add')}
        onDelete={(material) =>
          handleMaterialChange(material, 'accepted', 'delete')
        }
      />
      <br />
      <Flex justify="space-between" align="center">
        <h3>{prohibitedHeader}:</h3>

        <Flex align="center">
          {showResetToDefaults && (
            <RestoreButton
              variant="link"
              onClick={() => restoreDefaults('prohibited')}
            >
              Restore Defaults
            </RestoreButton>
          )}
          <Tooltip style={{ marginLeft: '1rem' }}>
            What materials do you NOT accept?
          </Tooltip>
        </Flex>
      </Flex>
      <TagInput
        tabIndex={isMobile() ? -1 : null}
        delimiters={delimiters}
        style={{ marginTop: '0.5rem' }}
        name="prohibited"
        defaultTags={materials.prohibited}
        placeholder={inputPlaceholder}
        className="prohibited"
        onAdd={(material) =>
          handleMaterialChange(material, 'prohibited', 'add')
        }
        onDelete={(material) =>
          handleMaterialChange(material, 'prohibited', 'delete')
        }
      />
    </MaterialsPickerContainer>
  );
};

const MaterialsPickerContainer = styled.div`
  h3 {
    margin: 0 !important;
    font-size: 1rem;
  }

  .accepted {
    span {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: #fbfbfb;
    }
  }

  .prohibited {
    span {
      color: ${({ theme }) => theme.colors.error};
      border-color: ${({ theme }) => theme.colors.error};
      background-color: #fbfbfb;
    }
  }
`;

const RestoreButton = styled(Button)`
  padding: 0.5rem 0;
  font-size: 0.75rem;
`;
