import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { LightRoom, useLightRoom } from '../../../components/ui/LightRoom';
import { injectFilestackOptions } from '../../../utils/url';
import { Attachment } from './model';

type Props = {
  attachments: Attachment[];
  lightRoomEnabled?: boolean;
  removable?: boolean;
};

export const MessageAttachmentGrid: React.FC<Props> = ({
  attachments,
  lightRoomEnabled = false,
}) => {
  const [lightRoomState, setLightRoomState] = useLightRoom();

  if (attachments.length === 0) return null;

  const openLightRoom = (e: React.MouseEvent<HTMLLIElement>) => {
    if (!lightRoomEnabled) return;
    setLightRoomState({
      open: true,
      activeIndex: parseInt(e.currentTarget.getAttribute('data-index')),
    });
  };

  const closeLightRoom = () => {
    setLightRoomState({
      open: false,
      activeIndex: 0,
    });
  };

  const maxAttachmentsDisplay = 2;
  const totalAttachments = attachments.length;
  const shouldOverlay = totalAttachments > maxAttachmentsDisplay;
  let attachmentsToDisplay = attachments;

  if (attachmentsToDisplay.length > maxAttachmentsDisplay) {
    attachmentsToDisplay = attachments.slice(0, maxAttachmentsDisplay);
  }

  return (
    <MessageAttachmentsContainer>
      <LightRoom
        images={attachments.map((a) =>
          injectFilestackOptions(a.url, [`resize=width:600`])
        )}
        state={lightRoomState}
        setState={setLightRoomState}
        onClose={closeLightRoom}
      />
      <ul>
        {attachmentsToDisplay.map((attachment, i) => {
          const isLastAttachment = attachmentsToDisplay.length - 1 === i;
          return (
            <li
              key={attachment.fileName}
              data-index={i}
              onClick={openLightRoom}
            >
              {
                // add count overlay to last attachment
                shouldOverlay && isLastAttachment && (
                  <AttachmentOverlay>
                    <p>+{totalAttachments - attachmentsToDisplay.length}</p>
                  </AttachmentOverlay>
                )
              }
              {renderAttachment(attachment)}
            </li>
          );
        })}
      </ul>
    </MessageAttachmentsContainer>
  );
};

function renderAttachment(attachment: Attachment, width = 600) {
  // render accordingly to a file type
  if (attachment.fileType.includes('image/')) {
    return (
      <img
        src={injectFilestackOptions(attachment.url, [`resize=width:${width}`])}
        loading="lazy"
        alt="message attachment"
      />
    );
  }

  if (attachment.fileType.includes('application/pdf')) {
    return (
      <PdfAttachment key={attachment.fileName}>
        <FontAwesomeIcon icon="file-pdf" size="2x" />
        <span>{attachment.fileName}</span>
      </PdfAttachment>
    );
  }
}

const PdfAttachment = styled.div`
  background-color: rgba(51, 51, 51, 0.7);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: #fff;
`;

const AttachmentOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    margin: 0;
    font-size: 2rem;
    color: #fff;
  }
`;

const MessageAttachmentsContainer = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1px;

    & ::after {
      content: '';
      flex-grow: 999;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      height: 250px;
      cursor: pointer;
      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
`;
