export class DeferredPromise<T> {
  promise?: Promise<T>;
  resolve?: (value: T) => void;
  reject?: (reason: any) => void;
  pending?: boolean;
  timer?: any;

  constructor(tmo?: number, error?: string) {
    this.promise = new Promise((resolve, reject) => {
      this.pending = true;
      this.resolve = (v) => {
        this.clearState();
        resolve(v);
      };
      this.reject = (e) => {
        this.clearState();
        reject(e);
      };
    });
    if (tmo !== undefined) {
      this.timer = setTimeout(() => {
        this.reject(error);
      }, 5000);
    }
  }

  clearState() {
    this.pending = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
