import { Field, ErrorMessage, useFormikContext } from 'formik';
import styled from 'styled-components';
import { Input, FormInputError } from '../../components/ui';
import { useAuthUser } from '../auth/useAuthUser';
import {
  additionalContactInfoRequired,
  firstNameRequired,
} from '../auth/utils';
import { useState } from 'react';
import { WizardForm } from './model';
import { UserDisplayName } from '../../components/ui/UserDisplayName';

export const Step4 = ({ stepCount }) => {
  const authUser = useAuthUser();

  const makerNameRequired = firstNameRequired(authUser, 'create');
  const contactInfoRequired = additionalContactInfoRequired(authUser, 'create');

  const { values, setFieldValue } = useFormikContext<WizardForm>();

  const [firstName, setFirstName] = useState(authUser.firstName);
  const [lastName, setLastName] = useState(authUser.lastName);

  return (
    <AdditionalContactInfoForm>
      <h1 className="step-title">
        Create a soil site: Step 4/
        {stepCount}
      </h1>
      <hr />

      {makerNameRequired && (
        <FormSection>
          <h3>What is your name?</h3>
          <HelpText>
            So other people on MakeSoil know whom they&apos;re connecting with.
          </HelpText>

          <label>First Name *</label>
          <Field
            type="text"
            name="firstName"
            as={Input}
            onChange={({ target: { value } }) => {
              setFieldValue('firstName', value);
              setFirstName(value);
            }}
          />
          <ErrorMessage name="firstName" component={FormInputError} />

          <label style={{ marginTop: '1rem' }}>Last Name</label>
          <Field
            type="text"
            name="lastName"
            as={Input}
            onChange={({ target: { value } }) => {
              setFieldValue('lastName', value);
              setLastName(value);
            }}
          />

          <div>
            {values.firstName && (
              <span>
                <HelpText>
                  People will see you as:{' '}
                  <strong>
                    <UserDisplayName
                      firstName={firstName}
                      lastName={lastName}
                    />
                  </strong>
                </HelpText>
              </span>
            )}
          </div>
        </FormSection>
      )}

      {contactInfoRequired && (
        <>
          <FormSection>
            <h3>Please verify your contact info.</h3>
            <HelpText>
              Just in case MakeSoil ever needs to contact you about the
              management of your soil site.
            </HelpText>

            <label>Phone Number *</label>
            <Field type="tel" name="phoneNum" as={Input} />
            <ErrorMessage name="phoneNum" component={FormInputError} />
          </FormSection>

          <h4
            style={{
              marginBottom: '0',
              fontSize: '0.95rem',
              fontWeight: 'bold',
            }}
          >
            Social Media (optional)
          </h4>

          <Field
            type="text"
            name="instagram"
            as={Input}
            placeholder="Instagram handle"
            style={{ marginTop: '0.5rem' }}
          />

          <Field
            type="text"
            name="twitter"
            as={Input}
            placeholder="X (Twitter) handle"
            style={{ marginTop: '0.5rem' }}
          />

          <Field
            type="text"
            name="facebook"
            as={Input}
            placeholder="Facebook URL"
            style={{ marginTop: '0.5rem' }}
          />
          <Field
            type="text"
            name="linkedin"
            as={Input}
            placeholder="LinkedIn URL"
            style={{ marginTop: '0.5rem' }}
          />
        </>
      )}
    </AdditionalContactInfoForm>
  );
};

const AdditionalContactInfoForm = styled.div`
  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.95rem;
    font-weight: bold;
  }
`;

const HelpText = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.4;
  color: #838383;
`;

const FormSection = styled.section`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;

  h3 {
    margin: 0;
    margin-bottom: 1.5rem;
  }
`;
