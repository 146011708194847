import styled from 'styled-components';

import { RegionSelector } from '../../features/insights/RegionSelector';
import { Regional } from '../../features/insights/Regional';
import { useState } from 'react';

const regions = [
  {
    id: 1,
    displayName: 'Cincinnati, OH, USA',
    keyword: 'Cincinnati',
    placeId: 'ChIJ-SE43rFRQIgRF5PA5It--2k',
  },
  {
    id: 2,
    displayName: 'Birmingham, UK',
    keyword: 'Birmingham',
    placeId: 'ChIJc3FBGy2UcEgRmHnurvD-gco',
  },
  {
    id: 3,
    displayName: 'Dubai - United Arab Emirates',
    keyword: 'Dubai',
    placeId: 'ChIJRcbZaklDXz4RYlEphFBu5r0',
  },
];

const Insights = () => {
  const [regionId, setRegionId] = useState(1);

  return (
    <PageContainer>
      <PageHeader>
        <strong>MakeSoil Insights</strong> |
        <RegionSelector regions={regions} onSelected={setRegionId} />
      </PageHeader>

      <Regional region={regions.find((r) => r.id === regionId)} />
    </PageContainer>
  );
};

export default Insights;

const PageContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const PageHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: fit-content;
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
  padding: 0.75rem 1rem;
`;
