import { apolloClient } from '../../app/apolloClient';
import {
  DELETE,
  GET_NOTIFICATIONS,
  LOAD_NOTIFICATIONS,
  MARK_READ_MULTIPLE,
  SUBSCRIBE,
  UPDATE_READ_STATE,
} from './graphql';

class NotificationCentreService {
  subscribe = (userId) => {
    return apolloClient.subscribe({
      query: SUBSCRIBE,
      variables: { userId },
      fetchPolicy: 'no-cache',
    });
  };

  load = async () => {
    return apolloClient.query({
      query: LOAD_NOTIFICATIONS,
      fetchPolicy: 'no-cache',
    });
  };

  getNotifications = async (cursor: Date | null = null) => {
    return apolloClient.query({
      query: GET_NOTIFICATIONS,
      variables: { cursor },
      fetchPolicy: 'no-cache',
    });
  };

  updateReadState = async (notificationId: number, read: boolean) => {
    return apolloClient.mutate({
      mutation: UPDATE_READ_STATE,
      variables: { notificationId, read },
      fetchPolicy: 'no-cache',
    });
  };

  markRead = async (notifications: number[]) => {
    return apolloClient.mutate({
      mutation: MARK_READ_MULTIPLE,
      variables: { notifications },
      fetchPolicy: 'no-cache',
    });
  };

  delete = async (notificationId: number) => {
    return apolloClient.mutate({
      mutation: DELETE,
      variables: { notificationId },
      fetchPolicy: 'no-cache',
    });
  };
}

export default new NotificationCentreService();
