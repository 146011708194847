import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SmartImage } from '../../components/ui';
import { Card } from './Card';
import { Badge, ColorVariant } from '../../components/ui/Badge';
import { pluralize } from '../../utils/helpers';

type SiteCardProps = {
  picture: string;
  siteName: string;
  isSoilMaker: boolean;
  soilMaker: string;
  siteUrl: string;
  participants: number;
  active: boolean;
  disabled: boolean;
  displayEditButton?: boolean;
  pendingAccessRequests: number;
  dropOffs: number;
};

export const SiteCard: React.FC<SiteCardProps> = (props) => {
  const {
    picture,
    siteName,
    isSoilMaker,
    soilMaker,
    siteUrl,
    active,
    disabled,
    displayEditButton = false,
    pendingAccessRequests,
    dropOffs,
  } = props;

  const showPendingAccessrequests = isSoilMaker && pendingAccessRequests > 0;

  return (
    <Card
      image={
        <Link to={siteUrl} style={disabled ? { pointerEvents: 'none' } : {}}>
          <SiteImage
            src={picture}
            width={400}
            type="site"
            asBackground
            deactivated={!active}
          >
            {!active && (
              <div>
                <span>DEACTIVATED</span>
              </div>
            )}
          </SiteImage>
        </Link>
      }
      content={
        <>
          <h4>{siteName}</h4>
          {!isSoilMaker && (
            <p>
              Soil Maker: <strong>{soilMaker}</strong>
            </p>
          )}

          {showPendingAccessrequests && (
            <Link to={`${siteUrl}?tab=participants`}>
              <PendingAccessRequests>
                <Badge variant="error">
                  {pendingAccessRequests}{' '}
                  {pluralize(pendingAccessRequests, {
                    singular: 'person',
                    plural: 'people',
                  })}
                </Badge>{' '}
                waiting to join.
              </PendingAccessRequests>
            </Link>
          )}
        </>
      }
      actions={
        <>
          {displayEditButton && (
            <Link
              to={`${siteUrl}/edit`}
              className="outlined"
              style={
                disabled
                  ? { pointerEvents: 'none', backgroundColor: '#ccc' }
                  : {}
              }
            >
              Edit Site
            </Link>
          )}
          <Link
            to={siteUrl}
            style={
              disabled ? { pointerEvents: 'none', backgroundColor: '#ccc' } : {}
            }
          >
            Enter Site
          </Link>
        </>
      }
      footer={<DropOffScore dropOffs={dropOffs} />}
    />
  );
};

const SiteImage = styled(SmartImage)<{ deactivated?: boolean }>`
  border-radius: 4px 4px 0 0;
  position: relative;

  ${({ deactivated }) =>
    deactivated &&
    `
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);

			font-size: 1.75rem;
			font-style: italic;
			color: #fff;
			text-shadow: 0 0 5px #000;

			div {
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba(0, 0, 0, 0.5);
				height: 100%;
				width: 100%;

				position: absolute;
        bottom: 0;
			}
		`}
`;

const PendingAccessRequests = styled.p`
  padding: 0.5rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.error};
  border: 1px solid ${({ theme }) => theme.colors.error};
  border-radius: 4px;
`;

export function DropOffScore({ dropOffs }) {
  const variant: ColorVariant = dropOffs > 0 ? 'primary' : 'error';

  return (
    <DropOffScoreContainer variant={variant}>
      <Badge variant={variant}>{dropOffs}</Badge> Drop-offs recorded
    </DropOffScoreContainer>
  );
}

const DropOffScoreContainer = styled.p<{ variant: ColorVariant }>`
  font-size: 0.875rem;
  margin: 0;
`;
