import { useRef } from 'react';

export function useScrollIntoView() {
  const ref = useRef(null);

  function scrollIntoView() {
    return ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return {
    ref,
    scrollIntoView,
  };
}
