import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  images: string[];
  interval?: number;
  duration?: number;
};

export const ImageSlider = ({
  images,
  interval = 7000,
  duration = 1,
}: Props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (interval) {
      setTimeout(() => {
        // start from scratch
        if (slideIndex === images.length - 1) return setSlideIndex(0);
        return setSlideIndex(slideIndex + 1);
      }, interval);
    }
  }, [slideIndex, interval, images.length]);

  return (
    <AnimatePresence>
      <Slider>
        {images.map((img, i) => {
          if (slideIndex !== i) return null;
          return (
            <Slide
              key={img}
              url={img}
              variants={{
                enter: {
                  opacity: 0.1,
                },
                center: {
                  zIndex: 1,
                  opacity: 1,
                },
                exit: {
                  zIndex: 0,
                  opacity: 0.1,
                },
              }}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                opacity: { duration },
              }}
            />
          );
        })}
      </Slider>
    </AnimatePresence>
  );
};

const Slider = styled.div`
  height: 100%;
  width: 100%;

  display: block;
  position: relative;
  overflow: hidden;
`;

const Slide = styled(motion.div)<{ url: string }>`
  background-image: url('${(props) => props.url}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  width: 100%;
  height: 100%;
  object-fit: cover;

  //   aspect ratio  | padding-bottom value
  // --------------|----------------------
  //     16:9      |       56.25%
  //     4:3       |       75%
  //     3:2       |       66.66%
  //     8:5       |       62.5%

  padding-bottom: 56.25%;
`;
