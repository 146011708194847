import { WeightUnit } from './formatting';

export function kgToLbs(weight: number): number {
  return weight * 2.2046;
}

export function lbsToKg(weight: number): number {
  return weight / 2.2046;
}

export function convertWeight(weight: number, weightUnit: WeightUnit) {
  return weightUnit === 'pound' ? kgToLbs(weight) : lbsToKg(weight);
}
