import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { credentials } from '../utils/config';

export const useAnalytics = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      new Promise((resolve) => {
        ReactGA.initialize([
          {
            trackingId: credentials.get('GA4_KEY'),
          },
        ]);
        if (!!(window as any).ga) resolve(true);
      }).then(() => {
        setInitialized(!!(window as any).ga);
      });
    }
  }, [initialized]);

  return { initialized };
};
