import { ILocation } from '../../../app/model/ILocation';
import { Modal } from '../../../components/ui';
import { Directions } from '../../directions/Directions';

type Props = {
  isOpen: boolean;
  destination: ILocation;
  destinationName: string;
  onClose: () => void;
};

export const DirectionsModal: React.FC<Props> = ({
  isOpen,
  destination,
  destinationName,
  onClose,
}) => {
  return (
    <Modal
      header={`Directions to ${destinationName}`}
      isOpen={isOpen}
      onClose={onClose}
      noPadding
      full
    >
      <Directions destination={destination} />
    </Modal>
  );
};
