import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { AuthPageLayout } from '..';
import {
  Button,
  ErrorComponent,
  Flex,
  Loader,
  SmartImage,
} from '../../components/ui';
import { additionalContactInfoRequired } from '../../features/auth/utils';
import { formatDate } from '../../utils/date';
import { getQueryParameter, getIdFromIdentifier } from '../../utils/url';
import { GET_SITE_MINIMAL_DETAILS } from '../../features/site/graphql';
import { useAuthUser } from '../../features/auth/useAuthUser';

type SiteMinimalDetailsResult = {
  soilSiteMinimalDetails: {
    id: number;
    name: string;
    picture?: string;
    createdAt: Date;
    membersCount: number;
  };
};

type RouteParams = {
  siteIdentifier: string;
};

const AcceptInvitation = (props: RouteComponentProps<RouteParams>) => {
  const { match, location, history } = props;

  const authUser = useAuthUser();

  const { siteIdentifier } = match.params;
  const siteId = getIdFromIdentifier(siteIdentifier);
  const invitationCode = getQueryParameter(location.search, 'invite');
  const result = getQueryParameter(location.search, 'result');

  const { data, loading, error } = useQuery<SiteMinimalDetailsResult>(
    GET_SITE_MINIMAL_DETAILS,
    {
      variables: { id: siteId },
    }
  );

  useEffect(() => {
    if (!invitationCode) {
      return history.push(`/map/site/${siteIdentifier}`);
    }
  }, [history, invitationCode, siteIdentifier]);

  if (loading) return <Loader />;
  if (error) return <ErrorComponent error={error} />;

  const site = data.soilSiteMinimalDetails;

  const handleAcceptInvitation = async () => {
    const state = {
      action: 'invitation',
      site: {
        siteId,
        siteName: site.name,
      },
      invitation: {
        siteIdentifier,
        invitationCode,
      },
    };

    if (!authUser.hasPassword) {
      return history.push(`/onboarding/create-password`, state);
    } else if (!authUser.firstName) {
      return history.push(`/onboarding/your-name`, state);
    } else if (additionalContactInfoRequired(authUser, 'invitation')) {
      return history.push(`/onboarding/verify-contact-info`, state);
    } else {
      return history.push(`/onboarding/stay-safe`, state);
    }
  };

  return (
    <AuthPageLayout contentContainerStyle={{ justifyContent: 'center' }}>
      <PageContainer>
        {result === 'waitlist' ? (
          <WaitlistResult siteName={site.name} />
        ) : (
          <Welcome
            authUser={authUser}
            siteDetails={site}
            returnUrl={`?returnUrl=/invitation/${siteIdentifier}?invite=${invitationCode}`}
            onAcceptInvitation={handleAcceptInvitation}
          />
        )}
      </PageContainer>
    </AuthPageLayout>
  );
};

const Welcome = ({ onAcceptInvitation, authUser, returnUrl, siteDetails }) => {
  return (
    <div>
      <h2>{authUser.firstName && `Welcome ${authUser.firstName}`}</h2>
      <h3>You have been invited to a soil site on MakeSoil.</h3>

      <SiteMedia site={siteDetails} />

      {!authUser.isLoggedIn ? (
        <>
          <p style={{ marginTop: '2rem' }}>
            To join this soil site, please create an account.
          </p>

          <Link to={`/signup${returnUrl}`}>
            <Button style={{ marginTop: '1rem' }}>Create Account</Button>
          </Link>
          <small style={{ marginTop: '1rem' }}>
            Already have an account?{' '}
            <Link to={`/login${returnUrl}`}>Log in</Link>
          </small>
        </>
      ) : (
        <Button style={{ marginTop: '2rem' }} onClick={onAcceptInvitation}>
          ACCEPT INVITATION
        </Button>
      )}
    </div>
  );
};

const WaitlistResult = ({ siteName }) => {
  return (
    <div>
      <p>
        You need the approval of the Soil Maker to join their soil site{' '}
        <strong>{siteName}</strong>.
      </p>

      <p>If you are approved to join, we will notify you.</p>

      <Link to="/soil-sites-near-you">
        <Button style={{ marginTop: '2rem' }}>VIEW SOIL SITES NEAR YOU</Button>
      </Link>
    </div>
  );
};

const SiteMedia = ({ site }) => {
  const { picture, membersCount, createdAt, name } = site;
  return (
    <div
      style={{
        backgroundColor: 'rgba(51, 51, 51, 0.5)',
        border: '1px solid rgb(68, 68, 68)',
      }}
    >
      <Flex gap="1rem" style={{ padding: '1rem' }}>
        <SmartImage src={picture} width={100} />
        <div style={{ textAlign: 'left' }}>
          <h3 style={{ margin: '0 0 0.85rem' }}>{name}</h3>
          <div style={{ fontSize: '0.875rem' }}>
            <p style={{ margin: '0.25rem 0' }}>
              Registered:{' '}
              {formatDate(createdAt, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
            <p style={{ margin: '0.25rem 0' }}>Participants: {membersCount}</p>
          </div>
        </div>
      </Flex>
    </div>
  );
};

// our condition is ret
export default AcceptInvitation;

const PageContainer = styled.div``;
