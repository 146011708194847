import { gql } from '@apollo/client';
import { apolloClient } from '../../app/apolloClient';
import { cdn } from '../../utils/config';
import { rollbar } from '../../utils/rollbar';

export const GET_REMOVE_FILE_URLS = gql`
  mutation getRemoveFileUrls($handles: [String!]!) {
    getRemoveFileUrls(handles: $handles) {
      urls
    }
  }
`;

export async function removeOldFilestackImage(imageUrl: string) {
  // if this is the default site image the iageUrl will be empty string
  // we do not want to rem
  if (!imageUrl) return;
  const handle = extractHandle(imageUrl);
  // we still do the check for default image in case something changes up the chain
  const isDefaultSiteImage = handle === extractHandle(cdn.siteImage.FALLBACK);
  if (isDefaultSiteImage) return;

  const { data } = await getRemoveFileUrls([handle]);
  if (data) {
    removeFilesFromFilestack(data.getRemoveFileUrls.urls);
  }
}

/**
 * Extracts the filestack file handle
 * @param url filestack cdn url
 * @returns handle string
 */
export function extractHandle(url: string) {
  return url.substr(url.lastIndexOf('/') + 1);
}

export async function removeFilesFromFilestack(urls: string[]) {
  const removeResult = {
    filesRemoved: [] as string[],
    filesFailed: [] as string[],
  };

  urls.forEach(async (url) => {
    try {
      const result = await fetch(url, {
        method: 'DELETE',
      });

      if (result.status === 200) {
        removeResult.filesRemoved.push(url);
      }
    } catch (error) {
      console.error(error);
      removeResult.filesFailed.push(url);
    }
  });

  if (removeResult.filesFailed.length > 0) {
    const { filesFailed } = removeResult;
    const handles = filesFailed.map((url) => extractHandle(url));

    console.log('Filestack Files Removal Failed', handles);
    rollbar.error('Filestack Files Removal Failed', {
      handles,
    });
  }

  return removeResult;
}

type RemoveUrls = { urls: string[] };

type GetRemoveFilesUrlResult = {
  getRemoveFileUrls: RemoveUrls;
};

export async function getRemoveFileUrls(handles: string[]) {
  return apolloClient.mutate<GetRemoveFilesUrlResult>({
    mutation: GET_REMOVE_FILE_URLS,
    variables: {
      handles,
    },
  });
}
