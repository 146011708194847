import { useEffect } from 'react';
import { queries } from '../../app/theme';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useMessenger } from './useMessenger';

/**
 * Toggle sidebar when screen size changes
 */
export function useToggleSidebar() {
  const isMedium = useMediaQuery(queries.md);
  const { toggleSidebar } = useMessenger();

  useEffect(() => {
    toggleSidebar(!isMedium);
  }, [toggleSidebar, isMedium]);
}
