import { useEffect, useState } from 'react';
import { ScreenSize } from '../features/layout/layoutSlice';
import { theme } from '../app/theme';
import { useDebounce } from './useDebounce';

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<ScreenSize>(() =>
    getDeviceConfig(window.innerWidth)
  );

  const debouncedBreakpoint = useDebounce(breakpoint, 200);

  useEffect(() => {
    const calcInnerWidth = () =>
      setBreakpoint(getDeviceConfig(window.innerWidth));

    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return debouncedBreakpoint;
};

function getDeviceConfig(width: number) {
  const sm = parseInt(theme.breakpoints.sm.replace('px', ''));
  const md = parseInt(theme.breakpoints.md.replace('px', ''));
  const lg = parseInt(theme.breakpoints.lg.replace('px', ''));
  const xl = parseInt(theme.breakpoints.xl.replace('px', ''));

  if (width < sm) {
    return 'sm';
  } else if (width >= sm && width < md) {
    return 'md';
  } else if (width >= md && width < lg) {
    return 'lg';
  } else if (width >= lg && width < xl) {
    return 'xl';
  } else if (width >= xl) {
    return 'xl';
  }
}
