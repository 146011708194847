import { _isProd } from './url';

/**
 * Get the credentials from the environment
 */
export const credentials = {
  get: (
    key:
      | 'MAPS_API_KEY'
      | 'FILESTACK'
      | 'HASHER_SALT'
      | 'GOOGLE_ANALYTICS_KEY'
      | 'GA4_KEY'
      | 'RECAPTCHA_SITE_KEY'
      | 'ROLLBAR'
      | 'VAPID_PUBLIC_KEY'
      | 'STRIPE_PUBLISHABLE_KEY'
  ) => {
    return process.env[`REACT_APP_${key}`];
  },
};

export const cdn = {
  profileImage: {
    MAX_WIDTH: 480,
    MAX_FILE_SIZE: 5 * 1024 * 1024,
    RATIO: '1/1',
    TRANSFORMATIONS: {
      crop: {
        aspectRatio: 1 / 1,
        force: true,
      },
      rotate: true,
    },
    FALLBACK: 'https://cdn.filestackcontent.com/OWxUQCYRGG8IEPnklrZP',
  },
  siteImage: {
    MAX_WIDTH: 1920,
    MAX_FILE_SIZE: 5 * 1024 * 1024,
    RATIO: '4/3',
    TRANSFORMATIONS: {
      crop: {
        aspectRatio: 4 / 3,
        force: true,
      },
      rotate: true,
      circle: false,
    },
    FALLBACK: 'https://cdn.filestackcontent.com/7io1TwdUQaUWrYINmD6r',
  },
};

export const mobileAppStoreUrls = {
  googlePlayStore:
    'https://play.google.com/store/apps/details?id=org.makesoil.app',
  appleStore: 'https://apps.apple.com/app/makesoil/id1613073059',
};

const defaultSiteImagesLocal = [
  'https://cdn.filestackcontent.com/emRCxz3sQrW5t5cn9QQV',
  'https://cdn.filestackcontent.com/6ytbF00TJei1qQzhru5w',
  'https://cdn.filestackcontent.com/Hu3OHSYxQNeyLU5esFY7',
  'https://cdn.filestackcontent.com/6s2FcDigSJiIx2gw1yPK',
  'https://cdn.filestackcontent.com/CViGgB5ZQ4e8HicRwdGW',
];

const defaultSiteImagesProduction = [
  'https://cdn.filestackcontent.com/4TeWSNaQdOqcILT5oIbg',
  'https://cdn.filestackcontent.com/hPxeQhtoRy2Zs4HTdtaz',
  'https://cdn.filestackcontent.com/pEAQLHZQ5qsefP6RsopP',
  'https://cdn.filestackcontent.com/wKFOv4HWTjO7NH8jsQIe',
  'https://cdn.filestackcontent.com/iq5QVsXkQgslFHR9L0mQ',
];

export const defaultSiteImages = _isProd
  ? defaultSiteImagesProduction
  : defaultSiteImagesLocal;
