import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { theme } from '../../app/theme';
import { useDirectionsContext } from './DirectionsContext';

type Props = {
  route: google.maps.DirectionsRoute;
};

export const RouteDetails = ({ route }: Props) => {
  const {
    routeDetailsVisible,
    setRouteDetailsVisible,
  } = useDirectionsContext();

  if (!routeDetailsVisible) return null;

  return (
    <RouteDetailsContainer>
      <BackButton onClick={() => setRouteDetailsVisible(false)}>
        <FontAwesomeIcon icon="chevron-left" />
        <h5>Go back</h5>
      </BackButton>

      {!!route.warnings.length && (
        <Warnings>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            color={theme.colors.warning}
          />
          <small>{route.warnings}</small>
        </Warnings>
      )}

      <small
        style={{
          margin: '1rem',
        }}
      >
        Route details:
      </small>

      <Route>
        <ul>
          {route.legs[0].steps.map((step) => (
            <li key={step.start_location.toString()}>
              <ManeueverIcon>
                {renderManeuverIcon((step as any).maneuver)}
              </ManeueverIcon>
              <div style={{ fontSize: '0.875rem' }}>
                <div dangerouslySetInnerHTML={{ __html: step.instructions }} />
                <small>{step.distance.text}</small>
              </div>
            </li>
          ))}
        </ul>
        <Copyrights>
          <small>{route.copyrights}</small>
        </Copyrights>
      </Route>
    </RouteDetailsContainer>
  );
};

function renderManeuverIcon(
  maneuever:
    | ''
    | 'turn-right'
    | 'turn-slight-right'
    | 'turn-sharp-right'
    | 'turn-left'
    | 'turn-slight-left'
    | 'turn-sharp-left'
) {
  let icon;
  switch (maneuever) {
    case 'turn-right':
      icon = <FontAwesomeIcon icon="long-arrow-alt-right" />;
      break;
    case 'turn-slight-right':
      icon = <FontAwesomeIcon icon="long-arrow-alt-right" />;
      break;
    case 'turn-sharp-right':
      icon = <FontAwesomeIcon icon="long-arrow-alt-right" />;
      break;
    case 'turn-left':
      icon = <FontAwesomeIcon icon="long-arrow-alt-left" />;
      break;
    case 'turn-slight-left':
      icon = <FontAwesomeIcon icon="long-arrow-alt-left" />;
      break;
    case 'turn-sharp-left':
      icon = <FontAwesomeIcon icon="long-arrow-alt-left" />;
      break;
    default:
      icon = <FontAwesomeIcon icon="long-arrow-alt-up" />;
      break;
  }

  return icon;
}

const RouteDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h4,
  h5 {
    margin: 0;
  }
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.75rem 1rem;
  border-bottom: 1px solid #ebebeb;

  :hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

const Route = styled.div`
  height: 100%;
  overflow: auto;

  ul {
    li {
      display: flex;
      align-items: center;
      gap: 1rem;

      padding: 0.5rem 1rem;
      border-bottom: 1px solid #ebebeb;

      :first-child {
        padding: 0 1rem 0.5rem 1rem;
      }
    }
  }
`;

const ManeueverIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2rem;
  width: 2rem;
  min-width: 2rem;
`;

const Warnings = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  padding: 1rem;
`;

const Copyrights = styled.div`
  padding: 0.25rem 1rem;
  text-align: right;
`;
