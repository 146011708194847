import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { AuthPageLayout } from '..';
import { Button, FormInputError } from '../../components/ui';
import { isEmail, validationMessages } from '../../utils/formValidation';
import { RecaptchaFormControl } from '../../components/ui/forms/RecaptchaFormControl';
import { isMobileApp } from '../../mobile/mobile';
import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';

const SEND_CREATE_PASSWORD = gql`
  mutation SendCreatePasswordEmail($email: String!, $recaptcha: String) {
    sendCreatePasswordEmail(email: $email, recaptcha: $recaptcha) {
      message
      errors
    }
  }
`;

export default function SendCreatePassword() {
  const recaptchaRef = useRef(null);

  const [sendCreatePasswordEmail] = useMutation(SEND_CREATE_PASSWORD);

  const handleSubmit = async (
    { email, recaptcha }: any,
    { setSubmitting }: any
  ) => {
    try {
      const { data } = await sendCreatePasswordEmail({
        variables: {
          email,
          recaptcha,
        },
      });

      setSubmitting(false);

      recaptchaRef.current?.reset();

      if (data) {
        const { sendCreatePasswordEmail } = data;

        if (sendCreatePasswordEmail.errors.length) {
          return toast.error(sendCreatePasswordEmail.errors[0]);
        }

        toast.success(sendCreatePasswordEmail.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const validateForm = (values: { email: string; recaptcha: string }) => {
    const { email, recaptcha } = values;

    let errors: FormikErrors<{ email: string; recaptcha: string }> = {};

    if (!isEmail(email)) {
      errors.email = validationMessages.email;
    }

    if (!isMobileApp() && !recaptcha) {
      errors.recaptcha = validationMessages.recaptcha;
    }

    return errors;
  };

  return (
    <AuthPageLayout contentContainerStyle={{ justifyContent: 'center' }}>
      <PageContainer>
        <h2>Create New Password</h2>

        <p>We'll send you an email with a link to create a new password.</p>

        <Formik
          initialValues={{
            email: '',
            recaptcha: '',
          }}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Field
                  type="email"
                  name="email"
                  placeholder="Email address"
                  autoFocus
                />
                <ErrorMessage name="email" component={FormInputError} />

                {!isMobileApp() && (
                  <div style={{ marginTop: '1rem' }}>
                    <RecaptchaFormControl ref={recaptchaRef} name="recaptcha" />
                  </div>
                )}

                <Button type="submit" disabled={isSubmitting}>
                  CREATE NEW PASSWORD
                </Button>

                <Link style={{ marginTop: '1.5rem' }} to="/login">
                  <small>
                    <FontAwesomeIcon icon="chevron-left" /> Back to log in
                  </small>
                </Link>
              </Form>
            );
          }}
        </Formik>
      </PageContainer>
    </AuthPageLayout>
  );
}

const PageContainer = styled.div`
  button {
    margin-top: 1.5rem;
  }
`;
