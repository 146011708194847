import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, Button, TextArea } from '../../../components/ui';

import { useSiteManager } from '../hooks';
import { useSoilSiteContext } from '../SoilSiteContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const LeaveSiteModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { soilSite } = useSoilSiteContext();

  const { leave: leaveSite } = useSiteManager(soilSite.id);

  const [reason, setReason] = useState('');

  const handleLeave = async () => {
    try {
      const { data } = await leaveSite(reason);

      if (data && data.leaveSite.errors?.length > 0) {
        toast.error(`${data.leaveSite.errors[0]}`);
      }

      if (data && data.leaveSite.soilSite) {
        onClose();
        toast.success(`You have left the soil site.`);
        history.push('/my-soil-sites');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      header="Leave soil site"
      isOpen={isOpen}
      onClose={onClose}
      actionButton={<Button onClick={handleLeave}>LEAVE SOIL SITE</Button>}
    >
      <p>
        Are you sure you no longer want to participate in{' '}
        <strong>{soilSite.name}</strong>?
      </p>
      <p>
        You will no longer be able to communicate with the other participants.
      </p>

      <p style={{ marginBottom: '0.5rem' }}>
        Let MakeSoil know why you're leaving this soil site.
      </p>

      <TextArea
        value={reason}
        placeholder="Please enter the reason."
        onChange={({ target }) => setReason(target.value)}
      />
    </Modal>
  );
};
