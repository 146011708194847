import React from 'react';
import styled, { css } from 'styled-components';

const GridContainer = styled.div<GridProps>`
  display: grid;
  grid-template-rows: auto;

  ${(props) =>
    props.gap &&
    css`
      grid-gap: ${props.gap};
    `}

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) =>
      props.col &&
      css`
        grid-template-columns: ${props.col};
      `}
  }
`;

type GridProps = {
  /** grid-template-columns: (ex. repeat(3, 1fr)) */
  col: string;
  gap: string;
  style?: React.CSSProperties;
};

export const Grid: React.FC<GridProps> = ({ children, col, gap, style }) => {
  return (
    <GridContainer col={col} gap={gap} style={style}>
      {children}
    </GridContainer>
  );
};
