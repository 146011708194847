import { FetchResult } from '@apollo/client';
import { apolloClient } from '../../app/apolloClient';
import {
  DELETE_MY_ACCOUNT,
  GET_USER_PROFILE,
  UPDATE_PROFILE_PHOTO,
  UPDATE_USER_PROFILE,
} from './graphql';
import { IUserProfile } from './model';

type UserProfileResponse = {
  profile: IUserProfile;
};

type UpdateUserProfileResponse = {
  updateUserProfile: IUserProfile;
};

export type DeleteUserAccountResponse = {
  deleteMyAccount: { errors?: string[]; message: string };
};

class UserProfileService {
  getUserProfile = (id: number): Promise<FetchResult<UserProfileResponse>> => {
    return apolloClient.query({
      query: GET_USER_PROFILE,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  };

  updateUserProfile = (
    profile: IUserProfile
  ): Promise<FetchResult<UpdateUserProfileResponse>> => {
    return apolloClient.mutate({
      mutation: UPDATE_USER_PROFILE,
      variables: {
        ...profile,
        location: profile.location
          ? { lat: profile.location.lat, lng: profile.location.lng }
          : null,
      },
      fetchPolicy: 'no-cache',
    });
  };

  updateUserProfilePhoto = (
    picture: string
  ): Promise<FetchResult<{ id: number; picture: string }>> => {
    return apolloClient.mutate({
      mutation: UPDATE_PROFILE_PHOTO,
      variables: { picture },
      fetchPolicy: 'no-cache',
    });
  };

  deleteMyAccount = () => {
    return apolloClient.mutate<DeleteUserAccountResponse>({
      mutation: DELETE_MY_ACCOUNT,
      fetchPolicy: 'no-cache',
    });
  };
}

export default new UserProfileService();
