import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Avatar, Flex } from '../../../components/ui';
import { DropMenu } from '../../../components/ui/DropMenu';

import PostInput, { InputControl } from './PostInput';

import { parseUrlsAsLinks } from '../../../utils/url';
import { SiteMember } from '../model';
import { PostType, SitePost } from './model';
import { MessageAttachmentGrid } from './MessageAttachmentGrid';

import { UserDisplayName } from '../../../components/ui/UserDisplayName';
import { TimeAgo } from '../../../components/ui/TimeAgo';

import {
  WeightUnit,
  formatWeight,
  getWeightUnit,
} from '../../../utils/formatting';
import { convertWeight } from '../../../utils/weightConverter';
import { usePost } from './usePost';

function getDisplayWeight(weight: number) {
  // we store weight in DB as 'pound'
  const defaultSystemWeightUnit: WeightUnit = 'pound';
  const displayWeightUnit = getWeightUnit();

  let displayWeight = weight;
  if (displayWeightUnit.unit !== defaultSystemWeightUnit) {
    displayWeight = convertWeight(weight, displayWeightUnit.unit);
  }

  return {
    displayWeight,
    displayWeightUnit: displayWeightUnit.unit,
  };
}

function renderHeaderText(post: SitePost) {
  if (post.type === 'message') {
    return <span>posted a message</span>;
  }

  if (post.type === 'dropoff') {
    if (!post.dropOff?.weight) {
      return <span>dropped off scraps</span>;
    }

    const { displayWeight, displayWeightUnit } = getDisplayWeight(
      post.dropOff.weight
    );

    return (
      <span>
        dropped off{' '}
        <strong>{formatWeight(displayWeight, displayWeightUnit)}</strong> of
        scraps
      </span>
    );
  }

  // user participation events
  if (post.type === 'user.joined') {
    return <span>joined this soil site</span>;
  }

  if (post.type === 'user.left') {
    return <span>left this soil site</span>;
  }
}

export type PostProps = {
  post: SitePost;
  participants: SiteMember[];
  onEditDropOff: (post: SitePost) => void;
};

export const Post = (props: PostProps) => {
  const { post, participants, onEditDropOff } = props;
  const { text, attachments } = post;

  const {
    editMode,
    toggleEditMode,
    handleCancelEdit,
    handleUpdateMessage,
  } = usePost(post);

  const handleEditPost = () => {
    if (post.type === PostType.DROPOFF) {
      return onEditDropOff(post);
    }
    toggleEditMode();
  };

  return (
    <PostWrapper direction="column">
      <PostHeader post={post} onEditPost={handleEditPost} />

      <PostContainer>
        {editMode ? (
          <PostInput
            initialPost={post}
            style={{ borderRadius: '0' }}
            onPostMessage={handleUpdateMessage}
            users={participants}
            autoFocus={true}
          >
            <InputControl onClick={handleCancelEdit} title="Cancel Edit">
              <FontAwesomeIcon icon="ban" />
            </InputControl>
          </PostInput>
        ) : (
          <>
            <PostText text={text} />
            <MessageAttachmentGrid lightRoomEnabled attachments={attachments} />
          </>
        )}
      </PostContainer>
    </PostWrapper>
  );
};

const PostHeader = ({ post, onEditPost }) => {
  const { id, author, type, createdAt } = post;
  const { canEdit, canDelete, handleDeleteMessage } = usePost(post);

  const showActionHandle = canEdit || canDelete;

  return (
    <PostHeaderContainer
      justify="space-between"
      align="center"
      backgroundColor={type === 'dropoff' ? '#68b74e21' : undefined}
    >
      <Flex>
        <Avatar
          user={author}
          size="sm"
          // badge={isAuthorAdmin ? <MakeSoilBadge /> : undefined}
        />
        <div style={{ marginLeft: '0.5rem' }}>
          <Flex gap="0.25rem" align="center" style={{ flexWrap: 'wrap' }}>
            <strong>
              <UserDisplayName
                firstName={author.firstName}
                lastName={author.lastName}
              />
            </strong>

            {renderHeaderText(post)}
          </Flex>
          <small>
            <TimeAgo date={createdAt} />
          </small>
        </div>
      </Flex>

      <MessageActions show={showActionHandle}>
        {canEdit && (
          <span onClick={onEditPost}>
            <FontAwesomeIcon icon="edit" /> Edit
          </span>
        )}
        {/* {canPin && (
            <span onClick={() => handlePinMessage(id, !pinned)}>
              <FontAwesomeIcon icon="thumbtack" />{" "}
              {pinned ? "Unpin" : "Pin"} Message
            </span>
          )} */}
        {canDelete && (
          <span onClick={() => handleDeleteMessage(id)}>
            <FontAwesomeIcon icon="trash" /> Delete
          </span>
        )}
      </MessageActions>
    </PostHeaderContainer>
  );
};

export const MessageActions = ({ children, show }) => {
  if (!show) return null;

  return (
    <Toggle>
      <DropMenu triggerElement={<FontAwesomeIcon icon="ellipsis-h" />}>
        {children}
      </DropMenu>
    </Toggle>
  );
};

const PostText = ({ text }) => {
  if (!text) return null;

  return (
    <PostContent
      padding={text.length > 0}
      dangerouslySetInnerHTML={{
        __html: parseUrlsAsLinks(text.replace(/\n/g, '<br />')),
      }}
    />
  );
};

export const PostFooter = ({ messageId, comments, commentsCount }) => {
  //if (commentsCount === 0) return null;

  const showCommentInput = (messageId: number) => {};
  return (
    <PostFooterContainer>
      <span onClick={() => showCommentInput(messageId)}>
        <FontAwesomeIcon icon="comment" /> Add Comment
      </span>
      <div>
        {commentsCount > 0 && (
          <small
            onClick={() => console.log(`load comments for post: ${messageId}`)}
          >
            {commentsCount} {commentsCount > 1 ? `comments` : `comment`}
          </small>
        )}
      </div>
    </PostFooterContainer>
  );
};

export const PostWrapper = styled(Flex)`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &.exit {
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

const PostHeaderContainer = styled(Flex)<{ backgroundColor?: string }>`
  padding: 0.5rem;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#fbfbfb'};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #f3f3f3;

  font-size: 0.875rem;
  color: #828282;

  small {
    font-size: 0.775rem;
    color: #a0a0a0;
  }
`;

export const PostContainer = styled.div`
  width: 100%;
`;

export const PostContent = styled.div<{ padding: boolean }>`
  ${({ padding }) => padding && `padding: 0.75rem 1rem 1rem`};
  line-height: 1.45;
  font-size: 0.925rem;
  word-break: break-word;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;

const Toggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 1.5rem;
  width: 1.5rem;

  border-radius: 50%;
  border: 1px solid transparent;
  color: #616161;

  :hover {
    background-color: #ebebeb;
    border: 1px solid #cfcfcf;
  }

  transition 0.35s ease;
`;

export const PostFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0.75rem 1rem;
  border-top: 1px solid #f3f3f3;
  font-size: 0.85rem;

  small {
    margin-left: 1rem;
  }
`;
