import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const BouncingArrow = styled(FontAwesomeIcon)`
  margin-top: 5rem;

  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  animation: bounce 2s ease-in infinite;

  @-webkit-keyframes bounce {
    100% {
      -webkit-transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-10px);
    }
    60% {
      -webkit-transform: translateY(-5px);
    }
  }

  @keyframes bounce {
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;
