import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const endpoint = '/api/getcurrentversion';

type VersionResult = { version: string };

type VersionSliceState = {
  isUnknown: boolean;
  currentVersion: string;
  hasNewVersion: boolean;
  lastFetched: number;
};

const initialState: VersionSliceState = {
  hasNewVersion: false,
  currentVersion: 'unknown',
  isUnknown: true,
  lastFetched: null,
};

export const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkForUpdate.fulfilled, (state, { payload }) => {
        if (payload.version !== state.currentVersion) {
          state.currentVersion = payload.version;
          state.hasNewVersion = true;
        }
        state.lastFetched = Date.now();
      })
      .addCase(fetchInitialVersion.fulfilled, (state, { payload }) => {
        if (payload.version === 'unknown') {
          console.warn('Could not find current app version.');
        } else {
          console.log('Fetched initial version', payload.version);
          state.currentVersion = payload.version;
          state.lastFetched = Date.now();
        }
      });
  },
});

export const selectHasNewVersion = (state: RootState) =>
  state.version.hasNewVersion;
export const selectCurrentVersion = (state: RootState) =>
  state.version.currentVersion;
export const selectLastFetched = (state: RootState) =>
  state.version.lastFetched;

export default versionSlice.reducer;

const getCurrentVersion = async () => {
  let response = await fetch(endpoint);
  if (response.status > 400) {
    console.error(`can't fetch version`);
    return { version: 'unknown' };
  } else {
    return await response.json();
  }
};

export const fetchInitialVersion = createAsyncThunk<VersionResult>(
  'version/fetchInitialVersion',
  async () => {
    return getCurrentVersion();
  }
);

export const checkForUpdate = createAsyncThunk<VersionResult>(
  'version/checkForUpdate',
  async () => {
    return getCurrentVersion();
  }
);
