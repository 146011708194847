import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  isPending,
  isRejected,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { ILocation } from '../../app/model/ILocation';
import { RootState } from '../../app/store';

import { storage } from '../../utils/storage';
import { isAdmin } from '../auth/utils';
import { Site } from '../site/model';
import mapService, { SearchResults } from './mapService';
import { Bounds, IMapItem, MapItemType, SiteDetails } from './model';
import accessrequestService from '../site/accessrequestService';

export type MapItemFilter = {
  id: number;
  type: MapItemType;
  icon: 'soilSite' | 'soilSupporter' | 'unmatchedUser' | 'communityGarden';
  name: string;
  active: boolean;
};

const filters: MapItemFilter[] = [
  {
    id: 1,
    type: MapItemType.SoilSite,
    icon: 'soilSite',
    name: 'Soil Site',
    active: true,
  },
  {
    id: 2,
    type: MapItemType.SoilSupporter,
    icon: 'soilSupporter',
    name: 'Soil Supporter',
    active: false,
  },
  {
    id: 3,
    type: MapItemType.UnmatchedUser,
    icon: 'unmatchedUser',
    name: 'Unmatched User',
    active: false,
  },
  // {
  //   id: 4,
  //   type: MapItemType.CommunityGarden,
  //   icon: 'communityGarden',
  //   name: 'Community Garden',
  //   active: false,
  // },
];

type SearchState = {
  results: {
    predictions: google.maps.places.AutocompletePrediction[];
    makesoil: IMapItem[];
  };
  searchTerm: string;
  selectedPlace: {
    prediction: google.maps.places.AutocompletePrediction;
    details: google.maps.GeocoderResult;
  };
  resultsVisible: boolean;
};

type MapSliceState = {
  filterPanelOpen: boolean;
  filters: MapItemFilter[];

  searchBoxVisible: boolean;
  searchBoxFocused: boolean;
  drawerOpen: boolean;

  search: SearchState;

  mapCenter: ILocation;
  mapZoom: number;
  mapBounds: Bounds;
  mapItems: IMapItem[];

  highlightedDrawerItemId: number;
  selectedMapItem: IMapItem;
  selectedMapItemDetails: SiteDetails;
  selectedMapItemHistory: SiteDetails[];

  loading: boolean;
  error: string;
};

const DefaultZoom = 3;

const getDefaultCenter = () => {
  // middle of atlantic ocean, showing most of the world
  let defaultCenter = {
    lat: 25.0,
    lng: 0.0,
  };
  try {
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const timeZoneOffset = new Date().getTimezoneOffset();
    const mapOffset =
      (Math.max(Math.min(timeZoneOffset, 720), -720) / 720) * -180;
    // console.log(`timezone=${timeZone} offset=${timeZoneOffset} => ${mapOffset}`);
    defaultCenter.lng = mapOffset;
  } catch (e) {
    console.log('Could not retrieve timezome, using default map center');
  }
  // console.log(`defaultMapCenter: ${JSON.stringify(defaultCenter)}`);
  return defaultCenter;
};

export const getInitialCenter = () => {
  let center = storage.local.get('mapCenter');
  if (!center) {
    center = getDefaultCenter();
  }
  // console.log(`initialMapCenter: ${JSON.stringify(center)}`);

  return center;
};

export const didSetCenter = () => {
  return storage.local.get('mapCenter') !== null;
};

export const isDefaultCenter = () => {
  const center = storage.local.get('mapCenter');
  return (
    center === null ||
    (getDefaultZoom() === DefaultZoom &&
      JSON.stringify(center) === JSON.stringify(getDefaultCenter()))
  );
};

const getDefaultZoom = () => {
  return storage.local.get('mapZoom') || DefaultZoom;
};

const initialState: MapSliceState = {
  searchBoxVisible: true,
  searchBoxFocused: false,

  search: {
    searchTerm: '',
    results: { makesoil: [], predictions: [] },
    selectedPlace: { prediction: null, details: null },
    resultsVisible: false,
  },

  drawerOpen: false,
  filterPanelOpen: false,
  filters,

  mapCenter: getInitialCenter(),
  mapZoom: getDefaultZoom(),
  mapBounds: null,
  mapItems: [],

  highlightedDrawerItemId: null,
  selectedMapItem: null,
  selectedMapItemDetails: null,
  selectedMapItemHistory: [],

  loading: false,
  error: '',
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    drawerToggled(state, { payload }: PayloadAction<boolean>) {
      state.drawerOpen = payload;
      if (!payload) {
        state.searchBoxVisible = true;
      } else {
        if (!state.selectedMapItem) {
          state.searchBoxVisible = true;
        } else {
          state.searchBoxVisible = false;
        }
      }
    },
    filterPanelToggled(state, { payload }: PayloadAction<boolean>) {
      state.filterPanelOpen = payload;
    },
    filterSelected(state, { payload }: PayloadAction<number>) {
      const filter = state.filters.find((f) => f.id === payload);
      filter.active = !filter.active;
    },
    searchTermChanged(state, { payload }: PayloadAction<string>) {
      state.search.searchTerm = payload;
    },
    searchBoxFocusToggled(state) {
      state.searchBoxFocused = true;
    },
    hideSearchResults(state) {
      state.search.resultsVisible = false;
    },
    placeSelected(
      state,
      { payload }: PayloadAction<google.maps.places.AutocompletePrediction>
    ) {
      state.search.resultsVisible = false;
      state.search.selectedPlace.prediction = payload;
    },
    mapLoaded(state, { payload }: PayloadAction<Bounds>) {
      state.mapBounds = payload;
    },
    boundsChanged(
      state,
      { payload }: PayloadAction<{ bounds: Bounds; center: ILocation }>
    ) {
      state.mapBounds = payload.bounds;
      // update mapCenter only when changed to prevent render loop
      if (JSON.stringify(state.mapCenter) !== JSON.stringify(payload.center)) {
        // console.log("Bounds changed mapCenter:" + JSON.stringify(payload.center));
        state.mapCenter = payload.center;
        storage.local.set('mapCenter', payload.center);
      }
    },
    centerChanged(state, { payload }: PayloadAction<ILocation>) {
      // console.log("Center changed mapCenter:" + JSON.stringify(payload));
      state.mapCenter = payload;
      storage.local.set('mapCenter', payload);
    },
    zoomChanged(state, { payload }: PayloadAction<number>) {
      state.mapZoom = payload;
      storage.local.set('mapZoom', payload);
    },
    mapItemSelected(state, { payload }: PayloadAction<IMapItem>) {
      state.selectedMapItem = payload;
      // open the drawer if closed
      if (!state.drawerOpen) state.drawerOpen = true;
      if (!payload) state.selectedMapItemDetails = null;

      state.searchBoxVisible = false;
    },
    clearSelectedMapItem(state) {
      state.selectedMapItem = null;
      state.selectedMapItemDetails = null;
      state.searchBoxVisible = true;
    },
    mapReset(state) {
      state.selectedMapItem = null;
      state.selectedMapItemDetails = null;
      state.drawerOpen = false;
      state.searchBoxVisible = true;
    },
    drawerItemHighlighted(state, { payload }: PayloadAction<number>) {
      state.highlightedDrawerItemId = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMapItemsWithinBounds.fulfilled, (state, { payload }) => {
        state.mapItems = payload;
        state.loading = false;
      })
      .addCase(fetchSiteById.fulfilled, (state, { payload }) => {
        const itemType = MapItemType.SoilSite;

        state.loading = false;
        state.searchBoxVisible = false;
        state.mapCenter = payload.location;
        state.selectedMapItem = {
          id: payload.id,
          name: payload.name,
          type: itemType,
          location: payload.location,
        };
        state.highlightedDrawerItemId = payload.id;
        state.selectedMapItemDetails = { ...payload, type: itemType };

        // store in the history to get locally rather than fetch
        // if (!state.selectedMapItemHistory.find((h) => h.id === payload.id)) {
        //   state.selectedMapItemHistory.push({ ...payload, type: itemType });
        // }
      })
      .addCase(fetchSearchResults.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.search.results = payload;
        state.search.resultsVisible = true;
      })
      .addCase(fetchPlaceDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.search.selectedPlace.details = payload.details;
        // if we have places within the payload and no places in state, store the places
        // this would happen if users lands on the map with search param
        // then we fetch places and details of a place (best match)
        if (payload.predictions && !state.search.results.predictions.length) {
          state.search.results = {
            predictions: payload.predictions,
            makesoil: [],
          };
        }
      })
      .addCase(cancelAccessRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.selectedMapItemDetails.accessRequests = state.selectedMapItemDetails.accessRequests.filter(
          (ac) => ac.id !== payload.requestId
        );
      })

      .addMatcher(
        isPending(
          fetchMapItemsWithinBounds,
          fetchSiteById,
          fetchSearchResults,
          cancelAccessRequest
        ),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(
          fetchMapItemsWithinBounds,
          fetchSiteById,
          fetchSearchResults,
          cancelAccessRequest
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(
          fetchMapItemsWithinBounds,
          fetchSiteById,
          fetchSearchResults,
          cancelAccessRequest
        ),
        (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export const {
  drawerToggled,
  filterPanelToggled,
  filterSelected,
  searchTermChanged,
  searchBoxFocusToggled,
  placeSelected,
  hideSearchResults,

  mapLoaded,
  boundsChanged,
  centerChanged,
  zoomChanged,

  drawerItemHighlighted,
  mapItemSelected,
  clearSelectedMapItem,
  mapReset,
} = mapSlice.actions;

export const selectSearchBoxVisible = (state: RootState) =>
  state.map.searchBoxVisible;
export const selectSearchBoxFocused = (state: RootState) =>
  state.map.searchBoxFocused;
export const selectSearchTerm = (state: RootState) =>
  state.map.search.searchTerm;
export const selectResultsVisible = (state: RootState) =>
  state.map.search.resultsVisible;
export const selectSearchResults = (state: RootState) =>
  state.map.search.results;
export const selectSelectedPlace = (state: RootState) =>
  state.map.search.selectedPlace;

export const selectMapItems = (state: RootState) => state.map.mapItems;
export const selectHighlightedDrawerItemId = (state: RootState) =>
  state.map.highlightedDrawerItemId;

export const selectSelectedMapItem = (state: RootState) =>
  state.map.selectedMapItem;
export const selectSelectedMapItemDetails = (state: RootState) =>
  state.map.selectedMapItemDetails;

export const selectDrawerOpen = (state: RootState) => state.map.drawerOpen;
export const selectFilterPanelOpen = (state: RootState) =>
  state.map.filterPanelOpen;

export const selectFilters = (state: RootState) => state.map.filters;

export const selectLoading = (state: RootState) => state.map.loading;
export const selectMapCenter = (state: RootState) => state.map.mapCenter;
export const selectMapZoom = (state: RootState) => state.map.mapZoom;
export const selectMapBounds = (state: RootState) => state.map.mapBounds;

export default mapSlice.reducer;

// thunks

/**
 * Searches for the Map Items within the Map Bounds.
 * Now it just searches for the Soil Sites and, if Admin, unmatched Users.
 * Eventually we may be looking for all types of Map Items.
 */
export const fetchMapItemsWithinBounds = createAsyncThunk<
  IMapItem[],
  Bounds,
  { state: RootState }
>(
  'map/fetchMapItemsWithinBounds',
  async (bounds, { rejectWithValue, getState }) => {
    try {
      const authUser = getState().auth.user;
      const filters = getState().map.filters;
      const mapItems = [];

      if (filters.find((f) => f.type === MapItemType.SoilSite).active) {
        const soilSites = await mapService.getSoilSitesWithinBounds(bounds);
        if (soilSites) {
          mapItems.push(...soilSites);
        }
      }

      if (isAdmin(authUser)) {
        if (filters.find((f) => f.type === MapItemType.UnmatchedUser).active) {
          const unmatchedUsers = await mapService.getUnmatchedUsersWithinBounds(
            bounds
          );
          if (unmatchedUsers) {
            mapItems.push(...unmatchedUsers);
          }
        }

        if (filters.find((f) => f.type === MapItemType.SoilSupporter).active) {
          const supporters = await mapService.getSupportersWithinBounds(bounds);
          if (supporters) {
            mapItems.push(...supporters);
          }
        }
      }

      return mapItems;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSiteById = createAsyncThunk<
  Site,
  number,
  { state: RootState }
>('map/fetchSiteById', async (id, { rejectWithValue, getState }) => {
  // const history = getState().map.selectedMapItemHistory;
  // const existingSiteDetails = history.find((h) => h.id === id);
  // if (existingSiteDetails) return existingSiteDetails;

  const { data, errors } = await mapService.getSoilSiteById(id);
  if (!data && errors) {
    return rejectWithValue(errors);
  }

  return data.soilSiteDetails;
});

export const fetchSearchResults = createAsyncThunk<
  SearchResults,
  string,
  { state: RootState }
>('map/fetchSearchResults', async (searchTerm, { getState }) => {
  // first check locally before hitting server and google
  const { search } = getState().map;
  const exactLocalMatch = search.results.predictions.find(
    (p) => p.structured_formatting.main_text === searchTerm
  );

  if (exactLocalMatch) return search.results;

  // fetching soil sites and google places
  const [makesoil, predictions] = await Promise.all([
    mapService.fetchSoilSitesBySearchTerm(searchTerm),
    mapService.fetchPlacesBySearchTerm(searchTerm),
  ]);

  return { makesoil, predictions };
});

export const fetchPlaceDetails = createAsyncThunk<
  {
    details: google.maps.GeocoderResult;
    predictions?: google.maps.places.AutocompletePrediction[];
  },
  string,
  { state: RootState }
>(
  'map/fetchPlaceDetails',
  async (searchTerm: string, { getState, rejectWithValue }) => {
    try {
      /**
       * Check if we already have a place prediction for this search term stored in state.
       * If we do we can get the details by it's place id without fetching the preditions.
       *
       * If we do have predition, let's check if we also happen to have the place details
       * (happens if the user's last search and selection was the same place)
       */
      const {
        search: { selectedPlace },
      } = getState().map;

      const existingPredictionMatches = selectedPlace.prediction?.description.includes(
        searchTerm
      );
      if (existingPredictionMatches) {
        // Check if we already have place details for this search term
        const isSamePlace =
          selectedPlace.prediction?.place_id ===
          selectedPlace.details?.place_id;
        if (isSamePlace) return { details: selectedPlace.details };

        return {
          details: await mapService.fetchPlaceLocation(
            selectedPlace.prediction
          ),
        };
      }

      // try to geocode before getting predictions
      const geocoderResult = await mapService.geocode(searchTerm);
      if (geocoderResult) {
        return {
          details: geocoderResult[0],
        };
      }

      const {
        predictions,
        details,
      } = await mapService.fetchPlaceLocationBySearchTerm(searchTerm);
      return { predictions, details };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cancelAccessRequest = createAsyncThunk<any, number>(
  'map/cancelAccessRequest',
  async (requestId, { rejectWithValue }) => {
    try {
      const result = await accessrequestService.cancelAccessRequest(requestId);
      return {
        requestId,
        ...result.data.cancelRequest,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
