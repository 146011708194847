import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Flex } from '../../components/ui';
import { useDirectionsContext } from './DirectionsContext';

export const RouteSelector = () => {
  const {
    travelMode,
    directions,
    selectedRouteIndex,
    setSelectedRouteIndex,
    setRouteDetailsVisible,
  } = useDirectionsContext();

  const handleShowDetails = () => {
    setRouteDetailsVisible(true);
  };

  return (
    <RouteSelectorContainer>
      <small
        style={{
          display: 'block',
          padding: '0.75rem 1rem',
          borderBottom: '1px solid #ebebeb',
        }}
      >
        Suggested routes
      </small>

      <ul style={{ overflow: 'auto' }}>
        {directions.routes.map((route, i) => (
          <RouteSelectorItem
            key={i}
            selected={selectedRouteIndex === i}
            onClick={() => setSelectedRouteIndex(i)}
          >
            <Flex align="center" gap="1rem">
              <TravelModeButton selected={false}>
                {renderTravelModeIcon(travelMode)}
              </TravelModeButton>
              <Flex direction="column" gap="0.25rem">
                <div style={{ fontSize: '0.925rem' }}>
                  via {(route as any).summary}
                </div>
                <DetailsButton onClick={handleShowDetails}>
                  Details
                </DetailsButton>
              </Flex>
            </Flex>
            <div style={{ textAlign: 'right' }}>
              <div>{route.legs[0].duration.text}</div>
              <div style={{ marginTop: '0.25rem' }}>
                <small>{route.legs[0].distance.text}</small>
              </div>
            </div>
          </RouteSelectorItem>
        ))}
      </ul>
    </RouteSelectorContainer>
  );
};

function renderTravelModeIcon(travelMode: google.maps.TravelMode) {
  let icon;
  switch (travelMode) {
    case 'WALKING':
      icon = <FontAwesomeIcon icon="walking" />;
      break;
    case 'BICYCLING':
      icon = <FontAwesomeIcon icon="biking" />;
      break;
    case 'DRIVING':
      icon = <FontAwesomeIcon icon="car" />;
      break;
    case 'TRANSIT':
      icon = <FontAwesomeIcon icon="subway" />;
      break;
    default:
      icon = <FontAwesomeIcon icon="walking" />;
      break;
  }

  return icon;
}

const RouteSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const TravelModeButton = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2rem;
  width: 2rem;
  border-radius: 50%;

  font-size: 1.25rem;

  ${({ theme, selected }) =>
    selected &&
    `
    background-color: ${theme.colors.primary};
    color: #fff;
  `}

  svg {
    pointer-events: none;
  }

  :hover {
    cursor: pointer;
    background-color: #ebebeb;
  }
`;

const RouteSelectorItem = styled.li<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  border-bottom: 1px solid #ebebeb;

  ${({ theme, selected }) =>
    selected &&
    `
      border-left: 5px solid ${theme.colors.primary};
  `}

  :hover {
    cursor: pointer;
    background-color: #f9f9f9;
  }
`;

const DetailsButton = styled.small`
  display: flex;
  padding: 0.25rem 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: -0.5rem;

  border-radius: 4px;

  align-self: flex-start;

  :hover {
    background-color: #45b13946;
  }
`;
