import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Meta } from '../../../components/Meta';
import { Drawer } from '../../../components/ui/Drawer';
import { viewportToPixels } from '../../../utils/helpers';
import {
  clearSelectedMapItem,
  drawerToggled,
  selectDrawerOpen,
  selectSelectedMapItemDetails,
} from '../mapSlice';
import { MapItemType } from '../model';
import { DrawerList } from './DrawerList';
import { SiteDetails } from './SiteDetails';

type MapDrawerProps = {};

export const MapDrawer: React.FC<MapDrawerProps> = () => {
  const filterOpen = false;

  const history = useHistory();

  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector(selectDrawerOpen);
  const selectedMapItemDetails = useAppSelector(selectSelectedMapItemDetails);

  const toggleDrawer = () => {
    dispatch(drawerToggled(!drawerOpen));
  };

  const handleBackToResults = () => {
    dispatch(clearSelectedMapItem());
    history.push('/map');
  };

  const handleSwipe = (e: SwipeEventData) => {
    console.log(e);

    if (e.dir === 'Left') {
      if (drawerOpen) {
        dispatch(drawerToggled(false));
      }
    }

    if (e.dir === 'Right') {
      // open the drawer if closed
      if (!drawerOpen) {
        dispatch(drawerToggled(true));
      }
      // when site details selected go back to results
      if (selectedMapItemDetails) {
        handleBackToResults();
      }
    }
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
    delta: 30,
  });

  const renderSelectedItem = (drawerItem: any) => {
    // based on the type (Soil Site, Soil Supporter, Garden etc.)
    // render the appropriate component
    switch (drawerItem.type) {
      case MapItemType.SoilSite:
        return <SiteDetails site={drawerItem} />;
      default:
        break;
    }
  };

  const getTopMargin = () => {
    if (!selectedMapItemDetails && filterOpen) return '14rem';
    if (!selectedMapItemDetails) return '7rem';
    return '0rem';
  };

  return (
    <div
      style={{
        minWidth: '2.5rem',
        minHeight: '2.5rem',
        height: '100%',
        position: 'absolute',
        top: '0',
        zIndex: 11,
      }}
      {...handlers}
    >
      <DrawerToggle open={drawerOpen} onClick={toggleDrawer}>
        <FontAwesomeIcon icon={drawerOpen ? 'chevron-left' : 'chevron-right'} />
      </DrawerToggle>

      <Drawer
        open={drawerOpen}
        onToggle={toggleDrawer}
        /* width is 100vw - toggle button width */
        width={`${viewportToPixels('100vw') - 42}px`}
      >
        <DrawerContent top={getTopMargin()}>
          {selectedMapItemDetails && (
            <>
              <Meta>
                <title>{selectedMapItemDetails.name} | MakeSoil</title>
              </Meta>
              <BackToListButton onClick={handleBackToResults}>
                <FontAwesomeIcon icon="chevron-left" /> Search results
              </BackToListButton>
              <SelectedItemDetails>
                {renderSelectedItem(selectedMapItemDetails)}
              </SelectedItemDetails>
            </>
          )}
          {!selectedMapItemDetails && <DrawerList />}
        </DrawerContent>
      </Drawer>
    </div>
  );
};

const DrawerToggle = styled.div<{ open: boolean }>`
  position: absolute;

  width: 2.5rem;
  height: 2.5rem;
  top: 8rem;

  border-radius: 0 4px 4px 0;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  font-size: 1.5rem;

  opacity: 0.8;

  cursor: pointer;
`;

const DrawerContent = styled.div<{ top: string }>`
  position: relative;
  height: calc(100% - ${({ top }) => top});
  margin-top: ${({ top }) => top};

  overflow-y: auto;
`;

const BackToListButton = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  padding-left: 1rem;
  background-color: #fff;
  border-top: 1px solid;
  border-color: #d6d6d6;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  height: 42px;
`;

const SelectedItemDetails = styled.div`
  height: calc(100% - 42px);
`;
