import styled from 'styled-components';
import { timeAgo } from '../../utils/date';

type Props = {
  date: Date | string;
};

export const TimeAgo: React.FC<Props> = ({ date }) => {
  if (!date) return null;

  const titleDate = typeof date === 'string' ? new Date(date) : date;

  return (
    <Container title={titleDate.toLocaleString()}>{timeAgo(date)}</Container>
  );
};

const Container = styled.span`
  font-weight: 500;
  white-space: nowrap;

  cursor: help;
`;
