import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
  isRejectedWithValue,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { Site } from '../../site/model';

import siteManagementService, {
  ParticipationInfo,
} from './SiteManagementService';

type SiteManagementSliceState = {
  sites: Site[];
  selectedSite: Site;

  loading: boolean;
  error: string;
};

const initialState: SiteManagementSliceState = {
  sites: [],
  selectedSite: null,

  loading: false,
  error: '',
};

export const siteManagementSlice = createSlice({
  name: 'siteManagement',
  initialState,
  reducers: {
    siteSelected(state, { payload }: PayloadAction<Site>) {
      state.selectedSite = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSites.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sites = payload;
      })
      .addCase(updateActivityStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        //state.selectedSite.active = payload.active;
        state.sites = state.sites.map((site) => {
          if (site.id === payload.id) {
            site.active = payload.active;
          }
          return site;
        });
      })
      .addCase(updateVisibility.fulfilled, (state, { payload }) => {
        state.loading = false;
        //state.selectedSite.isUnlisted = payload.isUnlisted;
        state.sites = state.sites.map((site) => {
          if (site.id === payload.id) {
            site.isUnlisted = payload.isUnlisted;
          }
          return site;
        });
      })

      // matchers
      .addMatcher(
        isPending(fetchSites, updateActivityStatus, updateVisibility),
        (state) => {
          state.loading = true;
          state.error = '';
        }
      )
      .addMatcher(
        isRejected(fetchSites, updateActivityStatus, updateVisibility),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addMatcher(
        isRejectedWithValue(fetchSites, updateActivityStatus, updateVisibility),
        (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export default siteManagementSlice.reducer;

export const { siteSelected } = siteManagementSlice.actions;

export const selectLoading = (state: RootState) =>
  state.admin.siteManagement.loading;
export const selectError = (state: RootState) =>
  state.admin.siteManagement.error;

export const selectSites = (state: RootState) =>
  state.admin.siteManagement.sites;

export const selectSelectedSite = (state: RootState) =>
  state.admin.siteManagement.selectedSite;

export const fetchSites = createAsyncThunk<Site[], string>(
  'siteManagement/fetchSites',
  async (keywords, { rejectWithValue }) => {
    const { data, errors } = await siteManagementService.findSites(keywords);
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.findSites;
  }
);

export const fetchParticipationInfo = createAsyncThunk<
  ParticipationInfo,
  number
>(
  'siteManagement/fetchParticipationInfo',
  async (siteId, { rejectWithValue }) => {
    const { data, errors } = await siteManagementService.fetchParticipationInfo(
      siteId
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.siteParticipationInfo;
  }
);

export const updateActivityStatus = createAsyncThunk<
  Pick<Site, 'id' | 'active'>,
  { siteId: number; active: boolean }
>(
  'siteManagement/updateActivityStatus',
  async ({ siteId, active }, { rejectWithValue }) => {
    const { data, errors } = await siteManagementService.updateActiveStatus(
      siteId,
      active
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.updateSiteActiveStatus.soilSite;
  }
);

export const updateVisibility = createAsyncThunk<
  Pick<Site, 'id' | 'isUnlisted'>,
  { siteId: number; isUnlisted: boolean }
>(
  'siteManagement/updateVisibility',
  async ({ siteId, isUnlisted }, { rejectWithValue }) => {
    const { data, errors } = await siteManagementService.updateVisibility(
      siteId,
      isUnlisted
    );
    if (!data && errors) {
      return rejectWithValue(errors[0]);
    }

    return data.updateSiteVisbility.soilSite;
  }
);
