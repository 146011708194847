import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from '../../app/theme';
import { Tooltip } from '../../components/ui/Tooltip';

export function ImpactTooltip({ visible }) {
  if (!visible) return null;

  return (
    <Tooltip
      triggerElement={
        <span style={{ fontSize: '1rem' }}>
          <FontAwesomeIcon icon="info-circle" color={theme.colors.primary} />
        </span>
      }
    >
      Unless the exact drop-off weight has been manually recorded, we use an
      average drop-off weight derived from thousands of recorded drop-offs
      around the world. The "new soil created" calculation is also derived from
      real user data. Emissions impact is based on the US EPA's WARM Model.
    </Tooltip>
  );
}
