import { useEffect, useState } from 'react';
import { storage } from '../utils/storage';

// Get from local storage then
// parse stored json or return initialValue
function readValue<T>(key: string, initialValue: T) {
  // Prevent build error "window is undefined" but keep keep working
  if (typeof window === 'undefined') {
    return initialValue;
  }
  try {
    const item = storage.local.get(key);
    return item ? item : initialValue;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return initialValue;
  }
}

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window == 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }
    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;
      // Save to local storage
      storage.local.set(key, newValue);
      // Save state
      setStoredValue(newValue);
      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  /**
  Set the stored value on first render.
   */
  useEffect(() => {
    setStoredValue(readValue(key, initialValue));
  }, []);

  /**
   * Handler function for storage events
   */
  function handleStorageChange() {
    setStoredValue(readValue(key, initialValue));
  }

  /**
   * Set up storage event listeners on first render and cleanup on unmount.
   */
  useEffect(() => {
    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);
    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener('local-storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
  }, []);

  return [storedValue, setValue] as [
    storedValue: T,
    setValue: React.Dispatch<React.SetStateAction<T>>
  ];
};
