import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useDirectionsContext } from './DirectionsContext';

export const TravelModePicker = () => {
  const {
    travelMode,
    setTravelMode,
    setSelectedRouteIndex,
  } = useDirectionsContext();

  const handleTravelModeChange = (e) => {
    if (travelMode !== e.target.id) {
      setTravelMode(e.target.id);
      setSelectedRouteIndex(0);
    }
  };

  return (
    <TravelModePickerContainer>
      <TravelModeButton
        id="WALKING"
        selected={travelMode === 'WALKING'}
        onClick={handleTravelModeChange}
      >
        <FontAwesomeIcon icon="walking" />
      </TravelModeButton>
      <TravelModeButton
        id="BICYCLING"
        selected={travelMode === 'BICYCLING'}
        onClick={handleTravelModeChange}
      >
        <FontAwesomeIcon icon="biking" />
      </TravelModeButton>
      <TravelModeButton
        id="DRIVING"
        selected={travelMode === 'DRIVING'}
        onClick={handleTravelModeChange}
      >
        <FontAwesomeIcon icon="car" />
      </TravelModeButton>
      <TravelModeButton
        id="TRANSIT"
        selected={travelMode === 'TRANSIT'}
        onClick={handleTravelModeChange}
      >
        <FontAwesomeIcon icon="subway" />
      </TravelModeButton>
    </TravelModePickerContainer>
  );
};

const TravelModePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  padding: 1rem;

  border-left: 1px solid #ebebeb;
`;

const TravelModeButton = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;

  font-size: 1.25rem;

  svg {
    pointer-events: none;
  }

  :hover {
    cursor: pointer;
    background-color: #ebebeb;
  }

  ${({ theme, selected }) =>
    selected &&
    `
    background-color: ${theme.colors.primary};
    color: #fff;

    :hover {
      background-color: ${theme.colors.primary};
    }
  `}
`;
