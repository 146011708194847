import { ErrorMessage, Field, Form, Formik, FormikErrors } from 'formik';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { useAppDispatch } from '../../app/hooks';
import { Button, Flex, FormInputError, Input } from '../../components/ui';
import { FormControl } from '../../components/ui/forms/Forms';
import { updateContactInfo } from '../auth/authSlice';
import { trimFormValues } from '../../utils/helpers';
import { isPhoneNumber } from '../../utils/formValidation';
import { ContactInfoFormData } from '../auth/model';
import { SiteOnboardLocationState } from './model';
import { useAuthUser } from '../auth/useAuthUser';
import { AuthPageLayout } from '../../pages';
import { generateSiteIdentifier } from '../../utils/url';

const VerifyContactInfo: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  const initialValues: ContactInfoFormData = {
    phoneNum: authUser.phoneNum || '',
    twitter: authUser.twitter || '',
    instagram: authUser.instagram || '',
    facebook: authUser.facebook || '',
    linkedin: authUser.linkedin || '',
  };

  const state = location.state as SiteOnboardLocationState;

  const mapUrl = () => {
    if (
      state.action === 'join' ||
      state.action === 'request' ||
      state.action === 'invitation'
    ) {
      return `/map/site/${generateSiteIdentifier(
        state.site.siteId,
        state.site.siteName
      )}`;
    }

    return `/map`;
  };

  useEffect(() => {
    const state = location.state as SiteOnboardLocationState;
    if (state.action === 'create') return history.goBack();
    if (!state?.site) history.goBack();
  }, [history, location.state]);

  const validateForm = (values: ContactInfoFormData) => {
    let errors: FormikErrors<ContactInfoFormData> = {};

    const { phoneNum, twitter, instagram, facebook, linkedin } = values;

    const errorMessage =
      'Please provide a valid phone number, \n or at least 2 alternative forms of contact via social media.';

    if (phoneNum) {
      if (!isPhoneNumber(phoneNum)) {
        errors.phoneNum = errorMessage;
      }
    } else {
      if (
        [twitter, instagram, facebook, linkedin].filter((v) => !!v).length < 2
      ) {
        errors.phoneNum = errorMessage;
      }
    }

    return errors;
  };

  const submitForm = async (values: ContactInfoFormData) => {
    values = trimFormValues(values);
    await dispatch(updateContactInfo(values));

    const state = location.state as SiteOnboardLocationState;
    if (!authUser.hasAddress) {
      return history.push(`/onboarding/your-location`, state);
    } else {
      return history.push(`/onboarding/stay-safe`, state);
    }
  };

  return (
    <AuthPageLayout onClose={() => history.push(mapUrl())}>
      <TextWrapper>
        <h2>Please verify your contact info</h2>
        <p>
          Just in case MakeSoil ever needs to contact you about your
          participation in this soil site.
        </p>
      </TextWrapper>

      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={submitForm}
        validateOnMount
      >
        {({ isSubmitting, isValid }) => {
          return (
            <FormContainer>
              <Form>
                <FormControl>
                  <label style={{ textAlign: 'left' }}>Phone Number *</label>
                  <Field
                    type="tel"
                    name="phoneNum"
                    as={Input}
                    style={{ marginTop: '0.5rem' }}
                  />
                </FormControl>

                <h4
                  style={{
                    marginTop: '1.5rem',
                    fontSize: '0.95rem',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: '#e1e1e1',
                  }}
                >
                  Social Media (optional)
                </h4>

                <FormGroup
                  style={{
                    gap: '1rem',
                  }}
                >
                  <Field
                    type="text"
                    name="instagram"
                    as={Input}
                    placeholder="Instagram handle"
                    style={{ marginTop: '0.5rem' }}
                  />

                  <FormControl>
                    <Field
                      type="text"
                      name="twitter"
                      as={Input}
                      placeholder="X (Twitter) handle"
                    />
                  </FormControl>

                  <FormControl>
                    <Field
                      type="text"
                      name="facebook"
                      as={Input}
                      placeholder="Facebook URL"
                    />
                  </FormControl>

                  <FormControl>
                    <Field
                      type="text"
                      name="linkedin"
                      as={Input}
                      placeholder="LinkedIn URL"
                    />
                  </FormControl>

                  <StyledErrorMessage
                    name="phoneNum"
                    component={FormInputError}
                  />
                </FormGroup>

                <Flex direction="column" style={{ marginTop: '1.5rem' }}>
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                  >
                    CONTINUE
                  </Button>
                </Flex>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </AuthPageLayout>
  );
};

export default withRouter(VerifyContactInfo);

const FormContainer = styled.div`
  h4 {
    margin: 0;
    color: #616161;
    font-weight: bold;
  }
`;

const FormGroup = styled.div`
  flex-direction: column;

  div {
    flex: 1;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
`;

const TextWrapper = styled.div`
  padding: 0 1rem;
`;
