import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { PasswordInput } from '../../components/ui/forms/PasswordInput';
import { Button, Flex, FormInputError } from '../../components/ui';
import { Meta } from '../../components/Meta';
import { ErrorMessage, Form, Formik } from 'formik';
import styled from 'styled-components';
import { SiteOnboardLocationState } from './model';
import { useAuthUser } from '../auth/useAuthUser';
import { additionalContactInfoRequired } from '../auth/utils';
import { AuthPageLayout } from '../../pages';
import { SecondaryButton } from '../../pages/auth/AuthPageLayout';

const CREATE_PASSWORD = gql`
  mutation SetPassword($password: String!) {
    setPassword(password: $password) {
      message
      errors
    }
  }
`;

type CreatePasswordFormData = {
  password: string;
};

export default function CreatePassword() {
  const history = useHistory();
  const authUser = useAuthUser();

  const [setPassword] = useMutation(CREATE_PASSWORD);

  const nextStep = () => {
    if (history.location.state) {
      const state = history.location.state as SiteOnboardLocationState;

      if (state.action === 'create') {
        return history.push(`/create-soil-site`, state);
      }

      // join the site onboarding flow
      if (!authUser.firstName) {
        return history.push(`/onboarding/your-name`, state);
      } else if (additionalContactInfoRequired(authUser, state.action)) {
        return history.push(`/onboarding/verify-contact-info`, state);
      } else {
        return history.push(`/onboarding/stay-safe`, state);
      }
    }

    return history.push('/onboarding/choose-your-role');
  };

  const handleSubmit = async (
    { password }: CreatePasswordFormData,
    { setSubmitting }: any
  ) => {
    await setPassword({ variables: { password } });
    setSubmitting(false);
    nextStep();
  };

  return (
    <AuthPageLayout onClose={() => history.push('/map')}>
      <Meta>
        <title>Create Password | MakeSoil</title>
      </Meta>

      <Container>
        <h2>Create your password</h2>

        <p style={{ margin: '1rem 1rem 0 1rem' }}>
          You can easily change this later.
        </p>
      </Container>

      <Formik
        initialValues={{ password: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form>
            <PasswordInput
              placeholder="Create password"
              name="password"
              autoFocus={true}
            />
            <ErrorMessage
              name="password"
              component={FormInputError}
              render={(props) => {
                return (
                  <div
                    style={{
                      color: 'red',
                      marginTop: '0.5rem',
                      textAlign: 'left',
                    }}
                  >
                    <span>Password must have at least:</span>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{props}</div>
                  </div>
                );
              }}
            />

            <Flex direction="column" gap="1rem" style={{ marginTop: '1.5rem' }}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                CREATE PASSWORD
              </Button>

              <SecondaryButton onClick={nextStep}>
                I'LL DO IT LATER
              </SecondaryButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </AuthPageLayout>
  );
}

const Container = styled.div`
  padding: 0 1rem 2rem;
`;
