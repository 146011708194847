import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { fetchMe } from '../features/auth/authSlice';
import { useAuthUser } from '../features/auth/useAuthUser';

/**
 * Fetches additional info about the current user
 */
export const useMeQuery = () => {
  const dispatch = useAppDispatch();
  const authUser = useAuthUser();

  useEffect(() => {
    (async function getMe() {
      if (authUser.isLoggedIn) {
        dispatch(fetchMe(authUser.id));
      }
    })();
  }, [authUser.id, authUser.isLoggedIn, dispatch]);
};
