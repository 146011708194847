import { gql } from '@apollo/client';

export const FIND_SOIL_SITES = gql`
  query SearchSoilSites($keywords: String!) {
    findSites(keywords: $keywords) {
      id
      name
      picture
      location {
        lat
        lng
      }
      createdAt
      active
      isUnlisted
    }
  }
`;

export const FETCH_PARTICIPATION_INFO = gql`
  query SiteParticipationInfo($id: Int!) {
    siteParticipationInfo(id: $id) {
      siteId
      participants {
        id
        email
        firstName
        lastName
        picture
        phoneNum
        facebook
        twitter
        instagram
        role {
          name
        }
        orphaned
      }
      usersRequestingAccess {
        id
        email
        firstName
        lastName
        picture
        phoneNum
        facebook
        twitter
        instagram
        createdAt
        orphaned
      }
    }
  }
`;

export const UPDATE_ACTIVE_STATUS = gql`
  mutation UpdateActiveStatus($id: Int!, $active: Boolean!) {
    updateSiteActiveStatus(input: { id: $id, active: $active }) {
      soilSite {
        id
        active
      }
      message
      errors
    }
  }
`;

export const UPDATE_SITE_VISIBILITY = gql`
  mutation UpdateVisibility($id: Int!, $isUnlisted: Boolean!) {
    updateSiteVisbility(input: { id: $id, isUnlisted: $isUnlisted }) {
      soilSite {
        id
        isUnlisted
      }
      message
      errors
    }
  }
`;
